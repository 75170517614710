import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as commissionerActionTypes from '../commissionerActionTypes';

const loadCommissioner = function* loadCommissioner({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {match} = payload;
    const {commissionerId} = match.params;

    yield fork(
        fetchRequest,
        commissionerActionTypes.FETCH_COMMISSIONER_REQUEST,
        partnerManagementService.getACEPartner,
        {
            acePartnerId: commissionerId,
        },
    );

    const responseAction = yield take([
        commissionerActionTypes.FETCH_COMMISSIONER_REQUEST_FAILED,
        commissionerActionTypes.FETCH_COMMISSIONER_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTO: commissionerDTO} = response;

        yield put({
            type: commissionerActionTypes.STORE_COMMISSIONERS,
            payload: {commissionerDTOs: [commissionerDTO]},
        });
    }
};

export default loadCommissioner;
