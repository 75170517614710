import {produce} from 'immer';
import {ACEPartner, RentalCarPricing, efAdditionalServiceRequestedTypes} from '@ace-de/eua-entity-types';
import * as serviceProviderActionTypes from './serviceProviderActionTypes';

const initialState = {
    serviceProviderSearchResults: [],
    serviceProviderSearchResultsCount: 0,
    serviceProviders: {},
    emergencyLawyers: {},
    standbyTimesMap: new Map(),
    rentalCarAdditionalServicesMap: new Map(),
};

/**
 * Service provider reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const serviceProviderReducer = produce((draft, action) => {
    switch (action.type) {
        case serviceProviderActionTypes.STORE_SERVICE_PROVIDER_SEARCH_RESULTS: {
            const {serviceProviderSearchResults, serviceProviderSearchResultsCount} = action.payload;
            draft.serviceProviderSearchResults = serviceProviderSearchResults.map(serviceProviderDTO => {
                return new ACEPartner().fromDTO(serviceProviderDTO);
            });
            draft.serviceProviderSearchResultsCount = typeof serviceProviderSearchResultsCount === 'number'
                ? serviceProviderSearchResultsCount : 0;
            break;
        }

        case serviceProviderActionTypes.STORE_SERVICE_PROVIDERS: {
            const {serviceProviderDTOs} = action.payload;
            serviceProviderDTOs.forEach(serviceProviderDTO => {
                const serviceProvider = draft.serviceProviders[serviceProviderDTO.id];
                if (!serviceProvider) {
                    const newServiceProvider = new ACEPartner().fromDTO(serviceProviderDTO);
                    draft.serviceProviders[newServiceProvider.id] = newServiceProvider;
                    return;
                }
                draft.serviceProviders[serviceProviderDTO.id] = serviceProvider.fromDTO(serviceProviderDTO);
            });
            break;
        }

        case serviceProviderActionTypes.STORE_EMERGENCY_LAWYERS: {
            const {emergencyLawyerDTOs} = action.payload;
            draft.standbyTimesMap = new Map();
            emergencyLawyerDTOs.forEach(emergencyLawyerDTO => {
                const emergencyLawyer = draft.emergencyLawyers[emergencyLawyerDTO.id];
                if (!emergencyLawyer) {
                    const newEmergencyLawyer = new ACEPartner().fromDTO(emergencyLawyerDTO);
                    draft.emergencyLawyers[newEmergencyLawyer.id] = newEmergencyLawyer;
                    if (newEmergencyLawyer.legalAdvice?.standByTimes) {
                        newEmergencyLawyer.legalAdvice.standByTimes.forEach(standbyTime => {
                            draft.standbyTimesMap.set(standbyTime.id, {
                                ...standbyTime,
                                serviceProviderId: newEmergencyLawyer.id,
                            });
                        });
                    }
                    return;
                }
                const updatedEmergencyLawyer = emergencyLawyer.fromDTO(emergencyLawyerDTO);
                draft.emergencyLawyers[emergencyLawyerDTO.id] = updatedEmergencyLawyer;
                if (updatedEmergencyLawyer.legalAdvice?.standByTimes) {
                    updatedEmergencyLawyer.legalAdvice.standByTimes.forEach(standbyTime => {
                        draft.standbyTimesMap.set(standbyTime.id, {
                            ...standbyTime,
                            serviceProviderId: updatedEmergencyLawyer.id,
                        });
                    });
                }
            });
            break;
        }

        case serviceProviderActionTypes.STORE_SERVICE_PROVIDER_RENTAL_CAR_PRICING: {
            const {serviceProviderId, rentalCarPricingDTOs, rentalCarPricingId} = action.payload;
            const serviceProvider = draft.serviceProviders[serviceProviderId];
            if (!serviceProvider || !rentalCarPricingDTOs) return;

            if (rentalCarPricingId) {
                const rentalCarPricingDTO = rentalCarPricingDTOs[0];
                if (!rentalCarPricingDTO) return;
                const index = serviceProvider.rentalCarPricing.findIndex(pricing => pricing.id === rentalCarPricingId);
                if (index >= 0) {
                    // update existing SP's rental car pricing data
                    serviceProvider.rentalCarPricing[index] = new RentalCarPricing().fromDTO(rentalCarPricingDTO);
                    if (rentalCarPricingDTO.additionalService) {
                        draft.rentalCarAdditionalServicesMap.set(
                            rentalCarPricingDTO.additionalService,
                            new RentalCarPricing().fromDTO(rentalCarPricingDTO),
                        );
                    }
                    return;
                }
                // create new SP's rental car pricing data
                serviceProvider.rentalCarPricing.push(new RentalCarPricing().fromDTO(rentalCarPricingDTO));
                if (rentalCarPricingDTO.additionalService) {
                    draft.rentalCarAdditionalServicesMap.set(
                        rentalCarPricingDTO.additionalService,
                        new RentalCarPricing().fromDTO(rentalCarPricingDTO),
                    );
                }
                return;
            }

            // fetch SP's rental car pricing
            serviceProvider.rentalCarPricing = rentalCarPricingDTOs.map(rentalCarPricingDTO => {
                if (rentalCarPricingDTO.additionalService) {
                    draft.rentalCarAdditionalServicesMap.set(
                        rentalCarPricingDTO.additionalService,
                        new RentalCarPricing().fromDTO(rentalCarPricingDTO),
                    );
                }
                return new RentalCarPricing().fromDTO(rentalCarPricingDTO);
            });
            break;
        }

        case serviceProviderActionTypes.REMOVE_SP_RENTAL_CAR_PRICING: {
            const {rentalCarPricingId, serviceProviderId} = action.payload;
            const serviceProvider = draft.serviceProviders[serviceProviderId];

            if (!serviceProvider) return;

            const index = serviceProvider.rentalCarPricing?.findIndex(pricing => pricing.id === rentalCarPricingId);
            if (index >= 0 && serviceProvider.rentalCarPricing[index]) {
                serviceProvider.rentalCarPricing[index].deleted = true;
            }
            break;
        }

        case serviceProviderActionTypes.SET_RENTAL_CAR_ADDITIONAL_SERVICES_MAP: {
            const additionalServices = Object.values(efAdditionalServiceRequestedTypes).filter(additionalService => {
                return additionalService !== efAdditionalServiceRequestedTypes.OTHER
                    && additionalService !== efAdditionalServiceRequestedTypes.ONE_WAY_TAX;
            });
            additionalServices.forEach(additionalService => (
                draft.rentalCarAdditionalServicesMap.set(additionalService, null)
            ));
            break;
        }

        case serviceProviderActionTypes.STORE_ESTIMATION_ACTIVE_STATUS: {
            const {isActive, serviceProviderId} = action.payload;

            if (!draft.serviceProviders[serviceProviderId]) return;

            draft.serviceProviders[serviceProviderId].isEstimationActive = isActive;
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default serviceProviderReducer;
