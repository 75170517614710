import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as commissionerActionTypes from '../commissionerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import successMessageTypes from '../../application/successMessageTypes';
import errorTypes from '../../application/errorTypes';

/**
 * Commissioner create flow (uses modal)
 * creates a new commissioner on the APM
 */
const createCommissionerFlow = function* createCommissionerFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let chosenModalOption;
    let shouldRetryCommissionerCreation = false;
    let commissionerCreationData;

    while (true) {
        if (shouldWaitForAction) {
            yield take(commissionerActionTypes.INITIATE_CREATE_COMMISSIONER_FLOW);

            yield* openModal(modalIds.CREATE_COMMISSIONER);

            chosenModalOption = yield take([
                commissionerActionTypes.CONFIRM_CREATE_COMMISSIONER,
                commissionerActionTypes.DECLINE_CREATE_COMMISSIONER,
            ]);
        }


        if ((chosenModalOption
            && chosenModalOption.type === commissionerActionTypes.CONFIRM_CREATE_COMMISSIONER)
        || shouldRetryCommissionerCreation) {
            const commissionerData = shouldRetryCommissionerCreation
                ? commissionerCreationData
                : chosenModalOption.payload;

            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                commissionerActionTypes.CREATE_COMMISSIONER_REQUEST,
                partnerManagementService.createACEPartner,
                {
                    acePartnerDTO: ACEPartner.objectToDTO(commissionerData),
                },
            );
            const responseAction = yield take([
                commissionerActionTypes.CREATE_COMMISSIONER_REQUEST_SUCCEEDED,
                commissionerActionTypes.CREATE_COMMISSIONER_REQUEST_FAILED,
            ]);

            yield* closeModal(modalIds.CREATE_COMMISSIONER);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {acePartnerDTO: commissionerDTO} = response;

                shouldWaitForAction = true;
                shouldRetryCommissionerCreation = false;
                commissionerCreationData = null;

                if (retryActionPersistenceState === persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }

                yield put({
                    type: commissionerActionTypes.STORE_COMMISSIONERS,
                    payload: {commissionerDTOs: [commissionerDTO]},
                });

                yield put({
                    type: applicationActionTypes.INITIATE_SUCCESS_MESSAGE_FLOW,
                    payload: {successMessageType: successMessageTypes.COMMISSIONER_CREATED},
                });

                continue;
            }
            if (responseAction.error) {
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.COMMISSIONER_CREATION_FAILED});

                const nextAction = yield take([
                    commissionerActionTypes.RETRY_COMMISSIONER_CREATION,
                    commissionerActionTypes.CANCEL_COMMISSIONER_CREATION,
                ]);

                if (nextAction.type === commissionerActionTypes.RETRY_COMMISSIONER_CREATION) {
                    shouldWaitForAction = false;
                    shouldRetryCommissionerCreation = true;
                    commissionerCreationData = commissionerData;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    continue;
                }
                shouldWaitForAction = true;
                shouldRetryCommissionerCreation = false;
                commissionerCreationData = null;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorTypes: ''});

                if (retryActionPersistenceState === persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
                continue;
            }
        }

        yield* closeModal(modalIds.CREATE_COMMISSIONER);
    }
};

export default createCommissionerFlow;
