/**
 * Command action types
 */
export const INITIATE_CREATE_TIME_PERIOD_FLOW = '[budgets] INITIATE_CREATE_TIME_PERIOD_FLOW';
export const CONFIRM_CREATE_TIME_PERIOD = '[budgets] CONFIRM_CREATE_TIME_PERIOD';
export const DECLINE_CREATE_TIME_PERIOD = '[budgets] DECLINE_CREATE_TIME_PERIOD';
export const INITIATE_UPDATE_TIME_PERIOD_FLOW = '[budgets] INITIATE_UPDATE_TIME_PERIOD_FLOW';
export const CONFIRM_UPDATE_BUDGETS = '[budgets] CONFIRM_UPDATE_BUDGETS';
export const DECLINE_UPDATE_BUDGETS = '[budgets] DECLINE_UPDATE_BUDGETS';
export const INITIATE_BUDGETS_UPDATE_FLOW = '[budgets] INITIATE_BUDGETS_UPDATE_FLOW';
export const INITIATE_FETCH_BUDGETS_BY_TIME_FLOW = '[budgets] INITIATE_FETCH_BUDGETS_BY_TIME_FLOW';

/**
 * Event action types
 */
export const CREATE_TIME_PERIOD_REQUEST = '[budgets] CREATE_TIME_PERIOD_REQUEST';
export const CREATE_TIME_PERIOD_REQUEST_SENT = '[budgets] CREATE_TIME_PERIOD_REQUEST_SENT';
export const CREATE_TIME_PERIOD_REQUEST_SUCCEEDED = '[budgets] CREATE_TIME_PERIOD_REQUEST_SUCCEEDED';
export const CREATE_TIME_PERIOD_REQUEST_FAILED = '[budgets] CREATE_TIME_PERIOD_REQUEST_FAILED';

export const UPDATE_TIME_PERIOD_REQUEST = '[budgets] UPDATE_TIME_PERIOD_REQUEST';
export const UPDATE_TIME_PERIOD_REQUEST_SENT = '[budgets] UPDATE_TIME_PERIOD_REQUEST_SENT';
export const UPDATE_TIME_PERIOD_REQUEST_SUCCEEDED = '[budgets] UPDATE_TIME_PERIOD_REQUEST_SUCCEEDED';
export const UPDATE_TIME_PERIOD_REQUEST_FAILED = '[budgets] UPDATE_TIME_PERIOD_REQUEST_FAILED';

export const FETCH_TIME_PERIODS_REQUEST = '[budgets] FETCH_TIME_PERIODS_REQUEST';
export const FETCH_TIME_PERIODS_REQUEST_SENT = '[budgets] FETCH_TIME_PERIODS_REQUEST_SENT';
export const FETCH_TIME_PERIODS_REQUEST_SUCCEEDED = '[budgets] FETCH_TIME_PERIODS_REQUEST_SUCCEEDED';
export const FETCH_TIME_PERIODS_REQUEST_FAILED = '[budgets] FETCH_TIME_PERIODS_REQUEST_FAILED';

export const FETCH_BUDGET_PERIOD_REQUEST = '[budgets] FETCH_BUDGET_PERIOD_REQUEST';
export const FETCH_BUDGET_PERIOD_REQUEST_SENT = '[budgets] FETCH_BUDGET_PERIOD_REQUEST_SENT';
export const FETCH_BUDGET_PERIOD_REQUEST_SUCCEEDED = '[budgets] FETCH_BUDGET_PERIOD_REQUEST_SUCCEEDED';
export const FETCH_BUDGET_PERIOD_REQUEST_FAILED = '[budgets] FETCH_BUDGET_PERIOD_REQUEST_FAILED';

export const UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST = '[budgets] UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST';
export const UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_SENT = '[budgets] UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_SENT';
export const UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_SUCCEEDED = '[budgets] UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_SUCCEEDED';
export const UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_FAILED = '[budgets] UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_UPDATED_PERIOD = '[budgets] STORE_UPDATED_PERIOD';
export const STORE_TIME_PERIODS = '[budgets] STORE_TIME_PERIODS';
export const SET_BUDGETS_PERSISTENCE_STATE = '[budgets] SET_BUDGETS_PERSISTENCE_STATE';
export const STORE_BUDGETS_BY_TIME = '[budgets] STORE_BUDGETS_BY_TIME';
export const RESET_BUDGETS_BY_TIME = '[budgets] RESET_BUDGETS_BY_TIME';
export const SET_TIME_PERIOD_BUDGETS_PERSISTENCE_STATE = '[budgets] SET_TIME_PERIOD_BUDGETS_PERSISTENCE_STATE';
