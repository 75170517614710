import {produce} from 'immer';
import {User} from '@ace-de/eua-entity-types';
import * as userProfileActionTypes from './userProfileActionTypes';

const initialState = {
    user: null,
    users: {},
    allowedShiftRoles: null,
    loggedInUsers: {},
    userSearchResults: [],
    userSearchResultsCount: 0,
    userProfiles: new Map(),
    lockedServiceCaseResults: new Map(),
};

const userReducer = produce((draft, action) => {
    const {payload, type} = action;
    switch (type) {
        case userProfileActionTypes.STORE_USER_PROFILE: {
            const {userDTO} = payload;
            draft.user = new User().fromDTO(userDTO);
            break;
        }

        case userProfileActionTypes.STORE_USER_SEARCH_RESULTS: {
            const {userSearchResults, userSearchResultsCount} = action.payload;
            draft.userSearchResults = userSearchResults.map(userDTO => {
                return new User().fromDTO(userDTO);
            });
            draft.userSearchResultsCount = userSearchResultsCount;
            break;
        }

        case userProfileActionTypes.STORE_USER_DETAILS: {
            const {userDTO} = payload;
            const user = new User().fromDTO(userDTO);
            draft.userProfiles.set(user.id, user);
            break;
        }

        case userProfileActionTypes.STORE_USER_PROFILES: {
            const {userDTOs} = payload;
            userDTOs.forEach(userDTO => {
                const userId = userDTO.id;
                const user = draft.users[userId];
                if (!user) {
                    draft.users[userId] = new User().fromDTO(userDTO);
                    return;
                }
                draft.users[userId] = user.fromDTO(userDTO);
            });
            break;
        }

        case userProfileActionTypes.SET_USER_ENABLED_STATE: {
            const {isEnabled, userId} = payload;
            const user = draft.userProfiles.get(userId);
            user.isEnabled = isEnabled;
            draft.userProfiles.set(userId, user);
            break;
        }

        case userProfileActionTypes.STORE_LOCKED_SERVICE_CASE_RESULTS: {
            const {lockedServiceCaseResults, userId} = action.payload;

            if (draft.lockedServiceCaseResults.has(userId)) draft.lockedServiceCaseResults.delete(userId);

            if (lockedServiceCaseResults.length) {
                lockedServiceCaseResults.forEach(lockedServiceCase => {
                    draft.lockedServiceCaseResults.has(lockedServiceCase.lockedBy)
                        ? draft.lockedServiceCaseResults.get(lockedServiceCase.lockedBy).push(lockedServiceCase)
                        : draft.lockedServiceCaseResults.set(lockedServiceCase.lockedBy, [lockedServiceCase]);
                });
            }
            break;
        }

        case userProfileActionTypes.SET_LOCKED_SERVICE_CASE_RESULT: {
            const {userId, serviceCaseId} = action.payload;
            const usersLockedServiceCases = draft.lockedServiceCaseResults.get(userId);
            if (!usersLockedServiceCases) return;
            const updatedServiceCases = Object.values(usersLockedServiceCases).filter(lockedServiceCase => {
                return lockedServiceCase.serviceCaseId !== serviceCaseId;
            });
            draft.lockedServiceCaseResults.set(userId, updatedServiceCases);
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default userReducer;
