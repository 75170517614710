import {produce} from 'immer';
import {ACEPartner} from '@ace-de/eua-entity-types';
import * as commissionerActionTypes from './commissionerActionTypes';

const initialState = {
    commissionerSearchResults: [],
    commissionerSearchResultsCount: 0,
    commissioners: {},
};

/**
 * Commissioner reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const commissionerReducer = produce((draft, action) => {
    switch (action.type) {
        case commissionerActionTypes.STORE_COMMISSIONER_SEARCH_RESULTS: {
            const {commissionerSearchResults, commissionerSearchResultsCount} = action.payload;
            draft.commissionerSearchResults = commissionerSearchResults.map(commissionerDTO => {
                return new ACEPartner().fromDTO(commissionerDTO);
            });
            draft.commissionerSearchResultsCount = typeof commissionerSearchResultsCount === 'number'
                ? commissionerSearchResultsCount : 0;
            break;
        }
        case commissionerActionTypes.STORE_COMMISSIONERS: {
            const {commissionerDTOs} = action.payload;
            commissionerDTOs.forEach(commissionerDTO => {
                const commissioner = draft.commissioners[commissionerDTO.id];
                if (!commissioner) {
                    const newCommissioner = new ACEPartner().fromDTO(commissionerDTO);
                    draft.commissioners[newCommissioner.id] = newCommissioner;
                    return;
                }
                draft.commissioners[commissionerDTO.id] = commissioner.fromDTO(commissionerDTO);
            });
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default commissionerReducer;
