import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as applicationActionTypes from '../applicationActionTypes';
import modalIds from '../../modalIds';

const errorMessageModalFlow = function* errorMessageModalFlow() {
    while (true) {
        const {payload} = yield take(applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW);
        const {errorType} = payload;

        yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType});

        yield take([
            applicationActionTypes.DECLINE_ERROR_MESSAGE_FLOW,
        ]);

        yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType});
    }
};

export default errorMessageModalFlow;
