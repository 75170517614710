import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {apmTemporaryRestrictionCategoryTypes} from '@ace-de/eua-entity-types';
import {Panel, useStyles, ButtonPrimary, NoResultsBlock, Paginator} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, plusIcon, findCaseIcon, InteractiveIcon, editIcon, noServiceIcon, waitingIcon, questionmarkIcon, otherIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as contractPartnersSelectors from './contractPartnerSelectors';
import config from '../config';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';

const categoryIconMap = {
    [apmTemporaryRestrictionCategoryTypes.RESTRICTED_SERVICES]: questionmarkIcon,
    [apmTemporaryRestrictionCategoryTypes.TEMPORARILY_CLOSED]: noServiceIcon,
    [apmTemporaryRestrictionCategoryTypes.WAITING_TIME]: waitingIcon,
    [apmTemporaryRestrictionCategoryTypes.OTHER]: otherIcon,
};

const filterPastTemporaryRestrictions = (temporaryRestrictions, page) => {
    if (temporaryRestrictions.length === 0) return [];
    const pageSize = config.TEMPORARY_RESTRICTIONS_DEFAULT_PAGE_SIZE;
    return temporaryRestrictions.filter((restriction, idx) => {
        return idx < pageSize * page && (idx >= (pageSize * page - pageSize));
    });
};

const ContractPartnerTemporaryRestrictionsTab = props => {
    const {cx} = useStyles();
    const {contractPartner} = props;
    const {pastTemporaryRestrictions, activeTemporaryRestrictions} = props;
    const {initiateEditTemporaryRestrictionFlow, initiateCreateTemporaryRestrictionFlow} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_temporary_restrictions_tab');
    const pageCount = Math.ceil(pastTemporaryRestrictions.length / config.TEMPORARY_RESTRICTIONS_DEFAULT_PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(0);
    const [pastTemporaryRestrictionsInView, setPastTemporaryRestrictionsInView] = useState([]);

    const createTemporaryRestriction = () => {
        initiateCreateTemporaryRestrictionFlow({contractPartner});
    };

    useEffect(() => {
        setPastTemporaryRestrictionsInView(filterPastTemporaryRestrictions(pastTemporaryRestrictions, currentPage + 1));
    }, [pastTemporaryRestrictions, currentPage]);

    const handlePaginationPage = page => {
        setCurrentPage(page);
    };

    const handleEditTemporaryRestriction = temporaryRestrictionId => {
        initiateEditTemporaryRestrictionFlow({contractPartner, temporaryRestrictionId});
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Fragment>
            <Panel
                className={cx('ace-c-panel--full-bleed-content')}
                title={translateTab('panel_header.active_and_upcoming_restrictions')}
                actions={(
                    <ButtonPrimary onClick={createTemporaryRestriction}>
                        <Icon
                            icon={plusIcon}
                            className={cx([
                                'ace-c-icon--color-contrast',
                                'global!ace-u-margin--right-8',
                            ])}
                        />
                        {translateTab('button_label.create_temporary_restriction')}
                    </ButtonPrimary>
                )}
            >
                <Table qaIdent="cp-active-temporary-restrictions">
                    {activeTemporaryRestrictions.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateTab(`no_results.message`)}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="tr-start-date-time">
                                {translateTab('table_header.restriction_start_datetime')}
                            </TableCell>
                            <TableCell qaIdentPart="tr-end-date-time">
                                {translateTab('table_header.restriction_end_datetime')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="tr-category">
                                {translateTab('table_header.restriction_category')}
                            </TableCell>
                            <TableCell colSpan={5} qaIdentPart="tr-description">
                                {translateTab('table_header.description')}
                            </TableCell>
                            <TableCell qaIdentPart="tr-creator-name">
                                {translateTab('table_header.creator_name')}
                            </TableCell>
                            <TableCell qaIdentPart="tr-edit" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activeTemporaryRestrictions.length > 0
                            && activeTemporaryRestrictions.map(temporaryRestriction => {
                                return (
                                    <TableRow
                                        key={temporaryRestriction.id}
                                        qaIdentPart={temporaryRestriction.id}
                                    >
                                        <TableCell
                                            qaIdentPart="tr-start-date-time"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.restrictionStartDateTime
                                                ? moment(temporaryRestriction.restrictionStartDateTime).format('DD.MM.YYYY HH:mm')
                                                : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="tr-end-date-time"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.restrictionEndDateTime
                                                ? moment(temporaryRestriction.restrictionEndDateTime).format('DD.MM.YYYY HH:mm')
                                                : ''}
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            qaIdentPart="tr-category"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            <div
                                                className={cx([
                                                    'global!ace-u-inline-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-flex--justify-flex-start',
                                                ])}
                                            >
                                                <Icon
                                                    icon={categoryIconMap[temporaryRestriction.restrictionCategory]}
                                                    className={cx(['ace-c-icon--m', 'global!ace-u-margin--right-8'])}
                                                />
                                                <div>
                                                    {translate(`global.temporary_restriction_category.${snakeCase(temporaryRestriction.restrictionCategory)}`)}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            colSpan={5}
                                            qaIdentPart="tr-description"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.description}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="tr-creator-name"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.creatorName}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="tr-edit"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            <InteractiveIcon
                                                icon={editIcon}
                                                onClick={() => handleEditTemporaryRestriction(temporaryRestriction.id)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Panel>
            {pastTemporaryRestrictionsInView.length > 0 && (
                <Panel
                    className={cx('ace-c-panel--full-bleed-content')}
                    title={translateTab('panel_header.past_restrictions')}
                >
                    <Table qaIdent="cp-past-temporary-restrictions">
                        <TableHead>
                            <TableRow>
                                <TableCell qaIdentPart="tr-start-date-time">
                                    {translateTab('table_header.restriction_start_datetime')}
                                </TableCell>
                                <TableCell qaIdentPart="tr-end-date-time">
                                    {translateTab('table_header.restriction_end_datetime')}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="tr-category">
                                    {translateTab('table_header.restriction_category')}
                                </TableCell>
                                <TableCell colSpan={5} qaIdentPart="tr-description">
                                    {translateTab('table_header.description')}
                                </TableCell>
                                <TableCell qaIdentPart="tr-creator-name">
                                    {translateTab('table_header.creator_name')}
                                </TableCell>
                                <TableCell qaIdentPart="tr-edit" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pastTemporaryRestrictionsInView.map(temporaryRestriction => {
                                return (
                                    <TableRow
                                        key={temporaryRestriction.id}
                                        qaIdentPart={temporaryRestriction.id}
                                    >
                                        <TableCell
                                            qaIdentPart="tr-start-date-time"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.restrictionStartDateTime
                                                ? moment(temporaryRestriction.restrictionStartDateTime).format('DD.MM.YYYY HH:mm')
                                                : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="tr-end-date-time"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.restrictionEndDateTime
                                                ? moment(temporaryRestriction.restrictionEndDateTime).format('DD.MM.YYYY HH:mm')
                                                : ''}
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            qaIdentPart="tr-category"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            <div
                                                className={cx([
                                                    'global!ace-u-inline-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-flex--justify-flex-start',
                                                ])}
                                            >
                                                <Icon
                                                    icon={categoryIconMap[temporaryRestriction.restrictionCategory]}
                                                    className={cx(['ace-c-icon--m', 'global!ace-u-margin--right-8'])}
                                                />
                                                <div>
                                                    {translate(
                                                        `global.temporary_restriction_category.${snakeCase(temporaryRestriction.restrictionCategory)}`,
                                                    )}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            colSpan={5}
                                            qaIdentPart="tr-description"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.description}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="tr-creator-name"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            {temporaryRestriction.creatorName}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="tr-edit"
                                            qaIdentPartPostfix={temporaryRestriction.id}
                                        >
                                            <InteractiveIcon
                                                icon={editIcon}
                                                onClick={() => handleEditTemporaryRestriction(temporaryRestriction.id)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Paginator
                        page={+currentPage}
                        count={pageCount}
                        onClick={handlePaginationPage}
                    />
                </Panel>
            )}
        </Fragment>
    );
};

ContractPartnerTemporaryRestrictionsTab.propTypes = {
    initiateCreateTemporaryRestrictionFlow: PropTypes.func.isRequired,
    initiateEditTemporaryRestrictionFlow: PropTypes.func.isRequired,
    contractPartner: PropTypes.object,
    activeTemporaryRestrictions: PropTypes.array,
    pastTemporaryRestrictions: PropTypes.array,
};

ContractPartnerTemporaryRestrictionsTab.defaultProps = {
    contractPartner: null,
    activeTemporaryRestrictions: [],
    pastTemporaryRestrictions: [],
};

const mapStateToProps = (state, props) => {
    const getContractPartner = contractPartnersSelectors.createContractPartnerSelector();
    const activeTemporaryRestrictionsSelector = contractPartnersSelectors
        .createActiveAndUpcomingTemporaryRestrictionsSelector();
    const pastTemporaryRestrictionsSelector = contractPartnersSelectors.createPastTemporaryRestrictionsSelector();

    return {
        contractPartner: getContractPartner(state, props),
        activeTemporaryRestrictions: activeTemporaryRestrictionsSelector(state, props),
        pastTemporaryRestrictions: pastTemporaryRestrictionsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateTemporaryRestrictionFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW,
        payload,
    }),
    initiateEditTemporaryRestrictionFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_UPDATE_TEMPORARY_RESTRICTION_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerTemporaryRestrictionsTab));
