import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useStyles, Modal, Divider, InputField, ButtonPrimary, CheckboxGroup} from '@ace-de/ui-components';
import {Icon, closeIcon, InteractiveIcon, timeIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {Form, TextAreaField, Checkbox} from '@ace-de/ui-components/form';
import {apmContractPartnerAssignmentChannelTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../../config';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import {validateEmail} from '../../utils/validation';

const validateFax = fax => {
    const re = /^([+])[0-9]{8,15}$/;
    return re.test(fax);
};

const EditAssignmentChannelsModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, contractPartner} = props;
    const {declineContractPartnerAssignmentChannelsUpdate, confirmContractPartnerAssignmentChannelsUpdate} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_assignment_channels_modal');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isFaxValid, setIsFaxValid] = useState(true);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isFaxEmpty, setIsFaxEmpty] = useState(false);
    const [assignmentFormData, setAssignmentFormData] = useState({
        assignmentChannelsRequested: contractPartner?.assignmentChannels || [],
        assignmentEmail: contractPartner?.assignmentEmail
            ? contractPartner.assignmentEmail : '',
        assignmentFaxNo: contractPartner?.assignmentFaxNo
            ? contractPartner.assignmentFaxNo : '',
        assignmentChannelsComment: contractPartner.assignmentChannelsComment || '-',
    });

    const handleCloseModal = () => {
        declineContractPartnerAssignmentChannelsUpdate();
    };

    const handleOnChange = formValues => {
        setAssignmentFormData({
            ...formValues,
            ...({
                assignmentFaxNo: formValues.assignmentChannelsRequested
                    .includes(apmContractPartnerAssignmentChannelTypes.FAX)
                    ? formValues.assignmentFaxNo : '',
            }),
        });
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;
        let hasError = false;

        // if the assignmentEmail is not valid or none of the channels are selected, do NOT send
        if (!validateEmail(formValues['assignmentEmail'])
            && formValues['assignmentChannelsRequested'].includes(apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML)
        ) {
            setIsEmailEmpty(formValues['assignmentEmail'] === '');
            setIsEmailValid(false);
            hasError = true;
        } else {
            setIsEmailValid(true);
            setIsEmailEmpty(false);
        }
        // if the assignmentFaxNo is not valid or none of the channels are selected, do NOT send
        if (!validateFax(formValues['assignmentFaxNo'])
            && formValues['assignmentChannelsRequested'].includes(apmContractPartnerAssignmentChannelTypes.FAX)
        ) {
            setIsFaxEmpty(formValues['assignmentFaxNo'] === '');
            setIsFaxValid(false);
            hasError = true;
        } else {
            setIsFaxValid(true);
            setIsFaxEmpty(false);
        }

        if (hasError) return;

        if (!formValues['assignmentChannelsRequested']?.length) return;

        const contractPartnerData = {
            assignmentChannels: formValues.assignmentChannelsRequested,
            assignmentFaxNo: formValues.assignmentChannelsRequested.includes(apmContractPartnerAssignmentChannelTypes.FAX) ? formValues.assignmentFaxNo : '',
            assignmentEmail: formValues.assignmentEmail,
            assignmentChannelsComment: formValues.assignmentChannelsComment,
        };

        confirmContractPartnerAssignmentChannelsUpdate({contractPartnerId: contractPartner.id, contractPartnerData});
    };

    const has24h7EmergencyContact = useMemo(() => {
        if (!contractPartner?.emergencyContacts?.length) return false;
        return !!contractPartner.emergencyContacts.find(contact => contact.is24h7Emergency);
    }, [contractPartner?.emergencyContacts]);

    return (
        <Modal
            title={translateModal('header.title')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={handleCloseModal}
                />
            )}
            contentClassName={cx(['ace-c-modal__content--scrollable', 'global!ace-u-modal-content-size--s'])}
        >
            <Divider className={cx('global!ace-u-margin--24-0')} />
            <div className={cx('global!ace-u-full-width')}>
                <Form name="editAssignmentChannelsForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    {formValues => {
                        const isEmailChannelSelected = formValues['assignmentChannelsRequested']
                            && formValues['assignmentChannelsRequested']
                                .includes(apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML);
                        const isFaxChannelSelected = formValues['assignmentChannelsRequested']
                            && formValues['assignmentChannelsRequested']
                                .includes(apmContractPartnerAssignmentChannelTypes.FAX);

                        return (
                            <div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-margin--bottom-32',
                                    ])}
                                >
                                    <Icon
                                        icon={timeIcon}
                                        className={cx([
                                            'global!ace-u-margin--right-8',
                                            'ace-c-icon--xs',
                                        ])}
                                    />
                                    <p className={cx('global!ace-u-typography--variant-body')}>
                                        {translateModal('text.working_hours', {
                                            workingHours: '00:00 - 24:00',
                                        })}
                                    </p>
                                </div>
                                <CheckboxGroup
                                    name="assignmentChannelsRequested"
                                    value={assignmentFormData.assignmentChannelsRequested}
                                >
                                    <Checkbox
                                        key="assignmentDriverApp"
                                        name="assignmentDriverApp"
                                        value={apmContractPartnerAssignmentChannelTypes.DRIVER_APP}
                                        className={cx('global!ace-u-margin--bottom-32')}
                                    >
                                        {translateModal(snakeCase(apmContractPartnerAssignmentChannelTypes.DRIVER_APP))}
                                    </Checkbox>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--column-gap-8',
                                            'global!ace-u-margin--bottom-32',
                                        ])}
                                    >
                                        <Checkbox
                                            key="assignmentEmailLink"
                                            name="assignmentEmailLink"
                                            value={apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML}
                                            className={cx('global!ace-u-margin--top-24')}
                                        />
                                        <InputField
                                            name="assignmentEmail"
                                            label={translateModal('input_label.email')}
                                            value={assignmentFormData.assignmentEmail || ''}
                                            isDisabled={!isEmailChannelSelected}
                                            errors={!isEmailValid && isEmailChannelSelected
                                                ? isEmailEmpty
                                                    ? [translateModal('text.empty')]
                                                    : [translateModal('text.please_enter_valid_email')]
                                                : []}
                                            className={cx('global!ace-u-flex--basis-50')}
                                            errorClassName={cx('global!ace-u-margin--top-8')}
                                        />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--column-gap-8',
                                            'global!ace-u-margin--bottom-32',
                                        ])}
                                    >
                                        <Checkbox
                                            key="assignmentFax"
                                            name="assignmentFax"
                                            value={apmContractPartnerAssignmentChannelTypes.FAX}
                                            className={cx('global!ace-u-margin--top-24')}
                                        />
                                        <InputField
                                            name="assignmentFaxNo"
                                            label={translateModal('input_label.fax_no')}
                                            isDisabled={!isFaxChannelSelected}
                                            value={assignmentFormData.assignmentFaxNo}
                                            errors={!isFaxValid && isFaxChannelSelected
                                                ? isFaxEmpty
                                                    ? [translateModal('text.empty')]
                                                    : [translateModal('text.please_enter_valid_fax')]
                                                : []}
                                            className={cx('global!ace-u-flex--basis-50')}
                                            errorClassName={cx('global!ace-u-margin--top-8')}
                                        />
                                    </div>
                                    <Checkbox
                                        key="assignmentManualPhone"
                                        name="assignmentManualPhone"
                                        value={apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE}
                                        isDisabled={!has24h7EmergencyContact}
                                        className={cx(has24h7EmergencyContact
                                            ? 'global!ace-u-margin--bottom-32' : 'global!ace-u-margin--bottom-8')}
                                    >
                                        {translateModal('checkbox_label.call_additionally')}
                                    </Checkbox>
                                    {!has24h7EmergencyContact && (
                                        <div
                                            className={cx([
                                                'global!ace-u-typography--variant-body-bold',
                                                'global!ace-u-typography--color-warning',
                                                'global!ace-u-margin--bottom-32',
                                            ])}
                                        >
                                            {translateModal('pill_label.no_emergency_contact')}
                                        </div>
                                    )}
                                </CheckboxGroup>
                                <TextAreaField
                                    name="assignmentChannelsComment"
                                    label={translateModal('input_label.note')}
                                    value={contractPartner?.assignmentChannelsComment || ''}
                                    maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                    isResizable={false}
                                    className={cx(['global!ace-u-full-width', 'global!ace-u-margin--bottom-32'])}
                                />
                                {assignmentFormData.assignmentChannelsRequested
                                && assignmentFormData.assignmentChannelsRequested.length === 0
                                && (
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-row-reverse',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-margin--bottom-8',
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-typography--color-warning',
                                        ])}
                                    >
                                        {translateModal('error.necessary_channels_not_selected')}
                                        <Icon
                                            icon={infoAlertIcon}
                                            className={cx([
                                                'global!ace-u-margin--right-4',
                                                'ace-c-icon--s',
                                                'ace-c-icon--color-warning',
                                                'global!ace-u-flex--shrink-0',
                                            ])}
                                        />
                                    </div>
                                )}
                                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-flex-end')}>
                                    <ButtonPrimary
                                        name="submitCPAssignmentChannelData"
                                        type="submit"
                                        isDisabled={assignmentFormData.assignmentChannelsRequested
                                            && assignmentFormData.assignmentChannelsRequested.length === 0}
                                    >
                                        {translateModal('button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

EditAssignmentChannelsModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    contractPartner: PropTypes.object,
    declineContractPartnerAssignmentChannelsUpdate: PropTypes.func.isRequired,
    confirmContractPartnerAssignmentChannelsUpdate: PropTypes.func.isRequired,
};

EditAssignmentChannelsModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const getContractPartner = contractPartnerSelectors.createContractPartnerSelector();
    return {
        contractPartner: getContractPartner(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineContractPartnerAssignmentChannelsUpdate: payload => dispatch({
        type: contractPartnerActionTypes.DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE,
        payload,
    }),
    confirmContractPartnerAssignmentChannelsUpdate: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignmentChannelsModal);
