import React from 'react';
import {useStyles} from '@ace-de/ui-components';
import styles from './ACEAdminLogo.module.scss';

const ACEAdminLogo = React.forwardRef((props, ref) => {
    const {cx} = useStyles(props, styles);

    return (
        <div ref={ref} className={cx('ace-c-admin-logo')}>
            <span
                className={cx([
                    'global!ace-u-typography--variant-h3',
                    'global!ace-u-margin--right-4',
                ])}
            >
                ACE
            </span>
            <span
                className={cx([
                    'global!ace-u-typography--variant-subtitle',
                    'global!ace-u-typography--color-disabled',
                ])}
            >
                ADMIN
            </span>
        </div>
    );
});

ACEAdminLogo.displayName = 'ACEAdminLogo';

export default ACEAdminLogo;
