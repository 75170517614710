/**
 * Command action types
 */
export const SEARCH_CONTRACT_PARTNERS = '[contract-partners] SEARCH_CONTRACT_PARTNERS';
export const INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW = '[contract-partners] INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW';
export const CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE = '[contract-partners] CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE';
export const DECLINE_CONTRACT_PARTNER_STATUS_UPDATE = '[contract-partners] DECLINE_CONTRACT_PARTNER_STATUS_UPDATE';
export const SUBMIT_CP_CONTACT_DETAILS_FORM = '[contract-partners] SUBMIT_CP_CONTACT_DETAILS_FORM';
export const INITIATE_UPDATE_CP_LOCATION_DATA = '[contract-partners] INITIATE_UPDATE_CP_LOCATION_DATA';
export const SEARCH_CP_LOCATION_GEOLOCATION = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION';
export const INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW = '[contract-partners] INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW';
export const CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE = '[contract-partners] CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE';
export const DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE = '[contract-partners] DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE';
export const INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW = '[contract-partners] INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW';
export const CONFIRM_CREATE_TEMPORARY_RESTRICTION = '[contract-partners] CONFIRM_CREATE_TEMPORARY_RESTRICTION';
export const DECLINE_CREATE_TEMPORARY_RESTRICTION = '[contract-partners] DECLINE_CREATE_TEMPORARY_RESTRICTION';
export const INITIATE_UPDATE_TEMPORARY_RESTRICTION_FLOW = '[contract-partners] INITIATE_UPDATE_TEMPORARY_RESTRICTION_FLOW';
export const CONFIRM_UPDATE_TEMPORARY_RESTRICTION = '[contract-partners] CONFIRM_UPDATE_TEMPORARY_RESTRICTION';
export const DECLINE_UPDATE_TEMPORARY_RESTRICTION = '[contract-partners] DECLINE_UPDATE_TEMPORARY_RESTRICTION';
export const SEARCH_SERVICE_CASES = '[contract-partners] SEARCH_SERVICE_CASES';
export const INITIATE_CP_SERVICES_UPDATE = '[contract-partners] INITIATE_CP_SERVICES_UPDATE';
export const INITIATE_CREATE_CP_CONTACT_DATA_FLOW = '[contract-partners] INITIATE_CREATE_CP_CONTACT_DATA_FLOW';
export const CONFIRM_CREATE_CP_CONTACT_DATA = '[contract-partners] CONFIRM_CREATE_CP_CONTACT_DATA';
export const DECLINE_CREATE_CP_CONTACT_DATA = '[contract-partners] DECLINE_CREATE_CP_CONTACT_DATA';
export const INITIATE_DELETE_CP_CONTACT_DATA_FLOW = '[contract-partners] INITIATE_DELETE_CP_CONTACT_DATA_FLOW';
export const CONFIRM_DELETE_CP_CONTACT_DATA = '[contract-partners] CONFIRM_DELETE_CP_CONTACT_DATA';
export const DECLINE_DELETE_CP_CONTACT_DATA = '[contract-partners] DECLINE_DELETE_CP_CONTACT_DATA';
export const INITIATE_UPDATE_CP_CONTACT_DATA_FLOW = '[contract-partners] INITIATE_UPDATE_CP_CONTACT_DATA_FLOW';
export const CONFIRM_UPDATE_CP_CONTACT_DATA = '[contract-partners] CONFIRM_UPDATE_CP_CONTACT_DATA';
export const DECLINE_UPDATE_CP_CONTACT_DATA = '[contract-partners] DECLINE_UPDATE_CP_CONTACT_DATA';
export const INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP = '[contract-partners] INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP';
export const RETRY_CP_CONTACT_DATA_DELETE = '[contract-partners] RETRY_CP_CONTACT_DATA_DELETE';
export const CANCEL_CP_CONTACT_DATA_DELETE = '[contract-partners] CANCEL_CP_CONTACT_DATA_DELETE';
export const RETRY_CP_CONTACT_DATA_CREATE = '[contract-partners] RETRY_CP_CONTACT_DATA_CREATE';
export const CANCEL_CP_CONTACT_DATA_CREATE = '[contract-partners] CANCEL_CP_CONTACT_DATA_CREATE';
export const RETRY_CP_CONTACT_DATA_EDIT = '[contract-partners] RETRY_CP_CONTACT_DATA_EDIT';
export const CANCEL_CP_CONTACT_DATA_EDIT = '[contract-partners] CANCEL_CP_CONTACT_DATA_EDIT';
export const RETRY_UPDATE_CP_SERVICES = '[contract-partners] RETRY_UPDATE_CP_SERVICES';
export const CANCEL_UPDATE_CP_SERVICES = '[contract-partners] CANCEL_UPDATE_CP_SERVICES';
export const RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE = '[contract-partners] RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE';
export const CANCEL_CP_ASSIGNMENT_CHANNELS_UPDATE = '[contract-partners] CANCEL_CP_ASSIGNMENT_CHANNELS_UPDATE';
export const RETRY_CP_CONTACT_DETAILS_UPDATE = '[contract-partners] RETRY_CP_CONTACT_DETAILS_UPDATE';
export const CANCEL_CP_CONTACT_DETAILS_UPDATE = '[contract-partners] CANCEL_CP_CONTACT_DETAILS_UPDATE';
export const RETRY_CP_STATUS_UPDATE = '[contract-partners] RETRY_CP_STATUS_UPDATE';
export const CANCEL_CP_STATUS_UPDATE = '[contract-partners] CANCEL_CP_STATUS_UPDATE';

/**
 * Event action types
 */
export const SEARCH_CONTRACT_PARTNERS_REQUEST = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_SENT = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_SENT';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_FAILED = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNERS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST';
export const FETCH_CONTRACT_PARTNERS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNERS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST';
export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_FAILED';

export const UPDATE_CP_CONTACT_DETAILS_REQUEST = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST';
export const UPDATE_CP_CONTACT_DETAILS_REQUEST_SENT = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST_SENT';
export const UPDATE_CP_CONTACT_DETAILS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST_SUCCEEDED';
export const UPDATE_CP_CONTACT_DETAILS_REQUEST_FAILED = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST_FAILED';

export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SENT = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_FAILED = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const UPDATE_CP_LOCATION_REQUEST = '[contract-partners] UPDATE_CP_LOCATION_REQUEST';
export const UPDATE_CP_LOCATION_REQUEST_SENT = '[contract-partners] UPDATE_CP_LOCATION_REQUEST_SENT';
export const UPDATE_CP_LOCATION_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CP_LOCATION_REQUEST_SUCCEEDED';
export const UPDATE_CP_LOCATION_REQUEST_FAILED = '[contract-partners] UPDATE_CP_LOCATION_REQUEST_FAILED';

export const FILTER_CP_SERVICE_AREAS_REQUEST = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST';
export const FILTER_CP_SERVICE_AREAS_REQUEST_SENT = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST_SENT';
export const FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED';
export const FILTER_CP_SERVICE_AREAS_REQUEST_FAILED = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST_FAILED';

export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST';
export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SENT = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SENT';
export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED';
export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_FAILED = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST';
export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED';

export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST';
export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT';
export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED';
export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED';

export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST = '[contract-partners] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST';
export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT = '[contract-partners] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT';
export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED = '[contract-partners] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED';
export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED = '[contract-partners] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SENT = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED';

export const SEARCH_SERVICE_CASES_REQUEST = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST';
export const SEARCH_SERVICE_CASES_REQUEST_SENT = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST_SENT';
export const SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_CASES_REQUEST_FAILED = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST_FAILED';

export const JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST = '[contract-partners] JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST';
export const JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SENT = '[contract-partners] JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SENT';
export const JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SUCCEEDED = '[contract-partners] JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SUCCEEDED';
export const JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_FAILED = '[contract-partners] JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_FAILED';

export const CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST = '[contract-partners] CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST';
export const CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_SENT = '[contract-partners] CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_SENT';
export const CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_SUCCEEDED';
export const CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_FAILED = '[contract-partners] CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_FAILED';

export const FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST = '[contract-partners] FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST';
export const FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_SENT = '[contract-partners] FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_SENT';
export const FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_SUCCEEDED = '[contract-partners] FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_SUCCEEDED';
export const FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_FAILED = '[contract-partners] FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST';
export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_FAILED';

export const CREATE_CP_CONTACT_DATA_REQUEST = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST';
export const CREATE_CP_CONTACT_DATA_REQUEST_SENT = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST_SENT';
export const CREATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED';
export const CREATE_CP_CONTACT_DATA_REQUEST_FAILED = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST_FAILED';

export const DELETE_CP_CONTACT_DATA_REQUEST = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST';
export const DELETE_CP_CONTACT_DATA_REQUEST_SENT = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST_SENT';
export const DELETE_CP_CONTACT_DATA_REQUEST_SUCCEEDED = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST_SUCCEEDED';
export const DELETE_CP_CONTACT_DATA_REQUEST_FAILED = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST_FAILED';

export const UPDATE_CP_CONTACT_DATA_REQUEST = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST';
export const UPDATE_CP_CONTACT_DATA_REQUEST_SENT = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST_SENT';
export const UPDATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED';
export const UPDATE_CP_CONTACT_DATA_REQUEST_FAILED = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST_FAILED';

export const FETCH_ACE_COMMISSIONERS_REQUEST = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST';
export const FETCH_ACE_COMMISSIONERS_REQUEST_SENT = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_SENT';
export const FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED';
export const FETCH_ACE_COMMISSIONERS_REQUEST_FAILED = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_FAILED';

export const DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST = '[contract-partners] DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST';
export const DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_SENT = '[contract-partners] DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_SENT';
export const DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_SUCCEEDED = '[contract-partners] DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_SUCCEEDED';
export const DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_FAILED = '[contract-partners] DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_CONTRACT_PARTNER_SEARCH_RESULTS = '[contract-partners] STORE_CONTRACT_PARTNER_SEARCH_RESULTS';
export const STORE_CONTRACT_PARTNERS = '[contract-partners] STORE_CONTRACT_PARTNERS';
export const SET_CP_LOCATION_RECOMMENDATIONS = '[contract-partners] SET_CP_LOCATION_RECOMMENDATIONS';
export const STORE_CP_TEMPORARY_RESTRICTIONS = '[contract-partners] STORE_CP_TEMPORARY_RESTRICTIONS';
export const STORE_SERVICE_CASE_SEARCH_RESULTS = '[contract-partners] STORE_SERVICE_CASE_SEARCH_RESULTS';
export const STORE_CP_CONTACT_DATA = '[contract-partners] STORE_CP_CONTACT_DATA';
export const REMOVE_CP_CONTACT_DATA = '[contract-partners] REMOVE_CP_CONTACT_DATA';
export const STORE_ACE_COMMISSIONERS = '[contract-partners] STORE_ACE_COMMISSIONERS';
export const STORE_ESTIMATION_ACTIVE_STATUS = '[contract-partners] STORE_ESTIMATION_ACTIVE_STATUS';
