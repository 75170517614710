import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Checkbox} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';

const UserRolesSection = ({roles, system, userDetailsData}) => {
    const {translate} = useTranslate();

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        {roles.map(userRole => (
                            <TableCell key={`${system}-${userRole}`}>
                                {translate(`global.user_role.${userRole.toLowerCase()}`)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {roles.map(userRole => (
                            <TableCell key={`${system}-${userRole}`}>
                                <Checkbox
                                    name={userRole}
                                    value={userRole}
                                    isDisabled={(userDetailsData.ecsClient?.includes(eupUserRoleTypes.VIEWER)
                                        && userRole !== eupUserRoleTypes.VIEWER
                                        && system === clientTypes.ECS_CLIENT)
                                        || (userDetailsData.leaClient?.includes(eupUserRoleTypes.LEA_VIEWER)
                                            && userRole === eupUserRoleTypes.AUDITOR)
                                        || (userDetailsData.leaClient?.includes(eupUserRoleTypes.AUDITOR)
                                            && userRole === eupUserRoleTypes.LEA_VIEWER)
                                    }
                                />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

UserRolesSection.propTypes = {
    roles: PropTypes.array,
    system: PropTypes.string,
    userDetailsData: PropTypes.object,
};

UserRolesSection.defaultProps = {
    roles: [],
    system: '',
    userDetailsData: {},
};

export default withRouter(UserRolesSection);
