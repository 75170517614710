import {select, take, fork, put} from 'redux-saga/effects';
import * as budgetActionTypes from '../budgetsActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const updateTimePeriodFlow = function* updateTimePeriodFlow({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {budgetTimePeriodId, budgetTimePeriodData} = payload;

    yield fork(
        fetchRequest,
        budgetActionTypes.UPDATE_TIME_PERIOD_REQUEST,
        pricingManagementService.updateBudgetTimePeriod,
        {
            budgetTimePeriodId,
            budgetTimePeriodData,
        },
    );

    const responseAction = yield take([
        budgetActionTypes.UPDATE_TIME_PERIOD_REQUEST_SUCCEEDED,
        budgetActionTypes.UPDATE_TIME_PERIOD_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {budgetTimePeriodDTO} = response;

        yield put({
            type: budgetActionTypes.STORE_UPDATED_PERIOD,
            payload: {budgetTimePeriodDTO},
        });
    }
    if (responseAction.error) {
        yield put({
            type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
            payload: {errorType: errorTypes.BUDGET_TIME_PERIOD_UPDATE_FAILED},
        });
    }
};

export default updateTimePeriodFlow;
