import {fork, select, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as budgetActionTypes from '../budgetsActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import errorTypes from '../../application/errorTypes';

const updateBudgetsByTimePeriod = function* updateBudgetsByTimePeriod({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {budgetPeriodId, budgets} = payload;

    yield fork(
        fetchRequest,
        budgetActionTypes.UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST,
        pricingManagementService.updateTimePeriodBudgets,
        {
            budgetPeriodData: {budgets},
        },
    );

    const responseAction = yield take([
        budgetActionTypes.UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_SUCCEEDED,
        budgetActionTypes.UPDATE_BUDGETS_BY_TIME_PERIOD_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        yield put({
            type: budgetActionTypes.INITIATE_FETCH_BUDGETS_BY_TIME_FLOW,
            payload: {budgetPeriodId},
        });
    }

    if (responseAction.error) {
        yield put({
            type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
            payload: {errorType: errorTypes.UPDATE_BUDGETS_BY_TIME_UPDATE_FAILED},
        });
    }
};

export default updateBudgetsByTimePeriod;
