import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import filterMapCPOperationAreasById from './filterMapCPOperationAreasById';

const loadContractPartnerOperationAreas = function* loadContractPartnerOperationAreas({payload}) {
    const {activeTab} = yield* selectSearchQueryParams();
    const {match} = payload;
    const {contractPartnerId} = match.params;

    if (activeTab !== contractPartnerScreenTabs.OPERATION_AREA) return;

    yield* filterMapCPOperationAreasById(contractPartnerId);
};

export default loadContractPartnerOperationAreas;
