import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {matchPath, renderRoutes, useSearchQueryParams} from '@computerrock/formation-router';
import {AppLayoutMain, NavigationBar} from '@ace-de/ui-components/app-layout';
import NavigationBarContent from './ui-elements/NavigationBarContent';
import routePaths from '../routePaths';
import BudgetTimePeriodsNavigationBar from './ui-elements/BudgetTimePeriodsNavigationBar';
import * as budgetActionTypes from '../budgets/budgetsActionTypes';

const DashboardSectionScreen = props => {
    const {route, isFeatureListSet, history} = props;
    const {initiateFetchPricesByTime} = props;
    const {budgetPeriodId} = useSearchQueryParams();

    useEffect(() => {
        if (budgetPeriodId) {
            initiateFetchPricesByTime({budgetPeriodId});
        }
    }, [initiateFetchPricesByTime, budgetPeriodId]);

    if (!isFeatureListSet) return null;

    const budgetsScreenMatch = matchPath(history.location.pathname, {
        path: routePaths.BUDGETS,
        exact: true,
    });

    return (
        <Fragment>
            <NavigationBar>
                <NavigationBarContent />
            </NavigationBar>
            {budgetsScreenMatch && <BudgetTimePeriodsNavigationBar />}
            <AppLayoutMain>
                {renderRoutes(route.routes)}
            </AppLayoutMain>
        </Fragment>
    );
};

DashboardSectionScreen.propTypes = {
    route: PropTypes.object.isRequired,
    isFeatureListSet: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    initiateFetchPricesByTime: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        isFeatureListSet: state.acSystemAccessReducer.isFeatureListSet,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateFetchPricesByTime: payload => dispatch({
        type: budgetActionTypes.INITIATE_FETCH_BUDGETS_BY_TIME_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSectionScreen);
