export const acFeatureActions = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
};

export const acFeatures = {
    USER_ACCOUNT: 'USER_ACCOUNT',
    USER_BASIC_DATA: 'USER_BASIC_DATA',
    USER_ROLES_ECS_CLIENT: 'USER_ROLES_ECS_CLIENT',
    USER_ROLES_VPM_CLIENT: 'USER_ROLES_VPM_CLIENT',
    USER_ROLES_AC_CLIENT: 'USER_ROLES_AC_CLIENT',
    USER_ROLES_LEA_CLIENT: 'USER_ROLES_LEA_CLIENT',
    USER_ACTIVE_STATUS: 'USER_ACTIVE_STATUS',
    ECS_SERVICE_CASES: 'ECS_SERVICE_CASES',
    SERVICE_PROVIDERS: 'SERVICE_PROVIDERS',
    COMMISSIONERS: 'COMMISSIONERS',
    CONTRACT_PARTNERS: 'CONTRACT_PARTNERS',
    COMMISSIONER_ACTIVE_STATUS: 'COMMISSIONER_ACTIVE_STATUS',
    SP_ACTIVE_STATUS: 'SP_ACTIVE_STATUS',
    VPM_TRANSLATIONS: 'VPM_TRANSLATIONS',
    ECS_TRANSLATIONS: 'ECS_TRANSLATIONS',
    AC_TRANSLATIONS: 'AC_TRANSLATIONS',
    LEA_TRANSLATIONS: 'LEA_TRANSLATIONS',
    STANDBY_TIMES: 'STANDBY_TIMES',
    INA_RULES: 'INA_RULES',
    FEES_AND_ESTIMATION: 'FEES_AND_ESTIMATION',
    BUDGETS: 'BUDGETS',
    // todo add other features
};
