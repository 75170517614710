import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmACEPartnerServiceTypes, LegalAdviceService} from '@ace-de/eua-entity-types';
import {useStyles, Modal, DateField, Option, SelectField, Form, ButtonPrimary, Divider} from '@ace-de/ui-components';
import {Icon, closeIcon, calendarIcon} from '@ace-de/ui-components/icons';
import * as serviceProviderSelectors from '../serviceProviderSelectors';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

const StandbyTimesDataModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, standbyTimesEntry, emergencyLawyers, standbyTimesMap} = props;
    const {declineStandbyTimesUpdate, declineStandbyTimesCreation} = props;
    const {confirmStandbyTimesUpdate, confirmStandbyTimesCreation} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('standby_times_data_modal');
    const sortedStandbyTimes = useMemo(() => (
        [...standbyTimesMap.values()].sort((standbyTimeA, standbyTimeB) => {
            return moment(standbyTimeA.endDate).isSameOrAfter(moment(standbyTimeB.endDate)) ? 1 : -1;
        })), [standbyTimesMap]);
    const lastStandbyTimeEntry = sortedStandbyTimes.pop();
    const [standbyTimesEntryData, setStandbyTimesEntryData] = useState({
        serviceProviderId: standbyTimesEntry?.serviceProviderId || '',
        startDate: standbyTimesEntry
            ? standbyTimesEntry?.startDate
            : (
                lastStandbyTimeEntry?.endDate
                    ? moment(lastStandbyTimeEntry.endDate).add(1, 'days').toISOString()
                    : moment().toISOString()
            ),
        endDate: standbyTimesEntry
            ? standbyTimesEntry?.endDate
            : (lastStandbyTimeEntry ? '' : moment().add(7, 'days').toISOString()),
    });

    const validateDates = (startDate, endDate) => {
        return moment(startDate).isValid() && moment(endDate).isValid()
            && moment(endDate).isAfter(moment(startDate))
            && moment().diff(startDate, 'days') <= 0;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;
        const {serviceProviderId} = formValues;
        const startDate = moment(formValues.startDate).format('YYYY-MM-DD');
        const endDate = moment(formValues.endDate).format('YYYY-MM-DD');
        const serviceProvider = emergencyLawyers[serviceProviderId];
        let doesServiceExist = false;

        const doesStandbyTimeEntryExist = standbyTimesEntry
            && serviceProvider.legalAdvice?.standByTimes?.find(standbyTime => standbyTime.id === standbyTimesEntry.id);

        // update only existing basic services, and keep all the other services
        const updatedServices = serviceProvider.services?.length
            ? serviceProvider.services.map(service => {
                if (service.serviceType === apmACEPartnerServiceTypes.LEGAL_ADVICE) {
                    doesServiceExist = true;
                    return {
                        ...service,
                        standByTimes: doesStandbyTimeEntryExist
                            ? serviceProvider.legalAdvice?.standByTimes?.map(standbyTime => {
                                if (standbyTime.id === standbyTimesEntry.id) {
                                    return {
                                        ...standbyTime,
                                        startDate: startDate,
                                        endDate: endDate,
                                    };
                                }
                                return standbyTime;
                            })
                            : [...serviceProvider.legalAdvice?.standByTimes, {
                                startDate: startDate,
                                endDate: endDate,
                            }],
                    };
                }
                return service;
            }) : [];

        // if service is not already present in services array, add it
        if (!doesServiceExist) {
            updatedServices.push({
                ...(new LegalAdviceService()),
                serviceType: apmACEPartnerServiceTypes.LEGAL_ADVICE,
                standByTimes: [{
                    startDate: startDate,
                    endDate: endDate,
                }],
            });
        }

        const servicesData = serviceProvider.services?.length
            ? updatedServices
            : [
                {
                    ...(new LegalAdviceService()),
                    serviceType: apmACEPartnerServiceTypes.LEGAL_ADVICE,
                    standByTimes: [{
                        startDate: startDate,
                        endDate: endDate,
                    }],
                },
            ];

        if (standbyTimesEntry) {
            const updatedServiceProviderData = {
                serviceProviderData: {
                    services: servicesData,
                },
                serviceProviderId,
            };

            // If SP is changed in editing mode, remove the old standbyTimes entry
            if (!doesStandbyTimeEntryExist) {
                const entryToBeRemoved = standbyTimesEntry && standbyTimesMap.get(standbyTimesEntry.id);
                const oldServiceProvider = emergencyLawyers[entryToBeRemoved.serviceProviderId];
                const servicesData = oldServiceProvider.services?.length
                    ? oldServiceProvider.services.map(service => {
                        if (service.serviceType === apmACEPartnerServiceTypes.LEGAL_ADVICE) {
                            return {
                                ...service,
                                standByTimes: oldServiceProvider?.legalAdvice?.standByTimes?.filter(standbyTime => (
                                    standbyTime.id !== entryToBeRemoved.id
                                )),
                            };
                        }
                        return service;
                    }) : [];

                const serviceProviderToBeRemoved = {
                    serviceProviderData: {
                        services: servicesData,
                    },
                    serviceProviderId: entryToBeRemoved.serviceProviderId,
                };
                confirmStandbyTimesUpdate({
                    serviceProviderToBeRemoved,
                    updatedServiceProviderData,
                });
                return;
            }

            confirmStandbyTimesUpdate({updatedServiceProviderData});
            return;
        }

        confirmStandbyTimesCreation({
            serviceProviderData: {
                services: servicesData,
            },
            serviceProviderId,
        });
    };

    const handleOnChange = formValues => {
        setStandbyTimesEntryData(formValues);
    };

    return (
        <Modal
            title={standbyTimesEntry
                ? translateModal('title.edit_standby_times')
                : translateModal('title.add_standby_times')}
            action={(
                <Icon
                    icon={closeIcon}
                    onClick={standbyTimesEntry ? declineStandbyTimesUpdate : declineStandbyTimesCreation}
                />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-margin--top-32')}
        >
            <Divider />
            <Form name="createStandbyTimesEntryForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column', 'global!ace-u-padding--32-0'])}>
                    <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--bottom-32'])}>
                        <DateField
                            name="startDate"
                            label={translateModal('date_field_label.start_date')}
                            className={cx('global!ace-u-grid-column--span-6')}
                            value={standbyTimesEntryData.startDate || ''}
                            icon={calendarIcon}
                            isFieldRequired={true}
                            format="DD.MM.YYYY"
                        />
                        <DateField
                            name="endDate"
                            label={translateModal('date_field_label.end_date')}
                            className={cx('global!ace-u-grid-column--span-6')}
                            value={standbyTimesEntryData.endDate || ''}
                            icon={calendarIcon}
                            isFieldRequired={true}
                            format="DD.MM.YYYY"
                        />
                    </div>
                    <div className={cx('global!ace-u-grid')}>
                        <SelectField
                            name="serviceProviderId"
                            label={translateModal('date_field_label.attorney')}
                            className={cx('global!ace-u-grid-column--span-12')}
                            value={standbyTimesEntryData.serviceProviderId || ''}
                            isFieldRequired={true}
                        >
                            {Object.values(emergencyLawyers).map((emergencyLawyer, idx) => (
                                <Option
                                    key={`${emergencyLawyer.id}-${idx}`}
                                    name={`emergencyLawyerOption-${emergencyLawyer.id}`}
                                    value={emergencyLawyer.id}
                                >
                                    {emergencyLawyer.name}
                                </Option>
                            ))}
                        </SelectField>
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <ButtonPrimary
                        name="submitStandbyTimesEntry"
                        type="submit"
                        className={cx('global!ace-u-margin--top-32')}
                        isDisabled={!validateDates(
                            standbyTimesEntryData.startDate,
                            standbyTimesEntryData.endDate,
                        ) || !standbyTimesEntryData.serviceProviderId}
                    >
                        {translateModal('button_label.save')}
                    </ButtonPrimary>
                </div>
            </Form>
        </Modal>
    );
};

StandbyTimesDataModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineStandbyTimesCreation: PropTypes.func.isRequired,
    confirmStandbyTimesCreation: PropTypes.func.isRequired,
    declineStandbyTimesUpdate: PropTypes.func.isRequired,
    confirmStandbyTimesUpdate: PropTypes.func.isRequired,
    emergencyLawyers: PropTypes.object,
    standbyTimesEntry: PropTypes.object,
    standbyTimesMap: PropTypes.object,
};

StandbyTimesDataModal.defaultProps = {
    hasBackdrop: true,
    emergencyLawyers: {},
    standbyTimesEntry: null,
    standbyTimesMap: null,
};

const mapStateToProps = (state, props) => {
    const standbyTimesEntrySelector = serviceProviderSelectors.createStandbyTimesEntrySelector();

    return {
        emergencyLawyers: serviceProviderSelectors.getEmergencyLawyers(state),
        standbyTimesEntry: standbyTimesEntrySelector(state, props),
        standbyTimesMap: serviceProviderSelectors.getStandbyTimesMap(state),
    };
};

const mapDispatchToProps = dispatch => ({
    declineStandbyTimesCreation: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_CREATE_STANDBY_TIMES_ENTRY,
    }),
    confirmStandbyTimesCreation: payload => dispatch({
        type: serviceProviderActionTypes.CONFIRM_CREATE_STANDBY_TIMES_ENTRY,
        payload,
    }),
    declineStandbyTimesUpdate: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_UPDATE_STANDBY_TIMES_ENTRY,
    }),
    confirmStandbyTimesUpdate: payload => dispatch({
        type: serviceProviderActionTypes.CONFIRM_UPDATE_STANDBY_TIMES_ENTRY,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandbyTimesDataModal);
