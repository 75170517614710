import {ampMemberStatusTypes} from '@ace-de/eua-entity-types';

const memberStatuses = [
    ampMemberStatusTypes.EMPTY,
    ampMemberStatusTypes.SILVER,
    ampMemberStatusTypes.GOLD,
    ampMemberStatusTypes.PLATINUM,
];

export default memberStatuses;
