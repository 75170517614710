import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, List, Divider, InteractiveIcon, Form, Checkbox, Input, ButtonPrimary, Button, CheckboxGroup} from '@ace-de/ui-components';
import {apmContractPartnerAdditionalServiceTypes} from '@ace-de/eua-entity-types';
import {Icon, checkmarkIcon, closeIcon, editIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';

const ContractPartnerAdditionalServicesPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_services_tab');
    const {contractPartner, submitAdditionalServicesForm} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [formData, setFormData] = useState({
        additionalServices: contractPartner?.additionalServices,
        brands: contractPartner?.brands,
    });

    const resetFormData = () => {
        setFormData({
            additionalServices: contractPartner?.additionalServices,
            brands: contractPartner?.brands,
        });
    };

    const toggleEditMode = () => {
        if (isEditModeActive) {
            resetFormData();
        }
        setIsEditModeActive(prevState => !prevState);
    };

    const handleOnChange = formValues => {
        setFormData({
            ...formValues,
            additionalServices: formValues.additionalServices,
            brands: formValues.brands,
        });
    };

    const handleOnSubmit = formValues => {
        const additionalServices = [...formValues.additionalServices];
        if (formValues.brands) {
            additionalServices.push(apmContractPartnerAdditionalServiceTypes.BRANDS);
        }

        submitAdditionalServicesForm({
            contractPartnerData: {
                additionalServices: additionalServices,
                brands: formValues.brands,
                partnerType: contractPartner.partnerType,
            },
            contractPartnerId: contractPartner.id,
        });

        setIsEditModeActive(false);
    };

    const handleOnDiscard = event => {
        event.preventDefault();
        resetFormData();
        toggleEditMode();
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    const {additionalServices, brands} = contractPartner;

    return (
        <Panel
            title={translateTab('panel_title.additional_services')}
            actions={(
                <InteractiveIcon
                    icon={!isEditModeActive
                        ? editIcon
                        : closeIcon
                    }
                    onClick={toggleEditMode}
                />
            )}
        >
            {!isEditModeActive
                ? (
                    <List>
                        {Object.values(apmContractPartnerAdditionalServiceTypes).map(additionalService => {
                            const hasCPAdditionalService = additionalServices.includes(additionalService);
                            return (
                                <Fragment key={additionalService}>
                                    <div
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-padding--16',
                                        ])}
                                    >
                                        {additionalService === apmContractPartnerAdditionalServiceTypes.BRANDS ? (
                                            <div className={cx('global!ace-u-typography--variant-body')}>
                                                <span>
                                                    {`${translate(`global.contract_partner_additional_services.${(additionalService).toLowerCase()}`)}: `}
                                                </span>
                                                <span>{brands || ''}</span>
                                            </div>
                                        ) : (
                                            <Fragment>
                                                <span className={cx('global!ace-u-typography--variant-body')}>
                                                    {translate(`global.contract_partner_additional_services.${(additionalService).toLowerCase()}`)}
                                                </span>
                                                <Icon
                                                    icon={hasCPAdditionalService ? checkmarkIcon : closeIcon}
                                                    className={cx(hasCPAdditionalService
                                                        ? 'ace-c-icon--color-success'
                                                        : 'ace-c-icon--color-warning')}
                                                />
                                            </Fragment>
                                        )}
                                    </div>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                    </List>
                ) : (
                    <Form name="additionalServicesForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                        <CheckboxGroup name="additionalServices" value={formData.additionalServices}>
                            {Object.values(apmContractPartnerAdditionalServiceTypes).map(additionalService => {
                                return (
                                    <Fragment key={additionalService}>
                                        <div
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--justify-space-between',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-padding--16',
                                            ])}
                                        >
                                            {additionalService === apmContractPartnerAdditionalServiceTypes.BRANDS ? (
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-center',
                                                        'global!ace-u-typography--variant-body',
                                                        'global!ace-u-full-width',
                                                    ])}
                                                >
                                                    <span className={cx('global!ace-u-margin--right-16')}>
                                                        {`${translate(`global.contract_partner_additional_services.${(additionalService).toLowerCase()}`)}: `}
                                                    </span>
                                                    <Input
                                                        name="brands"
                                                        value={formData.brands || ''}
                                                        className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                                        maxLength={config.MAXIMUM_INPUT_CONTENT_LENGTH}
                                                    />
                                                </div>
                                            ) : (
                                                <Fragment>
                                                    <span className={cx('global!ace-u-typography--variant-body')}>
                                                        {translate(`global.contract_partner_additional_services.${(additionalService).toLowerCase()}`)}
                                                    </span>
                                                    <Checkbox
                                                        key={`${additionalService}Key`}
                                                        name={additionalService}
                                                        value={additionalService}
                                                    />
                                                </Fragment>
                                            )}
                                        </div>
                                        <Divider />
                                    </Fragment>
                                );
                            })}
                        </CheckboxGroup>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-row',
                                'global!ace-u-flex--align-items-flex-end',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            <Button
                                name="discardAdditionalServicesData"
                                className={cx('global!ace-u-margin--right-24')}
                                onClick={handleOnDiscard}
                            >
                                {translateTab('button_label.discard')}
                            </Button>
                            <ButtonPrimary
                                name="submitAdditionalServicesData"
                                type="submit"
                            >
                                {translateTab('button_label.save')}
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}

        </Panel>
    );
};

ContractPartnerAdditionalServicesPanel.propTypes = {
    contractPartner: PropTypes.object,
    submitAdditionalServicesForm: PropTypes.func.isRequired,
};

ContractPartnerAdditionalServicesPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitAdditionalServicesForm: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_SERVICES_UPDATE,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerAdditionalServicesPanel));
