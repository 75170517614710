import {put, select, take, fork} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import * as feesAndEstimationActionTypes from '../../fees-and-estimation/feesAndEstimationActionTypes';
import config from '../../config';

const loadContractPartner = function* loadContractPartner({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {activeTab} = yield* selectSearchQueryParams();
    const {match, location} = payload;
    const {contractPartnerId} = match.params;

    // on the temporary restriction tab, do not reload contract partner if TR modal is open/closed
    if (activeTab === contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS
        && (location.query?.modal || location.state?.isModalClosed)
    ) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagementService.getACEPartner,
        {
            acePartnerId: contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {acePartnerDTO: contractPartnerDTO} = response;

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
        payload: {contractPartnerDTOs: [contractPartnerDTO]},
    });

    if (contractPartnerDTO?.address && activeTab === contractPartnerScreenTabs.BASIC_DATA) {
        yield put({
            type: contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION,
            payload: {
                searchQueryString: [contractPartnerDTO['address'].street,
                    contractPartnerDTO['address'].postCode,
                    contractPartnerDTO['address'].city,
                    contractPartnerDTO['address'].country].filter(value => !!value).join(', '),
            },
        });
    }

    if (!!contractPartnerDTO.address?.country && contractPartnerDTO.address.country !== config.CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE) { // eslint-disable-line max-len
        yield fork(
            fetchRequest,
            feesAndEstimationActionTypes.FETCH_ESTIMATION_REQUEST,
            pricingManagementService.getEstimation,
            {
                estimationId: contractPartnerId,
            },
        );

        const estimationResponseAction = yield take([
            feesAndEstimationActionTypes.FETCH_ESTIMATION_REQUEST_FAILED,
            feesAndEstimationActionTypes.FETCH_ESTIMATION_REQUEST_SUCCEEDED,
        ]);

        if (!estimationResponseAction.error) {
            const {response} = estimationResponseAction.payload;
            const {estimation} = response;
            yield put({
                type: contractPartnerActionTypes.STORE_ESTIMATION_ACTIVE_STATUS,
                payload: {isActive: estimation?.active, contractPartnerId},
            });
        }
    }
};

export default loadContractPartner;
