import React, {Fragment, useState, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resolveRoute} from '@computerrock/formation-router';
import {snakeCase} from 'change-case';
import {useStyles, Panel, Paginator, NoResultsBlock, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {InputField, ButtonIcon, Form, SelectField, Option} from '@ace-de/ui-components/form';
import {Table, TableHead, TableBody, TableRow, TableCell, TableCaption} from '@ace-de/ui-components/data-elements';
import {Icon, InteractiveIcon, resetIcon, findCaseIcon, linkIcon, searchIcon} from '@ace-de/ui-components/icons';
import {europeanCountries, apmACEPartnerServiceTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../config';
import routePaths from '../routePaths';
import * as serviceProviderSelectors from './serviceProviderSelectors';
import EmergencyLawyersPanel from './ui-elements/EmergencyLawyersPanel';

const initialSPFilteringParams = {
    searchTerm: '',
    serviceType: 'ALL',
    country: 'ALL',
};

const ServiceProviderSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('service_provider_management_search_screen');
    const {history, serviceProviderSearchResults, serviceProviderSearchResultsCount} = props;
    const [filterFormData, setFilterFormData] = useState(initialSPFilteringParams);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const paginatorCount = Math.ceil(serviceProviderSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const sortedCountries = useMemo(() => {
        return Object.entries(europeanCountries).map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ]).sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });
    }, [translate]);
    const resetRef = useRef(false);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();
        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '' && formData[formField] !== 'ALL') {
                apiQueryParams.append(`${formField}`, formData[formField]);
            }
        });
        return apiQueryParams;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) {
            return;
        }

        const apiQueryParams = formatQueryParams(formValues);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.SERVICE_PROVIDER_SEARCH, {}, {search: queryParamsString}));
    };

    const handleOnChange = formValues => {
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }
        setFilterFormData(formValues);
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.SERVICE_PROVIDER_SEARCH, {}, {search: queryParamsString}));
    };

    const handleResetFilter = () => {
        resetRef.current = true;
        setFilterFormData(initialSPFilteringParams);
        history.push(resolveRoute(routePaths.SERVICE_PROVIDER_SEARCH, {}, {search: ''}));
    };

    const openServiceProviderScreen = serviceProviderId => {
        const {pathname} = resolveRoute(routePaths.SERVICE_PROVIDER, {serviceProviderId});
        window.open(pathname, '_blank');
    };

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Fragment>
                    <Panel title={translateScreen('service_provider_search_panel.title')}>
                        <Form name="serviceProviderSearchForm" isSubmitOnEnterEnabled={true} onSubmit={handleOnSubmit} onChange={handleOnChange}>
                            <div className={cx('global!ace-u-grid')}>
                                <div className={cx('global!ace-u-grid-column--span-5')}>
                                    <InputField
                                        label={translateScreen('input_field_label.free_search')}
                                        name="searchTerm"
                                        className={cx('global!ace-u-full-width')}
                                        value={queryParams.get('searchTerm') || filterFormData.searchTerm}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-3')}>
                                    <SelectField
                                        name="serviceType"
                                        className={cx('global!ace-u-full-width')}
                                        label={translateScreen('select_field_label.service')}
                                        value={queryParams.get('serviceType') || filterFormData.serviceType}
                                    >
                                        <Option
                                            name="serviceTypeOptionAll"
                                            value="ALL"
                                        >
                                            {translate(`global.ace_partner_service_type.all`)}
                                        </Option>
                                        {Object.values(apmACEPartnerServiceTypes).map((serviceType, id) => (
                                            <Option
                                                key={`${serviceType}-${id}`}
                                                name={serviceType}
                                                value={serviceType}
                                                className={cx('ace-c-option--small')}
                                            >
                                                {translate(`global.ace_partner_service_type.${serviceType.toLowerCase()}`)}
                                            </Option>
                                        ))}
                                    </SelectField>
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-3')}>
                                    <SelectField
                                        name="country"
                                        className={cx('global!ace-u-full-width')}
                                        label={translateScreen('select_field_label.country')}
                                        value={queryParams.get('country') || filterFormData.country}
                                    >
                                        <Option
                                            name="countryOptionAll"
                                            value="ALL"
                                        >
                                            {translate(`global.country.all`)}
                                        </Option>
                                        {sortedCountries
                                            .map(([countryCode, country]) => {
                                                return (
                                                    <Option
                                                        key={country.id}
                                                        name={`country-${country.id}`}
                                                        value={countryCode}
                                                        className={cx('ace-c-option--small')}
                                                    >
                                                        {country.name}
                                                    </Option>
                                                );
                                            })}
                                    </SelectField>
                                </div>
                                <div className={cx(['global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24'])}>
                                    <ButtonIcon
                                        name="searchButton"
                                        icon={searchIcon}
                                        type="submit"
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                            </div>
                        </Form>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-space-between',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            <div>
                                <span className={cx('global!ace-u-typography--variant-body')}>
                                    {translateScreen('label.result_count')}
                                </span>&nbsp;
                                <span className={cx('global!ace-u-typography--variant-body-medium')}>
                                    {serviceProviderSearchResultsCount}
                                </span>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-margin--right-24',
                                ])}
                            >
                                <InteractiveIcon
                                    icon={resetIcon}
                                    className={cx([
                                        'ace-c-interactive-icon--reverse',
                                        'ace-c-interactive-icon--highlight',
                                    ])}
                                    onClick={handleResetFilter}
                                >
                                    {translateScreen('interactive_icon_label.reset_filter')}
                                </InteractiveIcon>
                            </div>
                        </div>
                    </Panel>
                    <Panel title={translateScreen('service_provider_panel.title')}>
                        <Table qaIdent="service-provider-search-results">
                            {serviceProviderSearchResults.length === 0 && (
                                <TableCaption>
                                    <NoResultsBlock
                                        icon={(
                                            <Icon
                                                className={cx('ace-c-icon--xxl')}
                                                icon={findCaseIcon}
                                            />
                                        )}
                                        message={translateScreen('no_results.message')}
                                        description={translateScreen('no_results.description')}
                                    />
                                </TableCaption>
                            )}
                            <TableHead>
                                <TableRow>
                                    <TableCell qaIdentPart="sp-id" colSpan={3}>
                                        {translateScreen('service_provider_table_cell.id')}
                                    </TableCell>
                                    <TableCell qaIdentPart="sp-name" colSpan={8}>
                                        {translateScreen('service_provider_table_cell.name')}
                                    </TableCell>
                                    <TableCell qaIdentPart="sp-service-type" colSpan={8}>
                                        {translateScreen('service_provider_table_cell.service_type')}
                                    </TableCell>
                                    <TableCell qaIdentPart="sp-post-code" colSpan={3}>
                                        {translateScreen('service_provider_table_cell.post_code')}
                                    </TableCell>
                                    <TableCell qaIdentPart="sp-city" colSpan={3}>
                                        {translateScreen('service_provider_table_cell.city')}
                                    </TableCell>
                                    <TableCell qaIdentPart="sp-country" colSpan={2}>
                                        {translateScreen('service_provider_table_cell.country')}
                                    </TableCell>
                                    <TableCell qaIdentPart="sp-edit" colSpan={2} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {serviceProviderSearchResults.length > 0
                                    && serviceProviderSearchResults.map(serviceProvider => {
                                        const {services, address, contractStatus} = serviceProvider;
                                        const serviceProviderId = serviceProvider.id;
                                        const serviceTypesString = services?.map(service => {
                                            return translate(`global.ace_partner_service_type.${service.serviceType.toLowerCase()}`);
                                        }).join(', ');
                                        const serviceProviderName = serviceProvider.name ? serviceProvider.name : '';

                                        return (
                                            <TableRow
                                                key={serviceProviderId}
                                                qaIdentPart={serviceProviderId}
                                            >
                                                <TableCell
                                                    qaIdentPart="sp-id"
                                                    qaIdentPartPostfix={serviceProviderId}
                                                    colSpan={3}
                                                >
                                                    {serviceProviderId || ''}
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="sp-name"
                                                    qaIdentPartPostfix={serviceProviderId}
                                                    colSpan={8}
                                                >
                                                    <div title={serviceProvider.name}>
                                                        {/* eslint-disable-next-line max-len */}
                                                        {contractStatus === apmContractPartnerContractStatusTypes.TERMINATED
                                                            ? (
                                                                <p>
                                                                    {serviceProviderName}
                                                                    <span
                                                                        className={cx([
                                                                            'global!ace-u-typography--color-highlighted',
                                                                            'global!ace-u-padding--left-4',
                                                                        ])}
                                                                    >
                                                                        {translateScreen('service_provider_table_cell.inactive')}
                                                                    </span>
                                                                </p>
                                                            ) : serviceProviderName}
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="sp-service-type"
                                                    qaIdentPartPostfix={serviceProviderId}
                                                    colSpan={8}
                                                >
                                                    <div>{serviceTypesString || ''}</div>
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="sp-post-code"
                                                    qaIdentPartPostfix={serviceProviderId}
                                                    colSpan={3}
                                                >
                                                    {address ? address.postCode : ''}
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="sp-city"
                                                    qaIdentPartPostfix={serviceProviderId}
                                                    colSpan={3}
                                                >
                                                    {address ? address.city : ''}
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="sp-country"
                                                    qaIdentPartPostfix={serviceProviderId}
                                                    colSpan={2}
                                                >
                                                    {address ? address.country : ''}
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="sp-edit"
                                                    qaIdentPartPostfix={serviceProviderId}
                                                    colSpan={2}
                                                >
                                                    <InteractiveIcon
                                                        icon={linkIcon}
                                                        onClick={() => openServiceProviderScreen(serviceProviderId)}
                                                        className={cx('ace-c-interactive-icon--primary')}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </Panel>
                    {serviceProviderSearchResults.length > 0 && (
                        <Paginator
                            page={+queryParams.get('page')}
                            count={paginatorCount}
                            onClick={handlePaginationPage}
                        />
                    )}
                </Fragment>
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <EmergencyLawyersPanel />
                </div>
            </ContentItem>
        </ContentBlock>
    );
};

ServiceProviderSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    serviceProviderSearchResults: PropTypes.array,
    serviceProviderSearchResultsCount: PropTypes.number,
};

ServiceProviderSearchScreen.defaultProps = {
    serviceProviderSearchResults: [],
    serviceProviderSearchResultsCount: 0,
};

const mapStateToProps = (state, props) => {
    return {
        serviceProviderSearchResults: serviceProviderSelectors.getServiceProviderSearchResults(state, props),
        serviceProviderSearchResultsCount: serviceProviderSelectors.getServiceProviderSearchResultsCount(state, props),
    };
};

export default connect(mapStateToProps, null)(ServiceProviderSearchScreen);
