import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase, camelCase} from 'change-case';
import {apmTemporaryRestrictionCategoryTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Option, Divider, InputField, ButtonPrimary} from '@ace-de/ui-components';
import {closeIcon, InteractiveIcon, calendarIcon} from '@ace-de/ui-components/icons';
import {Form, TextAreaField, SelectField, Checkbox} from '@ace-de/ui-components/form';
import {DateField} from '@ace-de/ui-components/text-inputs';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../../config';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';

const nonMandatoryFields = ['submitTemporaryRestrictionFormData', 'restrictionEndDateTime'];

const EditTemporaryRestrictionModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, temporaryRestriction} = props;
    const {declineEditTemporaryRestriction, confirmEditTemporaryRestriction, contractPartner} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_temporary_restriction_modal');
    const [temporaryRestrictionFormData, setTemporaryRestrictionFormData] = useState(temporaryRestriction);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [hasDurationError, setHasDurationError] = useState(false);

    const handleCloseModal = () => {
        declineEditTemporaryRestriction();
    };

    const isButtonDisabledCheck = formValues => {
        return Object.keys(formValues).filter(fieldName => {
            if (!fieldName) return false;
            if (nonMandatoryFields.includes(fieldName)) return false;
            switch (typeof formValues[fieldName]) {
                // if the mandatory field is an empty object/array, disable button
                case 'object': {
                    return formValues[fieldName]?.length === 0;
                }

                // if the mandatory checkbox is not checked, disable button
                case 'boolean': {
                    return !formValues[fieldName];
                }

                default: {
                    return !formValues[fieldName];
                }
            }
        })?.length > 0;
    };

    const handleOnChange = formValues => {
        if (!formValues) return;

        if (isButtonDisabledCheck(formValues) !== isButtonDisabled) {
            setIsButtonDisabled(!isButtonDisabled);
        }

        setTemporaryRestrictionFormData({
            ...formValues,
        });
    };

    const handleOnSubmit = formValues => {
        if (formValues.restrictionStartDateTime !== '' && formValues.restrictionEndDateTime !== '') {
            const startDateTime = moment(formValues.restrictionStartDateTime);
            const endDateTime = moment(formValues.restrictionEndDateTime);

            if (endDateTime.diff(startDateTime, 'second') <= 0) {
                setHasDurationError(true);
                return;
            }
        }

        if (hasDurationError) {
            setHasDurationError(false);
        }

        const {restrictionEndDateTime, ...restFormValues} = formValues;

        confirmEditTemporaryRestriction({
            temporaryRestrictionData: {
                ...restFormValues,
                // patch restriction end date only if it has been changed
                // NOTE: this is very important, since BE needs to create a generic QM feedback in this case
                ...(!!(restrictionEndDateTime || temporaryRestriction?.restrictionEndDateTime)
                    && restrictionEndDateTime !== temporaryRestriction?.restrictionEndDateTime
                    && {restrictionEndDateTime}),
            },
        });
    };

    return (
        <Modal
            title={translateModal('header.title')}
            hasBackdrop={hasBackdrop}
            className={cx('ace-c-modal--full-scrollable')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={handleCloseModal}
                />
            )}
            contentClassName={cx('ace-c-modal__content--scrollable')}
        >
            <Divider className={cx('global!ace-u-margin--24-0')} />
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-row',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <div className={cx('global!ace-u-margin--right-32')}>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-8',
                        ])}
                    >
                        {translateModal('section_header.contract_partner')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {contractPartner?.name}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-8',
                        ])}
                    >
                        {translateModal('section_header.creator')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-8',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {temporaryRestriction?.creatorName}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {moment(temporaryRestriction?.createDateTime).format('DD.MM.YYYY HH:mm')
                            + ' ' + translate('global.time_unit.time')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-8',
                        ])}
                    >
                        {translateModal('section_header.creator_role')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {temporaryRestriction?.creatorRole
                            ? translate(`global.user_shift_role.${snakeCase(temporaryRestriction?.creatorRole)}`)
                            : '-'}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-8',
                        ])}
                    >
                        {translateModal('section_header.updated_by')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {temporaryRestriction?.updatedByUser}
                    </p>
                    {temporaryRestriction?.updateDateTime && (
                        <p
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {moment(temporaryRestriction?.updateDateTime).format('DD.MM.YYYY HH:mm')
                                + ' ' + translate('global.time_unit.time')}
                        </p>
                    )}
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-8',
                        ])}
                    >
                        {translateModal('section_header.updated_by_role')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {temporaryRestriction?.updatedByRole
                            ? translate(`global.user_shift_role.${snakeCase(temporaryRestriction.updatedByRole)}`)
                            : '-'}
                    </p>
                </div>
                <Form name="temporaryRestrictionEditForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    <div>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('reporting_person')}
                        </p>
                        <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-16')}>
                            <InputField
                                name="reporterFirstName"
                                label={translateModal('input_label.reporter_first_name')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={temporaryRestrictionFormData?.reporterFirstName || ''}
                            />
                            <InputField
                                name="reporterLastName"
                                label={translateModal('input_label.reporter_last_name')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={temporaryRestrictionFormData?.reporterLastName || ''}
                            />
                            <InputField
                                name="reporterRole"
                                label={translateModal('input_label.reporter_role')}
                                className={cx('global!ace-u-grid-column--span-4')}
                                value={temporaryRestrictionFormData?.reporterRole || ''}
                            />
                        </div>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('duration')}
                        </p>
                        <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                            <DateField
                                name="restrictionStartDateTime"
                                label={translateModal('input_label.restriction_start_date_time')}
                                className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-full-width')}
                                icon={calendarIcon}
                                value={temporaryRestrictionFormData?.restrictionStartDateTime || ''}
                                format="DD.MM.YYYY, HH:mm"
                                isDisabled={true}
                            />
                            <DateField
                                name="restrictionEndDateTime"
                                label={translateModal('input_label.restriction_end_date_time')}
                                className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-full-width')}
                                icon={calendarIcon}
                                value={temporaryRestrictionFormData?.restrictionEndDateTime || ''}
                                format="DD.MM.YYYY, HH:mm"
                                errors={hasDurationError
                                    ? [translateModal('end_date_error_message.end_date_before_start')]
                                    : []
                                }
                                errorClassName={cx('global!ace-u-margin--top-8')}
                            />
                        </div>
                        <Checkbox
                            name="restrictionRequest"
                            className={cx('global!ace-u-margin--bottom-24')}
                            isSelected={temporaryRestrictionFormData?.restrictionRequest || false}
                            value={true}
                        >
                            {translateModal('checkbox_label.permission_report_restriction_requested')}
                        </Checkbox>
                        <div className={cx('global!ace-u-margin--bottom-16')}>
                            <span className={cx(['global!ace-u-typography--variant-body-bold'])}>
                                {translateModal('restriction')}
                            </span>
                        </div>
                        <SelectField
                            name="restrictionCategory"
                            value={temporaryRestrictionFormData?.restrictionCategory || ''}
                            label={translateModal('input_label.restriction_category')}
                            placeholder={translateModal('input_placeholder.select')}
                            className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-full-width')}
                            isDisabled={true}
                        >
                            {Object.keys(apmTemporaryRestrictionCategoryTypes).map(restrictionCategory => {
                                return (
                                    <Option
                                        name={`restrictionCategory${camelCase(restrictionCategory)}`}
                                        value={restrictionCategory}
                                        key={restrictionCategory}
                                    >
                                        {translate(`global.temporary_restriction_category.${snakeCase(restrictionCategory)}`)}
                                    </Option>
                                );
                            })}
                        </SelectField>
                        <TextAreaField
                            className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-24')}
                            name="description"
                            label={translateModal('input_label.description')}
                            placeholder={translateModal('input_placeholder.description')}
                            value={temporaryRestrictionFormData?.description || ''}
                            maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                            isResizable={false}
                        />
                        <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-flex-end')}>
                            <ButtonPrimary
                                type="submit"
                                name="submitTemporaryRestrictionFormData"
                                isDisabled={isButtonDisabled}
                            >
                                {translateModal('button_label.save')}
                            </ButtonPrimary>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

EditTemporaryRestrictionModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    contractPartner: PropTypes.object,
    temporaryRestriction: PropTypes.object,
    declineEditTemporaryRestriction: PropTypes.func.isRequired,
    confirmEditTemporaryRestriction: PropTypes.func.isRequired,
};

EditTemporaryRestrictionModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: null,
    temporaryRestriction: null,
};

const mapStateToProps = (state, props) => {
    const getTemporaryRestriction = contractPartnerSelectors.createTemporaryRestrictionSelector();
    const getContractPartner = contractPartnerSelectors.createContractPartnerSelector();
    return {
        temporaryRestriction: getTemporaryRestriction(state, props),
        contractPartner: getContractPartner(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineEditTemporaryRestriction: payload => dispatch({
        type: contractPartnerActionTypes.DECLINE_UPDATE_TEMPORARY_RESTRICTION,
        payload,
    }),
    confirmEditTemporaryRestriction: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_UPDATE_TEMPORARY_RESTRICTION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTemporaryRestrictionModal);
