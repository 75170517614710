import React, {useState, useRef, Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {camelCase, snakeCase} from 'change-case';
import {resolveRoute} from '@computerrock/formation-router';
import {useStyles, Modal, InteractiveIcon, Avatar, InputField, NumberInputField, Form, ButtonPrimary, CheckboxGroup, ToggleSwitch, Pill} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {eupUserRoleTypes, clientTypes, efServiceCaseStatusTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {closeIcon, noServiceIcon} from '@ace-de/ui-components/icons';
import {acFeatures, acFeatureActions} from '../../application/acFeatures';
import acAccessControl from '../../acAccessControl';
import * as userProfileActionTypes from '../userProfileActionTypes';
import * as userProfileSelectors from '../userProfileSelectors';
import UserRolesSection from '../ui-elements/UserRolesSection';
import config from '../../config';
import {validateEmail} from '../../utils/validation';

const getRolesByClientType = (clientType, includeLEAViewer = false) => {
    let roles;
    switch (clientType) {
        case clientTypes.ECS_CLIENT: {
            roles = [
                eupUserRoleTypes.EMERGENCY_CALL_ADVISOR,
                eupUserRoleTypes.DISPATCHER_1,
                eupUserRoleTypes.DISPATCHER_2,
                eupUserRoleTypes.DISPATCHER_3,
                eupUserRoleTypes.DISPO,
                eupUserRoleTypes.INBOX,
                eupUserRoleTypes.TEAM_LEAD,
                eupUserRoleTypes.ADMIN,
                eupUserRoleTypes.VIEWER,
            ];
            break;
        }
        case clientTypes.VPM_CLIENT: {
            roles = [eupUserRoleTypes.CP_MANAGER];
            break;
        }
        case clientTypes.AC_CLIENT: {
            roles = [
                eupUserRoleTypes.SUPER_ADMIN,
                eupUserRoleTypes.ECS_ADMIN,
                eupUserRoleTypes.VPM_ADMIN,
                eupUserRoleTypes.LEA_ADMIN,
            ];
            break;
        }
        case clientTypes.LEA_CLIENT: {
            roles = [
                eupUserRoleTypes.AUDITOR,
                ...(!!includeLEAViewer && [eupUserRoleTypes.LEA_VIEWER]),
            ];
            break;
        }
        default:
        // no-op
    }
    return roles;
};

const ecsSCRoutePathData = {
    ECS_SC_SECTION_ROUTE_PATH: '/service-cases/:serviceCaseId',
    ECS_SC_TAB_ASSIGNMENTS: 'assignments',
};

const UserDetailsModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('user_details_modal');
    const {userProfiles, lockedServiceCaseResults, unlockServiceCase, changeUserEnabledState} = props;
    const {declineEditUserProfile, confirmEditUserProfile, confirmCreateUserProfile, declineCreateUserProfile} = props;
    const {hasBackdrop, location, currentUser} = props;
    const isEditModeActive = location?.query?.mode === 'edit';
    const user = userProfiles.get(location?.query?.userId);
    const currentUserRoles = currentUser?.clientRoles?.find(clientRole => {
        return clientRole.client === clientTypes.AC_CLIENT;
    }).userRoles || [];
    const userHasSuperAdminRole = currentUserRoles ? currentUserRoles.includes(eupUserRoleTypes.ECS_ADMIN)
        || currentUserRoles.includes(eupUserRoleTypes.SUPER_ADMIN) : false;
    const userHasLEAAdminRole = currentUserRoles ? currentUserRoles.includes(eupUserRoleTypes.LEA_ADMIN) : false;

    const isUserAllowedToUpdateData = acAccessControl.grantFeatureAccess(
        acFeatures.USER_BASIC_DATA,
        acFeatureActions.UPDATE,
    );
    const isUserAllowedToDeactivateUser = acAccessControl.grantFeatureAccess(
        acFeatures.USER_ACTIVE_STATUS,
        acFeatureActions.UPDATE,
    ) || acAccessControl.grantFeatureAccess(
        acFeatures.USER_ACTIVE_STATUS,
        acFeatureActions.READ,
    );
    const isUserAllowedToUnlockServiceCases = acAccessControl.grantFeatureAccess(
        acFeatures.ECS_SERVICE_CASES,
        acFeatureActions.UPDATE,
    );
    const isUserAllowedToCreateUser = acAccessControl.grantFeatureAccess(
        acFeatures.USER_ACCOUNT,
        acFeatureActions.CREATE,
    );

    const [userDetailsData, setUserDetailsData] = useState({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
        phoneNumber: user?.phoneNumber || '',
        temporaryPassword: '',
        ecsClient: user?.clientRoles?.find(clientRole => clientRole.client === clientTypes.ECS_CLIENT)?.userRoles || [],
        vpmClient: user?.clientRoles?.find(clientRole => clientRole.client === clientTypes.VPM_CLIENT)?.userRoles || [],
        acClient: user?.clientRoles?.find(clientRole => clientRole.client === clientTypes.AC_CLIENT)?.userRoles || [],
        leaClient: isEditModeActive
            ? user?.clientRoles?.find(clientRole => clientRole.client === clientTypes.LEA_CLIENT)?.userRoles || []
            : !userHasSuperAdminRole
                ? [eupUserRoleTypes.AUDITOR]
                : [],
    });
    const areECSRolesChanged = useRef(false);
    const areVPMRolesChanged = useRef(false);
    const areLEARolesChanged = useRef(!isEditModeActive);
    const areACRolesChanged = useRef(false);
    const isViewerRoleSelected = useRef(false);
    const [emailError, setEmailErrorMessage] = useState();
    const [phoneExtensionError, setPhoneExtensionError] = useState();
    const usersLockedServiceCases = lockedServiceCaseResults && user?.id ? lockedServiceCaseResults.get(user.id) : {};

    const handleCloseUserDetailsModal = () => {
        if (isEditModeActive) {
            declineEditUserProfile();
        }
        declineCreateUserProfile();
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;

        const {firstName, lastName, email, temporaryPassword, phoneNumber} = formValues;
        const {ecsClient, vpmClient, acClient, leaClient} = formValues;
        const clientRoles = [];

        areECSRolesChanged.current && clientRoles.push({
            client: clientTypes.ECS_CLIENT,
            userRoles: ecsClient,
        });
        areVPMRolesChanged.current && clientRoles.push({
            client: clientTypes.VPM_CLIENT,
            userRoles: vpmClient,
        });
        areLEARolesChanged.current && clientRoles.push({
            client: clientTypes.LEA_CLIENT,
            userRoles: leaClient,
        });
        areACRolesChanged.current && clientRoles.push({
            client: clientTypes.AC_CLIENT,
            userRoles: acClient,
        });

        const userProfileData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            clientRoles: clientRoles,
        };

        isEditModeActive
            ? confirmEditUserProfile(
                temporaryPassword ? {
                    ...userProfileData,
                    temporaryPassword,
                } : userProfileData,
            )
            : confirmCreateUserProfile({
                ...userProfileData,
                temporaryPassword,
            });

        areECSRolesChanged.current = false;
        areVPMRolesChanged.current = false;
        areLEARolesChanged.current = false;
        areACRolesChanged.current = false;
    };

    const handleOnChange = formValues => {
        if ((!formValues.email && emailError) || validateEmail(formValues.email)) {
            setEmailErrorMessage([]);
        }
        if (formValues.email && !validateEmail(formValues.email)) {
            setEmailErrorMessage([translateModal('error_message.please_enter_valid_email')]);
        }
        if (formValues.phoneNumber
            && phoneExtensionError
            && formValues.phoneNumber.length <= config.MAXIMUM_PHONE_EXTENSION_DIGITS) {
            setPhoneExtensionError([]);
        }
        if (formValues.phoneNumber && formValues.phoneNumber.length > config.MAXIMUM_PHONE_EXTENSION_DIGITS) {
            setPhoneExtensionError([translateModal('error_message.please_enter_numbers_only')]);
        }

        if (formValues.ecsClient.length !== userDetailsData.ecsClient.length) areECSRolesChanged.current = true;
        if (formValues.vpmClient.length !== userDetailsData.vpmClient.length) areVPMRolesChanged.current = true;
        if (formValues.leaClient.length !== userDetailsData.leaClient.length) areLEARolesChanged.current = true;
        if (formValues.acClient.length !== userDetailsData.acClient.length) areACRolesChanged.current = true;

        if (formValues.ecsClient.includes(eupUserRoleTypes.VIEWER) && !isViewerRoleSelected.current) {
            setUserDetailsData({
                ...formValues,
                ecsClient: [eupUserRoleTypes.VIEWER],
            });
            isViewerRoleSelected.current = true;
            return;
        }

        setUserDetailsData(formValues);
    };

    const toggleActiveUser = () => {
        changeUserEnabledState({
            userId: user.id,
            isEnabled: !user?.isEnabled,
        });
    };

    const openServiceCaseOverviewScreen = serviceCaseId => {
        const {pathname} = resolveRoute(
            ecsSCRoutePathData.ECS_SC_SECTION_ROUTE_PATH,
            {serviceCaseId: serviceCaseId},
        );

        window.open(`${config.ACE_ECS_SITE_URL}${pathname}?activeTab=${ecsSCRoutePathData.ECS_SC_TAB_ASSIGNMENTS}`, '_blank');
    };

    const mandatoryFields = [
        'firstName',
        'lastName',
        'email',
        'temporaryPassword',
    ];

    const areMandatoryFieldsFilled = (isEditMode = false) => {
        return mandatoryFields.filter(mandatoryField => {
            if (isEditMode && mandatoryField === 'temporaryPassword') return false;
            return !userDetailsData[mandatoryField]
                || (mandatoryField === 'email' && !validateEmail(userDetailsData.email));
        }).length === 0;
    };

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={handleCloseUserDetailsModal}
                />
            )}
            title={isEditModeActive
                ? translateModal('title.edit_user_profile')
                : translateModal('title.create_user')}
            contentClassName={cx('ace-c-modal__content--scrollable')}
            hasBackdrop={hasBackdrop}
        >
            <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--top-64', 'global!ace-u-full-width'])}>
                <div className={cx('global!ace-u-grid-column--span-3')}>
                    <Avatar alt={user?.initials || '--'} className={cx('ace-c-avatar--large')} />
                    <div
                        className={cx([
                            'global!ace-u-margin--top-64',
                            'global!ace-u-margin--bottom-48',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('account_creation_label')}
                        </p>
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {user?.createdAt || ''}
                        </p>
                    </div>
                    <div>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('last_update_label')}
                        </p>
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {user?.updatedAt || ''}
                        </p>
                    </div>
                </div>
                <div className={cx('global!ace-u-grid-column--span-9')}>
                    <Form name="userDetailsForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                        <div className={cx('global!ace-u-grid')}>
                            <InputField
                                className={cx(['global!ace-u-margin--right-16', 'ace-u-grid-column--span-4'])}
                                name="firstName"
                                value={userDetailsData.firstName || ''}
                                label={translateModal('input_label.first_name')}
                                isFieldRequired={true}
                                isDisabled={(isEditModeActive && !isUserAllowedToUpdateData)
                                || (!isEditModeActive && !isUserAllowedToCreateUser)
                                || (user && !user?.isEnabled)
                                }
                            />
                            <InputField
                                className={cx(['global!ace-u-margin--right-16', 'ace-u-grid-column--span-4'])}
                                name="lastName"
                                value={userDetailsData.lastName || ''}
                                label={translateModal('input_label.last_name')}
                                isFieldRequired={true}
                                isDisabled={(isEditModeActive && !isUserAllowedToUpdateData)
                                || (!isEditModeActive && !isUserAllowedToCreateUser)
                                || (user && !user?.isEnabled)
                                }
                            />
                            <InputField
                                className={cx(['global!ace-u-margin--right-16', 'global!ace-u-grid-column--span-4'])}
                                name="email"
                                value={userDetailsData.email || ''}
                                label={translateModal('input_label.e_mail')}
                                isFieldRequired={true}
                                isDisabled={(isEditModeActive && !isUserAllowedToUpdateData)
                                || (!isEditModeActive && !isUserAllowedToCreateUser)
                                || (user && !user?.isEnabled)
                                }
                                errors={emailError}
                            />
                            <NumberInputField
                                className={cx(['global!ace-u-margin--right-16', 'global!ace-u-grid-column--span-4'])}
                                name="phoneNumber"
                                value={userDetailsData.phoneNumber || ''}
                                label={translateModal('input_label.phone_number_extension')}
                                isDisabled={(isEditModeActive && !isUserAllowedToUpdateData)
                                    || (!isEditModeActive && !isUserAllowedToCreateUser)
                                    || (user && !user?.isEnabled)
                                }
                                errors={phoneExtensionError}
                            />
                        </div>
                        <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--top-32'])}>
                            <InputField
                                className={cx(['global!ace-u-margin--right-16', 'global!ace-u-grid-column--span-4'])}
                                name="temporaryPassword"
                                value={userDetailsData.temporaryPassword || ''}
                                label={translateModal('input_label.set_password')}
                                placeholder={translateModal('input_placeholder.enter_temporary_password')}
                                isFieldRequired={!isEditModeActive}
                                isDisabled={(isEditModeActive && !isUserAllowedToUpdateData)
                                    || (!isEditModeActive && !isUserAllowedToCreateUser)
                                    || (user && !user?.isEnabled)
                                }
                            />
                        </div>
                        {Object.keys(clientTypes).map(clientType => {
                            return (
                                <CheckboxGroup
                                    key={clientType}
                                    name={camelCase(clientType)}
                                    value={userDetailsData[camelCase(clientType)]}
                                    isDisabled={isEditModeActive ? !user?.isEnabled : !areMandatoryFieldsFilled()}
                                >
                                    {acAccessControl.grantFeatureAccess(acFeatures[`USER_ROLES_${clientType}`], acFeatureActions.UPDATE) && (
                                        <div
                                            className={cx([
                                                'global!ace-u-typography--variant-body-medium',
                                                'global!ace-u-margin--top-64',
                                            ])}
                                        >
                                            <p className={cx('global!ace-u-margin--bottom-16')}>
                                                {translateModal(`role_section_label.${clientType.toLowerCase()}`)}
                                            </p>
                                            <UserRolesSection
                                                roles={getRolesByClientType(
                                                    clientType,
                                                    userHasSuperAdminRole || userHasLEAAdminRole,
                                                )}
                                                system={clientType}
                                                userDetailsData={userDetailsData}
                                            />
                                        </div>
                                    )}
                                </CheckboxGroup>
                            );
                        })}
                        {isEditModeActive && isUserAllowedToUnlockServiceCases && (
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--top-64',
                                ])}
                            >
                                <p className={cx('global!ace-u-margin--bottom-16')}>
                                    {translateModal('active_cases_section_label')}
                                </p>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translateModal('active_cases_table_cell.currently_working_on')}</TableCell>
                                            <TableCell>{translateModal('active_cases_table_cell.last_update')}</TableCell>
                                            <TableCell>{translateModal('active_cases_table_cell.status')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usersLockedServiceCases && usersLockedServiceCases.length ? (
                                            Object.values(usersLockedServiceCases).map(lockedServiceCase => {
                                                const {serviceCaseId, caseStatus, updatedAt} = lockedServiceCase;
                                                return (
                                                    <TableRow key={serviceCaseId}>
                                                        <TableCell>
                                                            <span
                                                                className={cx([
                                                                    'global!ace-u-typography--variant-body-medium',
                                                                    'global!ace-u-typography--color-highlighted',
                                                                    'global!ace-u-cursor--pointer',
                                                                ])}
                                                                onClick={() => {
                                                                    return openServiceCaseOverviewScreen(serviceCaseId);
                                                                }}
                                                            >
                                                                {`${translateModal('active_cases_table_cell.case')} ${serviceCaseId}`}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {`${moment(updatedAt).format('DD.MM.YYYY, HH:mm')} ${translate('global.time_unit.time')}`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {caseStatus !== efServiceCaseStatusTypes.UNKNOWN && (
                                                            <Pill
                                                                type={caseStatus === efServiceCaseStatusTypes.NEW
                                                                    ? 'positive' : 'pending'}
                                                                className={cx('global!ace-u-typography--variant-body-medium')}
                                                            >
                                                                {translate(`global.service_case_status_type.${snakeCase(caseStatus)}`)}
                                                            </Pill>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <InteractiveIcon
                                                                icon={noServiceIcon}
                                                                onClick={() => unlockServiceCase({
                                                                    serviceCaseId,
                                                                    userId: user.id,
                                                                })}
                                                                className={cx('ace-c-interactive-icon--primary')}
                                                            >
                                                                {translateModal('active_cases_table_button.stop_editing_case')}
                                                            </InteractiveIcon>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })) : null}
                                    </TableBody>
                                </Table>
                            </div>
                        )}
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-margin--top-48',
                                'global!ace-u-flex--justify-space-between',
                            ])}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-flex-start',
                                    'global!ace-u-typography--variant-body-medium',
                                ])}
                            >
                                {isEditModeActive && isUserAllowedToDeactivateUser && (
                                    <Fragment>
                                        <span className={cx('global!ace-u-margin--right-16')}>
                                            {translateModal('toggle_button_label.user_active')}
                                        </span>
                                        <ToggleSwitch
                                            name="deactivateUser"
                                            value={true}
                                            onChange={toggleActiveUser}
                                            isSelected={!!user?.isEnabled}
                                        />
                                    </Fragment>
                                )}
                            </div>
                            <ButtonPrimary
                                name="saveUserDetailsFormData"
                                type="submit"
                                className={cx('global!ace-u-flex--justify-flex-end')}
                                isDisabled={isEditModeActive
                                    ? !user?.isEnabled || !areMandatoryFieldsFilled(true)
                                    : !areMandatoryFieldsFilled()}
                            >
                                {translateModal('button_label.save')}
                            </ButtonPrimary>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

UserDetailsModal.propTypes = {
    location: PropTypes.object,
    hasBackdrop: PropTypes.bool,
    declineEditUserProfile: PropTypes.func.isRequired,
    confirmEditUserProfile: PropTypes.func.isRequired,
    userProfiles: PropTypes.object.isRequired,
    changeUserEnabledState: PropTypes.func.isRequired,
    lockedServiceCaseResults: PropTypes.object,
    unlockServiceCase: PropTypes.func.isRequired,
    confirmCreateUserProfile: PropTypes.func.isRequired,
    declineCreateUserProfile: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
};

UserDetailsModal.defaultProps = {
    location: null,
    lockedServiceCaseResults: null,
    hasBackdrop: true,
    currentUser: null,
};

const mapStateToProps = state => {
    return {
        userProfiles: userProfileSelectors.getUserProfiles(state),
        lockedServiceCaseResults: userProfileSelectors.getLockedServiceCaseResults(state),
        currentUser: userProfileSelectors.getUser(state),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmEditUserProfile: payload => dispatch({
        type: userProfileActionTypes.CONFIRM_EDIT_USER_PROFILE,
        payload,
    }),
    declineEditUserProfile: () => dispatch({
        type: userProfileActionTypes.DECLINE_EDIT_USER_PROFILE,
    }),
    changeUserEnabledState: payload => dispatch({
        type: userProfileActionTypes.CHANGE_USER_ENABLED_STATE,
        payload,
    }),
    unlockServiceCase: payload => dispatch({
        type: userProfileActionTypes.UNLOCK_SERVICE_CASE,
        payload,
    }),
    confirmCreateUserProfile: payload => dispatch({
        type: userProfileActionTypes.CONFIRM_CREATE_USER_PROFILE,
        payload,
    }),
    declineCreateUserProfile: () => dispatch({
        type: userProfileActionTypes.DECLINE_CREATE_USER_PROFILE,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsModal);
