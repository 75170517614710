import {put, fork, take, select} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import modalIds from '../../modalIds';

const loadContractPartnerTemporaryRestrictions = function* loadContractPartnerTemporaryRestrictions({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {activeTab} = yield* selectSearchQueryParams();
    const {match, location} = payload;
    const {contractPartnerId} = match.params;

    // do not load contract partner temporary restrictions if agent is not on that tab
    if (activeTab !== contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS) return;
    // do not reload temporary restrictions when agent opens add/edit temporary restriction modal
    if (location && location.state?.isModalOpen
        && [modalIds.CP_CREATE_TEMPORARY_RESTRICTION, modalIds.CP_EDIT_TEMPORARY_RESTRICTION]
            .includes(location.state?.modalId)
    ) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST,
        partnerManagementService.getACEPartnerRestrictions,
        {
            acePartnerId: contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;

    yield put({
        type: contractPartnerActionTypes.STORE_CP_TEMPORARY_RESTRICTIONS,
        payload: {contractPartnerId, temporaryRestrictionDTOs: response.acePartnerRestrictionDTOs},
    });
};

export default loadContractPartnerTemporaryRestrictions;
