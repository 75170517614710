import {call, select, all, take, fork} from 'redux-saga/effects';
import {euaFeatureEntityTypes, euaFeatureTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';
import config from '../../config';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import loadContractPartnerOperationAreas from './loadContractPartnerOperationAreas';

const loadCPOperationAreaMapLayers = function* loadCPOperationAreaMapLayers() {
    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP);
        const {mapName, match} = payload;
        if (mapName !== 'vpm-contract-partner-operation-areas') continue;

        const {serviceManager} = yield select(state => state.application);
        const arcGISMapService = serviceManager.loadService('arcGISMapService');

        const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partner-operation-areas');

        if (!arcGISMap) continue;

        if (!arcGISMap.isLayerSet('vpm-contract-partner-operation-area-service-areas')) {
            arcGISMap.createServiceLayer({
                layerId: 'vpm-contract-partner-operation-area-service-areas',
                serviceURL: config.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL,
                featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_SERVICE_AREA,
                featureType: euaFeatureTypes.SELECTABLE_SERVICE_AREA,
                isVisible: false,
                areFeaturesSelectableOnClick: false,
            });
        }

        if (!arcGISMap.isLayerSet('vpm-contract-partner-operation-area-locations')) {
            arcGISMap.createServiceLayer({
                layerId: 'vpm-contract-partner-operation-area-locations',
                serviceURL: config.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL,
                featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER,
                featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
                markerType: euaMarkerTypes.CONTRACT_PARTNER,
                renderByStatus: true,
                isVisible: false,
                areFeaturesSelectableOnClick: false,
                outFields: ['*'],
            });
        }

        yield all([
            fork(loadContractPartnerOperationAreas, {payload: {match}}),
        ]);
    }
};

export default loadCPOperationAreaMapLayers;
