import {take, fork, select, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';

const changeUserEnabledState = function* changeUserEnabledState() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    while (true) {
        const {payload} = yield take(userProfileActionTypes.CHANGE_USER_ENABLED_STATE);
        const {userId, isEnabled} = payload;

        yield fork(
            fetchRequest,
            userProfileActionTypes.CHANGE_USER_ENABLED_STATE_REQUEST,
            userProfileService.changeUserEnabledState,
            {
                userId: userId,
                isEnabled: isEnabled,
            },
        );

        const responseAction = yield take([
            userProfileActionTypes.CHANGE_USER_ENABLED_STATE_REQUEST_SUCCEEDED,
            userProfileActionTypes.CHANGE_USER_ENABLED_STATE_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            yield put({
                type: userProfileActionTypes.SET_USER_ENABLED_STATE,
                payload: {isEnabled, userId},
            });
        }
    }
};

export default changeUserEnabledState;
