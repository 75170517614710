import React, {Fragment, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resolveRoute} from '@computerrock/formation-router';
import {eupUserRoleTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ButtonPrimary, Paginator, NoResultsBlock, Avatar, Option, MultiSelectField} from '@ace-de/ui-components';
import {InputField, ButtonIcon} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, InteractiveIcon, resetIcon, findCaseIcon, editIcon, searchIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as userProfileSelectors from './userProfileSelectors';
import * as userProfileActionTypes from './userProfileActionTypes';
import {acFeatures, acFeatureActions} from '../application/acFeatures';
import acAccessControl from '../acAccessControl';
import config from '../config';
import routePaths from '../routePaths';

const applications = {
    ADMIN_COCKPIT: 'ADMIN_COCKPIT',
    ECS: 'ECS',
    LEA: 'LEA',
    VPM: 'VPM',
};

const appRoles = {
    ADMIN_COCKPIT: [
        eupUserRoleTypes.SUPER_ADMIN,
        eupUserRoleTypes.ECS_ADMIN,
        eupUserRoleTypes.VPM_ADMIN,
        eupUserRoleTypes.LEA_ADMIN,
    ],
    ECS: [
        eupUserRoleTypes.EMERGENCY_CALL_ADVISOR,
        eupUserRoleTypes.DISPATCHER_1,
        eupUserRoleTypes.DISPATCHER_2,
        eupUserRoleTypes.DISPATCHER_3,
        eupUserRoleTypes.DISPO,
        eupUserRoleTypes.INBOX,
        eupUserRoleTypes.TEAM_LEAD,
        eupUserRoleTypes.ADMIN,
        eupUserRoleTypes.VIEWER,
    ],
    LEA: [
        eupUserRoleTypes.AUDITOR,
        eupUserRoleTypes.LEA_VIEWER,
    ],
    VPM: [eupUserRoleTypes.CP_MANAGER],
};

const initialFormDataValues = {
    searchTerm: '',
    applications: [],
    userRole: [],
};

const UserSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('user_management_search_screen');
    const {initiateEditUserDetailsFlow, initiateCreateUserDetailsFlow} = props;
    const {history, userSearchResultsCount, userSearchResults} = props;
    const [availableRoles, setAvailableRoles] = useState(Object.values(appRoles).flat(1));
    const [formData, setFormData] = useState(initialFormDataValues);
    const isMountedRef = useRef(false);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const paginatorCount = Math.ceil(userSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const userHasLEAAdminRole = acAccessControl.grantFeatureAccess(
        acFeatures.USER_ROLES_LEA_CLIENT,
        acFeatureActions.UPDATE,
    );

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            let currentQueryParams = queryParams;
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
                currentQueryParams = newQueryParams;
            }
            if (!isMountedRef.current && userHasLEAAdminRole && !currentQueryParams.get('userRole')) {
                currentQueryParams.append('applications', applications.LEA);
                [eupUserRoleTypes.AUDITOR, eupUserRoleTypes.LEA_VIEWER].forEach(userRole => {
                    currentQueryParams.append('userRole', userRole);
                });
                history.push(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: currentQueryParams.toString()}));
            }
            isMountedRef.current = true;
            setFormData({
                searchTerm: currentQueryParams.get('searchTerm') || initialFormDataValues.searchTerm,
                applications: currentQueryParams.getAll('applications')?.length
                    ? currentQueryParams.getAll('applications')
                    : initialFormDataValues.applications,
                userRole: currentQueryParams.getAll('userRole')?.length
                    ? currentQueryParams.getAll('userRole')
                    : initialFormDataValues.userRole,
            });
        }
    }, [history.location.search, history, queryParams, userHasLEAAdminRole]);

    const handleResetFilter = () => {
        setFormData(initialFormDataValues);
        setAvailableRoles(Object.values(applications).map(app => appRoles[app]).flat(1));
        history.push(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: ''}));
    };

    const handleOnSubmit = () => {
        const apiQueryParams = new URLSearchParams();

        Object.keys(formData).forEach(key => {
            if (formData[key] === '' || !formData[key].length) return;

            if (key === 'applications' && !formData['userRole'].length) {
                formData[key].forEach(fieldValue => {
                    if (!fieldValue) return;
                    apiQueryParams.append(key, fieldValue);
                    appRoles[fieldValue].forEach(role => {
                        apiQueryParams.append('userRole', role);
                    });
                });
                return;
            }

            if (key === 'userRole' || key === 'applications') {
                formData[key].forEach(fieldValue => {
                    if (!fieldValue) return;
                    apiQueryParams.append(key, fieldValue);
                });
                return;
            }

            apiQueryParams.append(key, formData[key]);
        });

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: queryParamsString}));
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: queryParamsString}));
    };

    return (
        <Fragment>
            <Panel title={translateScreen('user_search_panel.title')}>
                <div className={cx('global!ace-u-grid')}>
                    <div className={cx('global!ace-u-grid-column--span-6')}>
                        <InputField
                            label={translateScreen('input_field_label.free_search')}
                            name="searchTerm"
                            value={formData.searchTerm}
                            onChange={value => setFormData(prevState => ({
                                ...prevState,
                                searchTerm: value,
                            }))}
                            className={cx('global!ace-u-full-width')}
                            onKeyDown={() => (formData.searchTerm ? handleOnSubmit() : null)}
                        />
                    </div>
                    <div className={cx('global!ace-u-grid-column--span-2')}>
                        <MultiSelectField
                            label={translateScreen('select_field_label.application')}
                            placeholder={translateScreen('placeholder_content.all')}
                            name="applications"
                            className={cx('global!ace-u-full-width')}
                            value={formData.applications}
                            onChange={value => {
                                const filteredRoles = value.length > 0
                                    ? value.map(app => appRoles[app]).flat(1)
                                    : Object.values(appRoles).flat(1);
                                setFormData(prevState => ({
                                    ...prevState,
                                    applications: value,
                                    userRole: formData.userRole.filter(role => filteredRoles.includes(role)),
                                }));
                                setAvailableRoles(filteredRoles);
                            }}
                        >
                            {Object.values(applications).map(app => (
                                <Option name={app} key={app} value={app}>
                                    {translate(`global.application.${app.toLowerCase()}`)}
                                </Option>
                            ))}
                        </MultiSelectField>
                    </div>
                    <div className={cx('global!ace-u-grid-column--span-2')}>
                        <MultiSelectField
                            label={translateScreen('select_field_label.role')}
                            placeholder={translateScreen('placeholder_content.all')}
                            name="userRole"
                            className={cx('global!ace-u-full-width')}
                            value={formData.userRole}
                            onChange={value => setFormData(prevState => ({
                                ...prevState,
                                userRole: value,
                            }))}
                        >
                            {availableRoles.map(role => (
                                <Option name={role} key={role} value={role}>
                                    {translate(`global.user_role.${role.toLowerCase()}`)}
                                </Option>
                            ))}
                        </MultiSelectField>
                    </div>
                    <div className={cx(['global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24'])}>
                        <ButtonIcon
                            name="searchButton"
                            icon={searchIcon}
                            className={cx('global!ace-u-margin--top-4')}
                            onClick={handleOnSubmit}
                        />
                    </div>
                </div>
                <InteractiveIcon
                    icon={resetIcon}
                    className={cx([
                        'ace-c-interactive-icon--reverse',
                        'ace-c-interactive-icon--highlight',
                        'global!ace-u-margin--top-24',
                    ])}
                    onClick={handleResetFilter}
                >
                    {translateScreen('interactive_icon_label.reset_filter')}
                </InteractiveIcon>
            </Panel>
            <Panel title={translateScreen('user_panel.title')}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    {acAccessControl.grantFeatureAccess(acFeatures.USER_ACCOUNT, acFeatureActions.CREATE) && (
                        <ButtonPrimary onClick={initiateCreateUserDetailsFlow}>
                            {translateScreen('user_panel_button.add_users')}
                        </ButtonPrimary>
                    )}
                </div>
                <div className={cx('global!ace-u-margin--top-32')}>
                    <Table qaIdent="user-search-results">
                        {userSearchResults.length === 0 && (
                            <TableCaption>
                                <NoResultsBlock
                                    icon={(
                                        <Icon
                                            className={cx('ace-c-icon--xxl')}
                                            icon={findCaseIcon}
                                        />
                                    )}
                                    message={translateScreen('no_results.message')}
                                    description={translateScreen('no_results.description')}
                                />
                            </TableCaption>
                        )}
                        <TableHead>
                            <TableRow>
                                <TableCell qaIdentPart="initials" colSpan={2} />
                                <TableCell qaIdentPart="first-name" colSpan={5}>
                                    {translateScreen('user_table_cell.first_name')}
                                </TableCell>
                                <TableCell qaIdentPart="last-name" colSpan={5}>
                                    {translateScreen('user_table_cell.last_name')}
                                </TableCell>
                                <TableCell qaIdentPart="e-mail-address" colSpan={5}>
                                    {translateScreen('user_table_cell.e_mail_address')}
                                </TableCell>
                                <TableCell qaIdentPart="edit" colSpan={1} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userSearchResults.length > 0 && userSearchResults.map(user => {
                                const userLastName = user.lastName ? user.lastName : '';

                                return (
                                    <TableRow
                                        key={user.id}
                                        qaIdentPart={user.id}
                                    >
                                        <TableCell
                                            qaIdentPart="initials"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={2}
                                        >
                                            <Avatar alt={user.initials} />
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="first-name"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={5}
                                        >
                                            {user.firstName}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="last-name"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={5}
                                        >
                                            {!user.isEnabled
                                                ? (
                                                    <p>
                                                        {userLastName}
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-typography--color-highlighted',
                                                                'global!ace-u-padding--left-4',
                                                            ])}
                                                        >
                                                            {translateScreen('user_table_cell.deactivated')}
                                                        </span>
                                                    </p>
                                                ) : userLastName}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="e-mail-address"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={5}
                                        >
                                            {user.email}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="edit"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={1}
                                        >
                                            <InteractiveIcon
                                                icon={editIcon}
                                                className={cx('ace-c-interactive-icon--primary')}
                                                onClick={() => initiateEditUserDetailsFlow({
                                                    queryParamsString: queryParams.toString(),
                                                    userId: user.id,
                                                    history,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Panel>
            {userSearchResults.length > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

UserSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    userSearchResults: PropTypes.array,
    userSearchResultsCount: PropTypes.number,
    initiateEditUserDetailsFlow: PropTypes.func.isRequired,
    initiateCreateUserDetailsFlow: PropTypes.func.isRequired,
};

UserSearchScreen.defaultProps = {
    userSearchResults: [],
    userSearchResultsCount: 0,
};

const mapStateToProps = (state, props) => {
    return {
        userSearchResults: userProfileSelectors.getUserSearchResults(state, props),
        userSearchResultsCount: state.user.userSearchResultsCount,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateEditUserDetailsFlow: payload => dispatch({
        type: userProfileActionTypes.INITIATE_EDIT_USER_PROFILE_FLOW,
        payload,
    }),
    initiateCreateUserDetailsFlow: payload => dispatch({
        type: userProfileActionTypes.INITIATE_CREATE_USER_PROFILE_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchScreen);
