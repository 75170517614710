import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, DataRow, Form, InputField, ButtonPrimary, Autocomplete, Option, Modal} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon, arrowDownIcon} from '@ace-de/ui-components/icons';
import {europeanCountries, ACEPartner, apmACEPartnerTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import * as commissionerActionTypes from '../commissionerActionTypes';
import {validateEmail} from '../../utils/validation';

const nonMandatoryFields = ['websiteUrl', 'faxNo', 'street', 'postCode', 'city', 'country', 'phoneNo', 'accountPartyId'];
const initialErrorFields = {
    prefix: [],
    name: [],
    email: [],
};

const validatePrefix = prefix => {
    const re = /^[0-9a-zA-Z]{3}$/;
    return re.test(prefix);
};

const CreateCommissionerModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('create_commissioner_modal');
    const {declineCreateCommissioner, confirmCreateCommissioner, hasBackdrop} = props;
    const [commissionerData, setCommissionerData] = useState(new ACEPartner());
    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ]).sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });
    const [errorFields, setErrorFields] = useState({...initialErrorFields});

    const handleFormFieldsValidation = formValues => {
        let hasError = false;
        const mandatoryErrorMessage = translateModal('field_cannot_be_empty');
        const emailErrorMessage = translateModal('please_enter_valid_email');
        const prefixErrorMessage = translateModal('prefix_has_to_be_three_characters');

        Object.entries(formValues).forEach(formValueEntry => {
            const key = formValueEntry[0];
            const value = formValueEntry[1];

            if (value === '' && !nonMandatoryFields.includes(key)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [mandatoryErrorMessage],
                }));
                hasError = true;
            } else if (key === 'email' && !validateEmail(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [emailErrorMessage],
                }));
                hasError = true;
            } else if (key === 'prefix' && !validatePrefix(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [prefixErrorMessage],
                }));
                hasError = true;
            } else if (errorFields[key]) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [],
                }));
            }
        });

        return hasError;
    };

    const handleOnSubmit = formValues => {
        const hasError = handleFormFieldsValidation(formValues);
        if (hasError || !formValues) return;
        const {street, city, postCode, phoneNo, email, faxNo, websiteUrl, prefix, country, name, accountPartyId} = formValues; // eslint-disable-line max-len

        const commissionerData = {
            name,
            id: prefix,
            address: {
                street,
                city,
                postCode,
                country,
            },
            businessContactDetails: {
                phoneNo,
                email,
                faxNo,
                websiteUrl,
            },
            partnerType: apmACEPartnerTypes.COMMISSIONER,
            contractStatus: apmContractPartnerContractStatusTypes.ACTIVE,
            accountPartyId,
        };

        confirmCreateCommissioner(commissionerData);
    };

    const handleOnChange = formValues => {
        setCommissionerData(formValues);
    };

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => declineCreateCommissioner()}
                />
            )}
            title={translateModal('title.add_commissioner')}
            contentClassName={cx(['global!ace-u-margin--top-32', 'global!ace-u-modal-content-size--m'])}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form name="createCommissionerData" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    {formValues => {
                        const isMandatoryFieldEmpty = !!(Object.entries(formValues)
                            .find(([fieldName, value]) => {
                                return fieldName !== 'submitCommissionerData'
                                    && !nonMandatoryFields.includes(fieldName)
                                    && !value;
                            }));
                        return (
                            <Fragment>
                                <div className={cx('global!ace-u-grid')}>
                                    <div className={cx('global!ace-u-grid-column--span-6')}>
                                        <DataRow
                                            label={translateModal('data_row_label.prefix')}
                                            qaIdent="commissioner-prefix"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="prefix"
                                                value={commissionerData.prefix || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.prefix}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.name')}
                                            qaIdent="commissioner-name"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="name"
                                                value={commissionerData.name || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.name}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.debtor_number')}
                                            qaIdent="commissioner-account-party-id"
                                        >
                                            <InputField
                                                name="accountPartyId"
                                                value={commissionerData.accountPartyId || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.street')}
                                            qaIdent="commissioner-street"
                                        >
                                            <InputField
                                                name="street"
                                                value={commissionerData.street || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.post_code')}
                                            qaIdent="commissioner-post-code"
                                        >
                                            <InputField
                                                name="postCode"
                                                value={commissionerData.postCode || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.city')}
                                            qaIdent="commissioner-city"
                                        >
                                            <InputField
                                                name="city"
                                                value={commissionerData.city || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.country')}
                                            qaIdent="commissioner-country"
                                        >
                                            <Autocomplete
                                                name="country"
                                                value={commissionerData.country || ''}
                                                className={cx(['ace-c-select--small', 'global!ace-u-full-width'])}
                                                icon={arrowDownIcon}
                                            >
                                                {sortedCountries
                                                    .map(([countryCode, country]) => {
                                                        return (
                                                            <Option
                                                                key={country.id}
                                                                name={`country-${country.id}`}
                                                                value={countryCode}
                                                                className={cx('ace-c-option--small')}
                                                            >
                                                                {country.name}
                                                            </Option>
                                                        );
                                                    })
                                                }
                                            </Autocomplete>
                                        </DataRow>
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-6')}>
                                        <DataRow
                                            label={translateModal('data_row_label.phone')}
                                            qaIdent="commissioner-phone"
                                        >
                                            <InputField
                                                name="phoneNo"
                                                value={commissionerData.phoneNo || ''}
                                                className={cx('ace-c-input--small')}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.fax')}
                                            qaIdent="commissioner-fax"
                                        >
                                            <InputField
                                                name="faxNo"
                                                value={commissionerData.faxNo || ''}
                                                className={cx('ace-c-input--small')}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.email')}
                                            qaIdent="commissioner-email"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="email"
                                                value={commissionerData.email || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.email}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translateModal('data_row_label.website')}
                                            qaIdent="commissioner-website"
                                        >
                                            <InputField
                                                name="websiteUrl"
                                                value={commissionerData.websiteUrl || ''}
                                                className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                                            />
                                        </DataRow>
                                    </div>
                                </div>
                                <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-flex-end'])}>
                                    <ButtonPrimary
                                        name="submitCommissionerData"
                                        type="submit"
                                        isDisabled={isMandatoryFieldEmpty}
                                    >
                                        {translateModal('button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </Fragment>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

CreateCommissionerModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmCreateCommissioner: PropTypes.func.isRequired,
    declineCreateCommissioner: PropTypes.func.isRequired,
};

CreateCommissionerModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    confirmCreateCommissioner: payload => dispatch({
        type: commissionerActionTypes.CONFIRM_CREATE_COMMISSIONER,
        payload,
    }),
    declineCreateCommissioner: () => dispatch({
        type: commissionerActionTypes.DECLINE_CREATE_COMMISSIONER,
    }),
});

export default connect(null, mapDispatchToProps)(CreateCommissionerModal);
