import React from 'react';
import {Panel} from '@ace-de/ui-components';

const DashboardScreen = () => {
    return (
        <Panel>
            Dashboard screen
        </Panel>
    );
};

export default DashboardScreen;
