import {put, select, take, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {TemporaryRestriction} from '@ace-de/eua-entity-types';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';

const updateTemporaryRestrictionFlow = function* updateTemporaryRestrictionFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_UPDATE_TEMPORARY_RESTRICTION_FLOW);
        const {contractPartner, temporaryRestrictionId} = payload;

        yield* openModal(modalIds.CP_EDIT_TEMPORARY_RESTRICTION, {temporaryRestrictionId: `${temporaryRestrictionId}`});

        const responseAction = yield take([
            contractPartnerActionTypes.CONFIRM_UPDATE_TEMPORARY_RESTRICTION,
            contractPartnerActionTypes.DECLINE_UPDATE_TEMPORARY_RESTRICTION,
        ]);

        if (responseAction.type === contractPartnerActionTypes.CONFIRM_UPDATE_TEMPORARY_RESTRICTION) {
            const {temporaryRestrictionData} = responseAction.payload;
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST,
                partnerManagementService.updateContractPartnerTemporaryRestriction,
                {
                    temporaryRestrictionId,
                    temporaryRestrictionDTO: TemporaryRestriction.objectToPatchDTO(temporaryRestrictionData),
                },
            );

            const updateTemporaryRestrictionResponseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED,
            ]);

            if (!updateTemporaryRestrictionResponseAction.error) {
                const {response} = updateTemporaryRestrictionResponseAction.payload;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_TEMPORARY_RESTRICTIONS,
                    payload: {
                        contractPartnerId: contractPartner.id,
                        temporaryRestrictionDTOs: [response.contractPartnerRestrictionDTO],
                    },
                });

                yield* closeModal(modalIds.CP_EDIT_TEMPORARY_RESTRICTION, {temporaryRestrictionId: `${temporaryRestrictionId}`});
                // display success popup
                yield* openModal(modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL);
                continue;
            }
        }

        yield* closeModal(modalIds.CP_EDIT_TEMPORARY_RESTRICTION, {temporaryRestrictionId: `${temporaryRestrictionId}`});
    }
};

export default updateTemporaryRestrictionFlow;
