import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, RentalCarPricing, SPPricing} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonPrimary} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, closeIcon} from '@ace-de/ui-components/icons';
import {Form, NumberInputField} from '@ace-de/ui-components/form';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import * as serviceProviderSelectors from '../serviceProviderSelectors';

const formatPricingArray = data => {
    if (data.fixedPrice) {
        return [new SPPricing().fromDTO({
            ...{dailyFrom: 1},
            ...(data.fixedPrice && typeof parseFloat(data.fixedPrice) === 'number' ? {price: data.fixedPrice} : {price: null}),
            ...(data.fixedPrice && {isFlatPrice: true}),
        })];
    }

    return [new SPPricing().fromDTO({
        ...{dailyFrom: 1},
        ...(data.dailyPrice && typeof parseFloat(data.dailyPrice) === 'number' ? {price: data.dailyPrice} : {price: null}),
        ...(data.dailyPrice && {isFlatPrice: false}),
    })];
};

const initialErrorsState = {
    dailyPrice: '',
    fixedPrice: '',
};

const SPRentalCarAdditionalServicesDataModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateModal = createTranslateShorthand('sp_rental_car_additional_services_data_modal');
    const {hasBackdrop, serviceProvider, location} = props;
    const {confirmRentalCarPricingCreation, declineRentalCarPricingCreation, rentalCarPricing} = props;
    const {confirmRentalCarPricingUpdate, declineRentalCarPricingUpdate} = props;
    const [errors, setErrors] = useState(initialErrorsState);
    const queryParams = new URLSearchParams(location?.search || '');
    const additionalService = queryParams.get('additionalService');
    const pricingData = useMemo(() => (rentalCarPricing
        ? {
            ...rentalCarPricing,
            oneDayPrice: rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 1) || '',
        }
        : new RentalCarPricing()
    ), [rentalCarPricing]);

    const validateFormValues = formValues => {
        let hasError = false;
        const updatedErrors = {};

        // validate price range
        [
            'dailyPrice',
            'fixedPrice',
        ].forEach(field => {
            if (formValues[field] && typeof parseFloat(formValues[field]) === 'number'
                && !(parseFloat(formValues[field]) >= 0 && parseFloat(formValues[field]) <= 999.99)) {
                updatedErrors[field] = translateModal('error_message.invalid_price_range');
                hasError = true;
            }

            const twoDecimalsFloatRegex = /^\d+([.,]\d{1,2})?$/;
            if (formValues[field] && typeof parseFloat(formValues[field]) === 'number'
                && !twoDecimalsFloatRegex.test(formValues[field])) {
                updatedErrors[field] = translateModal('error_message.invalid_price_format');
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(prevState => ({
                ...prevState,
                ...updatedErrors,
            }));
        }

        if (!hasError) setErrors(initialErrorsState);
        return hasError;
    };

    const handleOnSubmit = formValues => {
        setErrors(initialErrorsState);
        const hasError = validateFormValues(formValues);
        if (hasError || (!formValues.dailyPrice && !formValues.fixedPrice)) return;

        const rentalCarPricingData = {
            partnerId: serviceProvider.id,
            additionalService,
            pricing: formatPricingArray(formValues),
        };

        if (rentalCarPricing) {
            confirmRentalCarPricingUpdate({
                rentalCarPricingData,
                rentalCarPricingId: rentalCarPricing.id,
            });
            return;
        }
        confirmRentalCarPricingCreation({
            rentalCarPricingData,
        });
    };

    return (
        <Modal
            title={translateModal('title.edit_rental_car_pricing_data')}
            action={(
                <Icon
                    icon={closeIcon}
                    onClick={rentalCarPricing ? declineRentalCarPricingUpdate : declineRentalCarPricingCreation}
                />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('ace-c-modal__content--full-bleed')}
        >
            <div>
                <Form
                    name="updateSPRentalCarAdditionalServicesForm"
                    onSubmit={handleOnSubmit}
                    isDisabled={serviceProvider.persistenceState === persistenceStates.PENDING}
                >
                    {formValues => {
                        const {oneDayPrice} = pricingData;
                        const fixedPrice = oneDayPrice?.isFlatPrice ? oneDayPrice?.price : '';
                        const dailyPrice = !oneDayPrice?.isFlatPrice ? oneDayPrice?.price : '';

                        return (
                            <div className={cx('global!ace-u-margin--32-0')}>
                                <Table qaIdent="sp-rental-car-additional-services-data">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.additional_services')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.daily_rate')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.fixed_price')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                {translate(`global.rental_car_additional_services.${additionalService.toLowerCase()}`) || ''}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="dailyPrice"
                                                    value={dailyPrice || ''}
                                                    className={cx('global!ace-u-full-width')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.dailyPrice ? [errors.dailyPrice] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="fixedPrice"
                                                    value={fixedPrice || ''}
                                                    className={cx('global!ace-u-full-width')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.fixedPrice ? [errors.fixedPrice] : []}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--justify-flex-end',
                                    ])}
                                >
                                    <ButtonPrimary
                                        name="submitRentalCarPricing"
                                        type="submit"
                                        className={cx([
                                            'global!ace-u-margin--top-64',
                                            'global!ace-u-margin--right-32',
                                        ])}
                                    >
                                        {translateModal('button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

SPRentalCarAdditionalServicesDataModal.propTypes = {
    declineRentalCarPricingCreation: PropTypes.func.isRequired,
    confirmRentalCarPricingCreation: PropTypes.func.isRequired,
    declineRentalCarPricingUpdate: PropTypes.func.isRequired,
    confirmRentalCarPricingUpdate: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    serviceProvider: PropTypes.object,
    rentalCarPricing: PropTypes.object,
    location: PropTypes.object,
};

SPRentalCarAdditionalServicesDataModal.defaultProps = {
    hasBackdrop: true,
    serviceProvider: null,
    rentalCarPricing: null,
    location: {},
};

const mapStateToProps = (state, props) => {
    const serviceProviderSelector = serviceProviderSelectors.createServiceProviderSelector();
    const pricingDataSelector = serviceProviderSelectors.createRentalCarAdditionalServicePricingSelector();

    return {
        serviceProvider: serviceProviderSelector(state, props),
        rentalCarPricing: pricingDataSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineRentalCarPricingCreation: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_CREATE_SP_RENTAL_CAR_PRICING,
    }),
    confirmRentalCarPricingCreation: payload => dispatch({
        type: serviceProviderActionTypes.CONFIRM_CREATE_SP_RENTAL_CAR_PRICING,
        payload,
    }),
    declineRentalCarPricingUpdate: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_UPDATE_SP_RENTAL_CAR_PRICING,
    }),
    confirmRentalCarPricingUpdate: payload => dispatch({
        type: serviceProviderActionTypes.CONFIRM_UPDATE_SP_RENTAL_CAR_PRICING,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SPRentalCarAdditionalServicesDataModal);
