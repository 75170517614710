import {put} from 'redux-saga/effects';
import config from '../../config';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

const loadServiceProviderSearchResults = function* loadServiceProviderSearchResults({payload}) {
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('sort', 'name,asc');
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);

    yield put({
        type: serviceProviderActionTypes.SEARCH_SERVICE_PROVIDERS,
        payload: {searchQueryParams},
    });
};

export default loadServiceProviderSearchResults;
