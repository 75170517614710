import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles} from '@ace-de/ui-components';
import {HeaderBar, AppLayoutMain, ContentItem} from '@ace-de/ui-components/app-layout';
import * as commissionerSelectors from './commissionerSelectors';
import CommissionerHeader from './ui-elements/CommissionerHeader';
import CommissionerBasicDataPanel from './ui-elements/CommissionerBasicDataPanel';

const CommissionerScreen = props => {
    const {cx} = useStyles();
    const {commissioner} = props;

    return (
        <Fragment>
            <HeaderBar className={cx('ace-c-header-bar--primary-navigation')}>
                <CommissionerHeader commissioner={commissioner} />
            </HeaderBar>
            <AppLayoutMain>
                <ContentItem>
                    <CommissionerBasicDataPanel commissioner={commissioner} />
                </ContentItem>
            </AppLayoutMain>
        </Fragment>
    );
};

CommissionerScreen.propTypes = {
    commissioner: PropTypes.object,
};

CommissionerScreen.defaultProps = {
    commissioner: {},
};

const mapStateToProps = (state, props) => {
    const createCommissionerSelector = commissionerSelectors.createCommissionerSelector();

    return {
        commissioner: createCommissionerSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(CommissionerScreen);
