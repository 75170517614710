import {take, put, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {RentalCarPricing} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import modalIds from '../../modalIds';

const updateSPRentalCarPricingFlow = function* updateSPRentalCarPricingFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(serviceProviderActionTypes.INITIATE_UPDATE_SP_RENTAL_CAR_PRICE_DATA_FLOW);
        const {serviceProviderId, pricingDataId} = payload;

        yield* openModal(modalIds.SP_RENTAL_CAR_PRICING_DATA, {pricingDataId: `${pricingDataId}`});

        const chosenModalOption = yield take([
            serviceProviderActionTypes.CONFIRM_UPDATE_SP_RENTAL_CAR_PRICING,
            serviceProviderActionTypes.DECLINE_UPDATE_SP_RENTAL_CAR_PRICING,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceProviderActionTypes.CONFIRM_UPDATE_SP_RENTAL_CAR_PRICING
        ) {
            const {rentalCarPricingData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                serviceProviderActionTypes.UPDATE_SP_RENTAL_CAR_PRICING_REQUEST,
                partnerManagementService.updateSPRentalCarPricing,
                {
                    rentalCarPricingPatchDTO: RentalCarPricing.objectToPatchDTO(rentalCarPricingData),
                    rentalCarPricingId: pricingDataId,
                },
            );

            const responseAction = yield take([
                serviceProviderActionTypes.UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED,
                serviceProviderActionTypes.UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {rentalCarPricingDTO} = response;

                yield put({
                    type: serviceProviderActionTypes.STORE_SERVICE_PROVIDER_RENTAL_CAR_PRICING,
                    payload: {
                        rentalCarPricingDTOs: [rentalCarPricingDTO],
                        serviceProviderId,
                        rentalCarPricingId: pricingDataId,
                    },
                });
            }
        }

        yield* closeModal(modalIds.SP_RENTAL_CAR_PRICING_DATA, {pricingDataId: `${pricingDataId}`});
    }
};

export default updateSPRentalCarPricingFlow;
