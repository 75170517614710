import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, NoResultsBlock} from '@ace-de/ui-components';
import {InteractiveIcon, plusIcon, findCaseIcon, Icon, deleteIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell, TableCaption} from '@ace-de/ui-components/data-elements';
import * as serviceProviderSelectors from '../serviceProviderSelectors';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import config from '../../config';

const ServiceProviderRentalCarPricesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateTab = createTranslateShorthand('service_provider_rental_car_prices_panel');
    const {serviceProvider, initiateCreateSPRentalCarPriceData, initiateDeleteSPRentalCarPriceData} = props;
    const {initiateUpdateRCAdditionalServicesPriceData, initiateCreateRCAdditionalServicesPriceData} = props;
    const {initiateUpdateSPRentalCarPriceData, rentalCarAdditionalServicesMap} = props;

    const sortedSPRentalCarPricing = useMemo(() => {
        if (!serviceProvider?.rentalCarPricing) return [];
        return [...serviceProvider.rentalCarPricing].sort((serviceProviderA, serviceProviderB) => {
            return serviceProviderA.id < serviceProviderB.id ? -1 : 1;
        });
    }, [serviceProvider.rentalCarPricing]);

    const getPrice = (price, showCurrency = true) => {
        if (typeof price === 'number') {
            return price.toLocaleString(activeLocale, {
                ...(showCurrency ? {style: 'currency'} : {}),
                ...(showCurrency ? {currency: config.CURRENCY} : {}),
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    const handleOnCreateRentalCarPrices = () => {
        initiateCreateSPRentalCarPriceData({
            serviceProviderId: serviceProvider.id,
        });
    };

    const handleOnDeleteRentalCarPrices = pricingDataId => {
        initiateDeleteSPRentalCarPriceData({
            serviceProviderId: serviceProvider.id,
            pricingDataId,
        });
    };

    const handleOnEditRentalCarPrices = pricingDataId => {
        initiateUpdateSPRentalCarPriceData({
            serviceProviderId: serviceProvider.id,
            pricingDataId,
        });
    };

    const handleOnEditAdditionalServicePrices = additionalService => {
        if (rentalCarAdditionalServicesMap.get(additionalService)) {
            initiateUpdateRCAdditionalServicesPriceData({
                serviceProviderId: serviceProvider.id,
                additionalService,
            });
            return;
        }

        initiateCreateRCAdditionalServicesPriceData({
            serviceProviderId: serviceProvider.id,
            additionalService,
        });
    };

    if (!serviceProvider) return null;

    return (
        <Panel
            title={translateTab('title.rental_car_price_list')}
            className={cx('ace-c-panel--full-bleed-content')}
            actions={(
                <InteractiveIcon
                    icon={plusIcon}
                    className={cx([
                        'ace-c-interactive-icon--primary',
                        'ace-c-interactive-icon--highlight',
                    ])}
                    onClick={handleOnCreateRentalCarPrices}
                >
                    {translateTab('button_label.add_new_category')}
                </InteractiveIcon>
            )}
        >
            <Table qaIdent="sp-rental-car-price-list">
                {!serviceProvider?.rentalCarPricing?.length && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            message={translateTab('no_results.message')}
                            description={translateTab('no_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="sp-price-sipp-code">
                            {translateTab('table_cell.sipp_code')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="sp-price-one-day">
                            {translateTab('table_cell.one_day_price')}
                        </TableCell>
                        <TableCell colSpan={2}>{translateTab('table_cell.three_days_price')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('table_cell.five_days_price')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('table_cell.six_days_price')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('table_cell.seven_days_price')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('table_cell.tariff_price')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('table_cell.eight_days_price')}</TableCell>
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedSPRentalCarPricing.filter(price => !price.isDeleted && !price.additionalService)
                        .map(price => {
                            const oneDayPrice = price.pricing?.find(pricing => pricing.dailyFrom === 1)?.price;
                            const threeDaysPrice = price.pricing?.find(pricing => pricing.dailyFrom === 3)?.price;
                            const fiveDaysPrice = price.pricing?.find(pricing => pricing.dailyFrom === 5)?.price;
                            const sixDaysPrice = price.pricing?.find(pricing => pricing.dailyFrom === 6)?.price;
                            const sevenDaysPrice = price.pricing?.find(pricing => pricing.dailyFrom === 7)?.price;
                            const eightDaysPrice = price.pricing?.find(pricing => pricing.dailyFrom === 8)?.price;
                            const tariffPrice = price.pricing?.find(pricing => pricing.isFlatPrice)?.price;
                            return (
                                <TableRow
                                    key={price.id}
                                    qaIdentPart={price.id}
                                >
                                    <TableCell
                                        colSpan={2}
                                        qaIdentPart="sp-price-sipp-code"
                                        qaIdentPartPostfix={price.id}
                                    >
                                        {price.sippCode || ''}
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        qaIdentPart="sp-price-one-day"
                                        qaIdentPartPostfix={price.id}
                                    >
                                        {typeof oneDayPrice === 'number' ? getPrice(oneDayPrice) : '-'}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {typeof threeDaysPrice === 'number' ? getPrice(threeDaysPrice) : '-'}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {typeof fiveDaysPrice === 'number' ? getPrice(fiveDaysPrice) : '-'}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {typeof sixDaysPrice === 'number' ? getPrice(sixDaysPrice) : '-'}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {typeof sevenDaysPrice === 'number' ? getPrice(sevenDaysPrice) : '-'}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {typeof tariffPrice === 'number' ? getPrice(tariffPrice) : '-'}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {typeof eightDaysPrice === 'number' ? getPrice(eightDaysPrice) : '-'}
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                        <InteractiveIcon
                                            icon={editIcon}
                                            onClick={() => handleOnEditRentalCarPrices(price.id)}
                                            className={cx('global!ace-u-margin--right-16')}
                                        />
                                        <InteractiveIcon
                                            icon={deleteIcon}
                                            onClick={() => handleOnDeleteRentalCarPrices(price.id)}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <div
                className={cx([
                    'global!ace-u-margin--top-64',
                    'global!ace-u-margin--bottom-32',
                    'global!ace-u-margin--left-32',
                    'global!ace-u-typography--variant-h3',
                ])}
            >
                {translateTab('title.rental_car_additional_services')}
            </div>
            <Table qaIdent="sp-rental-car-additional-services">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>
                            {translateTab('table_cell.additional_services')}
                        </TableCell>
                        <TableCell colSpan={4}>
                            {translateTab('table_cell.daily_rate')}
                        </TableCell>
                        <TableCell colSpan={4}>{translateTab('table_cell.fixed_price')}</TableCell>
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...rentalCarAdditionalServicesMap.entries()].map((entry, idx) => {
                        const additionalService = entry[0];
                        const priceData = entry[1];
                        const oneDayPrice = priceData?.pricing?.find(pricing => pricing.dailyFrom === 1);
                        const fixedPrice = oneDayPrice?.isFlatPrice ? getPrice(oneDayPrice?.price) : '-';
                        const dailyPrice = !oneDayPrice?.isFlatPrice ? getPrice(oneDayPrice?.price) : '-';

                        return (
                            <TableRow key={idx}>
                                <TableCell colSpan={3}>
                                    {translate(`global.rental_car_additional_services.${additionalService.toLowerCase()}`) || ''}
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {dailyPrice || '-'}
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {fixedPrice || '-'}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => handleOnEditAdditionalServicePrices(additionalService)}
                                        className={cx('global!ace-u-margin--right-16')}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

ServiceProviderRentalCarPricesPanel.propTypes = {
    serviceProvider: PropTypes.object,
    rentalCarAdditionalServicesMap: PropTypes.object,
    initiateCreateSPRentalCarPriceData: PropTypes.func.isRequired,
    initiateDeleteSPRentalCarPriceData: PropTypes.func.isRequired,
    initiateUpdateSPRentalCarPriceData: PropTypes.func.isRequired,
    initiateUpdateRCAdditionalServicesPriceData: PropTypes.func.isRequired,
    initiateCreateRCAdditionalServicesPriceData: PropTypes.func.isRequired,
};

ServiceProviderRentalCarPricesPanel.defaultProps = {
    serviceProvider: null,
    rentalCarAdditionalServicesMap: null,
};

const mapStateToProps = (state, props) => {
    const createServiceProviderSelector = serviceProviderSelectors.createServiceProviderSelector();

    return {
        serviceProvider: createServiceProviderSelector(state, props),
        rentalCarAdditionalServicesMap: serviceProviderSelectors.getRentalCarAdditionalServicesMap(state),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateSPRentalCarPriceData: payload => dispatch({
        type: serviceProviderActionTypes.INITIATE_CREATE_SP_RENTAL_CAR_PRICE_DATA_FLOW,
        payload,
    }),
    initiateDeleteSPRentalCarPriceData: payload => dispatch({
        type: serviceProviderActionTypes.INITIATE_DELETE_SP_RENTAL_CAR_PRICE_DATA_FLOW,
        payload,
    }),
    initiateUpdateSPRentalCarPriceData: payload => dispatch({
        type: serviceProviderActionTypes.INITIATE_UPDATE_SP_RENTAL_CAR_PRICE_DATA_FLOW,
        payload,
    }),
    initiateUpdateRCAdditionalServicesPriceData: payload => dispatch({
        type: serviceProviderActionTypes.INITIATE_UPDATE_RC_ADDITIONAL_SERVICES_PRICE_DATA_FLOW,
        payload,
    }),
    initiateCreateRCAdditionalServicesPriceData: payload => dispatch({
        type: serviceProviderActionTypes.INITIATE_CREATE_RC_ADDITIONAL_SERVICES_PRICE_DATA_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceProviderRentalCarPricesPanel));
