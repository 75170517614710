import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {checkmarkIcon, closeIcon, editIcon, Icon, Panel, useStyles, InteractiveIcon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as inaRulesActionTypes from './inaRulesActionTypes';

const INARulesScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('ina_rules_screen');
    const {inaRules, initiateINARuleEditFlow} = props;

    return (
        <Panel
            title={translateScreen('panel_title.ina_rules')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="ina-rules-list">
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="isActive">
                            {translateScreen('table_header.active')}
                        </TableCell>
                        <TableCell colSpan={5} qaIdentPart="name">
                            {translateScreen('table_header.rule')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="channels">
                            {translateScreen('table_header.channel')}
                        </TableCell>
                        <TableCell colSpan={3} qaIdentPart="variables">
                            {translateScreen('table_header.variable')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inaRules.map(inaRule => (
                        <TableRow
                            key={inaRule.id}
                            qaIdentPart={inaRule.id}
                        >
                            <TableCell qaIdentPartPostfix={inaRule.id} qaIdentPart="isActive">
                                <Icon
                                    icon={inaRule.isActive ? checkmarkIcon : closeIcon}
                                    className={cx({
                                        'ace-c-icon--color-success': inaRule.isActive,
                                        'ace-c-icon--color-warning': !inaRule.isActive,
                                    })}
                                />
                            </TableCell>
                            <TableCell colSpan={5} qaIdentPartPostfix={inaRule.id} qaIdentPart="rule">
                                {translateScreen(`ina_rules.${inaRule.name.toLowerCase()}`)}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={inaRule.id} qaIdentPart="channel">
                                {[...inaRule.channels].sort((channelA, channelB) => {
                                    return channelA > channelB ? 1 : -1;
                                }).join(', ')}
                            </TableCell>
                            <TableCell colSpan={3} qaIdentPartPostfix={inaRule.id} qaIdentPart="variables">
                                {inaRule.variables && Object.keys(inaRule.variables).map(key => (
                                    translateScreen(`${key === 'varX' ? 'ina_variable_x' : 'ina_variable_y'}.${inaRule.name.toLowerCase()}`, {[key]: inaRule.variables[key]})
                                )).join(', ')}
                            </TableCell>
                            <TableCell qaIdentPartPostfix={inaRule.id}>
                                <InteractiveIcon
                                    icon={editIcon}
                                    onClick={() => initiateINARuleEditFlow({inaRuleId: inaRule.id})}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Panel>
    );
};

INARulesScreen.propTypes = {
    inaRules: PropTypes.array,
    initiateINARuleEditFlow: PropTypes.func.isRequired,
};

INARulesScreen.defaultProps = {
    inaRules: [],
};

const mapStateToProps = state => {
    return {
        inaRules: state.inaRules.inaRules,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateINARuleEditFlow: payload => dispatch({
        type: inaRulesActionTypes.INITIATE_INA_RULE_EDIT_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(INARulesScreen);
