import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmACEPartnerServiceTypes} from '@ace-de/eua-entity-types';

const VEHICLE_RELATED_SERVICES = [
    apmACEPartnerServiceTypes.TOWING,
    apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
    apmACEPartnerServiceTypes.RECOVERY,
    apmACEPartnerServiceTypes.VEHICLE_OPENING,
    apmACEPartnerServiceTypes.VEHICLE_RETURN_SERVICE,
    apmACEPartnerServiceTypes.TRAILER_RETURN,
    apmACEPartnerServiceTypes.PICKUP,
    apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING,
    apmACEPartnerServiceTypes.VEHICLE_STORAGE,
    apmACEPartnerServiceTypes.SPARE_PARTS_SERVICE,
    apmACEPartnerServiceTypes.REPAIR_SERVICE,
];
const PERSON_RELATED_SERVICES = [
    apmACEPartnerServiceTypes.MEDICAL_CLEARANCE,
    apmACEPartnerServiceTypes.SICK_PERSON_TRANSPORT,
    apmACEPartnerServiceTypes.REPLACEMENT_DRIVER,
    apmACEPartnerServiceTypes.MEDICAL_ADVICE,
    apmACEPartnerServiceTypes.MEDICINE_SHIPMENT,
    apmACEPartnerServiceTypes.LEGAL_ADVICE,
    apmACEPartnerServiceTypes.TRAVEL_CANCELLATION,
    apmACEPartnerServiceTypes.LUGGAGE_SERVICE,
    apmACEPartnerServiceTypes.RESCUE_RECOVERY,
    apmACEPartnerServiceTypes.HELP_IN_CASES_OF_EMERGENCY,
    apmACEPartnerServiceTypes.HELP_IN_CASE_OF_DEATH,
    apmACEPartnerServiceTypes.SICK_VISIT,
    apmACEPartnerServiceTypes.RETURN_SERVICE_FOR_KIDS,
    apmACEPartnerServiceTypes.RETURN_SERVICE_FOR_PETS,
];
const HOME_RELATED_SERVICES = [
    apmACEPartnerServiceTypes.BIKE_SERVICE,
    apmACEPartnerServiceTypes.DOCUMENT_SERVICE,
    apmACEPartnerServiceTypes.CREDIT_SERVICE,
    apmACEPartnerServiceTypes.GLASSES_SERVICE,
    apmACEPartnerServiceTypes.NOTIFICATION_SERVICE,
    apmACEPartnerServiceTypes.GLASS_DAMAGE,
    apmACEPartnerServiceTypes.ELECTRICITY_SANITY_SERVICE,
    apmACEPartnerServiceTypes.REMOVAL_OF_WASP_NESTS,
    apmACEPartnerServiceTypes.REPLACEMENT_APARTMENT,
    apmACEPartnerServiceTypes.HOME_KEY_SERVICE,
    apmACEPartnerServiceTypes.DOOR_LOCK_SERVICE,
    apmACEPartnerServiceTypes.OTHER_SERVICE,
];
const CONNECTION_SERVICES = [
    apmACEPartnerServiceTypes.RENTAL_CAR,
    apmACEPartnerServiceTypes.ACCOMMODATION,
    apmACEPartnerServiceTypes.RETURN_JOURNEY,
    apmACEPartnerServiceTypes.SHORT_TRIP,
];

const useServiceProviderServiceTypes = () => {
    const {translate} = useTranslate();
    if (!translate) return [];

    const sortServiceTypesAlphabetically = serviceTypes => {
        return [...serviceTypes].sort((typeA, typeB) => {
            const translationA = translate(`global.ace_partner_service_type.${snakeCase(typeA)}`);
            const translationB = translate(`global.ace_partner_service_type.${snakeCase(typeB)}`);
            return translationA[0].localeCompare(translationB[0]);
        });
    };

    const sortedVehicleRelatedServiceTypes = sortServiceTypesAlphabetically(VEHICLE_RELATED_SERVICES);
    const sortedPersonRelatedServiceTypes = sortServiceTypesAlphabetically(PERSON_RELATED_SERVICES);
    const sortedHomeRelatedServiceTypes = sortServiceTypesAlphabetically(HOME_RELATED_SERVICES);
    const sortedConnectionRelatedServiceTypes = sortServiceTypesAlphabetically(CONNECTION_SERVICES);

    const serviceTypesMatrix = [
        sortedVehicleRelatedServiceTypes,
        sortedPersonRelatedServiceTypes,
        sortedHomeRelatedServiceTypes,
        sortedConnectionRelatedServiceTypes,
    ];

    // take the largest chunk (serviceTypeMatrix[1] <=> sortedPersonRelatedServiceTypes)
    return serviceTypesMatrix[1].map((column, columnIndex) => (
        serviceTypesMatrix.map(row => row[columnIndex])
    ));
};

export default useServiceProviderServiceTypes;
