import '@computerrock/react-app-polyfill/ie11';
import '@computerrock/react-app-polyfill/stable';
import React, {useState, useEffect} from 'react';
import * as ReactDOMClient from 'react-dom/client';
import PropTypes from 'prop-types';
import {FormationApp, configureStore, serviceWorker} from '@computerrock/formation-core';
import {createBrowserHistory, renderRoutes} from '@computerrock/formation-router';
import {ArcGISMapProvider} from '@ace-de/eua-arcgis-map';
import {FileAssetsProvider} from '@ace-de/eua-file-assets';
import './application/initDynamicEnv';
import config from './config';
import getRoutes from './routes';
import routePaths from './routePaths';
import modals from './modals';
import reducers from './reducers';
import sagas from './sagas';
import serviceManager from './serviceManager';

// initialize history
const history = createBrowserHistory({basename: config.PUBLIC_URL});

// initialize store
const store = configureStore({
    routes: getRoutes(),
    history,
    reducers,
    serviceManager,
});
store.runSagas(sagas);

// load i18nService
const i18nService = serviceManager.loadService('i18nService');

// load arcGISMapService
const arcGISMapService = serviceManager.loadService('arcGISMapService');

// Application
const Application = ({store, history}) => {
    const [routes, setRoutes] = useState(getRoutes());

    useEffect(() => {
        return window.DYNAMIC_ENV.onEnvChange((property, value) => {
            if (property === 'IS_MAINTENANCE_MODE_ON') {
                setRoutes(getRoutes());
                console.log(`Dynamic ENV update: ${property}=${value}`); // eslint-disable-line no-console
            }
        });
    }, []);

    return (
        <FormationApp
            store={store}
            history={history}
            modals={modals}
            routes={routes}
            routePaths={routePaths}
            defaultRoutePath={routePaths.DASHBOARD}
            i18nService={i18nService}
            render={({children}) => (
                <FileAssetsProvider>
                    <ArcGISMapProvider service={arcGISMapService}>
                        {children}
                    </ArcGISMapProvider>
                </FileAssetsProvider>
            )}
        >
            {renderRoutes(routes)}
        </FormationApp>
    );
};

Application.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

// render application
const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<Application store={store} history={history} />);


// webpack hot module replacement for ./routes configuration,
// ./saga configuration and state is not hot reloaded but needs to
// be accepted so HMR can work properly
if (module.hot) {
    module.hot.accept(['./routes', './sagas'], () => {
        root.render(<Application store={store} history={history} />);
    });
}

// webpack hot module replacement for reducers
if (module.hot) {
    module.hot.accept('./reducers', () => {
        const nextReducers = require('./reducers').default;
        store.hotReloadReducers(nextReducers);
    });
}

// register service worker
serviceWorker.register({
    // reload on new content
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            });
            waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
        }
    },
});
