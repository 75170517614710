import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {User, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as userProfileActionTypes from '../userProfileActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import successMessageTypes from '../../application/successMessageTypes';
import errorTypes from '../../application/errorTypes';

/**
 * User profile create flow (uses modal)
 * creates a new user on the EUP
 */
const createUserProfileFlow = function* createUserProfileFlow() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');
    let shouldWaitForAction = true;
    let shouldRetryCreationAction = false;
    let creationPayload = null;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            yield take(userProfileActionTypes.INITIATE_CREATE_USER_PROFILE_FLOW);

            yield* openModal(modalIds.USER_DETAILS);

            chosenModalOption = yield take([
                userProfileActionTypes.CONFIRM_CREATE_USER_PROFILE,
                userProfileActionTypes.DECLINE_CREATE_USER_PROFILE,
            ]);
        }

        if ((chosenModalOption
            && chosenModalOption.type === userProfileActionTypes.CONFIRM_CREATE_USER_PROFILE)
            || shouldRetryCreationAction) {
            const userProfileData = shouldRetryCreationAction ? creationPayload : chosenModalOption.payload;
            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                userProfileActionTypes.CREATE_USER_PROFILE_REQUEST,
                userProfileService.createUserProfile,
                {
                    userProfileDTO: User.objectToDTO(userProfileData),
                },
            );
            const responseAction = yield take([
                userProfileActionTypes.CREATE_USER_PROFILE_REQUEST_SUCCEEDED,
                userProfileActionTypes.CREATE_USER_PROFILE_REQUEST_FAILED,
            ]);

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {userProfileDTO} = response;

                yield put({
                    type: userProfileActionTypes.STORE_USER_DETAILS,
                    payload: {userProfileDTO},
                });
                shouldWaitForAction = true;
                shouldRetryCreationAction = false;
                creationPayload = null;
                yield* closeModal(modalIds.USER_DETAILS);
                yield put({
                    type: applicationActionTypes.INITIATE_SUCCESS_MESSAGE_FLOW,
                    payload: {successMessageType: successMessageTypes.USER_CREATED},
                });
                continue;
            }

            if (responseAction.error) {
                yield* closeModal(modalIds.USER_DETAILS);
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.USER_CREATION_FAILED});

                const nextAction = yield take([
                    userProfileActionTypes.RETRY_USER_CREATION,
                    userProfileActionTypes.CANCEL_USER_CREATION,
                ]);

                if (nextAction.type === userProfileActionTypes.RETRY_USER_CREATION) {
                    shouldWaitForAction = false;
                    shouldRetryCreationAction = true;
                    creationPayload = userProfileData;

                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    continue;
                }
                if (nextAction.type === userProfileActionTypes.CANCEL_USER_CREATION) {
                    shouldWaitForAction = true;
                    shouldRetryCreationAction = false;
                    creationPayload = null;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }
            }
        }
        yield* closeModal(modalIds.USER_DETAILS);
    }
};

export default createUserProfileFlow;
