import {take, select, put, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';

const contractPartnerContactDetailsUpdateFlow = function* contractPartnerContactDetailsUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let payloadContent;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.SUBMIT_CP_CONTACT_DETAILS_FORM);
            payloadContent = payload;
        }
        const {retryActionPersistenceState} = yield select(state => state.application);

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.UPDATE_CP_CONTACT_DETAILS_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(payloadContent.contractPartnerData),
                    partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                },
                acePartnerId: payloadContent.contractPartnerId,
            },
        );

        const updateContractPartnerStatusResponseAction = yield take([
            contractPartnerActionTypes.UPDATE_CP_CONTACT_DETAILS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.UPDATE_CP_CONTACT_DETAILS_REQUEST_FAILED,
        ]);

        if (retryActionPersistenceState === persistenceStates.PENDING) {
            yield put({
                type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
        }

        if (!updateContractPartnerStatusResponseAction.error) {
            const {response} = updateContractPartnerStatusResponseAction.payload;
            const {acePartnerDTO} = response;

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {contractPartnerDTOs: [acePartnerDTO]},
            });
            if (!shouldWaitForAction) {
                shouldWaitForAction = true;
                payloadContent = null;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
            }
        }

        if (updateContractPartnerStatusResponseAction.error) {
            yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.CP_BASIC_INFO_UPDATE_FAILED});

            const nextAction = yield take([
                contractPartnerActionTypes.RETRY_CP_CONTACT_DETAILS_UPDATE,
                contractPartnerActionTypes.CANCEL_CP_CONTACT_DETAILS_UPDATE,
            ]);

            if (nextAction.type === contractPartnerActionTypes.RETRY_CP_CONTACT_DETAILS_UPDATE) {
                shouldWaitForAction = false;
                if (retryActionPersistenceState !== persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }
                continue;
            }

            if (nextAction.type === contractPartnerActionTypes.CANCEL_CP_CONTACT_DETAILS_UPDATE) {
                shouldWaitForAction = true;
                payloadContent = null;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
            }
        }
    }
};

export default contractPartnerContactDetailsUpdateFlow;
