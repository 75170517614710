import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';

const unlockServiceCase = function* unlockServiceCase({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const {serviceCaseId, userId} = payload;

    yield fork(
        fetchRequest,
        userProfileActionTypes.UNLOCK_SERVICE_CASE_REQUEST,
        ecsFlowService.privilegedUnlockServiceCase,
        {
            serviceCaseId,
        },
    );

    const responseAction = yield take([
        userProfileActionTypes.UNLOCK_SERVICE_CASE_REQUEST_SUCCEEDED,
        userProfileActionTypes.UNLOCK_SERVICE_CASE_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        yield put({
            type: userProfileActionTypes.SET_LOCKED_SERVICE_CASE_RESULT,
            payload: {userId, serviceCaseId},
        });
    }
};

export default unlockServiceCase;
