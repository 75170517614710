import {all, fork} from 'redux-saga/effects';
import initiateAssistanceFeesEditFlow from './sagas/initiateAssistanceFeesEditFlow';
import initiateEstimationEditFlow from './sagas/initiateEstimationEditFlow';

/**
 *  INA rules watcher saga
 */
const feesAndEstimationWatcher = function* feesAndEstimationWatcher() {
    yield all([
        fork(initiateAssistanceFeesEditFlow),
        fork(initiateEstimationEditFlow),
    ]);
};

export default feesAndEstimationWatcher;
