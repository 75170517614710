import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Modal, HighlightCircle, useStyles, ButtonSecondary, ButtonPrimary, ContentItem, ContentBlock} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, warningOutlinedIcon} from '@ace-de/ui-components/icons';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

const DeactivateSPConfirmationModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, declineServiceProviderDeactivate, confirmServiceProviderDeactivate} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('deactivate_sp_confirmation_modal');

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineServiceProviderDeactivate}
                />
            )}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--large-span-11',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            'ace-c-highlight-circle--warning',
                        ])}
                    >
                        <Icon
                            icon={warningOutlinedIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-warning',
                            ])}
                        />
                    </HighlightCircle>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        {translateModal('title.attention')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal('error_message.deactivate_sp')}
                    </p>
                </ContentItem>
                <ContentItem
                    className={cx([
                        'global!ace-u-flex--direction-column',
                        'global!ace-u--margin-0',
                    ])}
                >
                    <ButtonPrimary
                        className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                        onClick={confirmServiceProviderDeactivate}
                    >
                        {translateModal('button.deactivate')}
                    </ButtonPrimary>
                    <ButtonSecondary
                        className={cx('global!ace-u-full-width')}
                        onClick={declineServiceProviderDeactivate}
                    >
                        {translateModal('button.cancel')}
                    </ButtonSecondary>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

DeactivateSPConfirmationModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineServiceProviderDeactivate: PropTypes.func.isRequired,
    confirmServiceProviderDeactivate: PropTypes.func.isRequired,
};

DeactivateSPConfirmationModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    declineServiceProviderDeactivate: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_SERVICE_PROVIDER_DEACTIVATE,
    }),
    confirmServiceProviderDeactivate: () => dispatch({
        type: serviceProviderActionTypes.CONFIRM_SERVICE_PROVIDER_DEACTIVATE,
    }),
});

export default connect(null, mapDispatchToProps)(DeactivateSPConfirmationModal);

