/**
 * Modal ids
 */
export default {
    MODAL_ID: 'modal-id',
    USER_DETAILS: 'user-details',
    FETCH_USER_DATA_FAILED_WARNING: 'fetch-user-data-failed-warning',
    CREATE_COMMISSIONER: 'create-commissioner',
    DEACTIVATE_SP_CONFIRMATION: 'deactivate-sp-confirmation',
    STANDBY_TIMES_DATA_MODAL: 'standby-times-data-modal',
    SP_RENTAL_CAR_PRICING_DATA: 'sp-rental-car-pricing-data',
    SP_RENTAL_CAR_ADDITIONAL_SERVICE_PRICING_DATA: 'sp-rental-car-additional-service-pricing-data',
    DELETE_SP_PRICING_DATA_CONFIRMATION: 'delete-sp-pricing-data-confirmation',
    CP_STATUS_UPDATE: 'cp-status-update',
    CP_ASSIGNMENT_CHANNELS_UPDATE: 'cp-assignment-channels-update',
    CP_CREATE_TEMPORARY_RESTRICTION: 'cp-create-temporary-restriction',
    CP_EDIT_TEMPORARY_RESTRICTION: 'cp-edit-temporary-restriction',
    CP_TEMPORARY_RESTRICTION_SUCCESSFUL: 'cp-temporary-restriction-successful',
    CONTRACT_PARTNER_CONTACT_DATA: 'contract-partner-contact-data',
    DELETE_CP_CONTACT_DATA_CONFIRMATION: 'delete-cp-contact-data-confirmation',
    INA_RULES_EDIT_MODAL: 'ina-rules-edit-modal',
    ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL: 'assistance-fees-and-estimation-edit-modal',
    CREATE_TIME_PERIOD_MODAL: 'create-time-period-modal',
    EDIT_BUDGETS_MODAL: 'edit-budgets-modal',
    ERROR_MESSAGE_MODAL: 'error-message-modal',
    SUCCESS_MESSAGE_MODAL: 'success-message-modal',
};
