import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';

const contractPartnerAssignmentChannelsUpdateFlow = function* contractPartnerAssignmentChannelsUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let payloadContent;
    let chosenModalOption;

    while (true) {
        if (shouldWaitForAction) {
            yield take(contractPartnerActionTypes.INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW);

            yield* openModal(modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE);

            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE,
                contractPartnerActionTypes.DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE,
            ]);
        }

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE) {
            if (shouldWaitForAction) {
                payloadContent = chosenModalOption.payload;
            }
            const {retryActionPersistenceState} = yield select(state => state.application);

            const {contractPartnerData, contractPartnerId} = payloadContent;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST,
                partnerManagementService.updateACEPartner,
                {
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO(contractPartnerData),
                        partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                    },
                    acePartnerId: contractPartnerId,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_FAILED,
            ]);

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {acePartnerDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                    payload: {contractPartnerDTOs: [acePartnerDTO]},
                });

                if (!shouldWaitForAction) {
                    shouldWaitForAction = true;
                    payloadContent = null;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }
            }

            if (responseAction.error) {
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                    errorType: errorTypes.CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED,
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE,
                    contractPartnerActionTypes.CANCEL_CP_ASSIGNMENT_CHANNELS_UPDATE,
                ]);

                if (nextAction.type === contractPartnerActionTypes.RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE) {
                    shouldWaitForAction = false;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    continue;
                }
                if (nextAction.type === contractPartnerActionTypes.CANCEL_CP_ASSIGNMENT_CHANNELS_UPDATE) {
                    shouldWaitForAction = true;
                    payloadContent = null;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }
            }
        }

        yield* closeModal(modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE);
    }
};

export default contractPartnerAssignmentChannelsUpdateFlow;
