import {createSelector} from 'reselect';
import moment from 'moment';

/**
 * General state selectors
 */
export const getServiceProviders = state => state.serviceProviders.serviceProviders;
export const getServiceProviderSearchResults = state => state.serviceProviders.serviceProviderSearchResults;
export const getServiceProviderSearchResultsCount = state => state.serviceProviders.serviceProviderSearchResultsCount;
export const getEmergencyLawyers = state => state.serviceProviders.emergencyLawyers;
export const getStandbyTimesMap = state => state.serviceProviders.standbyTimesMap;
export const getRentalCarAdditionalServicesMap = state => state.serviceProviders.rentalCarAdditionalServicesMap;

/**
 * Returns selector for serviceProvider matched from route
 */
export const createServiceProviderSelector = () => createSelector(
    [
        getServiceProviders,
        (state, props) => {
            const {match} = props;
            return match?.params['serviceProviderId'];
        },
    ],
    (serviceProviders, serviceProviderId) => {
        if (!serviceProviderId) return null;
        return serviceProviders[serviceProviderId];
    },
);

/**
 * Returns selector for service provider's rental car pricing
 */
export const createSPRentalCarPricingSelector = () => createSelector(
    [
        createServiceProviderSelector(),
        (state, props) => {
            const {location} = props;
            const queryParams = new URLSearchParams(location?.search || '');
            return queryParams.get('pricingDataId') || null;
        },
    ],
    (serviceProvider, pricingDataId) => {
        if (!serviceProvider || !pricingDataId) return null;
        return serviceProvider.rentalCarPricing?.find(pricing => `${pricing.id}` === pricingDataId) || null;
    },
);

export const createStandbyTimesEntrySelector = () => createSelector(
    [
        getStandbyTimesMap,
        (state, props) => {
            const {location} = props;
            const queryParams = new URLSearchParams(location?.search || '');
            return queryParams.get('standbyTimesId') || null;
        },
    ],
    (standbyTimesMap, standbyTimesId) => {
        if (!standbyTimesMap || !standbyTimesId) return null;
        return standbyTimesMap.get(standbyTimesId) || null;
    },
);

export const createStandbyTimesSectionsSelector = () => createSelector(
    [
        getStandbyTimesMap,
    ],
    standbyTimesMap => {
        if (!standbyTimesMap) return null;

        return {
            activeSection: [...standbyTimesMap.values()]
                .filter(standbyTimes => standbyTimes.isActive)
                .sort((standbyTimeA, standbyTimeB) => {
                    return moment(standbyTimeA.startDate).isSameOrAfter(moment(standbyTimeB.startDate)) ? 1 : -1;
                }),
            standbySection: [...standbyTimesMap.values()]
                .filter(standbyTimes => !standbyTimes.isActive)
                .sort((standbyTimeA, standbyTimeB) => {
                    return moment(standbyTimeA.startDate).isSameOrAfter(moment(standbyTimeB.startDate)) ? 1 : -1;
                }),
        };
    },
);

export const createRentalCarAdditionalServicePricingSelector = () => createSelector(
    [
        getRentalCarAdditionalServicesMap,
        (state, props) => {
            const {location} = props;
            const queryParams = new URLSearchParams(location?.search || '');
            return queryParams.get('additionalService') || null;
        },
    ],
    (rentalCarAdditionalServicesMap, additionalService) => {
        if (!rentalCarAdditionalServicesMap || !additionalService) return null;
        return rentalCarAdditionalServicesMap.get(additionalService) || null;
    },
);
