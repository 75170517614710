import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {apmACEPartnerServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Divider, Button, NoResultsBlock} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, InteractiveIcon, plusIcon, editIcon, deleteIcon, findCaseIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import * as serviceProviderSelectors from '../serviceProviderSelectors';
import config from '../../config';

const StandbyTimesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('standby_times_panel');
    const {initiateCreateStandbyTimesFlow, initiateUpdateStandbyTimesFlow, submitServiceProviderForm} = props;
    const {emergencyLawyers, standbyTimesMap} = props;
    const sortedStandbyTimes = useMemo(() => (
        [...standbyTimesMap.values()].sort((standbyTimeA, standbyTimeB) => {
            return moment(standbyTimeA.startDate).isSameOrAfter(moment(standbyTimeB.startDate)) ? 1 : -1;
        })), [standbyTimesMap]);

    const handleOnCreate = () => {
        initiateCreateStandbyTimesFlow();
    };

    const handleOnEdit = standbyTimesId => {
        initiateUpdateStandbyTimesFlow({
            standbyTimesId,
        });
    };

    const handleOnDelete = (standbyTimesId, serviceProviderId) => {
        const serviceProvider = emergencyLawyers[serviceProviderId];
        const servicesData = serviceProvider.services?.length
            ? serviceProvider.services.map(service => {
                if (service.serviceType === apmACEPartnerServiceTypes.LEGAL_ADVICE) {
                    return {
                        ...service,
                        standByTimes: serviceProvider.legalAdvice.standByTimes.filter(standbyTime => (
                            standbyTime.id !== standbyTimesId
                        )),
                    };
                }
                return service;
            }) : [];

        submitServiceProviderForm({
            serviceProviderData: {
                services: servicesData,
            },
            serviceProviderId,
        });
    };

    return (
        <Panel
            title={translatePanel('title.edit_emergency_lawyers_standby_times')}
            actions={(
                <Button
                    onClick={handleOnCreate}
                    isDisabled={sortedStandbyTimes.length === config.MAX_STANDBY_ENTRIES_ALLOWED}
                >
                    <InteractiveIcon
                        icon={plusIcon}
                        className={cx([
                            'ace-c-icon--color-highlight',
                            'global!ace-u-margin--right-8',
                            'ace-c-interactive-icon--primary',
                        ])}
                    />
                    {translatePanel('label.add_new_entry')}
                </Button>
            )}
        >
            <Divider />
            <Table qaIdent="emergency-lawyers-standby-times">
                {sortedStandbyTimes.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                             )}
                            message={translatePanel('no_results.message')}
                            description={translatePanel('no_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3} qaIdentPart="emergency-lawyers-standby-start-date">
                            {translatePanel('table_row_label.start_date')}
                        </TableCell>
                        <TableCell colSpan={3} qaIdentPart="emergency-lawyers-standby-end-date">
                            {translatePanel('table_row_label.end_date')}
                        </TableCell>
                        <TableCell colSpan={5} qaIdentPart="emergency-lawyers-name">
                            {translatePanel('table_row_label.attorney')}
                        </TableCell>
                        <TableCell colSpan={1} />
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedStandbyTimes.map(standbyTimeEntry => {
                        const serviceProviderName = emergencyLawyers[standbyTimeEntry.serviceProviderId]?.name || '';
                        return (
                            <TableRow
                                key={standbyTimeEntry.id}
                                qaIdentPart={standbyTimeEntry.id}
                            >
                                <TableCell
                                    qaIdentPart="emergency-lawyers-standby-start-date"
                                    qaIdentPartPostfix={standbyTimeEntry.id}
                                    colSpan={3}
                                >
                                    {moment(standbyTimeEntry.startDate).format('DD.MM.YYYY') || ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="emergency-lawyers-standby-end-date"
                                    qaIdentPartPostfix={standbyTimeEntry.id}
                                    colSpan={3}
                                >
                                    {moment(standbyTimeEntry.endDate).format('DD.MM.YYYY') || ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="emergency-lawyers-name"
                                    qaIdentPartPostfix={standbyTimeEntry.id}
                                    colSpan={5}
                                >
                                    {serviceProviderName}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => handleOnEdit(standbyTimeEntry.id)}
                                        className={cx('ace-c-interactive-icon--primary')}
                                    />
                                </TableCell>
                                <TableCell colSpan={1}>
                                    <InteractiveIcon
                                        icon={deleteIcon}
                                        onClick={() => handleOnDelete(
                                            standbyTimeEntry.id,
                                            standbyTimeEntry.serviceProviderId,
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

StandbyTimesPanel.propTypes = {
    initiateCreateStandbyTimesFlow: PropTypes.func.isRequired,
    initiateUpdateStandbyTimesFlow: PropTypes.func.isRequired,
    submitServiceProviderForm: PropTypes.func.isRequired,
    emergencyLawyers: PropTypes.object,
    standbyTimesMap: PropTypes.object,
};

StandbyTimesPanel.defaultProps = {
    emergencyLawyers: {},
    standbyTimesMap: null,
};

const mapStateToProps = state => {
    return {
        emergencyLawyers: serviceProviderSelectors.getEmergencyLawyers(state),
        standbyTimesMap: serviceProviderSelectors.getStandbyTimesMap(state),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateStandbyTimesFlow: () => dispatch({
        type: serviceProviderActionTypes.INITIATE_CREATE_STANDBY_TIMES_ENTRY_FLOW,
    }),
    initiateUpdateStandbyTimesFlow: payload => dispatch({
        type: serviceProviderActionTypes.INITIATE_UPDATE_STANDBY_TIMES_FLOW,
        payload,
    }),
    submitServiceProviderForm: payload => dispatch({
        type: serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandbyTimesPanel);
