import {take, select, put, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import errorTypes from '../../application/errorTypes';

/**
 * Contract partner status update flow (uses modal)
 */
const contractPartnerStatusUpdateFlow = function* contractPartnerStatusUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let payloadContent;
    let chosenModalOption;
    let contractPartnerId;
    let isError = false;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW);
            contractPartnerId = payload.contractPartnerId;

            yield* openModal(modalIds.CP_STATUS_UPDATE);

            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE,
                contractPartnerActionTypes.DECLINE_CONTRACT_PARTNER_STATUS_UPDATE,
            ]);
        }

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE) {
            if (shouldWaitForAction) {
                payloadContent = chosenModalOption.payload;
            }

            const {contractPartnerData} = payloadContent;
            const {retryActionPersistenceState} = yield select(state => state.application);

            // delete scheduled status change (if exists)
            if (!contractPartnerData.scheduledAt) {
                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST,
                    partnerManagementService.deleteScheduledContractPartnerStatusChange,
                    {contractPartnerId},
                );
                const deleteCPScheduledStatusChangeResponseAction = yield take([
                    contractPartnerActionTypes.DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_FAILED,
                    contractPartnerActionTypes.DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_SUCCEEDED,
                ]);

                isError = !!deleteCPScheduledStatusChangeResponseAction.error;
            }
            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            if (!isError) {
                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST,
                    contractPartnerData.scheduledAt
                        ? partnerManagementService.scheduleContractPartnerStatusChange
                        : partnerManagementService.updateACEPartner,
                    {
                        ...(contractPartnerData.scheduledAt && {
                            contractPartnerData,
                            contractPartnerId,
                        }),
                        ...(!contractPartnerData.scheduledAt && {
                            acePartnerData: {
                                ...ACEPartner.objectToPatchDTO(contractPartnerData),
                                partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                            },
                            acePartnerId: contractPartnerId,
                        }),
                    },
                );

                const updateContractPartnerStatusResponseAction = yield take([
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SUCCEEDED,
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_FAILED,
                ]);

                if (!updateContractPartnerStatusResponseAction.error) {
                    const {response} = updateContractPartnerStatusResponseAction.payload;
                    const {acePartnerDTO} = response;

                    if (acePartnerDTO) {
                        yield put({
                            type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                            payload: {contractPartnerDTOs: [acePartnerDTO]},
                        });
                    }

                    if (!shouldWaitForAction) {
                        shouldWaitForAction = true;
                        payloadContent = null;
                        yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                    }
                }

                isError = !!updateContractPartnerStatusResponseAction.error;
            }

            if (isError) {
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                    errorType: errorTypes.CP_STATUS_UPDATE_FAILED,
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CP_STATUS_UPDATE,
                    contractPartnerActionTypes.CANCEL_CP_STATUS_UPDATE,
                ]);

                if (nextAction.type === contractPartnerActionTypes.RETRY_CP_STATUS_UPDATE) {
                    shouldWaitForAction = false;
                    isError = false;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    continue;
                }
                if (nextAction.type === contractPartnerActionTypes.CANCEL_CP_STATUS_UPDATE) {
                    shouldWaitForAction = true;
                    payloadContent = null;
                    isError = false;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }
            }
        }

        yield* closeModal(modalIds.CP_STATUS_UPDATE);
    }
};

export default contractPartnerStatusUpdateFlow;
