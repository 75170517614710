import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import ContractPartnerAddressDataPanel from './ui-elements/ContractPartnerAddressDataPanel';

const ContractPartnerBasicDataTab = props => {
    const {cx} = useStyles();
    const {contractPartner} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-12')}>
                <ContractPartnerAddressDataPanel />
            </ContentItem>
        </ContentBlock>
    );
};

ContractPartnerBasicDataTab.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerBasicDataTab.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerBasicDataTab));
