import {select, fork, take, put} from 'redux-saga/effects';
import moment from 'moment';
import {matchPath, parseSearchQueryParams, replace, resolveRoute} from '@computerrock/formation-router';
import {persistenceStates} from '@computerrock/formation-core';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as budgetsActionTypes from '../budgetsActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';

const loadTimePeriods = function* loadTimePeriods({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {location} = payload;

    if (location && location.state?.isModalOpen
        && [modalIds.CREATE_TIME_PERIOD_MODAL].includes(location.state?.modalId)) return;

    yield fork(
        fetchRequest,
        budgetsActionTypes.FETCH_TIME_PERIODS_REQUEST,
        pricingManagementService.getBudgetTimePeriods,
    );

    const responseAction = yield take([
        budgetsActionTypes.FETCH_TIME_PERIODS_REQUEST_SUCCEEDED,
        budgetsActionTypes.FETCH_TIME_PERIODS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {budgetTimePeriodDTOs} = response;

        yield put({
            type: budgetsActionTypes.STORE_TIME_PERIODS,
            payload: {timePeriods: budgetTimePeriodDTOs},
        });

        const activePricePeriod = budgetTimePeriodDTOs.find(pricePeriod => (
            moment(pricePeriod?.validFrom).isSameOrBefore(moment(), 'day')
            && moment(pricePeriod?.validUntil).isSameOrAfter(moment(), 'day')
        ));

        const {validFrom, validUntil, budgetPeriodId} = parseSearchQueryParams(location.search);

        const budgetsScreenMatch = matchPath(location.pathname, {
            path: routePaths.BUDGETS,
            exact: true,
        });

        if (!activePricePeriod && !budgetPeriodId) {
            yield put({
                type: budgetsActionTypes.SET_BUDGETS_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
            return;
        }

        if (budgetsScreenMatch && budgetTimePeriodDTOs && !validFrom && !validUntil && !budgetPeriodId) {
            const newSearchParams = new URLSearchParams(location.search);

            newSearchParams.append('validFrom', activePricePeriod.validFrom);
            newSearchParams.append('validUntil', activePricePeriod.validUntil);
            newSearchParams.append('budgetPeriodId', activePricePeriod.id);

            yield put(replace(resolveRoute(location.pathname, {}, {search: newSearchParams.toString()})));
        }
        return;
    }

    yield put({
        type: budgetsActionTypes.SET_BUDGETS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.FAILED},
    });
};

export default loadTimePeriods;
