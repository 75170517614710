import {fork, select, take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Fee, persistenceStates} from '@ace-de/eua-entity-types';
import * as feesAndEstimationActionTypes from '../feesAndEstimationActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import assistanceFeesAndEstimationModalTypes from '../modals/assistanceFeesAndEstimationModalTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import errorTypes from '../../application/errorTypes';

const initiateAssistanceFeesEditFlow = function* initiateAssistanceFeesEditFlow() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    let shouldWaitForAction = true;
    let shouldRetryAction = false;
    let chosenModalOption;

    while (true) {
        if (shouldWaitForAction) {
            yield take(feesAndEstimationActionTypes.INITIATE_ASSISTANCE_FEES_EDIT_FLOW);

            yield* openModal(modalIds.ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL, {
                type: assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES,
            });

            chosenModalOption = yield take([
                feesAndEstimationActionTypes.CONFIRM_ASSISTANCE_FEES_EDIT,
                feesAndEstimationActionTypes.DECLINE_ASSISTANCE_FEES_EDIT,
            ]);
        }

        if (chosenModalOption.type === feesAndEstimationActionTypes.CONFIRM_ASSISTANCE_FEES_EDIT || shouldRetryAction) {
            const {fees} = chosenModalOption.payload;
            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                feesAndEstimationActionTypes.UPDATE_ASSISTANCE_FEES_REQUEST,
                pricingManagementService.updateFees, {
                    feesData: {
                        data: fees.map(fee => Fee.objectToDTO(fee)),
                    },
                },
            );
            const responseAction = yield take([
                feesAndEstimationActionTypes.UPDATE_ASSISTANCE_FEES_REQUEST_SUCCEEDED,
                feesAndEstimationActionTypes.UPDATE_ASSISTANCE_FEES_REQUEST_FAILED,
            ]);

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            if (responseAction.error) {
                yield* closeModal(modalIds.ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL, {
                    type: assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES,
                });

                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.ASSISTANCE_FEES_EDIT_FAILED});

                const nextAction = yield take([
                    feesAndEstimationActionTypes.RETRY_ASSISTANCE_FEES_EDIT,
                    feesAndEstimationActionTypes.CANCEL_ASSISTANCE_FEES_EDIT,
                ]);

                if (nextAction.type === feesAndEstimationActionTypes.CANCEL_ASSISTANCE_FEES_EDIT) {
                    yield* closeModal(
                        modalIds.ERROR_MESSAGE_MODAL,
                        {errorType: errorTypes.ASSISTANCE_FEES_EDIT_FAILED},
                    );
                    shouldWaitForAction = true;
                    shouldRetryAction = false;
                    chosenModalOption = null;
                    continue;
                }
                if (retryActionPersistenceState !== persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }
                shouldWaitForAction = true;
                shouldRetryAction = false;
                continue;
            }

            if (!responseAction.error && shouldRetryAction) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.ASSISTANCE_FEES_EDIT_FAILED});
                shouldWaitForAction = true;
                shouldRetryAction = false;
                chosenModalOption = null;
                continue;
            }
        }

        yield* closeModal(modalIds.ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL, {
            type: assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES,
        });
    }
};

export default initiateAssistanceFeesEditFlow;
