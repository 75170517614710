import {produce} from 'immer';
import moment from 'moment';
import {alfINARuleNameTypes, INARule} from '@ace-de/eua-entity-types';
import * as inaRulesActionTypes from './inaRulesActionTypes';

const initialState = {
    inaRules: [],
};

/**
 * INA rules reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const inaRulesReducer = produce((draft, action) => {
    switch (action.type) {
        case inaRulesActionTypes.STORE_INA_RULES: {
            const {inaRulesDTO} = action.payload;
            draft.inaRules = inaRulesDTO.data
                .sort((ruleA, ruleB) => (ruleA.id > ruleB.id ? 1 : -1))
                .map(inaRuleDTO => {
                    const inaRule = new INARule().fromDTO(inaRuleDTO);
                    switch (inaRule.name) {
                        case alfINARuleNameTypes.AMOUNT_OF_DAMAGE_CASES: {
                            inaRule.variables = {
                                varX: inaRule.casesAmountLimit,
                                varY: inaRule.monthsCountLimit,
                            };
                            break;
                        }
                        case alfINARuleNameTypes.BOOKING_DATE_NOT_ALLOWED_FROM_DATE: {
                            inaRule.variables = {
                                varX: moment(inaRule.bookingDateFrom[0] === '+'
                                    ? inaRule.bookingDateFrom.substring(1)
                                    : inaRule.bookingDateFrom).format('DD.MM.'),
                            };
                            break;
                        }
                        case alfINARuleNameTypes.CREDITOR_AUTHORIZATION: {
                            inaRule.variables = {
                                varX: inaRule.creditorIds.join(', '),
                            };
                            break;
                        }
                        case alfINARuleNameTypes.DUNNING_LEVEL: {
                            inaRule.variables = {
                                varX: inaRule.dunningLevel,
                            };
                            break;
                        }
                        case alfINARuleNameTypes.NUMBER_OF_INVOICES_IN_CASE: {
                            inaRule.variables = {
                                varX: inaRule.numberOfInvoices,
                            };
                            break;
                        }
                        case alfINARuleNameTypes.OPEN_ACCOUNTS_RECEIVABLE_FOR_MEMBER: {
                            inaRule.variables = {
                                varX: inaRule.openAccountReceivableMemberLimit.toFixed(2) + ' €',
                            };
                            break;
                        }
                        case alfINARuleNameTypes.REQUESTED_AMOUNT_FOR_INVOICE: {
                            inaRule.variables = {
                                varX: inaRule.requestedAmountForInvoiceLimit.toFixed(2) + ' €',
                            };
                            break;
                        }
                        case alfINARuleNameTypes.TOTAL_APPROVED_AMOUNT: {
                            inaRule.variables = {
                                varX: inaRule.totalAmountLimit.toFixed(2) + ' €',
                                varY: inaRule.totalAmountMonthLimit,
                            };
                            break;
                        }

                        default:
                    }
                    return inaRule;
                });
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default inaRulesReducer;
