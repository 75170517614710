import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmDayOfWeekTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, NoResultsBlock, Pill} from '@ace-de/ui-components';
import {InteractiveIcon, plusIcon, findCaseIcon, Icon, deleteIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell, TableCaption} from '@ace-de/ui-components/data-elements';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const ContractPartnerEmergencyCallDataPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_contact_tab');
    const {contractPartner, initiateCreateCPContactData, initiateDeleteCPContactData} = props;
    const {initiateEditCPContactData, isAdditional} = props;

    const sortedEmergencyContacts = useMemo(() => {
        if (!contractPartner?.emergencyContacts?.length) return [];
        if (isAdditional) {
            return contractPartner.emergencyContacts
                .filter(contact => contact.isAdditional)
                .sort((contactA, contactB) => contactA.id - contactB.id);
        }
        const sortedContacts = contractPartner.emergencyContacts
            .filter(contact => !contact.is24h7Emergency && !contact.isAdditional)
            .sort((contactA, contactB) => contactA.id - contactB.id);
        const emergency24h7Contact = contractPartner.emergencyContacts.find(contact => !!contact.is24h7Emergency);
        if (emergency24h7Contact) {
            sortedContacts.splice(0, 0, emergency24h7Contact);
        }
        return sortedContacts;
    }, [contractPartner.emergencyContacts, isAdditional]);

    const handleOnCreate = () => {
        initiateCreateCPContactData({
            contractPartnerId: contractPartner.id,
            ...(!!isAdditional && {isAdditional: true}),
        });
    };

    const handleOnDelete = contactDataId => {
        initiateDeleteCPContactData({
            contractPartnerId: contractPartner.id,
            contactDataId,
        });
    };

    const handleOnEdit = contactDataId => {
        initiateEditCPContactData({
            contractPartnerId: contractPartner.id,
            contactDataId,
            ...(!!isAdditional && {isAdditional: true}),
        });
    };

    const displayWorkingHours = workingHoursDTO => {
        const fromString = workingHoursDTO?.from?.slice(0, -3) || '';
        const toString = workingHoursDTO?.to?.slice(0, -3) || '';
        return fromString && toString
            ? `${fromString}-${toString} ${translate('global.time_unit.time')}`
            : translate('global.placeholder.unknown');
    };

    if (!contractPartner) return null;

    return (
        <Panel
            title={translateTab(isAdditional
                ? 'panel_title.additional_contact_details'
                : 'panel_title.emergency_call_data')}
            className={cx('ace-c-panel--full-bleed-content')}
            actions={(
                <InteractiveIcon
                    icon={plusIcon}
                    className={cx([
                        'ace-c-interactive-icon--primary',
                        'ace-c-interactive-icon--highlight',
                    ])}
                    onClick={handleOnCreate}
                    isDisabled={isAdditional ? false : sortedEmergencyContacts?.length === 5}
                >
                    {translateTab('button_label.add_new_contact')}
                </InteractiveIcon>
            )}
        >
            <Table qaIdent="contract-partner-contact-details">
                {sortedEmergencyContacts?.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            message={translateTab('no_results.message')}
                            description={translateTab('no_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="cp-contact-details-index">
                            {translateTab('emergency_call_data_table_cell.contact')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="cp-contact-details-phone-number">
                            {translateTab('emergency_call_data_table_cell.telephone_number')}
                        </TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.name')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.function')}</TableCell>
                        <TableCell colSpan={1} />
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.reachability:')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.monday_friday')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.saturday')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.sunday_holiday')}</TableCell>
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedEmergencyContacts
                        .filter(contact => !contact.isDeleted)
                        .map((contact, idx) => {
                            const mondayToFridayAvailability = contact.availability?.find(data => {
                                return data.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY;
                            });
                            const saturdayAvailability = contact.availability?.find(data => {
                                return data.dayOfWeek === apmDayOfWeekTypes.SATURDAY;
                            });
                            const sundayAndHolidayAvailability = contact.availability?.find(data => {
                                return data.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY;
                            });
                            return (
                                <TableRow
                                    key={idx}
                                    qaIdentPart={idx}
                                >
                                    <TableCell
                                        colSpan={2}
                                        qaIdentPart="cp-contact-details-index"
                                        qaIdentPartPostfix={idx}
                                    >
                                        {contact.is24h7Emergency && (
                                            <Pill
                                                type="information"
                                                className={cx('global!ace-u-margin--right-8')}
                                            >
                                                {translateTab('pill_label.24_7')}
                                            </Pill>
                                        )}
                                        {contact.type || ''}
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-typography--color-highlighted',
                                        ])}
                                        qaIdentPart="cp-contact-details-phone-number"
                                        qaIdentPartPostfix={idx}
                                    >
                                        {contact.phoneNo || ''}
                                    </TableCell>
                                    <TableCell colSpan={2}>{contact.name || ''}</TableCell>
                                    <TableCell colSpan={2}>{contact.function || ''}</TableCell>
                                    <TableCell colSpan={1} />
                                    <TableCell colSpan={2} />
                                    <TableCell colSpan={2}>
                                        {displayWorkingHours(mondayToFridayAvailability)}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {displayWorkingHours(saturdayAvailability)}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {displayWorkingHours(sundayAndHolidayAvailability)}
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                        <InteractiveIcon
                                            icon={editIcon}
                                            onClick={() => handleOnEdit(contact.id)}
                                            className={cx('global!ace-u-margin--right-16')}
                                        />
                                        <InteractiveIcon
                                            icon={deleteIcon}
                                            onClick={() => handleOnDelete(contact.id)}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </Panel>
    );
};

ContractPartnerEmergencyCallDataPanel.propTypes = {
    contractPartner: PropTypes.object,
    isAdditional: PropTypes.bool,
    initiateCreateCPContactData: PropTypes.func.isRequired,
    initiateDeleteCPContactData: PropTypes.func.isRequired,
    initiateEditCPContactData: PropTypes.func.isRequired,
};

ContractPartnerEmergencyCallDataPanel.defaultProps = {
    contractPartner: null,
    isAdditional: false,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateCPContactData: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_CP_CONTACT_DATA_FLOW,
        payload,
    }),
    initiateDeleteCPContactData: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_DELETE_CP_CONTACT_DATA_FLOW,
        payload,
    }),
    initiateEditCPContactData: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_UPDATE_CP_CONTACT_DATA_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerEmergencyCallDataPanel));
