import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {ContentBlock, ContentItem, InteractiveIcon, Panel, useStyles} from '@ace-de/ui-components';
import {Icon, timeIcon, editIcon} from '@ace-de/ui-components/icons';
import {apmContractPartnerAssignmentChannelTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';

const ContractPartnerCommissioningTab = props => {
    const {cx} = useStyles(props);
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_commissioning_tab');
    const translateAssignmentChannel = createTranslateShorthand('global.contract_partner_assignment_channel');
    const {contractPartner, initiateContractPartnerAssignmentChannelsUpdateFlow} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-12')}>
                <Panel
                    contentClassName={cx('global!ace-u-grid')}
                    title={translateTab('panel_title.commissioning')}
                >
                    <Panel
                        header={(
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--32',
                                ])}
                            >
                                {translateTab('text.telephone', {phoneIndex: '24/7'})}
                            </p>
                        )}
                        className={cx([
                            'ace-c-panel--small',
                            'ace-c-panel--border',
                            'global!ace-u-grid-column--1-4',
                        ])}
                        actions={(
                            <InteractiveIcon
                                icon={editIcon}
                                onClick={() => {
                                    return initiateContractPartnerAssignmentChannelsUpdateFlow(
                                        {contractPartnerId: contractPartner.id},
                                    );
                                }}
                            />
                        )}
                    >
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-margin--bottom-16',
                            ])}
                        >
                            <Icon
                                icon={timeIcon}
                                className={cx([
                                    'global!ace-u-margin--right-8',
                                    'ace-c-icon--xs',
                                ])}
                            />
                            <p
                                className={cx('global!ace-u-typography--variant-body')}
                            >
                                {translateTab('text.working_hours', {workingHours: '00:00 - 24:00'})}
                            </p>
                        </div>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-16',
                            ])}
                        >
                            {translateTab('text.commissioning')}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {contractPartner.assignmentChannels?.length > 0
                                && contractPartner.assignmentChannels.map(channel => {
                                    if (channel === apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML) {
                                        return `${translateAssignmentChannel(snakeCase(channel))}: ${contractPartner.assignmentEmail || contractPartner.businessContactDetails?.email || ''}`;
                                    }

                                    if (channel === apmContractPartnerAssignmentChannelTypes.FAX) {
                                        return `${translateAssignmentChannel(snakeCase(channel))}: ${contractPartner.assignmentFaxNo || contractPartner.businessContactDetails?.faxNo || ''}`;
                                    }

                                    return translateAssignmentChannel(snakeCase(channel));
                                }).join(', ')}
                        </p>
                        <div className={cx('global!ace-u-flex')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--bottom-16',
                                    'global!ace-u-margin--right-8',
                                ])}
                            >
                                {translateTab('text.note')}
                            </p>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--bottom-16',
                                ])}
                            >
                                {contractPartner.assignmentChannelsComment || '-'}
                            </p>
                        </div>
                    </Panel>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

ContractPartnerCommissioningTab.propTypes = {
    contractPartner: PropTypes.object.isRequired,
    initiateContractPartnerAssignmentChannelsUpdateFlow: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateContractPartnerAssignmentChannelsUpdateFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW,
        payload,
    }),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerCommissioningTab));
