import {produce} from 'immer';
import moment from 'moment';
import {persistenceStates} from '@computerrock/formation-core';
import {apsBudgetTypes, apsAidServiceTypes, apsServiceTypes, apsMemberBudgetTariffTypes, ServicePerformanceBudget, AidServiceMaxBudget, MaxDurationBudget} from '@ace-de/eua-entity-types';
import memberStatuses from './memberStatuses';
import budgetedServicePerformanceServiceTypes from './budgetedServicePerformanceServiceTypes';
import budgetedMaxDurationServiceTypes from './budgetedMaxDurationServiceTypes';
import * as budgetActionTypes from './budgetsActionTypes';
import config from '../config';

const initialState = {
    timePeriods: [],
    latestTimePeriod: null,
    budgetsPersistenceState: persistenceStates.READY,
    budgets: [],
    servicePerformanceBudgets: {},
    aidServiceMaxBudgets: {},
    maxDurationBudgets: {},
    timePeriodBudgetsPersistenceState: persistenceStates.PENDING,
};

const budgetReducer = produce((draft, action) => {
    switch (action.type) {
        case budgetActionTypes.STORE_TIME_PERIODS: {
            const {timePeriods} = action.payload;
            if (Array.isArray(timePeriods) && timePeriods.length) {
                const sortedPeriods = timePeriods.sort((a, b) => moment(a.validUntil).diff(moment(b.validUntil)));
                draft.timePeriods = sortedPeriods;
                draft.latestTimePeriod = sortedPeriods[sortedPeriods.length - 1];
            }
            break;
        }

        case budgetActionTypes.STORE_UPDATED_PERIOD: {
            const {budgetTimePeriodDTO} = action.payload;
            draft.timePeriods = draft.timePeriods.map(timePeriod => {
                if (timePeriod.id === budgetTimePeriodDTO.id) {
                    return budgetTimePeriodDTO;
                }
                return timePeriod;
            });
            break;
        }

        case budgetActionTypes.SET_BUDGETS_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.budgetsPersistenceState = persistenceState;
            break;
        }

        case budgetActionTypes.STORE_BUDGETS_BY_TIME: {
            const {budgetTimePeriodDTO} = action.payload;
            const {budgets} = budgetTimePeriodDTO;
            draft.budgets = budgets;

            // create matrix for SERVICE_PERFORMANCE budgets
            const newServicePerformanceBudgets = Object.values(apsMemberBudgetTariffTypes)
                .reduce((budgetsByTariffType, apsMemberBudgetTariffType) => {
                    budgetsByTariffType[apsMemberBudgetTariffType] = Object.values(apsServiceTypes)
                        // we're listing only service types that are budgeted on the BE,
                        // story https://computerrock.atlassian.net/browse/ACELEA-3077
                        .filter(serviceType => budgetedServicePerformanceServiceTypes.includes(serviceType))
                        .reduce((budgetsByServiceType, serviceType) => {
                            budgetsByServiceType[serviceType] = Object.values(memberStatuses)
                                .reduce((budgetsByMemberStatus, memberStatus) => {
                                    budgetsByMemberStatus[memberStatus] = null;
                                    return budgetsByMemberStatus;
                                }, {});

                            return budgetsByServiceType;
                        }, {});

                    return budgetsByTariffType;
                }, {});

            // create matrix for AID_SERVICE_MAX budgets
            const currentAPSAidServiceTypes = Object.values(apsAidServiceTypes)
                .filter(aidServiceType => (
                    aidServiceType !== apsAidServiceTypes.ELEMENTAL_DAMAGE
                    || moment(budgetTimePeriodDTO.validFrom).isSameOrAfter(config.BUDGETS_DATE_INTERSECTION)
                ));
            const newAidServiceMaxBudgets = currentAPSAidServiceTypes
                .reduce((budgetsByServiceType, serviceType) => {
                    budgetsByServiceType[serviceType] = Object.values(memberStatuses)
                        .reduce((budgetsByMemberStatus, memberStatus) => {
                            budgetsByMemberStatus[memberStatus] = null;
                            return budgetsByMemberStatus;
                        }, {});

                    return budgetsByServiceType;
                }, {});

            // create matrix for MAX_DURATIONS budgets
            const newMaxDurationBudgets = Object.values(apsServiceTypes)
                // we're listing only service types that are budgeted on the BE,
                // story https://computerrock.atlassian.net/browse/ACELEA-3109
                .filter(serviceType => budgetedMaxDurationServiceTypes.includes(serviceType))
                .reduce((budgetsByServiceType, serviceType) => {
                    budgetsByServiceType[serviceType] = Object.values(apsMemberBudgetTariffTypes)
                        .reduce((budgetsByMemberTariff, apsMemberBudgetTariffType) => {
                            budgetsByMemberTariff[apsMemberBudgetTariffType] = null;
                            return budgetsByMemberTariff;
                        }, {});

                    return budgetsByServiceType;
                }, {});

            budgets?.forEach(budgetDTO => {
                switch (budgetDTO.type) {
                    case apsBudgetTypes.SERVICE_PERFORMANCE:
                        if (!Object.keys(apsMemberBudgetTariffTypes).includes(budgetDTO.tariffGroup)
                            || !budgetedServicePerformanceServiceTypes.includes(budgetDTO.serviceType)
                            || !memberStatuses.includes(budgetDTO.memberStatus)
                        ) break;

                        // eslint-disable-next-line
                        newServicePerformanceBudgets[budgetDTO.tariffGroup][budgetDTO.serviceType][budgetDTO.memberStatus] =
                            new ServicePerformanceBudget().fromDTO(budgetDTO);
                        break;

                    case apsBudgetTypes.AID_SERVICE_MAX:
                        if (!currentAPSAidServiceTypes.includes(budgetDTO.serviceType)
                            || !memberStatuses.includes(budgetDTO.memberStatus)
                        ) break;

                        // eslint-disable-next-line
                        newAidServiceMaxBudgets[budgetDTO.serviceType][budgetDTO.memberStatus] =
                            new AidServiceMaxBudget().fromDTO(budgetDTO);
                        break;

                    case apsBudgetTypes.MAX_DURATIONS:
                        if (!budgetedMaxDurationServiceTypes.includes(budgetDTO.serviceType)
                            || !Object.keys(apsMemberBudgetTariffTypes).includes(budgetDTO.tariffGroup)
                        ) break;

                        // eslint-disable-next-line
                        newMaxDurationBudgets[budgetDTO.serviceType][budgetDTO.tariffGroup] =
                            new MaxDurationBudget().fromDTO(budgetDTO);
                        break;

                    default:
                        // no-op
                }
            });
            draft.servicePerformanceBudgets = newServicePerformanceBudgets;
            draft.aidServiceMaxBudgets = newAidServiceMaxBudgets;
            draft.maxDurationBudgets = newMaxDurationBudgets;

            break;
        }

        case budgetActionTypes.SET_TIME_PERIOD_BUDGETS_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.timePeriodBudgetsPersistenceState = persistenceState;
            break;
        }

        default: // no-op
    }
}, initialState);

export default budgetReducer;
