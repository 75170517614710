/**
 * App config
 */
export default {
    // General
    APP_VERSION: process.env.APP_VERSION,
    APP_BUILD: process.env.APP_BUILD,
    DEPLOYMENT_ENV: process.env.DEPLOYMENT_ENV,
    PUBLIC_URL: process.env.PUBLIC_URL || '/',
    BACKDOOR_URL: process.env.BACKDOOR_URL,
    LOCALE: process.env.LOCALE || 'en',
    COOKIE_DOMAIN: process.env.COOKIE_DOMAIN || undefined,
    DATADOG_RUM_APPLICATION_ID: process.env.DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE: process.env.DATADOG_SERVICE,
    DATADOG_ENABLE: process.env.DATADOG_ENABLE === 'true',
    ACE_LOCALE_TRANSLATION_EN_US_URL: process.env.ACE_LOCALE_TRANSLATION_EN_US_URL,
    ACE_LOCALE_TRANSLATION_DE_DE_URL: process.env.ACE_LOCALE_TRANSLATION_DE_DE_URL,
    ACE_KEYCLOAK_SERVICE_URL: process.env.ACE_KEYCLOAK_SERVICE_URL,
    ACE_KEYCLOAK_REALM: process.env.ACE_KEYCLOAK_REALM,
    ACE_KEYCLOAK_CLIENT_ID: process.env.ACE_KEYCLOAK_CLIENT_ID,
    ACE_USER_PROFILE_EUP_SERVICE_URL: process.env.ACE_USER_PROFILE_EUP_SERVICE_URL || '',
    ACE_ECS_FLOW_EF_SERVICE_URL: process.env.ACE_ECS_FLOW_EF_SERVICE_URL || '',
    ACE_PARTNER_MANAGEMENT_APM_SERVICE_URL: process.env.ACE_PARTNER_MANAGEMENT_APM_SERVICE_URL || '',
    ACE_LEA_ASSIGNMENT_FLOW_ALF_SERVICE_URL: process.env.ACE_LEA_ASSIGNMENT_FLOW_ALF_SERVICE_URL || '',
    ACE_PRICING_MANAGEMENT_APS_SERVICE_URL: process.env.ACE_PRICING_MANAGEMENT_APS_SERVICE_URL || '',
    DEFAULT_PAGE_SIZE: 20,
    INITIAL_PAGE_INDEX: 0,
    ACTIVE_SERVICE_CASES_RESULTS_COUNT: 3,
    ACE_ECS_SITE_URL: process.env.ACE_ECS_SITE_URL,
    ECS_SERVICE_CASE_SCREEN_PATH: '/service-cases/:serviceCaseId',
    ACE_AC_SYSTEM_LABEL_TRANSLATION: process.env.ACE_AC_SYSTEM_LABEL_TRANSLATION,
    ACE_VPM_SYSTEM_LABEL_TRANSLATION: process.env.ACE_VPM_SYSTEM_LABEL_TRANSLATION,
    EUA_ECS_SYSTEM_LABEL_TRANSLATION: process.env.EUA_ECS_SYSTEM_LABEL_TRANSLATION,
    EUA_LEA_SYSTEM_LABEL_TRANSLATION: process.env.EUA_LEA_SYSTEM_LABEL_TRANSLATION,
    MAX_STANDBY_ENTRIES_ALLOWED: 60,
    MAXIMUM_INPUT_CONTENT_LENGTH: 50,
    MIN_ULTRA_HEAVY_WEIGHT_VEHICLE_TONNAGE: 7.51,
    CURRENCY: process.env.CURRENCY || 'EUR',
    CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE: 'DE',
    ARCGIS_OAUTH2_SERVICE_URL: process.env.ARCGIS_OAUTH2_SERVICE_URL,
    ARCGIS_OAUTH2_CLIENT_ID: process.env.ARCGIS_OAUTH2_CLIENT_ID,
    ARCGIS_SERVICE_URL: process.env.ARCGIS_SERVICE_URL,
    TEMP_ARCGIS_MAP_ID: process.env.TEMP_ARCGIS_MAP_ID || '',
    ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL || '',
    ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL || '',
    TEMPORARY_RESTRICTIONS_DEFAULT_PAGE_SIZE: 10,
    DEFAULT_TIME_PERIOD_IN_MONTHS: 6,
    ACE_APM_QUALITY_MANAGEMENT_FEEDBACKS_EXPORT_ENDPOINT: process.env.ACE_APM_QUALITY_MANAGEMENT_FEEDBACKS_EXPORT_ENDPOINT || '',
    ACE_APM_FILE_ASSETS_SERVICE_ENDPOINT: process.env.ACE_APM_FILE_ASSETS_SERVICE_ENDPOINT || '',
    MAXIMUM_QMN_DESCRIPTION_TEXT_AREA_CONTENT_LENGTH: 1000,
    MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES: 52428800,
    MAXIMUM_FILE_ASSETS_IN_VIEW: 5,
    MINIMUM_SEARCH_QUERY_LENGTH: 3,
    MAXIMUM_TEXT_AREA_CONTENT_LENGTH: 250,
    MAXIMUM_PHONE_EXTENSION_DIGITS: 4,
    MAXIMUM_INA_RULE_DUNNING_LEVEL: 6,
    MAXIMUM_INTEGER_SIZE: 2147483647,
    BUDGETS_DATE_INTERSECTION: '01.01.2025',
};
