import {AccessControlPlugin} from '@ace-de/eua-user-access-control';
import {clientTypes} from '@ace-de/eua-entity-types';
import acAuthorizationRoles from './application/acAuthorizationRoles';
import routePaths from './routePaths';

/**
 * Instantiate AccessControlPlugin for ECS auth layer
 *
 * @type {AccessControlPlugin}
 */

const acAccessControl = new AccessControlPlugin({
    name: 'acSystem',
    client: clientTypes.AC_CLIENT,
    systemRoles: acAuthorizationRoles,
    systemAccessDeniedURI: routePaths.SYSTEM_ACCESS_DENIED,
    routeAccessDeniedURI: routePaths.ROUTE_ACCESS_DENIED,
    defaultLayoutRole: '',
});

export default acAccessControl;
