import {all, takeLatest, fork} from 'redux-saga/effects';
import * as userProfileActionTypes from './userProfileActionTypes';
import searchUsers from './sagas/searchUsers';
import editUserDetailFlow from './sagas/editUserDetailsFlow';
import changeUserEnabledState from './sagas/changeUserEnabledState';
import unlockServiceCase from './sagas/unlockServiceCase';
import createUserProfileFlow from './sagas/createUserProfileFlow';

/**
 *  User profile watcher saga
 */
const userProfileWatcher = function* userProfileWatcher() {
    yield all([
        takeLatest(userProfileActionTypes.SEARCH_USERS, searchUsers),
        takeLatest(userProfileActionTypes.UNLOCK_SERVICE_CASE, unlockServiceCase),
        fork(editUserDetailFlow),
        fork(changeUserEnabledState),
        fork(createUserProfileFlow),
    ]);
};

export default userProfileWatcher;
