import {all, fork, takeLatest} from 'redux-saga/effects';
import initiateCreateTimePeriodFlow from './sagas/initiateCreateTimePeriodFlow';
import * as budgetActionTypes from './budgetsActionTypes';
import updateTimePeriodFlow from './sagas/updateTimePeriodFlow';
import initiateBudgetsUpdateFlow from './sagas/initiateBudgetsUpdateFlow';
import fetchBudgetsByTimePeriod from './sagas/fetchBudgetsByTimePeriod';

/**
 *  Budgets watcher saga
 */
const budgetsWatcher = function* budgetsWatcher() {
    yield all([
        fork(initiateCreateTimePeriodFlow),
        fork(initiateBudgetsUpdateFlow),
        takeLatest(budgetActionTypes.INITIATE_UPDATE_TIME_PERIOD_FLOW, updateTimePeriodFlow),
        takeLatest(budgetActionTypes.INITIATE_FETCH_BUDGETS_BY_TIME_FLOW, fetchBudgetsByTimePeriod),
    ]);
};

export default budgetsWatcher;
