import {take, fork, select, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {EmergencyContact, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';

const createCPContactDataFlow = function* createCPContactDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let shouldRetryCreation = false;
    let chosenModalOption;
    let payloadContent;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_CP_CONTACT_DATA_FLOW);
            payloadContent = payload;
            const {isAdditional = false} = payloadContent;

            yield* openModal(
                modalIds.CONTRACT_PARTNER_CONTACT_DATA,
                isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
            );

            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CREATE_CP_CONTACT_DATA,
                contractPartnerActionTypes.DECLINE_CREATE_CP_CONTACT_DATA,
            ]);
        }
        const {isAdditional = false} = payloadContent;

        if ((chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_CP_CONTACT_DATA)
            || shouldRetryCreation
        ) {
            const {contactData} = chosenModalOption.payload;
            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST,
                partnerManagementService.createCPContactData,
                {
                    contactDataDTO: EmergencyContact.objectToDTO(contactData),
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST_FAILED,
            ]);

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDataDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_CONTACT_DATA,
                    payload: {contactDataDTO, contractPartnerId: payloadContent.contractPartnerId},
                });

                if (shouldRetryCreation) {
                    shouldWaitForAction = true;
                    shouldRetryCreation = false;
                    chosenModalOption = null;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                    continue;
                }
            }

            if (responseAction.error) {
                yield* closeModal(
                    modalIds.CONTRACT_PARTNER_CONTACT_DATA,
                    isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
                );
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.CREATE_CP_CONTACT_DATA_FAILED});

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CP_CONTACT_DATA_CREATE,
                    contractPartnerActionTypes.CANCEL_CP_CONTACT_DATA_CREATE,
                ]);

                if (nextAction.type === contractPartnerActionTypes.RETRY_CP_CONTACT_DATA_CREATE) {
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    shouldWaitForAction = false;
                    shouldRetryCreation = true;
                    continue;
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_CP_CONTACT_DATA_CREATE) {
                    shouldWaitForAction = true;
                    shouldRetryCreation = false;
                    chosenModalOption = null;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                    continue;
                }
            }
        }

        yield* closeModal(
            modalIds.CONTRACT_PARTNER_CONTACT_DATA,
            isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
        );
    }
};

export default createCPContactDataFlow;
