import {apsServiceTypes} from '@ace-de/eua-entity-types';

const budgetedServicePerformanceServiceTypes = [
    apsServiceTypes.SHORT_TRIP,
    apsServiceTypes.ACCOMMODATION,
    apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY,
    apsServiceTypes.RENTAL_CAR__ABROAD_MAX,
    apsServiceTypes.RENTAL_CAR__ABROAD_HOME_MAX,
];

export default budgetedServicePerformanceServiceTypes;
