import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as applicationActionTypes from '../applicationActionTypes';
import modalIds from '../../modalIds';

const successMessageFlow = function* successMessageFlow() {
    while (true) {
        const {payload} = yield take(applicationActionTypes.INITIATE_SUCCESS_MESSAGE_FLOW);
        const {successMessageType} = payload;

        yield* openModal(modalIds.SUCCESS_MESSAGE_MODAL, {successMessageType});

        yield take([
            applicationActionTypes.DECLINE_SUCCESS_MESSAGE_FLOW,
        ]);

        yield* closeModal(modalIds.SUCCESS_MESSAGE_MODAL, {successMessageType});
    }
};

export default successMessageFlow;
