/**
 * Command action types
 */
export const INITIATE_ERROR_MESSAGE_FLOW = '[application] INITIATE_ERROR_MESSAGE_FLOW';
export const DECLINE_ERROR_MESSAGE_FLOW = '[application] DECLINE_ERROR_MESSAGE_FLOW';
export const INITIATE_SUCCESS_MESSAGE_FLOW = '[application] INITIATE_SUCCESS_MESSAGE_FLOW';
export const DECLINE_SUCCESS_MESSAGE_FLOW = '[application] DECLINE_SUCCESS_MESSAGE_FLOW';
export const INITIATE_WARNING_MESSAGE_FLOW = '[application] INITIATE_WARNING_MESSAGE_FLOW';
export const DECLINE_WARNING_MESSAGE_FLOW = '[application] DECLINE_WARNING_MESSAGE_FLOW';

/**
 * Store action types
 */
export const SET_RETRY_ACTION_PERSISTENCE_STATE = '[application] SET_RETRY_ACTION_PERSISTENCE_STATE';
