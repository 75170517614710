import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as inaRulesActionTypes from '../inaRulesActionTypes';

const loadINARules = function* loadINARules() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    yield fork(
        fetchRequest,
        inaRulesActionTypes.FETCH_INA_RULES_REQUEST,
        leaAssignmentFlowService.getINARules,
    );

    const responseAction = yield take([
        inaRulesActionTypes.FETCH_INA_RULES_REQUEST_SUCCEEDED,
        inaRulesActionTypes.FETCH_INA_RULES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {inaRulesDTO} = response;
        yield put({
            type: inaRulesActionTypes.STORE_INA_RULES,
            payload: {inaRulesDTO},
        });
    }
};

export default loadINARules;
