import {resolveRoute} from '@computerrock/formation-router';
import {FormationAuthPlugin} from '@computerrock/formation-auth';
import routePaths from './routePaths';

const {origin} = window.location;
const authorizeRoute = resolveRoute(routePaths.ARCGIS_AUTHORIZE);
const authenticateRoute = resolveRoute(routePaths.ARCGIS_AUTHENTICATE);
const {href: authorizeRedirectURI} = new URL(authorizeRoute.pathname, origin);
const {href: authenticateRedirectURI} = new URL(authenticateRoute.pathname, origin);

/**
 * Instantiate FormationAuthPlugin for ArcGIS auth layer
 *
 * @type {FormationAuthPlugin}
 */
const arcGISAuth = new FormationAuthPlugin({
    name: 'arcGIS',
    authServiceName: 'arcGISAuthService',
    rootApplicationRoute: routePaths.ROOT,
    authorizeRedirectURI,
    authenticateRedirectURI,
});

export default arcGISAuth;
