import {put, fork, take, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import modalIds from '../../modalIds';

const loadServiceProviderRentalCarPricing = function* loadServiceProviderRentalCarPricing({serviceProviderId}) {
    const {serviceManager} = yield select(state => state.application);
    const {location} = yield select(state => state.router);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    // do not reload sp rental car pricing when agent opens rental car (additional) pricing data modal
    if (location && location.state?.isModalOpen
        && [modalIds.SP_RENTAL_CAR_ADDITIONAL_SERVICE_PRICING_DATA, modalIds.SP_RENTAL_CAR_PRICING_DATA]
            .includes(location.state?.modalId)
    ) return;

    yield fork(
        fetchRequest,
        serviceProviderActionTypes.FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST,
        partnerManagementService.getServiceProviderRentalCarPricing,
        {serviceProviderId},
    );

    const responseAction = yield take([
        serviceProviderActionTypes.FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED,
        serviceProviderActionTypes.FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;

    yield put({
        type: serviceProviderActionTypes.SET_RENTAL_CAR_ADDITIONAL_SERVICES_MAP,
    });

    yield put({
        type: serviceProviderActionTypes.STORE_SERVICE_PROVIDER_RENTAL_CAR_PRICING,
        payload: {serviceProviderId, rentalCarPricingDTOs: response.rentalCarPricingDTOs},
    });
};

export default loadServiceProviderRentalCarPricing;
