import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, ScreenTitle, ToggleSwitch} from '@ace-de/ui-components';
import {acFeatures, acFeatureActions} from '../../application/acFeatures';
import acAccessControl from '../../acAccessControl';
import * as commissionerActionTypes from '../commissionerActionTypes';

const CommissionerHeader = ({commissioner, submitCommissionerForm}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateHeader = createTranslateShorthand('commissioner_header');
    const [commissionerName, setCommissionerName] = useState(commissioner?.name || '');
    const [isCommissionerActive, setIsCommissionerActive] = useState(
        commissioner?.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE,
    );

    useEffect(() => {
        if (commissioner) {
            setCommissionerName(commissioner.name);
            if (commissioner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE) {
                setIsCommissionerActive(true);
            } else if (commissioner.contractStatus === apmContractPartnerContractStatusTypes.TERMINATED) {
                setIsCommissionerActive(false);
            }
        }
    }, [commissioner]);

    const toggleActiveCommissioner = () => {
        const contractStatus = !isCommissionerActive
            ? apmContractPartnerContractStatusTypes.ACTIVE
            : apmContractPartnerContractStatusTypes.TERMINATED;

        submitCommissionerForm({
            commissionerId: commissioner.id,
            commissionerData: {
                contractStatus,
            },
        });
    };

    if (!commissioner) return;

    return (
        <Fragment>
            <ScreenTitle>
                <span
                    className={cx([
                        'global!ace-u-margin--right-8',
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-typography--color-contrast',
                    ])}
                >
                    {commissionerName}
                </span>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx('global!ace-u-typography--variant-body-medium')}
                    >
                        {translateHeader('label.prefix')}&nbsp;
                        <span className={cx('global!ace-u-typography--variant-body')}>
                            {commissioner.id || ''}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-48',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.created_at')}&nbsp;
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {commissioner.createdAt || ''}
                        </p>
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <span
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--right-8',
                        ])}
                    >
                        {translateHeader('label.commissioner_active')}
                    </span>
                    {acAccessControl.grantFeatureAccess(
                        acFeatures.COMMISSIONER_ACTIVE_STATUS,
                        acFeatureActions.UPDATE,
                    ) && (
                        <ToggleSwitch
                            name="toggleActiveCommissioner"
                            value={true}
                            onChange={toggleActiveCommissioner}
                            isSelected={isCommissionerActive}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

CommissionerHeader.propTypes = {
    commissioner: PropTypes.object,
    submitCommissionerForm: PropTypes.func.isRequired,
};

CommissionerHeader.defaultProps = {
    commissioner: null,
};

const mapDispatchToProps = dispatch => ({
    submitCommissionerForm: payload => dispatch({
        type: commissionerActionTypes.SUBMIT_COMMISSIONER_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(CommissionerHeader);
