import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useHistory} from '@computerrock/formation-router';
import {InputCurrency, Table, TableBody, TableCell, TableHead, TableRow, useStyles} from '@ace-de/ui-components';
import {apsAidServiceTypes} from '@ace-de/eua-entity-types';
import memberStatuses from '../memberStatuses';
import config from '../../config';
import getPrice from '../../utils/getPrice';

const AidServicesMaxTable = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('budgets_screen');
    const {isEdit, onChange, aidServiceMaxBudgets, timePeriods, formData} = props;
    const history = useHistory();
    const queryParams = new URLSearchParams(history?.location?.search);
    const currentPeriod = timePeriods
        .find(timePeriod => timePeriod.id === parseInt(queryParams.get('budgetPeriodId'), 10));

    const handleOnChange = (service, memberStatus, value) => {
        if (typeof onChange !== 'function') return;

        onChange(prevState => ({
            ...prevState,
            [service]: {
                ...(prevState[service]),
                [memberStatus]: {
                    ...(prevState[service]?.[memberStatus]),
                    value,
                },
            },
        }));
    };

    if (!aidServiceMaxBudgets) return null;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>
                        {translateScreen('table_header.service')}
                    </TableCell>
                    {memberStatuses.map(status => (
                        <TableCell
                            key={status}
                            className={cx({
                                'ace-c-table__cell--text-align-right': !isEdit,
                            })}
                        >
                            {translateScreen(`table_header.member_status_${status.toLowerCase()}`)}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(apsAidServiceTypes)
                    .filter(aidServiceType => (
                        aidServiceType !== apsAidServiceTypes.ELEMENTAL_DAMAGE
                        || moment(currentPeriod?.validFrom).isSameOrAfter(config.BUDGETS_DATE_INTERSECTION)
                    ))
                    .map(service => (
                        <TableRow key={service}>
                            <TableCell colSpan={2}>
                                {translateScreen(`table_cell.service_${service.toLowerCase()}`)}
                            </TableCell>
                            {!isEdit
                                ? !!aidServiceMaxBudgets[service]
                                    && memberStatuses.map(memberStatus => (
                                        <TableCell
                                            key={`${service}-${memberStatus}`}
                                            className={cx('ace-c-table__cell--text-align-right')}
                                        >
                                            {getPrice(aidServiceMaxBudgets[service][memberStatus]?.value, activeLocale)}
                                        </TableCell>
                                    ))
                                : memberStatuses.map(status => {
                                    return (
                                        <TableCell key={`${service}-${status}`}>
                                            <InputCurrency
                                                name={`${service}-${status}`}
                                                className={cx('ace-c-input-currency--small')}
                                                inputClassName={cx('global!ace-u-width--full')}
                                                currencySign={translate('global.currency.eur')}
                                                value={formData?.[service]?.[status]?.value >= 0
                                                    ? formData?.[service]?.[status]?.value
                                                    : ''}
                                                onChange={value => handleOnChange(service, status, value)}
                                            />
                                        </TableCell>
                                    );
                                })}
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

AidServicesMaxTable.propTypes = {
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
    aidServiceMaxBudgets: PropTypes.object,
    timePeriods: PropTypes.array,
    formData: PropTypes.object,
};

AidServicesMaxTable.defaultProps = {
    isEdit: false,
    onChange: null,
    aidServiceMaxBudgets: null,
    timePeriods: [],
    formData: null,
};

const mapStateToProps = state => ({
    aidServiceMaxBudgets: state.budgets.aidServiceMaxBudgets,
    timePeriods: state.budgets.timePeriods,
});

export default connect(mapStateToProps)(AidServicesMaxTable);
