import {put, select, take, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import modalIds from '../../modalIds';

const deleteSPRentalCarPricingFlow = function* deleteSPRentalCarPricingFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(serviceProviderActionTypes.INITIATE_DELETE_SP_RENTAL_CAR_PRICE_DATA_FLOW);
        const {pricingDataId, serviceProviderId} = payload;

        yield* openModal(modalIds.DELETE_SP_PRICING_DATA_CONFIRMATION);

        const responseAction = yield take([
            serviceProviderActionTypes.CONFIRM_DELETE_SP_RENTAL_CAR_PRICING,
            serviceProviderActionTypes.DECLINE_DELETE_SP_RENTAL_CAR_PRICING,
        ]);

        if (responseAction.type === serviceProviderActionTypes.CONFIRM_DELETE_SP_RENTAL_CAR_PRICING) {
            yield fork(
                fetchRequest,
                serviceProviderActionTypes.DELETE_SP_RENTAL_CAR_PRICING_REQUEST,
                partnerManagementService.deleteSPRentalCarPricing,
                {
                    rentalCarPricingId: pricingDataId,
                },
            );

            const responseAction = yield take([
                serviceProviderActionTypes.DELETE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED,
                serviceProviderActionTypes.DELETE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                yield put({
                    type: serviceProviderActionTypes.REMOVE_SP_RENTAL_CAR_PRICING,
                    payload: {
                        serviceProviderId,
                        rentalCarPricingId: pricingDataId,
                    },
                });
            }
        }

        yield* closeModal(modalIds.DELETE_SP_PRICING_DATA_CONFIRMATION);
    }
};

export default deleteSPRentalCarPricingFlow;
