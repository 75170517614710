import * as userProfileActionTypes from '../user-profiles/userProfileActionTypes';
import * as commissionerActionTypes from '../commissioners/commissionerActionTypes';
import * as serviceProviderActionTypes from '../service-providers/serviceProviderActionTypes';
import * as contractPartnerActionTypes from '../contract-partners/contractPartnerActionTypes';
import * as inaRulesActionTypes from '../ina-rules/inaRulesActionTypes';
import * as feesAndEstimationActionTypes from '../fees-and-estimation/feesAndEstimationActionTypes';
import errorTypes from './errorTypes';

const determineActionTypes = requestedAction => {
    if (!requestedAction) return null;

    switch (requestedAction) {
        case errorTypes.USER_CREATION_FAILED: {
            return {
                confirm: userProfileActionTypes.RETRY_USER_CREATION,
                decline: userProfileActionTypes.CANCEL_USER_CREATION,
            };
        }
        case errorTypes.USER_EDIT_FAILED: {
            return {
                confirm: userProfileActionTypes.RETRY_USER_EDIT,
                decline: userProfileActionTypes.CANCEL_USER_EDIT,
            };
        }
        case errorTypes.SERVICE_PROVIDER_UPDATE_FAILED: {
            return {
                confirm: serviceProviderActionTypes.RETRY_SERVICE_PROVIDER_UPDATE,
                decline: serviceProviderActionTypes.CANCEL_SERVICE_PROVIDER_UPDATE,
            };
        }
        case errorTypes.COMMISSIONER_CREATION_FAILED: {
            return {
                confirm: commissionerActionTypes.RETRY_COMMISSIONER_CREATION,
                decline: commissionerActionTypes.CANCEL_COMMISSIONER_CREATION,
            };
        }
        case errorTypes.COMMISSIONER_EDIT_FAILED: {
            return {
                confirm: commissionerActionTypes.RETRY_COMMISSIONER_EDIT,
                decline: commissionerActionTypes.CANCEL_COMMISSIONER_EDIT,
            };
        }
        case errorTypes.INA_RULES_EDIT_FAILED: {
            return {
                confirm: inaRulesActionTypes.RETRY_INA_RULE_EDIT,
                decline: inaRulesActionTypes.CANCEL_INA_RULE_EDIT,
            };
        }
        case errorTypes.ASSISTANCE_FEES_EDIT_FAILED: {
            return {
                confirm: feesAndEstimationActionTypes.RETRY_ASSISTANCE_FEES_EDIT,
                decline: feesAndEstimationActionTypes.CANCEL_ASSISTANCE_FEES_EDIT,
            };
        }
        case errorTypes.DELETE_CP_CONTACT_DATA_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_CONTACT_DATA_DELETE,
                decline: contractPartnerActionTypes.CANCEL_CP_CONTACT_DATA_DELETE,
            };
        }
        case errorTypes.CREATE_CP_CONTACT_DATA_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_CONTACT_DATA_CREATE,
                decline: contractPartnerActionTypes.CANCEL_CP_CONTACT_DATA_CREATE,
            };
        }
        case errorTypes.EDIT_CP_CONTACT_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_CONTACT_DATA_EDIT,
                decline: contractPartnerActionTypes.CANCEL_CP_CONTACT_DATA_EDIT,
            };
        }
        case errorTypes.CP_SERVICES_EDIT_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_UPDATE_CP_SERVICES,
                decline: contractPartnerActionTypes.CANCEL_UPDATE_CP_SERVICES,
            };
        }
        case errorTypes.CP_BASIC_INFO_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_CONTACT_DETAILS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_CP_CONTACT_DETAILS_UPDATE,
            };
        }

        case errorTypes.CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_CP_ASSIGNMENT_CHANNELS_UPDATE,
            };
        }

        case errorTypes.CP_STATUS_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_STATUS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_CP_STATUS_UPDATE,
            };
        }

        case errorTypes.ESTIMATION_EDIT_FAILED: {
            return {
                confirm: feesAndEstimationActionTypes.RETRY_ESTIMATION_EDIT,
                decline: feesAndEstimationActionTypes.CANCEL_ESTIMATION_EDIT,
            };
        }

        case errorTypes.STANDBY_TIMES_UPDATE_FAILED: {
            return {
                confirm: serviceProviderActionTypes.RETRY_STANDBY_TIMES_UPDATE,
                decline: serviceProviderActionTypes.CANCEL_STANDBY_TIMES_UPDATE,
            };
        }

        // todo add other actions
        default: return {
            confirm: null,
            decline: null,
        };
    }
};

export default determineActionTypes;
