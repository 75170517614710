import {fork, all, takeLatest} from 'redux-saga/effects';
import * as serviceProviderActionTypes from './serviceProviderActionTypes';
import searchServiceProviders from './sagas/searchServiceProviders';
import updateServiceProvider from './sagas/updateServiceProvider';
import changeServiceProviderActiveState from './sagas/changeServiceProviderActiveState';
import createStandbyTimesFlow from './sagas/createStandbyTimesFlow';
import updateStandbyTimesFlow from './sagas/updateStandbyTimesFlow';
import createSPRentalCarPricingFlow from './sagas/createSPRentalCarPricingFlow';
import updateSPRentalCarPricingFlow from './sagas/updateSPRentalCarPricingFlow';
import deleteSPRentalCarPricingFlow from './sagas/deleteSPRentalCarPricing';
import updateSPRentalCarAdditionalServicePricingFlow from './sagas/updateSPRentalCarAdditionalServicePricingFlow';
import createSPRentalCarAdditionalServicePricingFlow from './sagas/createSPRentalCarAdditionalServicePricingFlow';

/**
 *  Service provider watcher saga
 */
const serviceProviderWatcher = function* serviceProviderWatcher() {
    yield all([
        takeLatest(serviceProviderActionTypes.SEARCH_SERVICE_PROVIDERS, searchServiceProviders),
        fork(updateServiceProvider),
        fork(changeServiceProviderActiveState),
        fork(createStandbyTimesFlow),
        fork(updateStandbyTimesFlow),
        fork(createSPRentalCarPricingFlow),
        fork(updateSPRentalCarPricingFlow),
        fork(deleteSPRentalCarPricingFlow),
        fork(updateSPRentalCarAdditionalServicePricingFlow),
        fork(createSPRentalCarAdditionalServicePricingFlow),
    ]);
};

export default serviceProviderWatcher;
