import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, CheckboxGroup, Checkbox, ButtonPrimary} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon, closeIcon, Icon, checkmarkIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import useServiceProviderServiceTypes from '../useServiceProviderServiceTypes';
import createNewService from '../createNewService';

const formatServicesFromServiceTypes = (services, serviceTypes) => {
    return serviceTypes?.map(type => {
        const service = services?.find(service => service.serviceType === type);
        if (!service) return createNewService(type);
        return service;
    }) || services || [];
};

const ServiceProviderServicesPanel = ({serviceProvider, submitServiceProviderForm}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('service_provider_services_panel');
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const {services} = serviceProvider;
    const [serviceTypes, setServiceTypes] = useState(services?.map(service => service.serviceType) || []);

    const serviceTypesMatrix = useServiceProviderServiceTypes();

    useEffect(() => {
        setServiceTypes(services?.map(service => service.serviceType) || []);
    }, [services]);

    const toggleEditMode = () => {
        if (isEditModeActive) {
            resetServiceTypesData();
        }
        setIsEditModeActive(prevState => !prevState);
    };

    const resetServiceTypesData = () => {
        setServiceTypes(services?.map(service => service.serviceType) || []);
    };

    if (!serviceProvider) return null;

    const handleOnSubmit = () => {
        submitServiceProviderForm({
            serviceProviderData: {
                services: formatServicesFromServiceTypes(services, serviceTypes),
            },
            serviceProviderId: serviceProvider.id,
        });

        setIsEditModeActive(false);
    };

    return (
        <Panel
            title={translatePanel('panel_title.services')}
            actions={!isEditModeActive ? (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={toggleEditMode}
                />
            ) : (
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={toggleEditMode}
                />
            )}
        >
            {!isEditModeActive ? (
                <Table qaIdent="service-provider-service-types">
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="sp-service-types-vehicle" colSpan={3}>
                                {translatePanel('services_table_cell.vehicle_related')}
                            </TableCell>
                            <TableCell qaIdentPart="sp-service-types-person" colSpan={3}>
                                {translatePanel('services_table_cell.person_related')}
                            </TableCell>
                            <TableCell qaIdentPart="sp-service-types-home" colSpan={3}>
                                {translatePanel('services_table_cell.home_related')}
                            </TableCell>
                            <TableCell qaIdentPart="sp-service-types-connection" colSpan={3}>
                                {translatePanel('services_table_cell.connection_services')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceTypesMatrix.map((serviceTypeChunk, chunkIdx) => {
                            return (
                                <TableRow key={`serviceTypeChunk-${chunkIdx}`}>
                                    {
                                        serviceTypeChunk.map((serviceType, typeIdx) => {
                                            const hasSPServiceType = services?.find(service => {
                                                return service.serviceType === serviceType;
                                            });
                                            return (
                                                <TableCell key={`${serviceType}-${typeIdx}`} qaIdentPart="sp-service-type" colSpan={3}>
                                                    {serviceType && (
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-full-width',
                                                                'global!ace-u-flex',
                                                                'global!ace-u-flex--justify-space-between',
                                                                'global!ace-u-flex--align-center',
                                                            ])}
                                                        >
                                                            <span>
                                                                {translate(`global.ace_partner_service_type.${snakeCase(serviceType)}`)}
                                                            </span>
                                                            <Icon
                                                                icon={hasSPServiceType ? checkmarkIcon : closeIcon}
                                                                className={hasSPServiceType
                                                                    ? 'ace-c-icon--color-success'
                                                                    : 'ace-c-icon--color-default'
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </TableCell>
                                            );
                                        })
                                    }
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <Fragment>
                    <Table qaIdent="service-provider-service-types">
                        <TableHead>
                            <TableRow>
                                <TableCell qaIdentPart="sp-service-types-vehicle" colSpan={3}>
                                    {translatePanel('services_table_cell.vehicle_related')}
                                </TableCell>
                                <TableCell qaIdentPart="sp-service-types-person" colSpan={3}>
                                    {translatePanel('services_table_cell.person_related')}
                                </TableCell>
                                <TableCell qaIdentPart="sp-service-types-home" colSpan={3}>
                                    {translatePanel('services_table_cell.home_related')}
                                </TableCell>
                                <TableCell qaIdentPart="sp-service-types-connection" colSpan={3}>
                                    {translatePanel('services_table_cell.connection_services')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <CheckboxGroup name="serviceTypes" value={serviceTypes} onChange={setServiceTypes}>
                            <TableBody>
                                {serviceTypesMatrix.map((serviceTypeChunk, chunkIdx) => {
                                    return (
                                        <TableRow key={`serviceTypeChunk-${chunkIdx}`}>
                                            {serviceTypeChunk.map((serviceType, typeIdx) => {
                                                return (
                                                    <TableCell key={`${serviceType}-${typeIdx}`} qaIdentPart="sp-service-type" colSpan={3}>
                                                        {serviceType && (
                                                            <div
                                                                className={cx([
                                                                    'global!ace-u-full-width',
                                                                    'global!ace-u-flex',
                                                                    'global!ace-u-flex--justify-space-between',
                                                                    'global!ace-u-flex--align-center',
                                                                ])}
                                                            >
                                                                <span className={cx('global!ace-u-typography--variant-body')}>
                                                                    {translate(`global.ace_partner_service_type.${snakeCase(serviceType)}`)}
                                                                </span>
                                                                <Checkbox
                                                                    key={`${serviceType}Key`}
                                                                    name={serviceType}
                                                                    value={serviceType}
                                                                />
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </CheckboxGroup>
                    </Table>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-margin--top-16',
                        ])}
                    >
                        <ButtonPrimary onClick={handleOnSubmit}>
                            {translatePanel('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </Fragment>
            )}
        </Panel>
    );
};

ServiceProviderServicesPanel.propTypes = {
    serviceProvider: PropTypes.object,
    submitServiceProviderForm: PropTypes.func.isRequired,
};

ServiceProviderServicesPanel.defaultProps = {
    serviceProvider: {},
};

const mapDispatchToProps = dispatch => ({
    submitServiceProviderForm: payload => dispatch({
        type: serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(ServiceProviderServicesPanel);

