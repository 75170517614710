import {createSelector} from 'reselect';

/**
 * General state selectors
 */
export const getCommissioners = state => state.commissioners.commissioners;
export const getCommissionerSearchResults = state => state.commissioners.commissionerSearchResults;
export const getCommissionerSearchResultsCount = state => state.commissioners.commissionerSearchResultsCount;

/**
 * Returns selector for commissioner matched from route
 */
export const createCommissionerSelector = () => createSelector(
    [
        getCommissioners,
        (state, props) => {
            const {match} = props;
            return match?.params['commissionerId'];
        },
    ],
    (commissioners, commissionerId) => {
        if (!commissionerId) return null;
        return commissioners[commissionerId];
    },
);
