import {take, fork, select, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {resolveRoute} from '@computerrock/formation-router';
import {User, eupUserRoleTypes, clientTypes, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';
import * as selectors from '../userProfileSelectors';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import acAccessControl from '../../acAccessControl';
import successMessageTypes from '../../application/successMessageTypes';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const editUserDetailFlow = function* editUserDetailFlow() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    let shouldWaitForAction = true;
    let shouldRetryEditContact = false;
    let retryEditUserData = null;
    let responseAction = null;
    let payload = null;
    let currentUser = null;

    while (true) {
        if (shouldWaitForAction) {
            const initiationAction = yield take(userProfileActionTypes.INITIATE_EDIT_USER_PROFILE_FLOW);
            payload = initiationAction.payload;
            currentUser = yield select(selectors.getUser);
            const currentUserRoles = currentUser.clientRoles.find(clientRole => {
                return clientRole.client === clientTypes.AC_CLIENT;
            }).userRoles || [];

            const shouldFetchLockedCases = currentUserRoles ? currentUserRoles.includes(eupUserRoleTypes.ECS_ADMIN)
                || currentUserRoles.includes(eupUserRoleTypes.SUPER_ADMIN) : false;

            yield fork(
                fetchRequest,
                userProfileActionTypes.FETCH_USER_DETAILS_REQUEST,
                userProfileService.getUserDetails,
                {userId: payload.userId},
            );

            const fetchUserDetailsResponseAction = yield take([
                userProfileActionTypes.FETCH_USER_DETAILS_REQUEST_SUCCEEDED,
                userProfileActionTypes.FETCH_USER_DETAILS_REQUEST_FAILED,
            ]);

            if (shouldFetchLockedCases) {
                yield fork(
                    fetchRequest,
                    userProfileActionTypes.FETCH_LOCKED_SERVICE_CASES,
                    ecsFlowService.getLockedServiceCases,
                    {userId: payload.userId},
                );

                const fetchLockedServiceCasesResponseAction = yield take([
                    userProfileActionTypes.FETCH_LOCKED_SERVICE_CASES_REQUEST_SUCCEEDED,
                    userProfileActionTypes.FETCH_LOCKED_SERVICE_CASES_REQUEST_FAILED,
                ]);

                if (!fetchLockedServiceCasesResponseAction.error) {
                    const {lockedServiceCaseResults} = fetchLockedServiceCasesResponseAction.payload.response;

                    yield put({
                        type: userProfileActionTypes.STORE_LOCKED_SERVICE_CASE_RESULTS,
                        payload: {lockedServiceCaseResults, userId: payload.userId},
                    });
                }
            }

            if (fetchUserDetailsResponseAction.error) {
                yield* openModal(modalIds.FETCH_USER_DATA_FAILED_WARNING);
                continue;
            }
            const {userDTO} = fetchUserDetailsResponseAction.payload.response;

            yield put({
                type: userProfileActionTypes.STORE_USER_DETAILS,
                payload: {userDTO},
            });

            yield* openModal(modalIds.USER_DETAILS, {mode: 'edit', userId: payload.userId});

            responseAction = yield take([
                userProfileActionTypes.CONFIRM_EDIT_USER_PROFILE,
                userProfileActionTypes.DECLINE_EDIT_USER_PROFILE,
            ]);
        }
        if (responseAction.type === userProfileActionTypes.CONFIRM_EDIT_USER_PROFILE || shouldRetryEditContact) {
            const userData = shouldRetryEditContact ? retryEditUserData : responseAction?.payload;

            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                userProfileActionTypes.UPDATE_USER_PROFILE_REQUEST,
                userProfileService.updateUserProfile,
                {
                    userId: payload?.userId,
                    userDataPatchDTO: User.objectToPatchDTO(userData),
                },
            );

            const updateUserDataResponseAction = yield take([
                userProfileActionTypes.UPDATE_USER_PROFILE_REQUEST_SUCCEEDED,
                userProfileActionTypes.UPDATE_USER_PROFILE_REQUEST_FAILED,
            ]);

            if (!updateUserDataResponseAction.error) {
                if (shouldRetryEditContact) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.USER_EDIT_FAILED});
                }
                if (!shouldRetryEditContact) {
                    yield* closeModal(modalIds.USER_DETAILS, {mode: 'edit'});
                }

                payload?.history.replace(resolveRoute(
                    routePaths.USER_MANAGEMENT,
                    {},
                    {search: payload?.queryParamsString || ''},
                ));

                if (payload?.userId === currentUser?.id) {
                    yield put(acAccessControl.cleanUserPrivileges);
                    payload?.history.push(routePaths.ROOT);
                    continue;
                }

                shouldWaitForAction = true;
                shouldRetryEditContact = false;
                retryEditUserData = null;
                responseAction = null;
                payload = null;
                currentUser = null;

                yield put({
                    type: applicationActionTypes.INITIATE_SUCCESS_MESSAGE_FLOW,
                    payload: {successMessageType: successMessageTypes.USER_INFO_CHANGED},
                });

                if (retryActionPersistenceState === persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
                continue;
            }

            if (updateUserDataResponseAction.error) {
                yield* closeModal(modalIds.USER_DETAILS, {mode: 'edit'});
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.USER_EDIT_FAILED});

                const nextAction = yield take([
                    userProfileActionTypes.RETRY_USER_EDIT,
                    userProfileActionTypes.CANCEL_USER_EDIT,
                ]);

                if (retryActionPersistenceState === persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }

                if (nextAction.type === userProfileActionTypes.CANCEL_USER_EDIT) {
                    shouldWaitForAction = true;
                    shouldRetryEditContact = false;
                    retryEditUserData = null;
                    responseAction = null;
                    payload = null;
                    currentUser = null;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                    continue;
                }
                if (nextAction.type === userProfileActionTypes.RETRY_USER_EDIT) {
                    shouldWaitForAction = false;
                    shouldRetryEditContact = true;
                    retryEditUserData = userData;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                }
            }
        }
        yield* closeModal(modalIds.USER_DETAILS, {mode: 'edit', userId: payload.userId});
    }
};

export default editUserDetailFlow;
