import {fork, put, select, take} from 'redux-saga/effects';
import {persistenceStates} from '@computerrock/formation-core';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as budgetActionTypes from '../budgetsActionTypes';

const fetchBudgetsByTimePeriod = function* fetchBudgetsByTimePeriod({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {budgetPeriodId} = payload;

    yield put({
        type: budgetActionTypes.SET_TIME_PERIOD_BUDGETS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    yield put({
        type: budgetActionTypes.RESET_BUDGETS_BY_TIME,
    });

    yield fork(
        fetchRequest,
        budgetActionTypes.FETCH_BUDGET_PERIOD_REQUEST,
        pricingManagementService.getTimePeriodBudgets,
        {budgetPeriodId},
    );

    const responseAction = yield take([
        budgetActionTypes.FETCH_BUDGET_PERIOD_REQUEST_SUCCEEDED,
        budgetActionTypes.FETCH_BUDGET_PERIOD_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {budgetTimePeriodDTO} = response;

        yield put({
            type: budgetActionTypes.STORE_BUDGETS_BY_TIME,
            payload: {budgetTimePeriodDTO},
        });

        yield put({
            type: budgetActionTypes.SET_TIME_PERIOD_BUDGETS_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });

        return;
    }

    yield put({
        type: budgetActionTypes.SET_TIME_PERIOD_BUDGETS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.FAILED},
    });
};

export default fetchBudgetsByTimePeriod;
