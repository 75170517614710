import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import errorTypes from '../../application/errorTypes';

const updateServiceProvider = function* updateServiceProvider() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let deactivationPayload = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM);
            deactivationPayload = payload;
        }

        const {serviceProviderId, serviceProviderData} = deactivationPayload;
        const {retryActionPersistenceState} = yield select(state => state.application);

        yield fork(
            fetchRequest,
            serviceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(serviceProviderData),
                    partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
                },
                acePartnerId: serviceProviderId,
            },
        );

        const responseAction = yield take([
            serviceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST_SUCCEEDED,
            serviceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {acePartnerDTO: serviceProviderDTO} = response;

            yield put({
                type: serviceProviderActionTypes.STORE_SERVICE_PROVIDERS,
                payload: {serviceProviderDTOs: [serviceProviderDTO]},
            });

            if (!shouldWaitForAction) {
                shouldWaitForAction = true;
                deactivationPayload = null;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorTypes: ''});
            }
            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            yield* closeModal(modalIds.DEACTIVATE_SP_CONFIRMATION);
        }

        if (responseAction.error) {
            yield* closeModal(modalIds.DEACTIVATE_SP_CONFIRMATION);
            yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.SERVICE_PROVIDER_UPDATE_FAILED});

            const nextAction = yield take([
                serviceProviderActionTypes.RETRY_SERVICE_PROVIDER_UPDATE,
                serviceProviderActionTypes.CANCEL_SERVICE_PROVIDER_UPDATE,
            ]);

            if (nextAction.type === serviceProviderActionTypes.RETRY_SERVICE_PROVIDER_UPDATE) {
                shouldWaitForAction = false;
                if (retryActionPersistenceState !== persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }
                continue;
            }

            if (nextAction.type === serviceProviderActionTypes.CANCEL_SERVICE_PROVIDER_UPDATE) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorTypes: ''});
                shouldWaitForAction = true;
                deactivationPayload = null;

                if (retryActionPersistenceState === persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
            }
        }
    }
};

export default updateServiceProvider;
