import {put, select, take, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';

const deleteCPContactDataFlow = function* deleteCPContactDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let responseAction;
    let payloadContent;
    let shouldRetryAction = false;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_DELETE_CP_CONTACT_DATA_FLOW);
            payloadContent = payload;

            yield* openModal(modalIds.DELETE_CP_CONTACT_DATA_CONFIRMATION);

            responseAction = yield take([
                contractPartnerActionTypes.CONFIRM_DELETE_CP_CONTACT_DATA,
                contractPartnerActionTypes.DECLINE_DELETE_CP_CONTACT_DATA,
            ]);
        }

        if (responseAction.type === contractPartnerActionTypes.CONFIRM_DELETE_CP_CONTACT_DATA || shouldRetryAction) {
            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.DELETE_CP_CONTACT_DATA_REQUEST,
                partnerManagementService.deleteCPContactData,
                {
                    contactDataId: payloadContent.contactDataId,
                },
            );

            const deleteCPOperatingUnitResponseAction = yield take([
                contractPartnerActionTypes.DELETE_CP_CONTACT_DATA_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.DELETE_CP_CONTACT_DATA_REQUEST_FAILED,
            ]);

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            if (!deleteCPOperatingUnitResponseAction.error) {
                yield put({
                    type: contractPartnerActionTypes.REMOVE_CP_CONTACT_DATA,
                    payload: {
                        contractPartnerId: payloadContent.contractPartnerId,
                        contactDataId: payloadContent.contactDataId,
                    },
                });

                if (!shouldWaitForAction) {
                    shouldWaitForAction = true;
                    responseAction = null;
                    payloadContent = null;
                    shouldRetryAction = false;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }
            }

            if (deleteCPOperatingUnitResponseAction.error) {
                yield* closeModal(modalIds.DELETE_CP_CONTACT_DATA_CONFIRMATION);
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.DELETE_CP_CONTACT_DATA_FAILED});

                const nextAction = yield take([
                    contractPartnerActionTypes.CANCEL_CP_CONTACT_DATA_DELETE,
                    contractPartnerActionTypes.RETRY_CP_CONTACT_DATA_DELETE,
                ]);

                if (nextAction.type === contractPartnerActionTypes.CANCEL_CP_CONTACT_DATA_DELETE) {
                    shouldWaitForAction = true;
                    responseAction = null;
                    payloadContent = null;
                    shouldRetryAction = false;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                    continue;
                }

                if (nextAction.type === contractPartnerActionTypes.RETRY_CP_CONTACT_DATA_DELETE) {
                    shouldWaitForAction = false;
                    shouldRetryAction = true;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    continue;
                }
            }
        }

        yield* closeModal(modalIds.DELETE_CP_CONTACT_DATA_CONFIRMATION);
    }
};

export default deleteCPContactDataFlow;
