import {take, select, put, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';

const updateContractPartnerServices = function* updateContractPartnerServices() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let payloadContent;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CP_SERVICES_UPDATE);
            payloadContent = payload;
        }
        const {retryActionPersistenceState} = yield select(state => state.application);

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(payloadContent.contractPartnerData),
                    partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                },
                acePartnerId: payloadContent.contractPartnerId,
            },
        );

        const updateContractPartnerServicesResponseAction = yield take([
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_FAILED,
        ]);

        if (retryActionPersistenceState === persistenceStates.PENDING) {
            yield put({
                type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
        }

        if (!updateContractPartnerServicesResponseAction.error) {
            const {response} = updateContractPartnerServicesResponseAction.payload;
            const {acePartnerDTO} = response;

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {contractPartnerDTOs: [acePartnerDTO]},
            });
            if (!shouldWaitForAction) {
                shouldWaitForAction = true;
                payloadContent = null;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
            }
        }

        if (updateContractPartnerServicesResponseAction.error) {
            yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.CP_SERVICES_EDIT_FAILED});

            const nextAction = yield take([
                contractPartnerActionTypes.RETRY_UPDATE_CP_SERVICES,
                contractPartnerActionTypes.CANCEL_UPDATE_CP_SERVICES,
            ]);

            if (nextAction.type === contractPartnerActionTypes.RETRY_UPDATE_CP_SERVICES) {
                shouldWaitForAction = false;
                if (retryActionPersistenceState !== persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }
                continue;
            }
            if (nextAction.type === contractPartnerActionTypes.CANCEL_UPDATE_CP_SERVICES) {
                shouldWaitForAction = true;
                payloadContent = null;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
            }
        }
    }
};

export default updateContractPartnerServices;
