import {produce} from 'immer';
import {Estimation, Fee} from '@ace-de/eua-entity-types';
import * as feesAndEstimationActionTypes from './feesAndEstimationActionTypes';


const initialState = {
    assistanceFees: [],
    estimations: [],
};

/**
 * Fees and estimation reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const feesAndEstimationReducer = produce((draft, action) => {
    switch (action.type) {
        case feesAndEstimationActionTypes.STORE_ASSISTANCE_FEES: {
            const {fees} = action.payload;
            draft.assistanceFees = fees.map(fee => new Fee().fromDTO(fee));
            break;
        }
        case feesAndEstimationActionTypes.STORE_ESTIMATIONS: {
            const {estimations} = action.payload;
            draft.estimations = estimations.map(estimation => new Estimation().fromDTO(estimation));
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default feesAndEstimationReducer;
