import {persistenceStates} from '@ace-de/eua-entity-types';
import {produce} from 'immer';
import * as applicationActionTypes from './applicationActionTypes';
/**
 * Initial application domain state
 *
 * @type {Object}
 */
const initialState = {
    serviceManager: null,
    retryActionPersistenceState: persistenceStates.READY,
};

/**
 * Application reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */
const applicationReducer = produce((draft, action) => {
    switch (action.type) {
        case applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.retryActionPersistenceState = persistenceState;
            break;
        }
        default:
            // no-op
    }
}, initialState);

export default applicationReducer;
