/**
 * Command action types
 */
export const INITIATE_ASSISTANCE_FEES_EDIT_FLOW = '[fees-and-estimation] INITIATE_ASSISTANCE_FEES_EDIT_FLOW';
export const INITIATE_ESTIMATIONS_EDIT_FLOW = '[fees-and-estimation] INITIATE_ESTIMATIONS_EDIT_FLOW';
export const CONFIRM_ASSISTANCE_FEES_EDIT = '[fees-and-estimation] CONFIRM_ASSISTANCE_FEES_EDIT';
export const DECLINE_ASSISTANCE_FEES_EDIT = '[fees-and-estimation] DECLINE_ASSISTANCE_FEES_EDIT';
export const CONFIRM_ESTIMATIONS_EDIT = '[fees-and-estimation] CONFIRM_ESTIMATIONS_EDIT';
export const DECLINE_ESTIMATIONS_EDIT = '[fees-and-estimation] DECLINE_ESTIMATIONS_EDIT';
export const RETRY_ASSISTANCE_FEES_EDIT = '[fees-and-estimation] RETRY_ASSISTANCE_FEES_EDIT';
export const CANCEL_ASSISTANCE_FEES_EDIT = '[fees-and-estimation] CANCEL_ASSISTANCE_FEES_EDIT';
export const RETRY_ESTIMATION_EDIT = '[fees-and-estimation] RETRY_ESTIMATION_EDIT';
export const CANCEL_ESTIMATION_EDIT = '[fees-and-estimation] CANCEL_ESTIMATION_EDIT';

/**
 * Event action types
 */
export const FETCH_ASSISTANCE_FEES_REQUEST = '[fees-and-estimation] FETCH_ASSISTANCE_FEES_REQUEST';
export const FETCH_ASSISTANCE_FEES_REQUEST_SENT = '[fees-and-estimation] FETCH_ASSISTANCE_FEES_REQUEST_SENT';
export const FETCH_ASSISTANCE_FEES_REQUEST_FAILED = '[fees-and-estimation] FETCH_ASSISTANCE_FEES_REQUEST_FAILED';
export const FETCH_ASSISTANCE_FEES_REQUEST_SUCCEEDED = '[fees-and-estimation] FETCH_ASSISTANCE_FEES_REQUEST_SUCCEEDED';

export const UPDATE_ASSISTANCE_FEES_REQUEST = '[fees-and-estimation] UPDATE_ASSISTANCE_FEES_REQUEST';
export const UPDATE_ASSISTANCE_FEES_REQUEST_SENT = '[fees-and-estimation] UPDATE_ASSISTANCE_FEES_REQUEST_SENT';
export const UPDATE_ASSISTANCE_FEES_REQUEST_FAILED = '[fees-and-estimation] UPDATE_ASSISTANCE_FEES_REQUEST_FAILED';
export const UPDATE_ASSISTANCE_FEES_REQUEST_SUCCEEDED = '[fees-and-estimation] UPDATE_ASSISTANCE_FEES_REQUEST_SUCCEEDED';

export const FETCH_ESTIMATIONS_REQUEST = '[fees-and-estimation] FETCH_ESTIMATIONS_REQUEST';
export const FETCH_ESTIMATIONS_REQUEST_SENT = '[fees-and-estimation] FETCH_ESTIMATIONS_REQUEST_SENT';
export const FETCH_ESTIMATIONS_REQUEST_FAILED = '[fees-and-estimation] FETCH_ESTIMATIONS_REQUEST_FAILED';
export const FETCH_ESTIMATIONS_REQUEST_SUCCEEDED = '[fees-and-estimation] FETCH_ESTIMATIONS_REQUEST_SUCCEEDED';

export const UPDATE_ESTIMATIONS_REQUEST = '[fees-and-estimation] UPDATE_ESTIMATIONS_REQUEST';
export const UPDATE_ESTIMATIONS_REQUEST_SENT = '[fees-and-estimation] UPDATE_ESTIMATIONS_REQUEST_SENT';
export const UPDATE_ESTIMATIONS_REQUEST_FAILED = '[fees-and-estimation] UPDATE_ESTIMATIONS_REQUEST_FAILED';
export const UPDATE_ESTIMATIONS_REQUEST_SUCCEEDED = '[fees-and-estimation] UPDATE_ESTIMATIONS_REQUEST_SUCCEEDED';

export const FETCH_ESTIMATION_REQUEST = '[fees-and-estimation] FETCH_ESTIMATION_REQUEST';
export const FETCH_ESTIMATION_REQUEST_SENT = '[fees-and-estimation] FETCH_ESTIMATION_REQUEST_SENT';
export const FETCH_ESTIMATION_REQUEST_SUCCEEDED = '[fees-and-estimation] FETCH_ESTIMATION_REQUEST_SUCCEEDED';
export const FETCH_ESTIMATION_REQUEST_FAILED = '[fees-and-estimation] FETCH_ESTIMATION_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_ASSISTANCE_FEES = '[fees-and-estimation] STORE_ASSISTANCE_FEES';
export const STORE_ESTIMATIONS = '[fees-and-estimation] STORE_ESTIMATIONS';
