import {all, fork} from 'redux-saga/effects';
import initiateINARuleEditFlow from './sagas/initiateINARuleEditFlow';

/**
 *  INA rules watcher saga
 */
const inaRulesWatcher = function* inaRulesWatcher() {
    yield all([
        fork(initiateINARuleEditFlow),
    ]);
};

export default inaRulesWatcher;
