import {take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import modalIds from '../../modalIds';

const createStandbyTimesFlow = function* createStandbyTimesFlow() {
    while (true) {
        yield take(serviceProviderActionTypes.INITIATE_CREATE_STANDBY_TIMES_ENTRY_FLOW);

        yield* openModal(modalIds.STANDBY_TIMES_DATA_MODAL);

        const chosenModalOption = yield take([
            serviceProviderActionTypes.CONFIRM_CREATE_STANDBY_TIMES_ENTRY,
            serviceProviderActionTypes.DECLINE_CREATE_STANDBY_TIMES_ENTRY,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceProviderActionTypes.CONFIRM_CREATE_STANDBY_TIMES_ENTRY) {
            const {serviceProviderId, serviceProviderData} = chosenModalOption.payload;

            yield put({
                type: serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM,
                payload: {serviceProviderId, serviceProviderData},
            });
        }

        yield* closeModal(modalIds.STANDBY_TIMES_DATA_MODAL);
    }
};

export default createStandbyTimesFlow;
