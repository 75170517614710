import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, HighlightCircle} from '@ace-de/ui-components';
import {ButtonPrimary, ButtonSecondary} from '@ace-de/ui-components/buttons';
import {Icon, closeIcon, warningOutlinedIcon} from '@ace-de/ui-components/icons';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

const DeleteSPRentalCarPricingConfirmationModal = props => {
    const {cx} = useStyles();
    const {confirmSPRentalCarPricingDeletion, declineSPRentalCarPricingDeletion, hasBackdrop} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('delete_sp_rental_car_pricing_confirmation_modal');

    return (
        <Modal
            action={(
                <Icon icon={closeIcon} onClick={declineSPRentalCarPricingDeletion} />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--primary',
                    ])}
                >
                    <Icon
                        icon={warningOutlinedIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-highlight-pressed',
                        ])}
                    />
                </HighlightCircle>
                <p
                    className={cx([
                        'global!ace-u-margin--bottom-32',
                        'global!ace-u-typography--variant-h3',
                    ])}
                >
                    {translateModal('title.delete_rental_car_pricing')}
                </p>
                <p
                    className={cx([
                        'global!ace-u-margin--bottom-32',
                        'global!ace-u-typography--align-center',
                    ])}
                >
                    {translateModal('heading.delete_rental_car_pricing')}
                </p>
                <ButtonPrimary
                    className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                    onClick={confirmSPRentalCarPricingDeletion}
                >
                    {translateModal('button_label.delete')}
                </ButtonPrimary>
                <ButtonSecondary
                    className={cx('global!ace-u-full-width')}
                    onClick={declineSPRentalCarPricingDeletion}
                >
                    {translateModal('button_label.abort')}
                </ButtonSecondary>
            </div>
        </Modal>
    );
};

DeleteSPRentalCarPricingConfirmationModal.propTypes = {
    declineSPRentalCarPricingDeletion: PropTypes.func.isRequired,
    confirmSPRentalCarPricingDeletion: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
};

DeleteSPRentalCarPricingConfirmationModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    declineSPRentalCarPricingDeletion: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_DELETE_SP_RENTAL_CAR_PRICING,
    }),
    confirmSPRentalCarPricingDeletion: () => dispatch({
        type: serviceProviderActionTypes.CONFIRM_DELETE_SP_RENTAL_CAR_PRICING,
    }),
});

export default connect(null, mapDispatchToProps)(DeleteSPRentalCarPricingConfirmationModal);
