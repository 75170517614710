import {put, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

/**
 * Deactivate service provider flow (uses modal)
 */
const changeServiceProviderActiveState = function* changeServiceProviderActiveState() {
    while (true) {
        const {payload} = yield take(serviceProviderActionTypes.INITIATE_CHANGE_SP_ACTIVE_STATE_FLOW);
        const {serviceProviderId, contractStatus} = payload;
        const serviceProviderData = {
            contractStatus: contractStatus,
        };

        if (contractStatus === apmContractPartnerContractStatusTypes.ACTIVE) {
            yield put({
                type: serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM,
                payload: {serviceProviderId, serviceProviderData},
            });
            continue;
        }

        yield* openModal(modalIds.DEACTIVATE_SP_CONFIRMATION);

        const deactivateSPAction = yield take([
            serviceProviderActionTypes.CONFIRM_SERVICE_PROVIDER_DEACTIVATE,
            serviceProviderActionTypes.DECLINE_SERVICE_PROVIDER_DEACTIVATE,
        ]);

        if (deactivateSPAction.type === serviceProviderActionTypes.DECLINE_SERVICE_PROVIDER_DEACTIVATE) {
            yield* closeModal(modalIds.DEACTIVATE_SP_CONFIRMATION);
            continue;
        }

        if (deactivateSPAction.type === serviceProviderActionTypes.CONFIRM_SERVICE_PROVIDER_DEACTIVATE) {
            yield put({
                type: serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM,
                payload: {serviceProviderId, serviceProviderData},
            });
        }
    }
};

export default changeServiceProviderActiveState;
