import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

/**
 * Search service cases
 */
const searchServiceCases = function* searchServiceCases({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const {searchQueryParams} = payload;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.SEARCH_SERVICE_CASES_REQUEST,
        ecsFlowService.searchServiceCases,
        {
            searchQueryParams,
        },
    );

    const searchServiceCasesResponseAction = yield take([
        contractPartnerActionTypes.SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!searchServiceCasesResponseAction.error) {
        const {response} = searchServiceCasesResponseAction.payload;
        const {results: serviceCaseSearchResults, totalCount: serviceCaseSearchResultsCount} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
            payload: {serviceCaseSearchResults, serviceCaseSearchResultsCount},
        });
    }
};

export default searchServiceCases;
