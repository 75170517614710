import {put, select, take, fork} from 'redux-saga/effects';
import {apmACEPartnerServiceTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import loadServiceProviderRentalCarPricing from './loadServiceProviderRentalCarPricing';
import modalIds from '../../modalIds';
import * as feesAndEstimationActionTypes from '../../fees-and-estimation/feesAndEstimationActionTypes';
import config from '../../config';

const loadServiceProvider = function* loadServiceProvider({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {match, location} = payload;
    const {serviceProviderId} = match.params;

    // do not reload service provider when agent opens rental car (additional) pricing data modal
    if (location && location.state?.isModalOpen
        && [modalIds.SP_RENTAL_CAR_ADDITIONAL_SERVICE_PRICING_DATA, modalIds.SP_RENTAL_CAR_PRICING_DATA]
            .includes(location.state?.modalId)
    ) return;

    yield fork(
        fetchRequest,
        serviceProviderActionTypes.FETCH_SERVICE_PROVIDER_REQUEST,
        partnerManagementService.getACEPartner,
        {
            acePartnerId: serviceProviderId,
        },
    );

    const responseAction = yield take([
        serviceProviderActionTypes.FETCH_SERVICE_PROVIDER_REQUEST_FAILED,
        serviceProviderActionTypes.FETCH_SERVICE_PROVIDER_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTO: serviceProviderDTO} = response;

        yield put({
            type: serviceProviderActionTypes.STORE_SERVICE_PROVIDERS,
            payload: {serviceProviderDTOs: [serviceProviderDTO]},
        });

        if (serviceProviderDTO?.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.RENTAL_CAR;
        })) {
            // load service provider rental car pricing
            yield fork(loadServiceProviderRentalCarPricing, {serviceProviderId});
        }

        if (!!serviceProviderDTO.address?.country && serviceProviderDTO.address.country !== config.CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE) { // eslint-disable-line max-len
            yield fork(
                fetchRequest,
                feesAndEstimationActionTypes.FETCH_ESTIMATION_REQUEST,
                pricingManagementService.getEstimation,
                {
                    estimationId: serviceProviderId,
                },
            );

            const estimationResponseAction = yield take([
                feesAndEstimationActionTypes.FETCH_ESTIMATION_REQUEST_FAILED,
                feesAndEstimationActionTypes.FETCH_ESTIMATION_REQUEST_SUCCEEDED,
            ]);

            if (!estimationResponseAction.error) {
                const {response} = estimationResponseAction.payload;
                const {estimation} = response;
                yield put({
                    type: serviceProviderActionTypes.STORE_ESTIMATION_ACTIVE_STATUS,
                    payload: {isActive: estimation?.active, serviceProviderId},
                });
            }
        }
    }
};

export default loadServiceProvider;
