import React, {useRef, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useStyles, Panel, ButtonSecondary, ScrollableBlock} from '@ace-de/ui-components';
import {Icon, calendarIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import * as serviceProviderSelectors from '../serviceProviderSelectors';
import EmergencyLawyerItem from './EmergencyLawyerItem';
import styles from './EmergencyLawyersPanel.module.scss';

const EmergencyLawyersPanel = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('emergency_lawyers_panel');
    const standbyTimesButtonRef = useRef(null);
    const {emergencyLawyers, standbyTimesSections} = props;

    const openStandbyTimesScreen = () => {
        const {pathname} = resolveRoute(routePaths.STANDBY_TIMES);
        window.open(pathname, '_blank');
        standbyTimesButtonRef.current.blur();
    };

    return (
        <Panel
            title={translatePanel('title.emergency_lawyers')}
            contentClassName={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--justify-center',
            ])}
            className={cx(['global!ace-u-flex--grow-1', 'ace-c-emergency-lawyer-panel'])}
        >
            <Fragment>
                <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                    <div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--bottom-24',
                            ])}
                        >
                            <Icon icon={calendarIcon} />
                            <span className={cx('global!ace-u-margin--left-8')}>
                                {translatePanel('section_label.currently_active')}
                            </span>
                        </div>
                        {(standbyTimesSections.activeSection || []).map(standbyTime => {
                            const emergencyLawyer = emergencyLawyers[standbyTime.serviceProviderId];
                            const formattedStartDate = moment(standbyTime.startDate).format('DD.MM.YYYY');
                            const formattedEndDate = moment(standbyTime.endDate).format('DD.MM.YYYY');
                            const standbyTimeDates = `${formattedStartDate} - ${formattedEndDate}`;

                            return (
                                emergencyLawyer
                                    ? (
                                        <EmergencyLawyerItem
                                            key={standbyTime.id}
                                            emergencyLawyer={emergencyLawyer}
                                            standbyTimeDates={standbyTimeDates}
                                        />
                                    ) : null
                            );
                        })}
                    </div>
                    <div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--bottom-24',
                            ])}
                        >
                            <Icon icon={calendarIcon} />
                            <span className={cx('global!ace-u-margin--left-8')}>
                                {translatePanel('section_label.standby_calendar')}
                            </span>
                        </div>
                        {(standbyTimesSections.standbySection || []).map(standbyTime => {
                            const emergencyLawyer = emergencyLawyers[standbyTime.serviceProviderId];
                            const formattedStartDate = moment(standbyTime.startDate).format('DD.MM.YYYY');
                            const formattedEndDate = moment(standbyTime.endDate).format('DD.MM.YYYY');
                            const standbyTimeDates = `${formattedStartDate} - ${formattedEndDate}`;

                            return (
                                emergencyLawyer
                                    ? (
                                        <EmergencyLawyerItem
                                            key={standbyTime.id}
                                            emergencyLawyer={emergencyLawyer}
                                            standbyTimeDates={standbyTimeDates}
                                        />
                                    ) : null
                            );
                        })}
                    </div>
                </ScrollableBlock>
                <ButtonSecondary
                    ref={standbyTimesButtonRef}
                    name="openStandbyTimesScreenButton"
                    onClick={openStandbyTimesScreen}
                    className={cx('global!ace-u-margin--top-64')}
                >
                    {translatePanel('button.edit_standby_times')}
                </ButtonSecondary>
            </Fragment>
        </Panel>
    );
};

EmergencyLawyersPanel.propTypes = {
    emergencyLawyers: PropTypes.object,
    standbyTimesSections: PropTypes.object,
};

EmergencyLawyersPanel.defaultProps = {
    emergencyLawyers: {},
    standbyTimesSections: null,
};

const mapStateToProps = (state, props) => {
    const standbyTimesSectionsSelector = serviceProviderSelectors.createStandbyTimesSectionsSelector();

    return {
        emergencyLawyers: serviceProviderSelectors.getEmergencyLawyers(state),
        standbyTimesSections: standbyTimesSectionsSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(EmergencyLawyersPanel);
