import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ButtonPrimary, Checkbox, InputCurrency, Modal, useStyles} from '@ace-de/ui-components';
import {closeIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import * as feesAndEstimationActionTypes from '../feesAndEstimationActionTypes';
import assistanceFeesAndEstimationModalTypes from './assistanceFeesAndEstimationModalTypes';

const EditAssistanceFeesAndEstimationModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_assistance_fees_modal');
    const {assistanceFees, hasBackdrop, declineAssistanceFeesEditFlow, confirmAssistanceFeesEditFlow, location} = props;
    const {estimations, declineEstimationsEditFlow, confirmEstimationsEditFlow} = props;
    const modalType = location?.query?.type;
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if ((modalType === assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES && !assistanceFees?.length)
            || (modalType === assistanceFeesAndEstimationModalTypes.ESTIMATIONS && !estimations?.length)) return;
        const data = {};
        modalType === assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES
            ? assistanceFees.forEach(assistanceFee => {
                data[assistanceFee.id] = assistanceFee;
            })
            : estimations.forEach(estimation => {
                data[estimation.id] = {
                    ...estimation,
                    isEU: !!estimation.isEU,
                };
            });
        setFormData(data);
    }, [assistanceFees, estimations, modalType]);

    const handleOnSubmit = () => {
        const data = Object.values(formData);
        modalType === assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES
            ? confirmAssistanceFeesEditFlow({fees: data})
            : confirmEstimationsEditFlow({estimations: data});
    };

    return (
        <Modal
            title={modalType ? translateModal(`modal_title.${modalType.toLowerCase()}`) : ''}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={modalType === assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES
                        ? declineAssistanceFeesEditFlow
                        : declineEstimationsEditFlow
                }
                />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx([
                'ace-u-modal-content-size--m',
                'ace-c-modal__content--scrollable',
                'ace-c-modal__content--full-bleed',
            ])}
        >
            <Table qaIdent="assistance-fees-and-estimations" className={cx('global!ace-u-margin--top-24')}>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="id" colSpan={3}>
                            {translateModal('table_cell.id')}
                        </TableCell>
                        <TableCell
                            qaIdentPart="name"
                            colSpan={modalType === assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES ? 6 : 5}
                        >
                            {translateModal('table_cell.name')}
                        </TableCell>
                        {modalType === assistanceFeesAndEstimationModalTypes.ESTIMATIONS && (
                            <TableCell qaIdentPart="eu">
                                {translateModal('table_cell.eu')}
                            </TableCell>
                        )}
                        <TableCell
                            qaIdentPart="amount"
                            colSpan={3}
                            className={cx('ace-c-table__cell--text-align-right')}
                        >
                            {translateModal('table_cell.price')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {modalType === assistanceFeesAndEstimationModalTypes.ASSISTANCE_FEES
                        && Object.keys(formData)?.length > 0 && Object.keys(formData).map(key => (
                            <TableRow
                                key={formData[key].id}
                                qaIdentPart={formData[key].id}
                            >
                                <TableCell
                                    qaIdentPartPostfix={formData[key].id}
                                    qaIdentPart="id"
                                    colSpan={3}
                                >
                                    {formData[key].id}
                                </TableCell>
                                <TableCell
                                    qaIdentPartPostfix={formData[key].id}
                                    qaIdentPart="name"
                                    colSpan={6}
                                >
                                    {formData[key].name}
                                </TableCell>
                                <TableCell
                                    qaIdentPartPostfix={formData[key].id}
                                    qaIdentPart="amount"
                                    colSpan={3}
                                    className={cx('ace-c-table__cell--text-align-right')}
                                >
                                    <InputCurrency
                                        name="assistanceFeeAmount"
                                        value={formData[key].amount}
                                        onChange={value => setFormData(prevState => ({
                                            ...prevState,
                                            [key]: {
                                                ...formData[key],
                                                amount: value,
                                            },
                                        }))}
                                    />
                                </TableCell>
                            </TableRow>
                    ))}
                    {modalType === assistanceFeesAndEstimationModalTypes.ESTIMATIONS
                        && Object.keys(formData)?.length > 0 && Object.keys(formData).map(key => (
                            <TableRow
                                key={formData[key].id}
                                qaIdentPart={formData[key].id}
                            >
                                <TableCell
                                    qaIdentPartPostfix={formData[key].id}
                                    qaIdentPart="id"
                                    colSpan={3}
                                >
                                    {formData[key].id}
                                </TableCell>
                                <TableCell
                                    qaIdentPartPostfix={formData[key].id}
                                    qaIdentPart="name"
                                    colSpan={5}
                                >
                                    {formData[key].name}
                                </TableCell>
                                <TableCell
                                    qaIdentPartPostfix={formData[key].id}
                                    qaIdentPart="eu"
                                >
                                    <Checkbox
                                        name="isEU"
                                        value={true}
                                        isSelected={formData[key].isEU}
                                        onChange={value => setFormData(prevState => ({
                                            ...prevState,
                                            [key]: {
                                                ...formData[key],
                                                isEU: !!value,
                                            },
                                        }))}
                                    />
                                </TableCell>
                                <TableCell
                                    qaIdentPartPostfix={formData[key].id}
                                    qaIdentPart="amount"
                                    colSpan={3}
                                    className={cx('ace-c-table__cell--text-align-right')}
                                >
                                    <InputCurrency
                                        name="estimationAmount"
                                        value={formData[key].amount}
                                        onChange={value => setFormData(prevState => ({
                                            ...prevState,
                                            [key]: {
                                                ...formData[key],
                                                amount: value,
                                            },
                                        }))}
                                    />
                                </TableCell>
                            </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div
                className={cx([
                    'global!ace-u-margin--24-24-24-0',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <ButtonPrimary
                    onClick={handleOnSubmit}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

EditAssistanceFeesAndEstimationModal.propTypes = {
    assistanceFees: PropTypes.array,
    estimations: PropTypes.array,
    hasBackdrop: PropTypes.bool,
    declineAssistanceFeesEditFlow: PropTypes.func.isRequired,
    confirmAssistanceFeesEditFlow: PropTypes.func.isRequired,
    declineEstimationsEditFlow: PropTypes.func.isRequired,
    confirmEstimationsEditFlow: PropTypes.func.isRequired,
    location: PropTypes.object,
};

EditAssistanceFeesAndEstimationModal.defaultProps = {
    assistanceFees: null,
    estimations: undefined,
    hasBackdrop: false,
    location: null,
};

const mapStateToProps = state => ({
    assistanceFees: state.feesAndEstimation.assistanceFees,
    estimations: state.feesAndEstimation.estimations,
});

const mapDispatchToProps = dispatch => ({
    declineAssistanceFeesEditFlow: () => dispatch({
        type: feesAndEstimationActionTypes.DECLINE_ASSISTANCE_FEES_EDIT,
    }),
    confirmAssistanceFeesEditFlow: payload => dispatch({
        type: feesAndEstimationActionTypes.CONFIRM_ASSISTANCE_FEES_EDIT,
        payload,
    }),
    declineEstimationsEditFlow: () => dispatch({
        type: feesAndEstimationActionTypes.DECLINE_ESTIMATIONS_EDIT,
    }),
    confirmEstimationsEditFlow: payload => dispatch({
        type: feesAndEstimationActionTypes.CONFIRM_ESTIMATIONS_EDIT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAssistanceFeesAndEstimationModal);
