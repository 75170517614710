import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import {resolveRoute, useSearchQueryParams, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {InteractiveIcon, plusIcon, Tab, TabBar, useStyles} from '@ace-de/ui-components';
import routePaths from '../../routePaths';
import * as budgetActionTypes from '../../budgets/budgetsActionTypes';

const BudgetTimePeriodsNavigationBar = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('budgets_screen');
    const {timePeriods, history, initiateCreateTimePeriodFlow} = props;
    const {budgetPeriodId} = useSearchQueryParams();

    const navigateToRoute = budgetPeriodId => {
        if (!budgetPeriodId) return;
        const queryParams = new URLSearchParams();
        const timePeriod = timePeriods.find(timePeriod => timePeriod.id === budgetPeriodId);

        queryParams.append('validFrom', timePeriod.validFrom);
        queryParams.append('validUntil', timePeriod.validUntil);
        queryParams.append('budgetPeriodId', `${timePeriod.id}`);

        history.push(resolveRoute(routePaths.BUDGETS, {}, {search: queryParams.toString()}));
    };

    return (
        <TabBar
            name="priceManagementTabs"
            className={cx('ace-c-tab-bar--secondary-background')}
            onChange={navigateToRoute}
            value={budgetPeriodId ? parseInt(budgetPeriodId, 10) : ''}
        >
            {timePeriods.length > 0 && (timePeriods.map(timePeriod => (
                <Tab
                    key={timePeriod.id}
                    className={cx('ace-c-tab--secondary-background')}
                    name={`${timePeriod.validFrom}-${timePeriod.validUntil}`}
                    value={timePeriod.id}
                >
                    {`${moment(timePeriod.validFrom).format('DD.MM.YYYY')}-${moment(timePeriod.validUntil).format('DD.MM.YYYY')}`}
                </Tab>
            )))}
            <InteractiveIcon
                className={cx([
                    'ace-c-interactive-icon--primary',
                    'ace-c-interactive-icon--highlight',
                    'global!ace-u-margin--left-8',
                ], {
                    'global!ace-u-height--64': timePeriods.length === 0,
                })}
                onClick={initiateCreateTimePeriodFlow}
                icon={plusIcon}
            >
                {translateScreen('button_label.add_new_period')}
            </InteractiveIcon>
        </TabBar>
    );
};

BudgetTimePeriodsNavigationBar.propTypes = {
    timePeriods: PropTypes.array,
    initiateCreateTimePeriodFlow: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

BudgetTimePeriodsNavigationBar.defaultProps = {
    timePeriods: [],
};

const mapStateToProps = state => ({
    timePeriods: state.budgets.timePeriods,
});

const mapDispatchToProps = dispatch => ({
    initiateCreateTimePeriodFlow: () => dispatch({
        type: budgetActionTypes.INITIATE_CREATE_TIME_PERIOD_FLOW,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetTimePeriodsNavigationBar));
