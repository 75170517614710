import moment from 'moment/moment';

const checkIfTariffPeriodValid = (validFrom, validUntil) => {
    const momentFrom = moment(validFrom);
    const momentUntil = moment(validUntil);

    return momentFrom.year() === 2024 && momentUntil.year() === 2025;
};

export default checkIfTariffPeriodValid;
