import {put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import config from '../../config';
import * as commissionerActionTypes from '../commissionerActionTypes';
import routePaths from '../../routePaths';
import modalIds from '../../modalIds';

const loadCommissionerSearchResults = function* loadCommissionerSearchResults({payload}) {
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('sort', 'name,asc');
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);

    const commissionersSearchScreenMatch = matchPath(location.pathname, {
        path: routePaths.COMMISSIONER_SEARCH,
        exact: true,
    });

    if (!commissionersSearchScreenMatch) return;
    // do not reload commissioner search results when agent opens user details modal
    if (location && location.state?.isModalOpen && location.state?.modalId === modalIds.CREATE_COMMISSIONER) return;

    yield put({
        type: commissionerActionTypes.SEARCH_COMMISSIONERS,
        payload: {searchQueryParams},
    });
};

export default loadCommissionerSearchResults;
