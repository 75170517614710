import React, {useState, useMemo, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useArcGISMap} from '@ace-de/eua-arcgis-map';
import {ContentBlock, ContentItem, Panel, ScrollableBlock, useStyles} from '@ace-de/ui-components';
import SidebarExpansionButton from './ui-elements/SidebarExpansionButton';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import styles from './ContractPartnerOperationAreaTab.module.scss';

const ContractPartnerOperationAreaTab = props => {
    const {cx} = useStyles(props, styles);
    const {contractPartner, initiateCPOperationAreaMap, match} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_operation_area_tab');
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const arcGISMap = useArcGISMap('vpm-contract-partner-operation-areas');
    const isArcGISMapLoaded = useRef(false);

    useEffect(() => {
        if (!arcGISMap || isArcGISMapLoaded.current) return;
        isArcGISMapLoaded.current = true;
        initiateCPOperationAreaMap({
            mapName: 'vpm-contract-partner-operation-areas',
            match,
        });
    }, [arcGISMap, initiateCPOperationAreaMap, match]);

    const websiteUrl = useMemo(() => {
        if (!contractPartner?.businessContactDetails?.websiteUrl) return '';
        try {
            return new URL(contractPartner.businessContactDetails.websiteUrl).hostname;
        } catch (err) {
            return contractPartner?.businessContactDetails?.websiteUrl;
        }
    }, [contractPartner?.businessContactDetails?.websiteUrl]);

    // if no contract partner, don't render
    if (!contractPartner) return;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-contract-partner-operation-area-tab__left-sidebar',
                ], {
                    'ace-c-contract-partner-operation-area-tab__left-sidebar--shrinked': !isSidebarExpanded,
                })}
            >
                <Panel
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-padding--left-32',
                                'global!ace-u-padding--right-32',
                            ])}
                        >
                            {translateTab('list_title.main_location')}
                        </p>
                        <div
                            className={cx([
                                'global!ace-u-margin--left-32',
                                'global!ace-u-margin--right-32',
                                'global!ace-u-margin--top-16',
                                'global!ace-u-margin--bottom-4',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {contractPartner.name || ''}
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-typography--color-disabled',
                                'global!ace-u-padding--bottom-16',
                                'global!ace-u-padding--left-32',
                                'global!ace-u-padding--right-32',
                            ])}
                        >
                            <p>
                                {[
                                    contractPartner.address?.street,
                                    contractPartner.address?.postCode,
                                    contractPartner.address?.city,
                                ].filter(value => !!value).join(', ')}
                            </p>
                            <p>
                                {contractPartner.businessContactDetails?.phoneNo
                                    ? `${translateTab('contract_partner_item_text.tel')} ${contractPartner.businessContactDetails.phoneNo}` : ''}
                            </p>
                            {!!contractPartner.businessContactDetails?.websiteUrl && (
                                <a
                                    href={contractPartner.businessContactDetails?.websiteUrl}
                                    target="_blank"
                                    className={cx([
                                        'global!ace-u-typography--color-highlighted',
                                        'global!ace-u-typography--variant-body',
                                    ])}
                                    rel="noreferrer"
                                >
                                    {websiteUrl}
                                </a>
                            )}
                        </div>
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-9',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                ], {
                    'ace-c-content-item--span-12': !isSidebarExpanded,
                })}
            >
                <SidebarExpansionButton
                    isExpanded={isSidebarExpanded}
                    onClick={() => setIsSidebarExpanded(prevState => !prevState)}
                />
            </ContentItem>
        </ContentBlock>
    );
};

ContractPartnerOperationAreaTab.propTypes = {
    contractPartner: PropTypes.object,
    match: PropTypes.object,
    initiateCPOperationAreaMap: PropTypes.func.isRequired,
};

ContractPartnerOperationAreaTab.defaultProps = {
    contractPartner: null,
    match: {},
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();
    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCPOperationAreaMap: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerOperationAreaTab));
