import moment from 'moment/moment';

const checkTimePeriodOverlap = (validFrom, validUntil, timePeriods) => {
    const momentFrom = moment(validFrom, 'YYYY-MM-DD');
    const momentUntil = moment(validUntil, 'YYYY-MM-DD');

    if (momentUntil.isSameOrBefore(momentFrom)) {
        return true;
    }

    return timePeriods.some(timePeriod => {
        const periodFrom = moment(timePeriod.validFrom, 'YYYY-MM-DD');
        const periodUntil = moment(timePeriod.validUntil, 'YYYY-MM-DD');
        return (
            momentFrom.isBetween(periodFrom, periodUntil)
            || momentUntil.isBetween(periodFrom, periodUntil)
            || momentFrom.isSame(periodFrom)
            || momentFrom.isSame(periodUntil)
            || momentUntil.isSame(periodUntil)
            || momentUntil.isSame(periodFrom)
            || periodFrom.isBetween(momentFrom, momentUntil)
            || periodUntil.isBetween(momentFrom, momentUntil)
        );
    });
};

export default checkTimePeriodOverlap;
