import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';

/**
 * Search users
 */
const searchUsers = function* searchUsers({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');
    const {searchQueryParams} = payload;

    yield fork(
        fetchRequest,
        userProfileActionTypes.SEARCH_USERS_REQUEST,
        userProfileService.searchUsers,
        {
            searchQueryParams,
        },
    );

    const searchUsersResponseAction = yield take([
        userProfileActionTypes.SEARCH_USERS_REQUEST_SUCCEEDED,
        userProfileActionTypes.SEARCH_USERS_REQUEST_FAILED,
    ]);

    if (!searchUsersResponseAction.error) {
        const {response} = searchUsersResponseAction.payload;
        const {results: userSearchResults, totalCount: userSearchResultsCount} = response;

        yield put({
            type: userProfileActionTypes.STORE_USER_SEARCH_RESULTS,
            payload: {userSearchResults, userSearchResultsCount},
        });
    }
};

export default searchUsers;
