import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {capitalCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmDayOfWeekTypes, apmACEPartnerServiceTypes, apmContractPartnerEquipmentTypes, apmContractPartnerUndergroundParkingTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, InteractiveIcon, Divider, CheckboxGroup, Checkbox, Form, MultiSelect, MultiSelectOption, Input, timeIcon, InputTime, ToggleSwitch, ButtonSecondary, ButtonPrimary, NumberInput} from '@ace-de/ui-components';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@ace-de/ui-components/data-elements';
import {Icon, checkmarkIcon, closeIcon, editIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import createNewService from '../createNewService';

const rentalCarCooperations = {
    AVIS: 'AVIS',
    ENTERPRISE: 'ENTERPRISE',
    EUROPCAR: 'EUROPCAR',
    HERTZ: 'HERTZ',
    SIXT: 'SIXT',
};

const handleMidnightFormat = endTime => {
    if (endTime !== '00:00' && endTime !== '24:00') return endTime;
    return endTime === '00:00' ? '24:00' : '00:00';
};

const generateCustomTimeFieldOptions = () => {
    const options = [];
    for (let hours = 0; hours < 24; hours += 1) {
        const hoursString = hours < 10 ? `0${hours}` : hours;
        for (let minutes = 0; minutes < 60; minutes += 5) {
            const minutesString = minutes < 10 ? `0${minutes}` : minutes;
            options.push(`${hoursString}:${minutesString}`);
        }
    }
    options.push('24:00');
    return options;
};

const formatWorkingHours = workingHoursDTO => ({
    startTime: workingHoursDTO?.from?.slice(0, -3) || '',
    endTime: workingHoursDTO?.to?.slice(0, -3) || '',
});

const ContractPartnerOperatingFeaturesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_services_tab');
    const {contractPartner, submitCPOperatingFeaturesForm} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [isCamperSet, setIsCamperSet] = useState(
        !!contractPartner?.services.find(service => !!service.camperDimensions),
    );
    const [error, setError] = useState('');
    const formData = {
        equipment: contractPartner?.equipment || [],
        undergroundParking: contractPartner?.undergroundParking || [],
        directAccessToRentalCars: contractPartner?.rentalCar?.directAccessToRentalCars || false,
        ownRentalCarsAvailable: contractPartner?.rentalCar?.ownRentalCarsAvailable || false,
        oneWayUsagePossible: contractPartner?.rentalCar?.oneWayUsagePossible || false,
        cooperation: contractPartner?.rentalCar?.cooperation
            ? contractPartner.rentalCar.cooperation.filter(cooperator => !!cooperator) : [],
        otherCooperation: contractPartner?.rentalCar?.otherCooperation || '',
        oneWayUsageWithCooperation: contractPartner?.rentalCar?.oneWayUsageWithCooperation || false,
        camperDimensions: contractPartner?.recovery?.camperDimensions || null,
        accommodation: !!contractPartner?.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.ACCOMMODATION;
        }),
        personTransport: !!contractPartner?.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.SHORT_TRIP;
        }),
        waitingAreaWorkingHours: contractPartner?.waitingAreaWorkingHours,
        isCarDismantling: !!contractPartner?.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING;
        })?.isCarDismantling,
        usedCarStation: !!contractPartner?.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING;
        })?.usedCarStation,
    };
    const [timeErrorMessage, setTimeErrorMessage] = useState(false);
    const [cooperationField, setCooperationField] = useState(formData.cooperation);

    const mondayToFridayWorkingHours = contractPartner?.waitingAreaWorkingHours?.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY
    ));
    const saturdayWorkingHours = contractPartner?.waitingAreaWorkingHours?.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.SATURDAY
    ));
    const sundayHolidayWorkingHours = contractPartner?.waitingAreaWorkingHours?.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY
    ));

    const workingHours = {
        MONDAY_TO_FRIDAY: formatWorkingHours(mondayToFridayWorkingHours),
        SATURDAY: formatWorkingHours(saturdayWorkingHours),
        SUNDAY_AND_HOLIDAY: formatWorkingHours(sundayHolidayWorkingHours),
    };

    const [closedMondayToFriday, setClosedMondayToFriday] = useState(
        !workingHours.MONDAY_TO_FRIDAY.startTime && !workingHours.MONDAY_TO_FRIDAY.endTime,
    );
    const [closedOnSaturday, setClosedOnSaturday] = useState(
        !workingHours.SATURDAY.startTime && !workingHours.SATURDAY.endTime,
    );
    const [closedSundayAndHoliday, setClosedSundayAndHoliday] = useState(
        !workingHours.SUNDAY_AND_HOLIDAY.startTime && !workingHours.SUNDAY_AND_HOLIDAY.endTime,
    );

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
        setTimeErrorMessage('');
        setClosedMondayToFriday(
            !workingHours.MONDAY_TO_FRIDAY.startTime && !workingHours.MONDAY_TO_FRIDAY.endTime,
        );
        setClosedOnSaturday(
            !workingHours.SATURDAY.startTime && !workingHours.SATURDAY.endTime,
        );
        setClosedSundayAndHoliday(
            !workingHours.SUNDAY_AND_HOLIDAY.startTime && !workingHours.SUNDAY_AND_HOLIDAY.endTime,
        );
        setError('');
        setCooperationField(formData.cooperation);
    };

    const handleOnDiscard = event => {
        event.preventDefault();
        toggleEditMode();
    };

    const handleOnSetCamperChange = value => {
        setIsCamperSet(!!value);
    };

    const handleFormValidations = formValues => {
        let hasError = false;

        const availableDays = [
            apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
            apmDayOfWeekTypes.SATURDAY,
            apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
        ];

        availableDays.forEach(day => {
            if ((day === apmDayOfWeekTypes.MONDAY_TO_FRIDAY && closedMondayToFriday)
                || (day === apmDayOfWeekTypes.SATURDAY && closedOnSaturday)
                || (day === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY && closedSundayAndHoliday)) return;

            if (Object.entries(formValues[day.toLowerCase()]).length === 0
                && workingHours[day].startTime && workingHours[day].endTime) return;

            if (!moment(formValues[day.toLowerCase()]?.startTime, 'HH:mm', true).isValid()
                    || !moment(formValues[day.toLowerCase()]?.endTime, 'HH:mm', true).isValid()) {
                setTimeErrorMessage(translateTab('error_message_text.time_error'));
                hasError = true;
                return hasError;
            }
        });
        if (!hasError && timeErrorMessage) {
            setTimeErrorMessage('');
            hasError = false;
        }
        return hasError;
    };

    const handleOnSubmit = formValues => {
        const hasError = handleFormValidations(formValues);
        if (hasError) return;

        if (formValues.setCamper && !formValues.weight) {
            setError(translateTab('error_message.trailer_dimensions'));
            return;
        }

        const availableDays = [
            apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
            apmDayOfWeekTypes.SATURDAY,
            apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
        ];

        const newWorkingHours = availableDays.map(day => {
            if ((day === apmDayOfWeekTypes.MONDAY_TO_FRIDAY && !closedMondayToFriday)
                || (day === apmDayOfWeekTypes.SATURDAY && !closedOnSaturday)
                || (day === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY && !closedSundayAndHoliday)
            ) {
                return {
                    dayOfWeek: day,
                    from: formValues[day.toLowerCase()]?.startTime !== ''
                        ? formValues[day.toLowerCase()]?.startTime || workingHours[day].startTime
                        : '',
                    to: formValues[day.toLowerCase()]?.endTime !== ''
                        ? handleMidnightFormat(formValues[day.toLowerCase()]?.endTime)
                        || handleMidnightFormat(workingHours[day].endTime)
                        : '',
                };
            }
            return {
                dayOfWeek: day,
                from: '',
                to: '',
            };
        });

        const doesServiceExist = {
            [apmACEPartnerServiceTypes.RENTAL_CAR]: false,
            [apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE]: false,
            [apmACEPartnerServiceTypes.RECOVERY]: false,
            [apmACEPartnerServiceTypes.TOWING]: false,
            [apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING]: false,
        };
        // update only existing services, and keep all the other services
        let updatedServices = contractPartner?.services?.length
            ? contractPartner.services.map(service => {
                if ([
                    apmACEPartnerServiceTypes.RENTAL_CAR,
                    apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                    apmACEPartnerServiceTypes.RECOVERY,
                    apmACEPartnerServiceTypes.TOWING,
                    apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING,
                ].includes(service.serviceType)) {
                    doesServiceExist[service.serviceType] = true;
                    return {
                        ...service,
                        ...(service.serviceType === apmACEPartnerServiceTypes.RENTAL_CAR && {
                            directAccessToRentalCars: !!formValues.directAccessToRentalCars,
                            ownRentalCarsAvailable: !!formValues.ownRentalCarsAvailable,
                            oneWayUsagePossible: !!formValues.oneWayUsagePossible,
                            cooperation: Array.isArray(formValues.cooperation) ? formValues.cooperation : [],
                            otherCooperation: formValues.otherCooperation || '',
                            oneWayUsageWithCooperation: !!formValues.oneWayUsageWithCooperation,
                        }),
                        ...([
                            apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                            apmACEPartnerServiceTypes.RECOVERY,
                            apmACEPartnerServiceTypes.TOWING,
                        ].includes(service.serviceType) && {
                            camperDimensions: formValues.setCamper
                                ? {
                                    weight: Number(formValues.weight),
                                    length: Number(formValues.length),
                                    width: Number(formValues.width),
                                    height: Number(formValues.height),
                                } : null,
                        }),
                        ...(service.serviceType === apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING && {
                            isCarDismantling: !!formValues.isCarDismantling,
                            usedCarStation: !!formValues.usedCarStation,
                        }),
                    };
                }
                return service;
            }) : [];
        // if service is not already present in services array, add it
        for (const [serviceType, value] of Object.entries(doesServiceExist)) {
            if (!value) {
                // if the camper is not set, do not create the service
                if ([
                    apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                    apmACEPartnerServiceTypes.RECOVERY,
                    apmACEPartnerServiceTypes.TOWING,
                ].includes(serviceType) && !formValues.setCamper) continue;

                updatedServices.push({
                    ...(createNewService(serviceType)),
                    ...(serviceType === apmACEPartnerServiceTypes.RENTAL_CAR && {
                        directAccessToRentalCars: !!formValues.directAccessToRentalCars,
                        ownRentalCarsAvailable: !!formValues.ownRentalCarsAvailable,
                        oneWayUsagePossible: !!formValues.oneWayUsagePossible,
                        cooperation: Array.isArray(formValues.cooperation) ? formValues.cooperation : [],
                        otherCooperation: formValues.otherCooperation || '',
                        oneWayUsageWithCooperation: !!formValues.oneWayUsageWithCooperation,
                    }),
                    ...([
                        apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                        apmACEPartnerServiceTypes.RECOVERY,
                        apmACEPartnerServiceTypes.TOWING,
                    ].includes(serviceType) && {
                        camperDimensions: {
                            weight: Number(formValues.weight),
                            length: Number(formValues.length),
                            width: Number(formValues.width),
                            height: Number(formValues.height),
                        },
                    }),
                    ...(serviceType === apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING && {
                        isCarDismantling: !!formValues.isCarDismantling,
                        usedCarStation: !!formValues.usedCarStation,
                    }),
                });
            }
        }

        // depending on personTransport and accommodation values,
        // add/remove SHORT_TRIP and ACCOMMODATION services
        const shortTripService = updatedServices
            .find(service => service.serviceType === apmACEPartnerServiceTypes.SHORT_TRIP);
        const accommodationService = updatedServices
            .find(service => service.serviceType === apmACEPartnerServiceTypes.ACCOMMODATION);
        if (formValues.personTransport && !shortTripService) {
            updatedServices.push({
                ...(createNewService(apmACEPartnerServiceTypes.SHORT_TRIP)),
            });
        }
        if (formValues.accommodation && !accommodationService) {
            updatedServices.push({
                ...(createNewService(apmACEPartnerServiceTypes.ACCOMMODATION)),
            });
        }
        if (!formValues.personTransport && shortTripService) {
            updatedServices = updatedServices
                .filter(service => service.serviceType !== apmACEPartnerServiceTypes.SHORT_TRIP);
        }
        if (!formValues.accommodation && accommodationService) {
            updatedServices = updatedServices
                .filter(service => service.serviceType !== apmACEPartnerServiceTypes.ACCOMMODATION);
        }

        const operatingFeatures = {
            equipment: formValues.equipment,
            undergroundParking: formValues.undergroundParking,
            services: updatedServices,
            waitingAreaWorkingHours: newWorkingHours,
        };

        submitCPOperatingFeaturesForm({
            contractPartnerId: contractPartner?.id,
            contractPartnerData: {
                ...operatingFeatures,
            },
        });
        toggleEditMode();
    };

    const {
        equipment,
        undergroundParking,
        directAccessToRentalCars,
        ownRentalCarsAvailable,
        oneWayUsagePossible,
        cooperation,
        otherCooperation,
        oneWayUsageWithCooperation,
        camperDimensions,
        accommodation,
        personTransport,
        isCarDismantling,
        usedCarStation,
    } = formData;

    return (
        <Panel
            title={translateTab('panel_title.operating_features')}
            actions={(
                <InteractiveIcon
                    icon={!isEditModeActive ? editIcon : closeIcon}
                    onClick={toggleEditMode}
                />
            )}
        >
            {!isEditModeActive ? (
                <Fragment>
                    <div className={cx(['global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-48'])}>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={10}>{translateTab('table_header.equipment')}</TableCell>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.values(apmContractPartnerEquipmentTypes).map(cpEquipment => {
                                        const hasCPEquipment = equipment.length > 0
                                            ? equipment.includes(cpEquipment) : false;
                                        return (
                                            <TableRow key={cpEquipment}>
                                                <TableCell colSpan={10}>
                                                    {translateTab(`table_row_label.${cpEquipment.toLowerCase()}`)}
                                                </TableCell>
                                                <TableCell colSpan={2}>
                                                    <Icon
                                                        icon={hasCPEquipment ? checkmarkIcon : closeIcon}
                                                        className={hasCPEquipment
                                                            ? cx('ace-c-icon--color-success')
                                                            : cx('ace-c-icon--color-disabled')}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <Divider />
                        </div>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            {translateTab('table_header.usage_in_underground_parking')}
                                        </TableCell>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.values(apmContractPartnerUndergroundParkingTypes).map(
                                        undergroundParkingType => {
                                            const hasCPundergroundParking = undergroundParking.length > 0
                                                ? undergroundParking.includes(undergroundParkingType) : false;
                                            return (
                                                <TableRow key={undergroundParkingType}>
                                                    <TableCell colSpan={10}>
                                                        {translateTab(`table_row_label.${undergroundParkingType.toLowerCase()}`)}
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <Icon
                                                            icon={hasCPundergroundParking ? checkmarkIcon : closeIcon}
                                                            className={hasCPundergroundParking
                                                                ? cx('ace-c-icon--color-success')
                                                                : cx('ace-c-icon--color-disabled')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        },
                                    )}
                                </TableBody>
                            </Table>
                            <Divider />
                        </div>
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-48')}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6}>{translateTab('table_header.rental_cars')}</TableCell>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.direct_access_to_rental_cars')}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            icon={directAccessToRentalCars ? checkmarkIcon : closeIcon}
                                            className={directAccessToRentalCars
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={5} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.own_rental_cars_available')}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            icon={ownRentalCarsAvailable ? checkmarkIcon : closeIcon}
                                            className={ownRentalCarsAvailable
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.oneway_usage_possible')}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            icon={oneWayUsagePossible ? checkmarkIcon : closeIcon}
                                            className={oneWayUsagePossible
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.rental_car_cooperations')}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            icon={Array.isArray(cooperation) && cooperation.length
                                                ? checkmarkIcon : closeIcon}
                                            className={Array.isArray(cooperation) && cooperation.length > 0
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={5}>
                                        {Array.isArray(cooperation) ? cooperation.join(', ') : ''}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.other_rental_car_cooperation')}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            icon={otherCooperation ? checkmarkIcon : closeIcon}
                                            className={otherCooperation
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={5}>{otherCooperation}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.oneway_usage_with_other_rental_car_cooperation_possible')}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            icon={oneWayUsageWithCooperation ? checkmarkIcon : closeIcon}
                                            className={oneWayUsageWithCooperation
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-48')}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6}>{translateTab('table_header.person_transport')}</TableCell>
                                    <TableCell colSpan={2} />
                                    <TableCell colSpan={6} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.person_transport_possible')}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Icon
                                            icon={personTransport ? checkmarkIcon : closeIcon}
                                            className={personTransport
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={6} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.accommodation_possible')}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Icon
                                            icon={accommodation ? checkmarkIcon : closeIcon}
                                            className={accommodation
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={6} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.customer_waiting_area_available')}
                                    </TableCell>
                                    <Fragment>
                                        <TableCell colSpan={3}>
                                            <div className={cx('global!ace-u-flex')}>
                                                {translateTab('table_row_label.monday_friday')}
                                                {mondayToFridayWorkingHours && (
                                                    <div className={cx('global!ace-u-margin--left-16')}>
                                                        <span>
                                                            {workingHours.MONDAY_TO_FRIDAY.startTime}
                                                        </span>
                                                        <span>
                                                            {workingHours.MONDAY_TO_FRIDAY.startTime
                                                            && workingHours.MONDAY_TO_FRIDAY.endTime
                                                                ? '–' : translateTab('table_row_label.closed')}
                                                        </span>
                                                        <span>
                                                            {workingHours.MONDAY_TO_FRIDAY.endTime}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell colSpan={3}>
                                            <div className={cx('global!ace-u-flex')}>
                                                {translateTab('table_row_label.saturday')}
                                                {saturdayWorkingHours && (
                                                    <div className={cx('global!ace-u-margin--left-16')}>
                                                        <span>
                                                            {workingHours.SATURDAY.startTime}
                                                        </span>
                                                        <span>
                                                            {workingHours.SATURDAY.startTime
                                                            && workingHours.SATURDAY.endTime
                                                                ? '–' : translateTab('table_row_label.closed')}
                                                        </span>
                                                        <span>
                                                            {workingHours.SATURDAY.endTime}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <div className={cx('global!ace-u-flex')}>
                                                {translateTab('table_row_label.sunday_holiday').slice(0, 2)}
                                                {sundayHolidayWorkingHours && (
                                                    <div className={cx('global!ace-u-margin--left-16')}>
                                                        <span>
                                                            {workingHours.SUNDAY_AND_HOLIDAY.startTime}
                                                        </span>
                                                        <span>
                                                            {workingHours.SUNDAY_AND_HOLIDAY.startTime
                                                            && workingHours.SUNDAY_AND_HOLIDAY.endTime
                                                                ? '–' : translateTab('table_row_label.closed')}
                                                        </span>
                                                        <span>
                                                            {workingHours.SUNDAY_AND_HOLIDAY.endTime}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>
                                    </Fragment>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-48')}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6}>{translateTab('table_header.trailer_operation')}</TableCell>
                                    <TableCell colSpan={2} />
                                    <TableCell colSpan={6} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.roadside_assistance_pickup_and_recovery')}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            icon={isCamperSet ? checkmarkIcon : closeIcon}
                                            className={isCamperSet
                                                ? cx('ace-c-icon--color-success')
                                                : cx('ace-c-icon--color-disabled')}
                                        />
                                    </TableCell>
                                    {!isEditModeActive && !camperDimensions && (
                                        <TableCell colSpan={7} />
                                    )}
                                    {camperDimensions && (
                                        <TableCell colSpan={7}>
                                            <span className={cx('global!ace-u-margin--right-16')}>
                                                {`${translateTab('table_row_label.until')} ${camperDimensions?.weight} ${translate('global.metric_units.ton')}`}
                                            </span>
                                            <span className={cx('global!ace-u-margin--right-16')}>
                                                {`${camperDimensions?.height} ${translate('global.metric_units.meter')} ${translateTab('table_row_label.height')}`}
                                            </span>
                                            <span className={cx('global!ace-u-margin--right-16')}>
                                                {`${camperDimensions?.width} ${translate('global.metric_units.meter')} ${translateTab('table_row_label.broad')}`}
                                            </span>
                                            <span className={cx('global!ace-u-margin--right-16')}>
                                                {`${camperDimensions?.length} ${translate('global.metric_units.meter')} ${translateTab('table_row_label.length')}`}
                                            </span>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </div>
                    <div className={cx(['global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-48'])}>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                        >{translateTab('table_header.car_dismantling')}
                                        </TableCell>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            {translateTab('table_row_label.car_dismantling')}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Icon
                                                icon={isCarDismantling ? checkmarkIcon : closeIcon}
                                                className={isCarDismantling
                                                    ? cx('ace-c-icon--color-success')
                                                    : cx('ace-c-icon--color-disabled')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            {translateTab('table_row_label.used_car_station')}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Icon
                                                icon={usedCarStation ? checkmarkIcon : closeIcon}
                                                className={usedCarStation
                                                    ? cx('ace-c-icon--color-success')
                                                    : cx('ace-c-icon--color-disabled')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Divider />
                        </div>
                    </div>
                </Fragment>
            ) : (
                <Form
                    name="contractPartnerTransportDataForm"
                    onSubmit={handleOnSubmit}
                >
                    <div className={cx(['global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-48'])}>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={10}>{translateTab('table_header.equipment')}</TableCell>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <CheckboxGroup name="equipment" value={equipment}>
                                        {Object.values(apmContractPartnerEquipmentTypes).map(cpEquipment => {
                                            return (
                                                <TableRow className={cx('ace-c-table__row--body')} key={cpEquipment}>
                                                    <TableCell colSpan={10}>
                                                        {translateTab(`table_row_label.${cpEquipment.toLowerCase()}`)}
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <Checkbox
                                                            key={cpEquipment}
                                                            name={cpEquipment}
                                                            value={cpEquipment}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </CheckboxGroup>
                                </TableBody>
                            </Table>
                            <Divider />
                        </div>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            {translateTab('table_header.usage_in_underground_parking')}
                                        </TableCell>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <CheckboxGroup value={undergroundParking} name="undergroundParking">
                                        {Object.values(apmContractPartnerUndergroundParkingTypes).map(
                                            undergroundParkingType => {
                                                return (
                                                    <TableRow
                                                        className={cx('ace-c-table__row--body')}
                                                        key={undergroundParkingType}
                                                    >
                                                        <TableCell colSpan={10}>
                                                            {translateTab(`table_row_label.${undergroundParkingType.toLowerCase()}`)}
                                                        </TableCell>
                                                        <TableCell colSpan={2}>
                                                            <Checkbox
                                                                key={undergroundParkingType}
                                                                name={undergroundParkingType}
                                                                value={undergroundParkingType}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            },
                                        )}
                                    </CheckboxGroup>
                                </TableBody>
                            </Table>
                            <Divider />
                        </div>
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-48')}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6}>{translateTab('table_header.rental_cars')}</TableCell>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.direct_access_to_rental_cars')}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name="directAccessToRentalCars"
                                            isSelected={directAccessToRentalCars}
                                            value={true}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={5} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.own_rental_cars_available')}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name="ownRentalCarsAvailable"
                                            isSelected={!!ownRentalCarsAvailable || false}
                                            value={true}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.oneway_usage_possible')}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name="oneWayUsagePossible"
                                            isSelected={!!oneWayUsagePossible || false}
                                            value={true}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.rental_car_cooperations')}
                                    </TableCell>
                                    <TableCell />
                                    <TableCell colSpan={5}>
                                        <MultiSelect
                                            name="cooperation"
                                            value={cooperationField}
                                            className={cx(['global!ace-u-full-width', 'ace-c-select--small'])}
                                            onChange={value => setCooperationField(value)}
                                        >
                                            {Object.values(rentalCarCooperations).map(cooperationName => (
                                                <MultiSelectOption
                                                    key={cooperationName}
                                                    value={cooperationName}
                                                    name={`option-${cooperationName.toLowerCase()}`}
                                                    isSelected={true}
                                                >
                                                    {capitalCase(cooperationName)}
                                                </MultiSelectOption>
                                            ))}
                                        </MultiSelect>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.other_rental_car_cooperation')}
                                    </TableCell>
                                    <TableCell />
                                    <TableCell colSpan={5}>
                                        <Input
                                            className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                            name="otherCooperation"
                                            value={otherCooperation}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.oneway_usage_with_other_rental_car_cooperation_possible')}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name="oneWayUsageWithCooperation"
                                            isSelected={!!oneWayUsageWithCooperation}
                                            value={true}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-48')}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6}>{translateTab('table_header.person_transport')}</TableCell>
                                    <TableCell colSpan={2} />
                                    <TableCell colSpan={6} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.person_transport_possible')}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Checkbox
                                            name="personTransport"
                                            isSelected={!!personTransport}
                                            value={true}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={6} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.accommodation_possible')}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Checkbox
                                            name="accommodation"
                                            isSelected={!!accommodation}
                                            value={true}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={6} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.customer_waiting_area_available')}
                                    </TableCell>
                                    <TableCell colSpan={8}>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <span
                                                className={cx([
                                                    'global!ace-u-flex--basis-40',
                                                    'global!ace-u-typography--variant-body-medium',
                                                ])}
                                            >
                                                {translateTab('table_row_label.monday_friday')}:
                                            </span>
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <Form name="monday_to_friday">
                                                    <InputTime
                                                        name="startTime"
                                                        value={workingHours.MONDAY_TO_FRIDAY.startTime}
                                                        icon={timeIcon}
                                                        className={cx(['ace-c-input-time--small'])}
                                                        inputClassName={cx([
                                                            'global!ace-u-width--full',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                        customTimeOptions={generateCustomTimeFieldOptions()}
                                                        isDisabled={closedMondayToFriday}
                                                    />
                                                    <span
                                                        className={cx([
                                                            'global!ace-u-flex--basis-30',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                    >
                                                        –
                                                    </span>
                                                    <InputTime
                                                        name="endTime"
                                                        value={workingHours.MONDAY_TO_FRIDAY.endTime}
                                                        icon={timeIcon}
                                                        className={cx(['ace-c-input-time--small', 'global!ace-u-flex--grow-1'])}
                                                        inputClassName={cx([
                                                            'global!ace-u-width--full',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                        customTimeOptions={generateCustomTimeFieldOptions()}
                                                        isDisabled={closedMondayToFriday}
                                                    />
                                                </Form>
                                            </div>
                                            <ToggleSwitch
                                                name="openMondayToFriday"
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--justify-space-between',
                                                    'global!ace-u-margin--left-32',
                                                    'global!ace-u-margin--right-16',
                                                ])}
                                                value={true}
                                                isSelected={closedMondayToFriday}
                                                onChange={value => setClosedMondayToFriday(value)}
                                            >
                                                <span>{translateTab('table_row_label.closed')}</span>
                                            </ToggleSwitch>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6} />
                                    <TableCell colSpan={8}>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <span
                                                className={cx([
                                                    'global!ace-u-flex--basis-40',
                                                    'global!ace-u-typography--variant-body-medium',
                                                ])}
                                            >
                                                {translateTab('table_row_label.saturday')}:
                                            </span>
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <Form name="saturday">
                                                    <InputTime
                                                        name="startTime"
                                                        value={workingHours.SATURDAY.startTime}
                                                        icon={timeIcon}
                                                        className={cx(['ace-c-input-time--small'])}
                                                        inputClassName={cx([
                                                            'global!ace-u-width--full',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                        customTimeOptions={generateCustomTimeFieldOptions()}
                                                        isDisabled={closedOnSaturday}
                                                    />
                                                    <span
                                                        className={cx([
                                                            'global!ace-u-flex--basis-30',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                    >
                                                        –
                                                    </span>
                                                    <InputTime
                                                        name="endTime"
                                                        value={workingHours.SATURDAY.endTime}
                                                        icon={timeIcon}
                                                        className={cx(['ace-c-input-time--small', 'global!ace-u-flex--grow-1'])}
                                                        inputClassName={cx([
                                                            'global!ace-u-width--full',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                        customTimeOptions={generateCustomTimeFieldOptions()}
                                                        isDisabled={closedOnSaturday}
                                                    />
                                                </Form>
                                            </div>
                                            <ToggleSwitch
                                                name="openOnSaturday"
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--justify-space-between',
                                                    'global!ace-u-margin--left-32',
                                                    'global!ace-u-margin--right-16',
                                                ])}
                                                value={closedOnSaturday}
                                                isSelected={closedOnSaturday}
                                                onChange={value => setClosedOnSaturday(value)}
                                            >
                                                <span>{translateTab('table_row_label.closed')}</span>
                                            </ToggleSwitch>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6} />
                                    <TableCell colSpan={8}>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <span
                                                className={cx([
                                                    'global!ace-u-flex--basis-40',
                                                    'global!ace-u-typography--variant-body-medium',
                                                ])}
                                            >
                                                {translateTab('table_row_label.sunday_holiday').slice(0, 2)}:
                                            </span>
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <Form name="sunday_and_holiday">
                                                    <InputTime
                                                        name="startTime"
                                                        value={workingHours.SUNDAY_AND_HOLIDAY.startTime}
                                                        icon={timeIcon}
                                                        className={cx(['ace-c-input-time--small'])}
                                                        inputClassName={cx([
                                                            'global!ace-u-width--full',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                        customTimeOptions={generateCustomTimeFieldOptions()}
                                                        isDisabled={closedSundayAndHoliday}
                                                    />
                                                    <span
                                                        className={cx([
                                                            'global!ace-u-flex--basis-30',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                    >
                                                        –
                                                    </span>
                                                    <InputTime
                                                        name="endTime"
                                                        value={workingHours.SUNDAY_AND_HOLIDAY.endTime}
                                                        icon={timeIcon}
                                                        className={cx(['ace-c-input-time--small', 'global!ace-u-flex--grow-1'])}
                                                        inputClassName={cx([
                                                            'global!ace-u-width--full',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                        customTimeOptions={generateCustomTimeFieldOptions()}
                                                        isDisabled={closedSundayAndHoliday}
                                                    />
                                                </Form>
                                            </div>
                                            <ToggleSwitch
                                                name="openSundayAndHoliday"
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--justify-space-between',
                                                    'global!ace-u-margin--left-32',
                                                    'global!ace-u-margin--right-16',
                                                ])}
                                                value={closedSundayAndHoliday}
                                                isSelected={closedSundayAndHoliday}
                                                onChange={value => setClosedSundayAndHoliday(value)}
                                            >
                                                <span>{translateTab('table_row_label.closed')}</span>
                                            </ToggleSwitch>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-48')}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6}>{translateTab('table_header.trailer_operation')}</TableCell>
                                    <TableCell colSpan={2} />
                                    <TableCell colSpan={6} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        {translateTab('table_row_label.roadside_assistance_pickup_and_recovery')}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name="setCamper"
                                            isSelected={!!isCamperSet}
                                            value={true}
                                            onChange={handleOnSetCamperChange}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={7}>
                                        <div className={cx(['global!ace-u-flex'])}>
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <span
                                                    className={cx([
                                                        'global!ace-u-flex--basis-40',
                                                        'global!ace-u-typography--variant-body-medium',
                                                    ])}
                                                >
                                                    {translateTab('table_row_label.until')}
                                                </span>
                                                <NumberInput
                                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                                    name="weight"
                                                    min={0}
                                                    value={camperDimensions?.weight || ''}
                                                    isDisabled={!isCamperSet}
                                                />
                                                <span
                                                    className={cx([
                                                        'global!ace-u-flex--basis-40',
                                                        'global!ace-u-padding--16',
                                                    ])}
                                                >
                                                    {translate('global.metric_units.ton')}
                                                </span>
                                            </div>
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <NumberInput
                                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                                    name="height"
                                                    min={0}
                                                    value={camperDimensions?.height || ''}
                                                    isDisabled={!isCamperSet}
                                                />
                                                <span
                                                    className={cx([
                                                        'global!ace-u-flex--basis-40',
                                                        'global!ace-u-padding--16',
                                                    ])}
                                                >
                                                    {translateTab('table_row_label.height').slice(0, 1)}
                                                </span>
                                            </div>
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <NumberInput
                                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                                    name="width"
                                                    min={0}
                                                    value={camperDimensions?.width || ''}
                                                    isDisabled={!isCamperSet}
                                                />
                                                <span
                                                    className={cx([
                                                        'global!ace-u-flex--basis-40',
                                                        'global!ace-u-padding--16',
                                                    ])}
                                                >
                                                    {translateTab('table_row_label.broad').slice(0, 1)}
                                                </span>
                                            </div>
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <NumberInput
                                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                                    name="length"
                                                    min={0}
                                                    value={camperDimensions?.length || ''}
                                                    isDisabled={!isCamperSet}
                                                />
                                                <span
                                                    className={cx([
                                                        'global!ace-u-flex--basis-40',
                                                        'global!ace-u-padding--16',
                                                    ])}
                                                >
                                                    {translateTab('table_row_label.length').slice(0, 1)}
                                                </span>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </div>
                    <div className={cx(['global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-48'])}>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            {translateTab('table_header.car_dismantling')}
                                        </TableCell>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className={cx('ace-c-table__row--body')}>
                                        <TableCell colSpan={10}>
                                            {translateTab(`table_row_label.car_dismantling`)}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Checkbox
                                                name="isCarDismantling"
                                                value={true}
                                                isSelected={isCarDismantling}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={cx('ace-c-table__row--body')}>
                                        <TableCell colSpan={10}>
                                            {translateTab(`table_row_label.used_car_station`)}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Checkbox
                                                name="usedCarStation"
                                                value={true}
                                                isSelected={usedCarStation}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Divider />
                        </div>
                    </div>
                    {!!error && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-row-reverse',
                                'global!ace-u-margin--top-32',
                                'global!ace-u-margin--bottom-8',
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-typography--color-warning',
                            ])}
                        >
                            {error}
                        </div>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-full-width',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-margin--top-32',
                        ])}
                    >
                        {timeErrorMessage && (
                            <p
                                className={cx([
                                    'global!ace-u-margin--right-32',
                                    'global!ace-u-typography--color-warning',
                                ])}
                            >
                                {timeErrorMessage}
                            </p>
                        )}
                        <ButtonSecondary
                            onClick={handleOnDiscard}
                        >
                            {translateTab('button_label.discard')}
                        </ButtonSecondary>
                        <ButtonPrimary
                            className={cx('global!ace-u-margin--left-24')}
                            type="submit"
                        >
                            {translateTab('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </Form>
            )}
        </Panel>
    );
};

ContractPartnerOperatingFeaturesPanel.propTypes = {
    contractPartner: PropTypes.object,
    submitCPOperatingFeaturesForm: PropTypes.func.isRequired,
};

ContractPartnerOperatingFeaturesPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitCPOperatingFeaturesForm: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_SERVICES_UPDATE,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerOperatingFeaturesPanel));
