import React from 'react';
import {Divider, expandIcon, Icon, Panel, useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import ACEAdminLogo from '../dashboard/ui-elements/ACEAdminLogo';
import {acFeatures, acFeatureActions} from '../application/acFeatures';
import acAccessControl from '../acAccessControl';
import config from '../config';
import styles from './TextManagementScreen.module.scss';

const TextManagementScreen = () => {
    const {cx} = useStyles({}, styles);
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('text_management_screen');

    return (
        <Panel
            title={translate('navigation_bar.tab_name.text_management')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Divider />
            <div
                className={cx([
                    'global!ace-u-padding--128-64',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-space-between',
                ])}
            >
                {acAccessControl.grantFeatureAccess(acFeatures.VPM_TRANSLATIONS, acFeatureActions.READ) && (
                    <div
                        className={cx([
                            'global!ace-u-height--256',
                            'global!ace-u-flex--basis-25',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--direction-column',
                        ])}
                    >
                        <p>
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--right-4',
                                ])}
                            >
                                {translateScreen('link_out_title.ace')}
                            </span>
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-subtitle',
                                    'global!ace-u-typography--color-disabled',
                                ])}
                            >
                                {translateScreen('link_out_title.contract_partner_management')}
                            </span>
                        </p>
                        <a
                            href={config.ACE_VPM_SYSTEM_LABEL_TRANSLATION}
                            className={cx([
                                'global!ace-u-margin--32-0-16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-center',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translateScreen('link_out_text.contract_partner_management')}
                            <Icon
                                icon={expandIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--left-8',
                                ])}
                            />
                        </a>
                        <Divider />
                    </div>
                )}
                {acAccessControl.grantFeatureAccess(acFeatures.AC_TRANSLATIONS, acFeatureActions.READ) && (
                    <div
                        className={cx([
                            'global!ace-u-height--256',
                            'global!ace-u-flex--basis-25',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--direction-column',
                        ])}
                    >
                        <ACEAdminLogo />
                        <a
                            href={config.ACE_AC_SYSTEM_LABEL_TRANSLATION}
                            className={cx([
                                'global!ace-u-margin--32-0-16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-center',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translateScreen('link_out_text.admin_cockpit')}
                            <Icon
                                icon={expandIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--left-8',
                                ])}
                            />
                        </a>
                        <Divider />
                    </div>
                )}
                {acAccessControl.grantFeatureAccess(acFeatures.LEA_TRANSLATIONS, acFeatureActions.READ) && (
                    <div
                        className={cx([
                            'global!ace-u-height--256',
                            'global!ace-u-flex--basis-25',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--direction-column',
                        ])}
                    >
                        <p>
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--right-4',
                                    'ace-c-lea-logo',
                                ])}
                            >
                                {translateScreen('link_out_title.ace')}
                            </span>
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-subtitle',
                                    'ace-c-lea-logo',
                                ])}
                            >
                                {translateScreen('link_out_title.leist_tool')}
                            </span>
                        </p>
                        <a
                            href={config.EUA_LEA_SYSTEM_LABEL_TRANSLATION}
                            className={cx([
                                'global!ace-u-margin--32-0-16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-center',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translateScreen('link_out_text.leist_tool')}
                            <Icon
                                icon={expandIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--left-8',
                                ])}
                            />
                        </a>
                        <Divider />
                    </div>
                )}
                {acAccessControl.grantFeatureAccess(acFeatures.ECS_TRANSLATIONS, acFeatureActions.READ) && (
                    <div
                        className={cx([
                            'global!ace-u-height--256',
                            'global!ace-u-flex--basis-25',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--direction-column',
                        ])}
                    >
                        <p>
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                ])}
                            >
                                {translateScreen('link_out_title.eua').slice(0, 2)}
                            </span>
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-typography--color-highlighted',
                                    'global!ace-u-margin--right-4',
                                ])}
                            >
                                {translateScreen('link_out_title.eua').slice(2)}
                            </span>
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-subtitle',
                                    'global!ace-u-typography--color-disabled',
                                ])}
                            >
                                {translateScreen('link_out_title.emergency_call_system')}
                            </span>
                        </p>
                        <a
                            href={config.EUA_ECS_SYSTEM_LABEL_TRANSLATION}
                            className={cx([
                                'global!ace-u-margin--32-0-16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-center',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translateScreen('link_out_text.emergency_call_system')}
                            <Icon
                                icon={expandIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--left-8',
                                ])}
                            />
                        </a>
                        <Divider />
                    </div>
                )}
            </div>
        </Panel>
    );
};

export default TextManagementScreen;
