import {select, fork, take, put} from 'redux-saga/effects';
import {apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as commissionerActionTypes from '../commissionerActionTypes';

/**
 * Search commissioners
 */
const searchCommissioners = function* searchCommissioners({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {searchQueryParams} = payload;

    yield fork(
        fetchRequest,
        commissionerActionTypes.SEARCH_COMMISSIONERS_REQUEST,
        partnerManagementService.getACEPartnersV2, {
            searchQueryParams,
            partnerType: apmACEPartnerTypes.COMMISSIONER,
        },
    );

    const searchCommissionersResponseAction = yield take([
        commissionerActionTypes.SEARCH_COMMISSIONERS_REQUEST_FAILED,
        commissionerActionTypes.SEARCH_COMMISSIONERS_REQUEST_SUCCEEDED,
    ]);

    if (!searchCommissionersResponseAction.error) {
        const {response} = searchCommissionersResponseAction.payload;
        const {
            acePartnerDTOs: commissionerSearchResults,
            totalCount: commissionerSearchResultsCount,
        } = response;

        yield put({
            type: commissionerActionTypes.STORE_COMMISSIONER_SEARCH_RESULTS,
            payload: {commissionerSearchResults, commissionerSearchResultsCount},
        });
    }
};

export default searchCommissioners;
