import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {checkmarkIcon, closeIcon, editIcon, Icon, InteractiveIcon, Panel, useStyles} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import * as feesAndEstimationActionTypes from './feesAndEstimationActionTypes';
import getPrice from '../utils/getPrice';

const FeesAndEstimationScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('fees_and_estimation_screen');
    const {assistanceFees, estimations, initiateAssistanceFeesEditFlow, initiateEstimationsEditFlow} = props;

    return (
        <Fragment>
            <div className={cx('global!ace-u-grid')}>
                <Panel
                    title={translateTab('panel_title.assistance_fees')}
                    actions={(<InteractiveIcon icon={editIcon} onClick={initiateAssistanceFeesEditFlow} />)}
                    className={cx([
                        'global!ace-u-margin--0',
                        'global!ace-u-grid-column--span-5',
                        'ace-c-panel--full-bleed-content',
                    ])}
                >
                    <Table qaIdent="assistance-fees">
                        <TableHead>
                            <TableRow>
                                <TableCell qaIdentPart="assistance-fee-id" colSpan={3}>
                                    {translateTab('table_cell.id')}
                                </TableCell>
                                <TableCell qaIdentPart="assistance-fee-name" colSpan={6}>
                                    {translateTab('table_cell.name')}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="assistance-fee-amount"
                                    colSpan={3}
                                    className={cx('ace-c-table__cell--text-align-right')}
                                >
                                    {translateTab('table_cell.price')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assistanceFees?.length > 0 && assistanceFees.map(assistanceFee => (
                                <TableRow
                                    key={assistanceFee.id}
                                    qaIdentPart={assistanceFee.id}
                                >
                                    <TableCell
                                        qaIdentPartPostfix={assistanceFee.id}
                                        qaIdentPart="assistance-fee-id"
                                        colSpan={3}
                                    >
                                        {assistanceFee.id}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPartPostfix={assistanceFee.id}
                                        qaIdentPart="assistance-fee-name"
                                        colSpan={6}
                                    >
                                        {assistanceFee.name}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPartPostfix={assistanceFee.id}
                                        qaIdentPart="assistance-fee-amount"
                                        colSpan={3}
                                        className={cx('ace-c-table__cell--text-align-right')}
                                    >
                                        {getPrice(assistanceFee.amount, activeLocale)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Panel>
                <Panel
                    title={translateTab('panel_title.estimations')}
                    actions={(<InteractiveIcon icon={editIcon} onClick={initiateEstimationsEditFlow} />)}
                    className={cx([
                        'global!ace-u-margin--0',
                        'global!ace-u-grid-column--span-5',
                        'ace-c-panel--full-bleed-content',
                    ])}
                >
                    <Table qaIdent="estimations">
                        <TableHead>
                            <TableRow>
                                <TableCell qaIdentPart="estimation-id" colSpan={3}>
                                    {translateTab('table_cell.id')}
                                </TableCell>
                                <TableCell qaIdentPart="estimation-name" colSpan={5}>
                                    {translateTab('table_cell.name')}
                                </TableCell>
                                <TableCell qaIdentPart="estimation-eu">
                                    {translateTab('table_cell.eu')}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="estimation-amount"
                                    colSpan={3}
                                    className={cx('ace-c-table__cell--text-align-right')}
                                >
                                    {translateTab('table_cell.price')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estimations?.length > 0 && estimations.map(estimation => (
                                <TableRow
                                    key={estimation.id}
                                    qaIdentPart={estimation.id}
                                >
                                    <TableCell
                                        qaIdentPartPostfix={estimation.id}
                                        qaIdentPart="estimation-id"
                                        colSpan={3}
                                    >
                                        {estimation.id}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPartPostfix={estimation.id}
                                        qaIdentPart="estimation-name"
                                        colSpan={5}
                                    >
                                        {estimation.name}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPartPostfix={estimation.id}
                                        qaIdentPart="estimation-eu"
                                    >
                                        {estimation.isEU ? <Icon icon={checkmarkIcon} /> : <Icon icon={closeIcon} />}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPartPostfix={estimation.id}
                                        qaIdentPart="estimation-amount"
                                        colSpan={3}
                                        className={cx('ace-c-table__cell--text-align-right')}
                                    >
                                        {getPrice(estimation.amount, activeLocale)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Panel>
            </div>
        </Fragment>
    );
};

FeesAndEstimationScreen.propTypes = {
    assistanceFees: PropTypes.array,
    estimations: PropTypes.array,
    initiateAssistanceFeesEditFlow: PropTypes.func.isRequired,
    initiateEstimationsEditFlow: PropTypes.func.isRequired,
};

FeesAndEstimationScreen.defaultProps = {
    assistanceFees: null,
    estimations: null,
};

const mapStateToProps = state => ({
    assistanceFees: state.feesAndEstimation.assistanceFees,
    estimations: state.feesAndEstimation.estimations,
});

const mapDispatchToProps = dispatch => ({
    initiateAssistanceFeesEditFlow: () => dispatch({
        type: feesAndEstimationActionTypes.INITIATE_ASSISTANCE_FEES_EDIT_FLOW,
    }),
    initiateEstimationsEditFlow: () => dispatch({
        type: feesAndEstimationActionTypes.INITIATE_ESTIMATIONS_EDIT_FLOW,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeesAndEstimationScreen);
