import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Option} from '@ace-de/ui-components';
import ACEAdminLogo from '../../dashboard/ui-elements/ACEAdminLogo';
import AvatarMenu from '../../dashboard/ui-elements/AvatarMenu';
import routePaths from '../../routePaths';
import keycloakAuth from '../../keycloakAuth';

const StandbyTimesHeader = ({user, signOutUser}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateHeader = createTranslateShorthand('standby_times_screen_header_bar');

    const handleOnSelect = value => {
        if (value === 'logout') {
            signOutUser({});
        }
    };

    return (
        <div
            className={cx([
                'global!ace-u-flex--grow-1',
                'global!ace-u-flex',
                'global!ace-u-flex--justify-space-between',
                'global!ace-u-margin--left-32',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <Link to={resolveRoute(routePaths.DASHBOARD)}>
                    <ACEAdminLogo />
                </Link>
            </div>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-margin--right-32',
                ])}
            >
                {user && (
                    <AvatarMenu
                        userImage=""
                        userName={`${user.firstName[0]}${user.lastName[0]}`}
                        onSelect={handleOnSelect}
                    >
                        <Option
                            name="avatarOptionLogout"
                            value="logout"
                        >
                            {translateHeader('avatar_menu_option.log_out')}
                        </Option>
                    </AvatarMenu>
                )}
            </div>
        </div>
    );
};

StandbyTimesHeader.propTypes = {
    signOutUser: PropTypes.func,
    user: PropTypes.object,
};

StandbyTimesHeader.defaultProps = {
    signOutUser: () => null,
    user: null,
};

const mapStateToProps = state => ({
    user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
    signOutUser: () => dispatch(keycloakAuth.signOutUserAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandbyTimesHeader);
