import {fork, select, put, take} from 'redux-saga/effects';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import {Estimation, persistenceStates} from '@ace-de/eua-entity-types';
import * as feesAndEstimationActionTypes from '../feesAndEstimationActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import assistanceFeesAndEstimationModalTypes from '../modals/assistanceFeesAndEstimationModalTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import errorTypes from '../../application/errorTypes';

const initiateEstimationEditFlow = function* initiateEstimationEditFlow() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    let shouldWaitForAction = true;
    let chosenModalOption;
    let shouldRetryEstimationEdit = false;

    while (true) {
        if (shouldWaitForAction) {
            yield take(feesAndEstimationActionTypes.INITIATE_ESTIMATIONS_EDIT_FLOW);
            yield* openModal(modalIds.ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL, {
                type: assistanceFeesAndEstimationModalTypes.ESTIMATIONS,
            });

            chosenModalOption = yield take([
                feesAndEstimationActionTypes.CONFIRM_ESTIMATIONS_EDIT,
                feesAndEstimationActionTypes.DECLINE_ESTIMATIONS_EDIT,
            ]);
        }

        if ((chosenModalOption
                && chosenModalOption.type === feesAndEstimationActionTypes.CONFIRM_ESTIMATIONS_EDIT)
            || shouldRetryEstimationEdit) {
            const {estimations} = chosenModalOption.payload;
            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                feesAndEstimationActionTypes.UPDATE_ESTIMATIONS_REQUEST,
                pricingManagementService.updateEstimations, {
                    estimationData: {
                        data: estimations.map(estimation => Estimation.objectToDTO(estimation)),
                    },
                },
            );
            const responseAction = yield take([
                feesAndEstimationActionTypes.UPDATE_ESTIMATIONS_REQUEST_SUCCEEDED,
                feesAndEstimationActionTypes.UPDATE_ESTIMATIONS_REQUEST_FAILED,
            ]);

            yield* closeModal(modalIds.ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL, {
                type: assistanceFeesAndEstimationModalTypes.ESTIMATIONS,
            });

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            if (responseAction.error) {
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.ESTIMATION_EDIT_FAILED});

                const nextAction = yield take([
                    feesAndEstimationActionTypes.RETRY_ESTIMATION_EDIT,
                    feesAndEstimationActionTypes.CANCEL_ESTIMATION_EDIT,
                ]);

                if (nextAction.type === feesAndEstimationActionTypes.CANCEL_ESTIMATION_EDIT) {
                    shouldWaitForAction = true;
                    chosenModalOption = null;
                    shouldRetryEstimationEdit = false;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.ESTIMATION_EDIT_FAILED});
                    continue;
                }

                if (nextAction.type === feesAndEstimationActionTypes.RETRY_ESTIMATION_EDIT) {
                    shouldWaitForAction = false;
                    shouldRetryEstimationEdit = true;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                }
            }

            if (!responseAction.error && shouldRetryEstimationEdit) {
                shouldWaitForAction = true;
                chosenModalOption = null;
                shouldRetryEstimationEdit = false;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.ESTIMATION_EDIT_FAILED});
            }
        }
        yield* closeModal(modalIds.ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL, {
            type: assistanceFeesAndEstimationModalTypes.ESTIMATIONS,
        });
    }
};

export default initiateEstimationEditFlow;
