import React, {Fragment, useEffect, useState} from 'react';
import {snakeCase} from 'change-case';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ScreenTitle, ToggleSwitch} from '@ace-de/ui-components';
import {acFeatures, acFeatureActions} from '../../application/acFeatures';
import acAccessControl from '../../acAccessControl';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

const ServiceProviderHeader = ({serviceProvider, initiateChangeSPActiveStateFlow}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateHeader = createTranslateShorthand('service_provider_header');
    const [serviceProviderData, setServiceProviderData] = useState({
        name: serviceProvider?.name || '',
        contractStatus: serviceProvider?.contractStatus || '',
    });
    const [isServiceProviderActive, setIsServiceProviderActive] = useState(
        serviceProvider?.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE,
    );

    useEffect(() => {
        if (serviceProvider) {
            setServiceProviderData({
                name: serviceProvider.name,
                contractStatus: serviceProvider.contractStatus,
            });
            if (serviceProvider.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE) {
                setIsServiceProviderActive(true);
                return;
            }
            if (serviceProvider.contractStatus === apmContractPartnerContractStatusTypes.TERMINATED) {
                setIsServiceProviderActive(false);
            }
        }
    }, [serviceProvider]);

    const toggleActiveServiceProvider = () => {
        const contractStatus = !isServiceProviderActive
            ? apmContractPartnerContractStatusTypes.ACTIVE
            : apmContractPartnerContractStatusTypes.TERMINATED;

        initiateChangeSPActiveStateFlow({
            serviceProviderId: serviceProvider.id,
            contractStatus,
        });
    };

    if (!serviceProvider) return;

    const {contractStatus, name} = serviceProviderData;

    return (
        <Fragment>
            <ScreenTitle>
                <span
                    className={cx([
                        'global!ace-u-margin--right-8',
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-typography--color-contrast',
                    ])}
                >
                    {name}
                </span>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx('global!ace-u-typography--variant-body-medium')}
                    >
                        {translateHeader('label.creditor_id')}&nbsp;
                        <span className={cx('global!ace-u-typography--variant-body')}>
                            {serviceProvider.id || ''}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-48',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.status')}&nbsp;
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {(contractStatus === apmContractPartnerContractStatusTypes.ACTIVE
                                || contractStatus === apmContractPartnerContractStatusTypes.TERMINATED)
                                ? translate(`global.ace_partner_contract_status.${snakeCase(contractStatus)}`)
                                : ''
                            }
                        </p>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-48',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.created_at')}&nbsp;
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {serviceProvider.createdAt || ''}
                        </p>
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <span
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--right-8',
                        ])}
                    >
                        {translateHeader('label.service_provider_active')}
                    </span>
                    {acAccessControl.grantFeatureAccess(acFeatures.SP_ACTIVE_STATUS, acFeatureActions.UPDATE) && (
                        <ToggleSwitch
                            name="toggleActiveServiceProvider"
                            value={true}
                            onChange={toggleActiveServiceProvider}
                            isSelected={isServiceProviderActive}
                            isDisabled={typeof serviceProvider.isEstimationActive === 'boolean' && !serviceProvider.isEstimationActive}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

ServiceProviderHeader.propTypes = {
    serviceProvider: PropTypes.object,
    initiateChangeSPActiveStateFlow: PropTypes.func.isRequired,
};

ServiceProviderHeader.defaultProps = {
    serviceProvider: null,
};

const mapDispatchToProps = dispatch => ({
    initiateChangeSPActiveStateFlow: payload => dispatch({
        type: serviceProviderActionTypes.INITIATE_CHANGE_SP_ACTIVE_STATE_FLOW,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(ServiceProviderHeader);
