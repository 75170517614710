import {all, takeLatest, fork} from 'redux-saga/effects';
import * as commissionerActionTypes from './commissionerActionTypes';
import searchCommissioners from './sagas/searchCommissioners';
import updateCommissioner from './sagas/updateCommissioner';
import createCommissionerFlow from './sagas/createCommissionerFlow';

/**
 *  Commissioner watcher saga
 */
const commissionerWatcher = function* commissionerWatcher() {
    yield all([
        takeLatest(commissionerActionTypes.SEARCH_COMMISSIONERS, searchCommissioners),
        fork(updateCommissioner),
        fork(createCommissionerFlow),
    ]);
};

export default commissionerWatcher;
