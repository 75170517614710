import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {EmergencyContact, apmDayOfWeekTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonPrimary, InteractiveIcon} from '@ace-de/ui-components';
import {Form, InputField, TimeField, Checkbox} from '@ace-de/ui-components/form';
import {closeIcon, timeIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const customTimeFieldOptions = (() => {
    const options = [];
    for (let hours = 0; hours < 24; hours += 1) {
        const hoursString = hours < 10 ? `0${hours}` : hours;
        for (let minutes = 0; minutes < 60; minutes += 5) {
            const minutesString = minutes < 10 ? `0${minutes}` : minutes;
            options.push(`${hoursString}:${minutesString}`);
        }
    }
    options.push('24:00');
    return options;
})();

const formatDefaultWorkingHours = contactData => {
    if (!contactData?.availability?.length) {
        return [
            apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
            apmDayOfWeekTypes.SATURDAY,
            apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
        ].reduce((acc, dayOfWeek) => ({
            ...acc,
            [`${camelCase(dayOfWeek)}From`]: '',
            [`${camelCase(dayOfWeek)}To`]: '',
        }), {});
    }

    return [
        apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
        apmDayOfWeekTypes.SATURDAY,
        apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
    ].reduce((acc, dayOfWeek) => {
        const workingHours = contactData.availability.find(workingHoursDTO => {
            return workingHoursDTO?.dayOfWeek === dayOfWeek;
        });

        return {
            ...acc,
            [`${camelCase(dayOfWeek)}From`]: workingHours?.from?.slice(0, -3) || '',
            [`${camelCase(dayOfWeek)}To`]: workingHours?.to?.slice(0, -3) || '',
        };
    }, {});
};

const initialErrors = {
    type: '',
    phoneNo: '',
    mondayToFridayFrom: '',
    mondayToFridayTo: '',
    saturdayFrom: '',
    saturdayTo: '',
    sundayAndHolidayFrom: '',
    sundayAndHolidayTo: '',
};

const ContractPartnerContactDataModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('contract_partner_contact_data_modal');
    const {hasBackdrop, contractPartner, contactData, location} = props;
    const {confirmCreateCPContactData, declineCreateCPContactData} = props;
    const {confirmEditCPContactData, declineEditCPContactData} = props;
    const [errors, setErrors] = useState(initialErrors);

    const [formData] = useState({
        ...(contactData || new EmergencyContact()),
        ...(formatDefaultWorkingHours(contactData)),
    });

    const validateWorkingHoursFormat = workingHoursDTO => {
        const timeFormatRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        const isStartTimeValid = workingHoursDTO?.from
            ? workingHoursDTO.from === '24:00'
                ? true : timeFormatRegex.test(workingHoursDTO.from)
            : true;
        const isEndTimeValid = workingHoursDTO?.to
            ? workingHoursDTO.to === '24:00'
                ? true : timeFormatRegex.test(workingHoursDTO.to)
            : true;
        return !isStartTimeValid || !isEndTimeValid;
    };

    const validateFormValues = formValues => {
        const phoneNoRegex = /^\+[0-9]{8,15}$/;
        const updatedErrors = {...initialErrors};
        let hasError = false;
        if (!formValues.type) {
            updatedErrors['type'] = translateModal('error_message.enter_contact');
            hasError = true;
        }

        if (!formValues.phoneNo) {
            updatedErrors['phoneNo'] = translateModal('error_message.enter_phone');
            hasError = true;
        }

        if (formValues.phoneNo && !phoneNoRegex.test(formValues.phoneNo)) {
            updatedErrors['phoneNo'] = translateModal('error_message.phone_format');
            hasError = true;
        }

        // validate working hours
        [
            apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
            apmDayOfWeekTypes.SATURDAY,
            apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
        ].forEach(dayOfWeek => {
            const workingHours = {
                from: formValues[`${camelCase(dayOfWeek)}From`] || '',
                to: formValues[`${camelCase(dayOfWeek)}To`] || '',
            };
            // both 'from' and 'to' info needs to be set in order to validate the time range
            if ((workingHours.from && !workingHours.to) || (!workingHours?.from && workingHours.to)) {
                updatedErrors[`${camelCase(dayOfWeek)}From`] = translateModal('error_message.enter_time_range');
                updatedErrors[`${camelCase(dayOfWeek)}To`] = translateModal('error_message.enter_time_range');
                hasError = true;
                return;
            }
            // validate time format
            if (validateWorkingHoursFormat(workingHours)) {
                updatedErrors[`${camelCase(dayOfWeek)}From`] = translateModal('error_message.invalid_time_format');
                updatedErrors[`${camelCase(dayOfWeek)}To`] = translateModal('error_message.invalid_time_format');
                hasError = true;
                return;
            }
            // 'from' value must be less than 'to' value
            if ((workingHours.from > workingHours.to)
                || (workingHours.from && workingHours.from !== '00:00' ? workingHours.from === workingHours.to : false)
            ) {
                updatedErrors[`${camelCase(dayOfWeek)}From`] = translateModal('error_message.invalid_time_range');
                updatedErrors[`${camelCase(dayOfWeek)}To`] = translateModal('error_message.invalid_time_range');
                hasError = true;
                return;
            }
        });

        if (hasError) {
            setErrors(updatedErrors);
            return false;
        }

        setErrors(initialErrors);
        return true;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;

        if (!validateFormValues(formValues)) return;

        const data = {
            ...formValues,
            ...(!contactData && {contractPartnerId: contractPartner.id}),
            availability: [
                apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
                apmDayOfWeekTypes.SATURDAY,
                apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
            ].map(dayOfWeek => ({
                dayOfWeek,
                from: formValues[`${camelCase(dayOfWeek)}From`],
                to: formValues[`${camelCase(dayOfWeek)}To`],
            })).filter(workingHoursDTO => !!(workingHoursDTO.dayOfWeek)).map(workingHoursDTO => {
                if (workingHoursDTO?.to === '24:00') {
                    return {
                        ...workingHoursDTO,
                        to: '00:00',
                    };
                }
                return workingHoursDTO;
            }),
            is24h7Emergency: !!formValues.is24h7Emergency,
            isAdditional: !!location?.query?.isAdditional,
        };

        if (contactData) {
            confirmEditCPContactData({
                contactData: data,
            });
            return;
        }

        confirmCreateCPContactData({
            contactData: data,
        });
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={contactData ? declineEditCPContactData : declineCreateCPContactData}
                />
            )}
            hasBackdrop={hasBackdrop}
            title={contactData ? translateModal('title.edit_contact') : translateModal('title.add_new_contact')}
            contentClassName={cx('ace-c-modal__content--scrollable', 'global!ace-u-margin--top-32')}
        >
            <Form name="updateContactDataForm" onSubmit={handleOnSubmit}>
                {formValues => {
                    return (
                        <Fragment>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-48',
                                ])}
                            >
                                <InputField
                                    name="type"
                                    label={translateModal('input_label.contact')}
                                    value={formData.type}
                                    className={cx('global!ace-u-width--full')}
                                    isFieldRequired={true}
                                    errors={errors?.type ? [errors.type] : []}
                                />
                                <InputField
                                    name="phoneNo"
                                    label={translateModal('input_label.phone_number')}
                                    value={formData.phoneNo}
                                    className={cx('global!ace-u-width--full')}
                                    isFieldRequired={true}
                                    errors={errors?.phoneNo ? [errors.phoneNo] : []}
                                />
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-48',
                                ])}
                            >
                                <InputField
                                    name="name"
                                    label={translateModal('input_label.name')}
                                    value={formData.name}
                                    className={cx('global!ace-u-width--full')}
                                />
                                <InputField
                                    name="function"
                                    label={translateModal('input_label.function')}
                                    value={formData.function}
                                    className={cx('global!ace-u-width--full')}
                                />
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-flex-end',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-48',
                                ])}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-flex-end',
                                        'global!ace-u-flex--column-gap-8',
                                    ], {
                                        'global!ace-u-padding--bottom-24': (!!errors?.saturdayFrom
                                            || !!errors?.saturdayTo) && !(!!errors?.mondayToFridayFrom
                                                || !!errors?.mondayToFridayTo),
                                    })}
                                >
                                    <TimeField
                                        name={`${camelCase(apmDayOfWeekTypes.MONDAY_TO_FRIDAY)}From`}
                                        label={translateModal('input_label.monday_friday_availability')}
                                        value={formData[`${camelCase(apmDayOfWeekTypes.MONDAY_TO_FRIDAY)}From`] || ''}
                                        icon={timeIcon}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        customTimeOptions={customTimeFieldOptions}
                                        errors={errors?.mondayToFridayFrom ? [errors.mondayToFridayFrom] : []}
                                    />
                                    <span
                                        className={cx('global!ace-u-margin--bottom-16', {
                                            'global!ace-u-flex--align-self-center': !!errors?.mondayToFridayFrom
                                                || !!errors?.mondayToFridayTo,
                                            'global!ace-u-margin--top-16': !!errors?.mondayToFridayFrom
                                                || !!errors?.mondayToFridayTo,
                                        })}
                                    >
                                        &#45;
                                    </span>
                                    <TimeField
                                        name={`${camelCase(apmDayOfWeekTypes.MONDAY_TO_FRIDAY)}To`}
                                        value={formData[`${camelCase(apmDayOfWeekTypes.MONDAY_TO_FRIDAY)}To`] || ''}
                                        icon={timeIcon}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        customTimeOptions={customTimeFieldOptions}
                                        errors={errors?.mondayToFridayTo ? [errors.mondayToFridayTo] : []}
                                    />
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-flex-end',
                                        'global!ace-u-flex--column-gap-8',
                                        'global!ace-u-flex--grow-0',
                                    ], {
                                        'global!ace-u-padding--bottom-24': (!!errors?.mondayToFridayFrom
                                            || !!errors?.mondayToFridayTo) && !(!!errors?.saturdayFrom
                                                || !!errors?.saturdayTo),
                                    })}
                                >
                                    <TimeField
                                        name={`${camelCase(apmDayOfWeekTypes.SATURDAY)}From`}
                                        label={translateModal('input_label.saturday_availability')}
                                        value={formData[`${camelCase(apmDayOfWeekTypes.SATURDAY)}From`] || ''}
                                        icon={timeIcon}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        customTimeOptions={customTimeFieldOptions}
                                        errors={errors?.saturdayFrom ? [errors.saturdayFrom] : []}
                                    />
                                    <span
                                        className={cx('global!ace-u-margin--bottom-16', {
                                            'global!ace-u-flex--align-self-center': !!errors?.saturdayFrom
                                                || !!errors?.saturdayTo,
                                            'global!ace-u-margin--top-16': !!errors?.saturdayFrom
                                                || !!errors?.saturdayTo,
                                        })}
                                    >
                                        &#45;
                                    </span>
                                    <TimeField
                                        name={`${camelCase(apmDayOfWeekTypes.SATURDAY)}To`}
                                        value={formData[`${camelCase(apmDayOfWeekTypes.SATURDAY)}To`] || ''}
                                        icon={timeIcon}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        customTimeOptions={customTimeFieldOptions}
                                        errors={errors?.saturdayTo ? [errors.saturdayTo] : []}
                                    />
                                </div>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-flex-end',
                                    'global!ace-u-flex--column-gap-8',
                                    'global!ace-u-margin--bottom-48',
                                ])}
                            >
                                <TimeField
                                    name={`${camelCase(apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY)}From`}
                                    label={translateModal('input_label.sunday_holiday_availability')}
                                    value={formData[`${camelCase(apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY)}From`] || ''}
                                    icon={timeIcon}
                                    inputClassName={cx('global!ace-u-width--full')}
                                    customTimeOptions={customTimeFieldOptions}
                                    errors={errors?.sundayAndHolidayFrom ? [errors.sundayAndHolidayFrom] : []}
                                />
                                <span
                                    className={cx('global!ace-u-margin--bottom-16', {
                                        'global!ace-u-flex--align-self-center': !!errors?.sundayAndHolidayFrom
                                            || !!errors?.sundayAndHolidayTo,
                                        'global!ace-u-margin--top-16': !!errors?.sundayAndHolidayFrom
                                            || !!errors?.sundayAndHolidayTo,
                                    })}
                                >
                                    &#45;
                                </span>
                                <TimeField
                                    name={`${camelCase(apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY)}To`}
                                    value={formData[`${camelCase(apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY)}To`] || ''}
                                    icon={timeIcon}
                                    inputClassName={cx('global!ace-u-width--full')}
                                    customTimeOptions={customTimeFieldOptions}
                                    errors={errors?.sundayAndHolidayTo ? [errors.sundayAndHolidayTo] : []}
                                />
                            </div>
                            {(!location?.query?.isAdditional && (!!contactData?.is24h7Emergency
                                || !contractPartner.emergencyContacts?.find(contact => contact.is24h7Emergency))) && (
                                <Checkbox
                                    name="is24h7Emergency"
                                    value={true}
                                    isSelected={!!formData.is24h7Emergency}
                                    className={cx('global!ace-u-margin--bottom-16')}
                                >
                                    {translateModal(`checkbox_label.is_24_7`)}
                                </Checkbox>
                            )}
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-flex-end',
                                    'global!ace-u-margin--top-16',
                                ])}
                            >
                                <ButtonPrimary name="submitContactDataButton" type="submit">
                                    {translateModal('button_label.save')}
                                </ButtonPrimary>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Modal>
    );
};

ContractPartnerContactDataModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    contractPartner: PropTypes.object,
    confirmCreateCPContactData: PropTypes.func.isRequired,
    declineCreateCPContactData: PropTypes.func.isRequired,
    confirmEditCPContactData: PropTypes.func.isRequired,
    declineEditCPContactData: PropTypes.func.isRequired,
    contactData: PropTypes.object,
    location: PropTypes.object,
};

ContractPartnerContactDataModal.defaultProps = {
    hasBackdrop: false,
    contractPartner: null,
    contactData: null,
    location: {},
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();
    const contactDataSelector = contractPartnerSelectors.createCPContactDataSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        contactData: contactDataSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmCreateCPContactData: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CREATE_CP_CONTACT_DATA,
        payload,
    }),
    declineCreateCPContactData: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_CREATE_CP_CONTACT_DATA,
    }),
    confirmEditCPContactData: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_UPDATE_CP_CONTACT_DATA,
        payload,
    }),
    declineEditCPContactData: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_UPDATE_CP_CONTACT_DATA,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractPartnerContactDataModal);
