import {put, take, fork, select} from 'redux-saga/effects';
import {TemporaryRestriction} from '@ace-de/eua-entity-types';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const createTemporaryRestrictionFlow = function* createTemporaryRestrictionFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW);
        const {contractPartner} = payload;

        yield* openModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);

        const chosenModalOption = yield take([
            contractPartnerActionTypes.DECLINE_CREATE_TEMPORARY_RESTRICTION,
            contractPartnerActionTypes.CONFIRM_CREATE_TEMPORARY_RESTRICTION,
        ]);

        if (chosenModalOption.type === contractPartnerActionTypes.DECLINE_CREATE_TEMPORARY_RESTRICTION) {
            yield* closeModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);
            continue;
        }

        const {temporaryRestrictionData} = chosenModalOption.payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST,
            partnerManagementService.createContractPartnerTemporaryRestriction,
            {
                contractPartnerId: contractPartner.id,
                temporaryRestrictionData: TemporaryRestriction.objectToDTO(temporaryRestrictionData),
            },
        );

        const createTemporaryRestrictionResponseAction = yield take([
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED,
        ]);

        if (!createTemporaryRestrictionResponseAction.error) {
            const {response} = createTemporaryRestrictionResponseAction.payload;

            yield put({
                type: contractPartnerActionTypes.STORE_CP_TEMPORARY_RESTRICTIONS,
                payload: {
                    contractPartnerId: contractPartner.id,
                    temporaryRestrictionDTOs: [response.contractPartnerRestrictionDTO],
                },
            });

            yield* closeModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);
            // display success popup
            yield* openModal(modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL);
            continue;
        }

        yield* closeModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);
    }
};

export default createTemporaryRestrictionFlow;
