import {eventChannel} from 'redux-saga';
import {select, take} from 'redux-saga/effects';
import keycloakAuth from '../../keycloakAuth';

const provideKeycloakTokenToServicesFlow = function* provideKeycloakTokenToServicesFlow() {
    const {serviceManager} = yield select(state => state.application);

    const authSessionEventChannel = eventChannel(emitter => {
        // returns unsubscribe method to eventChannel
        return keycloakAuth.onAuthSessionStarted(sessionCredentials => {
            emitter({sessionCredentials});
        });
    });

    while (true) {
        const {sessionCredentials} = yield take(authSessionEventChannel);

        try {
            const userProfileService = serviceManager.loadService('userProfileService');
            userProfileService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            const ecsFlowService = serviceManager.loadService('ecsFlowService');
            ecsFlowService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            const partnerManagementService = serviceManager.loadService('partnerManagementService');
            partnerManagementService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            const apmFileAssetsService = serviceManager.loadService('apmFileAssetsService');
            apmFileAssetsService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
            leaAssignmentFlowService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            const pricingManagementService = serviceManager.loadService('pricingManagementService');
            pricingManagementService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }
    }
};

export default provideKeycloakTokenToServicesFlow;
