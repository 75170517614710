import {fork, put, select, take} from 'redux-saga/effects';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as feesAndEstimationActionTypes from '../feesAndEstimationActionTypes';

const loadEstimations = function* loadEstimations({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {location} = payload;

    if (location && location.state?.isModalOpen
        && location.state?.modalId === modalIds.ASSISTANCE_FEES_AND_ESTIMATION_EDIT_MODAL
    ) return;

    yield fork(
        fetchRequest,
        feesAndEstimationActionTypes.FETCH_ESTIMATIONS_REQUEST,
        pricingManagementService.getEstimations,
    );

    const responseAction = yield take([
        feesAndEstimationActionTypes.FETCH_ESTIMATIONS_REQUEST_SUCCEEDED,
        feesAndEstimationActionTypes.FETCH_ESTIMATIONS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {estimations} = response;
    yield put({
        type: feesAndEstimationActionTypes.STORE_ESTIMATIONS,
        payload: {estimations},
    });
};

export default loadEstimations;
