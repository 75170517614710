import React, {Fragment, useMemo, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute} from '@computerrock/formation-router';
import {useStyles, Panel, Paginator, NoResultsBlock} from '@ace-de/ui-components';
import {InputField, ButtonIcon, SelectField, Option, Form, ButtonPrimary} from '@ace-de/ui-components/form';
import {Table, TableHead, TableBody, TableRow, TableCell, TableCaption} from '@ace-de/ui-components/data-elements';
import {Icon, InteractiveIcon, resetIcon, findCaseIcon, linkIcon, searchIcon} from '@ace-de/ui-components/icons';
import {europeanCountries, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {acFeatures, acFeatureActions} from '../application/acFeatures';
import acAccessControl from '../acAccessControl';
import * as commissionerSelectors from './commissionerSelectors';
import * as commissionerActionTypes from './commissionerActionTypes';
import config from '../config';
import routePaths from '../routePaths';

const initialFilteringParams = {
    searchTerm: '',
    country: 'ALL',
};

const CommissionerSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('commissioner_management_search_screen');
    const {commissionerSearchResults, commissionerSearchResultsCount, history, initiateCreateCommissionerFlow} = props;
    const [filterFormData, setFilterFormData] = useState(initialFilteringParams);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const paginatorCount = Math.ceil(commissionerSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const resetRef = useRef(false);
    const sortedCountries = useMemo(() => {
        return Object.entries(europeanCountries).map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ]).sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });
    }, [translate]);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const handleResetFilter = () => {
        resetRef.current = true;
        setFilterFormData(initialFilteringParams);
        history.push(resolveRoute(routePaths.COMMISSIONER_SEARCH, {}, {search: ''}));
    };

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();
        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '' && formData[formField] !== 'ALL') {
                apiQueryParams.append(`${formField}`, formData[formField]);
            }
        });
        return apiQueryParams;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) {
            return;
        }

        const apiQueryParams = formatQueryParams(formValues);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.COMMISSIONER_SEARCH, {}, {search: queryParamsString}));
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.COMMISSIONER_SEARCH, {}, {search: queryParamsString}));
    };

    const handleOnChange = formValues => {
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }
        setFilterFormData(formValues);
    };

    const openCommissionerScreen = commissioner => {
        const {pathname} = resolveRoute(routePaths.COMMISSIONER, {commissionerId: commissioner.id});
        window.open(pathname, '_blank');
    };

    return (
        <Fragment>
            <Panel title={translateScreen('commissioner_search_panel.title')}>
                <Form name="commissionerSearchForm" isSubmitOnEnterEnabled={true} onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    <div className={cx('global!ace-u-grid')}>
                        <div className={cx('global!ace-u-grid-column--span-7')}>
                            <InputField
                                label={translateScreen('input_field_label.free_search')}
                                name="searchTerm"
                                className={cx('global!ace-u-full-width')}
                                value={queryParams.get('searchTerm') || filterFormData.searchTerm}
                            />
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-4')}>
                            <SelectField
                                name="country"
                                className={cx('global!ace-u-full-width')}
                                label={translateScreen('select_field_label.country')}
                                value={queryParams.get('country') || filterFormData.country}
                            >
                                <Option
                                    name="countryOptionAll"
                                    value="ALL"
                                >
                                    {translate(`global.country.all`)}
                                </Option>
                                {sortedCountries
                                    .map(([countryCode, country]) => {
                                        return (
                                            <Option
                                                key={country.id}
                                                name={`country-${country.id}`}
                                                value={countryCode}
                                                className={cx('ace-c-option--small')}
                                            >
                                                {country.name}
                                            </Option>
                                        );
                                    })}
                            </SelectField>
                        </div>
                        <div className={cx(['global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24'])}>
                            <ButtonIcon
                                name="searchCommissionersButton"
                                icon={searchIcon}
                                type="submit"
                                className={cx('global!ace-u-margin--top-4')}
                            />
                        </div>
                    </div>
                </Form>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-margin--top-32',
                    ])}
                >
                    <div>
                        <span className={cx('global!ace-u-typography--variant-body')}>
                            {translateScreen('label.result_count')}
                        </span>&nbsp;
                        <span className={cx('global!ace-u-typography--variant-body-medium')}>
                            {commissionerSearchResultsCount}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-margin--right-24',
                        ])}
                    >
                        <InteractiveIcon
                            icon={resetIcon}
                            className={cx([
                                'ace-c-interactive-icon--reverse',
                                'ace-c-interactive-icon--highlight',
                            ])}
                            onClick={handleResetFilter}
                        >
                            {translateScreen('interactive_icon_label.reset_filter')}
                        </InteractiveIcon>
                    </div>
                </div>
            </Panel>
            <Panel title={translateScreen('commissioner_panel.title')}>
                {acAccessControl.grantFeatureAccess(acFeatures.COMMISSIONERS, acFeatureActions.CREATE) && (
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-padding--32-0',
                        ])}
                    >
                        <ButtonPrimary onClick={initiateCreateCommissionerFlow}>
                            {translateScreen('commissioner_panel_button.add_commissioner')}
                        </ButtonPrimary>
                    </div>
                )}
                <Table qaIdent="commissioner-search-results">
                    {commissionerSearchResults.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                            )}
                                message={translateScreen('no_results.message')}
                                description={translateScreen('no_results.description')}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="commissioner-prefix" colSpan={2}>
                                {translateScreen('commissioner_table_cell.prefix')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-account-party-id" colSpan={3}>
                                {translateScreen('commissioner_table_cell.debtor_number')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-name" colSpan={4}>
                                {translateScreen('commissioner_table_cell.name')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-email" colSpan={4}>
                                {translateScreen('commissioner_table_cell.email')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-phone" colSpan={3}>
                                {translateScreen('commissioner_table_cell.phone')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-post-code" colSpan={3}>
                                {translateScreen('commissioner_table_cell.post_code')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-city" colSpan={3}>
                                {translateScreen('commissioner_table_cell.city')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-country" colSpan={2}>
                                {translateScreen('commissioner_table_cell.country')}
                            </TableCell>
                            <TableCell qaIdentPart="commissioner-edit" colSpan={1} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {commissionerSearchResults.length > 0
                            && commissionerSearchResults.map(commissioner => {
                                const {businessContactDetails, address, contractStatus} = commissioner;
                                const commissionerName = commissioner.name ? commissioner.name : '';

                                return (
                                    <TableRow
                                        key={commissioner.id}
                                        qaIdentPart={commissioner.id}
                                    >
                                        <TableCell
                                            qaIdentPart="commissioner-prefix"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={2}
                                        >
                                            {commissioner.id || ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-account-party-id"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={3}
                                        >
                                            {commissioner.accountPartyId || ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-name"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={4}
                                        >
                                            {contractStatus === apmContractPartnerContractStatusTypes.TERMINATED
                                                ? (
                                                    <p>
                                                        {commissionerName}
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-typography--color-highlighted',
                                                                'global!ace-u-padding--left-4',
                                                            ])}
                                                        >
                                                            {translateScreen('commissioner_table_cell.inactive')}
                                                        </span>
                                                    </p>
                                                ) : commissionerName}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-email"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={4}
                                        >
                                            {businessContactDetails ? businessContactDetails.email : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-phone"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={3}
                                        >
                                            {businessContactDetails ? businessContactDetails.phoneNo : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-post-code"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={3}
                                        >
                                            {address ? address.postCode : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-post-city"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={3}
                                        >
                                            {address ? address.city : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-country"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={2}
                                        >
                                            {address ? address.country : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="commissioner-edit"
                                            qaIdentPartPostfix={commissioner.id}
                                            colSpan={1}
                                        >
                                            <InteractiveIcon
                                                icon={linkIcon}
                                                className={cx('ace-c-interactive-icon--primary')}
                                                onClick={() => openCommissionerScreen(commissioner)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Panel>
            {commissionerSearchResults.length > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

CommissionerSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    commissionerSearchResults: PropTypes.array,
    commissionerSearchResultsCount: PropTypes.number,
    initiateCreateCommissionerFlow: PropTypes.func.isRequired,
};

CommissionerSearchScreen.defaultProps = {
    commissionerSearchResults: [],
    commissionerSearchResultsCount: 0,
};

const mapStateToProps = (state, props) => {
    return {
        commissionerSearchResults: commissionerSelectors.getCommissionerSearchResults(state, props),
        commissionerSearchResultsCount: commissionerSelectors.getCommissionerSearchResultsCount(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateCommissionerFlow: payload => dispatch({
        type: commissionerActionTypes.INITIATE_CREATE_COMMISSIONER_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommissionerSearchScreen);
