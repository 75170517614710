import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmACEPartnerServiceTypes} from '@ace-de/eua-entity-types';
import {Icon, NotificationBar, useStyles, warningAlertOutlinedIcon} from '@ace-de/ui-components';
import {HeaderBar, AppLayoutMain, ContentItem} from '@ace-de/ui-components/app-layout';
import * as serviceProviderSelectors from './serviceProviderSelectors';
import ServiceProviderHeader from './ui-elements/ServiceProviderHeader';
import ServiceProviderBasicDataPanel from './ui-elements/ServiceProviderBasicDataPanel';
import ServiceProviderServicesPanel from './ui-elements/ServiceProviderServicesPanel';
import ServiceProviderRentalCarPricesPanel from './ui-elements/ServiceProviderRentalCarPricesPanel';

const ServiceProviderScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('service_provider_screen');
    const {serviceProvider} = props;

    return (
        <Fragment>
            <HeaderBar className={cx('ace-c-header-bar--primary-navigation')}>
                <ServiceProviderHeader serviceProvider={serviceProvider} />
            </HeaderBar>
            {typeof serviceProvider.isEstimationActive === 'boolean' && !serviceProvider.isEstimationActive && (
                <NotificationBar
                    className={cx([
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-typography--variant-body',
                    ])}
                    type="negative"
                    contentClassName={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <Icon icon={warningAlertOutlinedIcon} className={cx('global!ace-u-margin--right-8')} />
                    {translateScreen('warning_banner.message')}
                </NotificationBar>
            )}
            <AppLayoutMain>
                <ContentItem>
                    <ServiceProviderBasicDataPanel serviceProvider={serviceProvider} />
                    <ServiceProviderServicesPanel serviceProvider={serviceProvider} />
                    {!!serviceProvider?.services?.find(service => {
                        return service.serviceType === apmACEPartnerServiceTypes.RENTAL_CAR;
                    }) && (
                        <ServiceProviderRentalCarPricesPanel />
                    )}
                </ContentItem>
            </AppLayoutMain>
        </Fragment>
    );
};

ServiceProviderScreen.propTypes = {
    serviceProvider: PropTypes.object,
};

ServiceProviderScreen.defaultProps = {
    serviceProvider: {},
};

const mapStateToProps = (state, props) => {
    const createServiceProviderSelector = serviceProviderSelectors.createServiceProviderSelector();

    return {
        serviceProvider: createServiceProviderSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(ServiceProviderScreen);
