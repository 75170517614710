import {call, fork, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const filterMapCPOperationAreasById = function* filterMapCPOperationAreasById(contractPartnerId) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partner-operation-areas');

    if (!arcGISMap) return;

    const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-area-locations');
    const vpmContractPartnersServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-area-service-areas');

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST,
        vpmContractPartnersLocationsLayer.filterFeaturesByAttribute,
        {
            where: `contractPa = '${contractPartnerId}'`,
        },
    );

    const filterCPLocationResponse = yield take([
        contractPartnerActionTypes.FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_FAILED,
    ]);

    if (filterCPLocationResponse.error) return;

    const {response} = filterCPLocationResponse?.payload || {};
    const contractPartnerFeatureDTOs = response?.featureDTOs || [];
    const contractPartnerFeatureDTO = contractPartnerFeatureDTOs[0] || null;

    if (!contractPartnerFeatureDTO) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST,
        vpmContractPartnersServiceAreasLayer.filterFeaturesByAttribute,
        {
            where: `contractPa = '${contractPartnerId}'`,
        },
    );

    yield take([
        contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_FAILED,
    ]);

    const contractPartnerCoordinates = [
        contractPartnerFeatureDTO['contractPartnerLongitude'],
        contractPartnerFeatureDTO['contractPartnerLatitude'],
    ];
    yield call(arcGISMap.setMapCenter, contractPartnerCoordinates);
    yield call(arcGISMap.setMapViewZoomLevel, 10);

    vpmContractPartnersLocationsLayer.show();
    vpmContractPartnersServiceAreasLayer.show();
};

export default filterMapCPOperationAreasById;
