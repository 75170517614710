import {fork, select, take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {INARule, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as inaRulesActionTypes from '../inaRulesActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';

const initiateINARuleEditFlow = function* initiateINARuleEditFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    let chosenAction;
    let shouldWaitForAction = true;
    let shouldRetryInaRuleEdit = false;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take([inaRulesActionTypes.INITIATE_INA_RULE_EDIT_FLOW]);
            const {inaRuleId} = payload;

            yield* openModal(modalIds.INA_RULES_EDIT_MODAL, {inaRuleId: inaRuleId.toString()});

            chosenAction = yield take([
                inaRulesActionTypes.CONFIRM_INA_RULE_EDIT,
                inaRulesActionTypes.DECLINE_INA_RULE_EDIT,
            ]);

            if (chosenAction.type === inaRulesActionTypes.DECLINE_INA_RULE_EDIT) {
                yield* closeModal(modalIds.INA_RULES_EDIT_MODAL, {inaRuleId: inaRuleId.toString()});
                continue;
            }
        }

        if (chosenAction.type === inaRulesActionTypes.CONFIRM_INA_RULE_EDIT || shouldRetryInaRuleEdit) {
            const {payload: chosenActionPayload} = chosenAction;
            const {inaRuleId, inaRuleData} = chosenActionPayload;
            const {retryActionPersistenceState} = yield select(state => state.application);

            yield fork(
                fetchRequest,
                inaRulesActionTypes.UPDATE_INA_RULE_REQUEST,
                leaAssignmentFlowService.updateINARule,
                {
                    ruleId: inaRuleId,
                    inaRulePatchDTO: INARule.objectToPatchDTO(inaRuleData),
                },
            );

            const ruleUpdateResponseAction = yield take([
                inaRulesActionTypes.UPDATE_INA_RULE_REQUEST_SUCCEEDED,
                inaRulesActionTypes.UPDATE_INA_RULE_REQUEST_FAILED,
            ]);

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }

            yield* closeModal(modalIds.INA_RULES_EDIT_MODAL, {inaRuleId: inaRuleId.toString()});

            if (ruleUpdateResponseAction.error) {
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.INA_RULES_EDIT_FAILED});

                const nextAction = yield take([
                    inaRulesActionTypes.RETRY_INA_RULE_EDIT,
                    inaRulesActionTypes.CANCEL_INA_RULE_EDIT,
                ]);

                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.INA_RULES_EDIT_FAILED});

                if (nextAction.type === inaRulesActionTypes.CANCEL_INA_RULE_EDIT) {
                    chosenAction = null;
                    shouldWaitForAction = true;
                    shouldRetryInaRuleEdit = false;
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.INA_RULES_EDIT_FAILED});
                    continue;
                }
                if (nextAction.type === inaRulesActionTypes.RETRY_INA_RULE_EDIT) {
                    shouldWaitForAction = false;
                    shouldRetryInaRuleEdit = true;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    continue;
                }
            }

            if (!ruleUpdateResponseAction.error) {
                chosenAction = null;
                shouldWaitForAction = true;
                shouldRetryInaRuleEdit = false;
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.INA_RULES_EDIT_FAILED});
            }
        }
    }
};

export default initiateINARuleEditFlow;
