import {all, fork} from 'redux-saga/effects';
import provideArcGISTokenToServicesFlow from './sagas/provideArcGISTokenToServicesFlow';
import provideKeycloakTokenToServicesFlow from './sagas/provideKeycloakTokenToServicesFlow';
import successMessageFlow from './sagas/successMessageFlow';
import errorMessageModalFlow from './sagas/errorMessageModalFlow';

const applicationWatcher = function* applicationWatcher() {
    yield all([
        fork(provideKeycloakTokenToServicesFlow),
        fork(provideArcGISTokenToServicesFlow),
        fork(successMessageFlow),
        fork(errorMessageModalFlow),
    ]);
};

export default applicationWatcher;
