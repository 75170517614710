import {createSelector} from 'reselect';
import moment from 'moment';

/**
 * General state selectors
 */
export const getContractPartners = state => state.contractPartners.contractPartners;
export const getContractPartnerSearchResults = state => state.contractPartners.contractPartnerSearchResults;
export const getContractPartnerSearchResultsCount = state => state.contractPartners.contractPartnerSearchResultsCount;
export const getContractPartnerLocationRecommendations = state => state.contractPartners.contractPartnerLocationRecommendations; // eslint-disable-line max-len
export const getServiceCaseSearchResults = state => state.contractPartners.serviceCaseSearchResults;
export const getServiceCaseSearchResultsCount = state => state.contractPartners.serviceCaseSearchResultsCount;
export const getCommissioners = state => state.contractPartners.commissioners;

/**
 * Returns selector for contractPartner matched from route
 */
export const createContractPartnerSelector = () => createSelector(
    [
        getContractPartners,
        (state, props) => {
            const {match} = props;
            return match?.params['contractPartnerId'];
        },
    ],
    (contractPartners, contractPartnerId) => {
        if (!contractPartnerId) return null;

        return contractPartners[contractPartnerId] || null;
    },
);

/**
 * Returns selector for contract partner's temporary restriction
 */
export const createTemporaryRestrictionSelector = () => createSelector(
    [
        (state, props) => {
            const {location} = props;
            return location?.query
                ? location.query['temporaryRestrictionId'] : null;
        },
        createContractPartnerSelector(),
    ],
    (temporaryRestrictionId, contractPartner) => {
        if (!contractPartner || !temporaryRestrictionId) return null;
        return contractPartner.temporaryRestrictions.get(temporaryRestrictionId);
    },
);

/**
 * Returns selector for all active and upcoming temporary restrictions of contractPartner matched from route
 */
export const createActiveAndUpcomingTemporaryRestrictionsSelector = () => createSelector(
    [
        createContractPartnerSelector(),
    ],
    contractPartner => {
        if (!contractPartner) return [];
        return [...contractPartner.temporaryRestrictions.values()]
            .filter(temporaryRestriction => {
                if (!temporaryRestriction.restrictionEndDateTime) {
                    return temporaryRestriction;
                }
                const now = moment();
                const restrictionEnd = moment(temporaryRestriction.restrictionEndDateTime);
                return now.isBefore(restrictionEnd);
            });
    },
);

/**
 * Returns selector for all past restrictions of contractPartner matched from route
 */
export const createPastTemporaryRestrictionsSelector = () => createSelector(
    [
        createContractPartnerSelector(),
    ],
    contractPartner => {
        if (!contractPartner) return null;
        return [...contractPartner.temporaryRestrictions.values()]
            .filter(temporaryRestriction => {
                const now = moment();
                const restrictionStart = temporaryRestriction.restrictionStartDateTime
                    ? moment(temporaryRestriction.restrictionStartDateTime) : null;
                const restrictionEnd = temporaryRestriction.restrictionEndDateTime
                    ? moment(temporaryRestriction.restrictionEndDateTime) : null;

                // if both dates exist, check if the current date is in between
                if (restrictionStart && restrictionEnd && restrictionStart.isValid() && restrictionEnd.isValid()) {
                    return now.isAfter(restrictionStart) && now.isAfter(restrictionEnd);
                }

                return false;
            }).sort((a, b) => (b.restrictionEndDateTime > a.restrictionEndDateTime ? 1 : -1));
    },
);

/**
 * Returns selector for contract partner's emergency contact
 */
export const createCPContactDataSelector = () => createSelector(
    [
        createContractPartnerSelector(),
        (state, props) => {
            const {location} = props;
            const queryParams = new URLSearchParams(location?.search || '');
            return queryParams.get('contactDataId') || null;
        },
    ],
    (contractPartner, contactDataId) => {
        if (!contractPartner || !contactDataId) return null;
        return contractPartner.emergencyContacts?.find(contact => `${contact.id}` === contactDataId) || null;
    },
);
