import {take, select, put, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as commissionerActionTypes from '../commissionerActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';

const updateCommissioner = function* updateCommissioner() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let commissionerUpdateData;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(commissionerActionTypes.SUBMIT_COMMISSIONER_FORM);
            commissionerUpdateData = payload;
        }
        const {commissionerId, commissionerData} = commissionerUpdateData;
        const {retryActionPersistenceState} = yield select(state => state.application);

        yield fork(
            fetchRequest,
            commissionerActionTypes.UPDATE_COMMISSIONER_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(commissionerData),
                    partnerType: apmACEPartnerTypes.COMMISSIONER,
                },
                acePartnerId: commissionerId,
            },
        );

        const responseAction = yield take([
            commissionerActionTypes.UPDATE_COMMISSIONER_REQUEST_SUCCEEDED,
            commissionerActionTypes.UPDATE_COMMISSIONER_REQUEST_FAILED,
        ]);
        if (retryActionPersistenceState === persistenceStates.PENDING) {
            yield put({
                type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
        }

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {acePartnerDTO: commissionerDTO} = response;

            yield put({
                type: commissionerActionTypes.STORE_COMMISSIONERS,
                payload: {commissionerDTOs: [commissionerDTO]},
            });
            if (!shouldWaitForAction) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.COMMISSIONER_EDIT_FAILED});
            }
            shouldWaitForAction = true;
            commissionerUpdateData = null;
            continue;
        }

        if (responseAction.error) {
            yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.COMMISSIONER_EDIT_FAILED});

            const nextAction = yield take([
                commissionerActionTypes.RETRY_COMMISSIONER_EDIT,
                commissionerActionTypes.CANCEL_COMMISSIONER_EDIT,
            ]);

            if (nextAction.type === commissionerActionTypes.RETRY_COMMISSIONER_EDIT) {
                shouldWaitForAction = false;
                if (retryActionPersistenceState !== persistenceStates.PENDING) {
                    yield put({
                        type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }
                continue;
            }
            shouldWaitForAction = true;
            commissionerUpdateData = null;
            yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
        }
    }
};

export default updateCommissioner;
