import {take, put, fork, select} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import errorTypes from '../../application/errorTypes';

const updateStandbyTimesFlow = function* updateEmergencyLawyersStandbyTimesFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForAction = true;
    let chosenModalOption;
    let shouldRetryStandbyTimesUpdate = false;
    let standbyTimesId;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(serviceProviderActionTypes.INITIATE_UPDATE_STANDBY_TIMES_FLOW);
            standbyTimesId = payload.standbyTimesId;

            yield* openModal(modalIds.STANDBY_TIMES_DATA_MODAL, {standbyTimesId: `${standbyTimesId}`});

            chosenModalOption = yield take([
                serviceProviderActionTypes.CONFIRM_UPDATE_STANDBY_TIMES_ENTRY,
                serviceProviderActionTypes.DECLINE_UPDATE_STANDBY_TIMES_ENTRY,
            ]);
        }

        if ((chosenModalOption
            && chosenModalOption.type === serviceProviderActionTypes.CONFIRM_UPDATE_STANDBY_TIMES_ENTRY)
            || shouldRetryStandbyTimesUpdate) {
            const {updatedServiceProviderData, serviceProviderToBeRemoved} = chosenModalOption.payload;
            const {retryActionPersistenceState} = yield select(state => state.application);
            const params = [];

            if (updatedServiceProviderData) {
                params.push({
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO(updatedServiceProviderData.serviceProviderData),
                        partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
                    },
                    acePartnerId: updatedServiceProviderData.serviceProviderId,
                });
            }
            if (serviceProviderToBeRemoved) {
                params.push({
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO(serviceProviderToBeRemoved.serviceProviderData),
                        partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
                    },
                    acePartnerId: serviceProviderToBeRemoved.serviceProviderId,
                });
            }
            yield fork(
                fetchRequest,
                serviceProviderActionTypes.UPDATE_STANDBY_TIMES_REQUEST,
                partnerManagementService.batchUpdateACEPartner,
                {
                    params,
                },
            );

            const responseAction = yield take([
                serviceProviderActionTypes.UPDATE_STANDBY_TIMES_REQUEST_SUCCEEDED,
                serviceProviderActionTypes.UPDATE_STANDBY_TIMES_REQUEST_FAILED,
            ]);

            if (retryActionPersistenceState === persistenceStates.PENDING) {
                yield put({
                    type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }
            yield* closeModal(modalIds.STANDBY_TIMES_DATA_MODAL, {standbyTimesId: `${standbyTimesId}`});


            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {acePartnerDTOs: serviceProviderDTOs} = response;

                yield put({
                    type: serviceProviderActionTypes.STORE_SERVICE_PROVIDERS,
                    payload: {serviceProviderDTOs},
                });
                if (shouldRetryStandbyTimesUpdate) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL,
                        {errorType: errorTypes.STANDBY_TIMES_UPDATE_FAILED});
                    shouldWaitForAction = true;
                    chosenModalOption = null;
                    shouldRetryStandbyTimesUpdate = false;
                    standbyTimesId = null;
                    continue;
                }
            }

            if (responseAction.error) {
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: errorTypes.STANDBY_TIMES_UPDATE_FAILED});

                const nextAction = yield take([
                    serviceProviderActionTypes.RETRY_STANDBY_TIMES_UPDATE,
                    serviceProviderActionTypes.CANCEL_STANDBY_TIMES_UPDATE,
                ]);

                if (nextAction.type === serviceProviderActionTypes.RETRY_STANDBY_TIMES_UPDATE) {
                    shouldWaitForAction = false;
                    shouldRetryStandbyTimesUpdate = true;
                    if (retryActionPersistenceState !== persistenceStates.PENDING) {
                        yield put({
                            type: applicationActionTypes.SET_RETRY_ACTION_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.PENDING},
                        });
                    }
                    continue;
                }

                if (nextAction.type === serviceProviderActionTypes.CANCEL_STANDBY_TIMES_UPDATE) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL,
                        {errorType: errorTypes.STANDBY_TIMES_UPDATE_FAILED});
                    shouldWaitForAction = true;
                    chosenModalOption = null;
                    shouldRetryStandbyTimesUpdate = false;
                    standbyTimesId = null;
                    continue;
                }
            }
        }

        yield* closeModal(modalIds.STANDBY_TIMES_DATA_MODAL, {standbyTimesId: `${standbyTimesId}`});
    }
};

export default updateStandbyTimesFlow;
