/**
 * Command action types
 */
export const INITIATE_INA_RULE_EDIT_FLOW = '[ina-rules] INITIATE_INA_RULE_EDIT_FLOW';
export const CONFIRM_INA_RULE_EDIT = '[ina-rules] CONFIRM_INA_RULE_EDIT';
export const DECLINE_INA_RULE_EDIT = '[ina-rules] DECLINE_INA_RULE_EDIT';
export const RETRY_INA_RULE_EDIT = '[ina-rules] RETRY_INA_RULE_EDIT';
export const CANCEL_INA_RULE_EDIT = '[ina-rules] CANCEL_INA_RULE_EDIT';

/**
 * Event action types
 */
export const FETCH_INA_RULES_REQUEST = '[ina-rules] FETCH_INA_RULES_REQUEST';
export const FETCH_INA_RULES_REQUEST_SENT = '[ina-rules] FETCH_INA_RULES_REQUEST_SENT';
export const FETCH_INA_RULES_REQUEST_SUCCEEDED = '[ina-rules] FETCH_INA_RULES_REQUEST_SUCCEEDED';
export const FETCH_INA_RULES_REQUEST_FAILED = '[ina-rules] FETCH_INA_RULES_REQUEST_FAILED';

export const UPDATE_INA_RULE_REQUEST = '[ina-rules] UPDATE_INA_RULE_REQUEST';
export const UPDATE_INA_RULE_REQUEST_SENT = '[ina-rules] UPDATE_INA_RULE_REQUEST_SENT';
export const UPDATE_INA_RULE_REQUEST_SUCCEEDED = '[ina-rules] UPDATE_INA_RULE_REQUEST_SUCCEEDED';
export const UPDATE_INA_RULE_REQUEST_FAILED = '[ina-rules] UPDATE_INA_RULE_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_INA_RULES = '[ina-rules] STORE_INA_RULES';
