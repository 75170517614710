import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, useHistory, resolveRoute, useRouterState} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Option} from '@ace-de/ui-components';
import {ContentBlock, ContentItem} from '@ace-de/ui-components/app-layout';
import {Tab, TabBar} from '@ace-de/ui-components/navigation';
import routePaths from '../../routePaths';
import keycloakAuth from '../../keycloakAuth';
import dashboardScreenTabs from '../dashboardScreenTabs';
import ACEAdminLogo from './ACEAdminLogo';
import AvatarMenu from './AvatarMenu';
import acAccessControl from '../../acAccessControl';
import {acFeatures, acFeatureActions} from '../../application/acFeatures';

const NavigationBarContent = ({signOutUser, user}) => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate} = useTranslate();
    const {activeRoutePath} = useRouterState();

    const handleOnSelect = value => {
        if (value === 'logout') {
            signOutUser({});
        }
    };

    const navigateToRoute = routePath => {
        if (!routePath) return;

        // other routes
        history.push(resolveRoute(routePath));
    };

    return (
        <ContentBlock
            className={cx([
                'ace-c-content-block',
                'global!ace-u-flex',
                'global!ace-u-flex--align-center',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--large-span-8',
                    'ace-c-content-item--x-large-span-8',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <Link to={resolveRoute(routePaths.DASHBOARD)}>
                    <ACEAdminLogo />
                </Link>
                <TabBar
                    name="dashboardTabs"
                    value={activeRoutePath}
                    onChange={navigateToRoute}
                >
                    <Tab
                        name={dashboardScreenTabs.USER_MANAGEMENT}
                        value={routePaths.USER_MANAGEMENT}
                    >
                        {translate('navigation_bar.tab_name.user_management')}
                    </Tab>
                    {acAccessControl.grantFeatureAccess(acFeatures.SERVICE_PROVIDERS, acFeatureActions.READ) && (
                        <Tab
                            name={dashboardScreenTabs.SERVICE_PROVIDERS}
                            value={routePaths.SERVICE_PROVIDER_SEARCH}
                        >
                            {translate('navigation_bar.tab_name.service_providers')}
                        </Tab>
                    )}
                    {acAccessControl.grantFeatureAccess(acFeatures.COMMISSIONERS, acFeatureActions.READ) && (
                        <Tab
                            name={dashboardScreenTabs.COMMISSIONERS}
                            value={routePaths.COMMISSIONER_SEARCH}
                        >
                            {translate('navigation_bar.tab_name.commissioners')}
                        </Tab>
                    )}
                    {acAccessControl.grantFeatureAccess(acFeatures.CONTRACT_PARTNERS, acFeatureActions.READ) && (
                        <Tab
                            name={dashboardScreenTabs.CONTRACT_PARTNERS}
                            value={routePaths.CONTRACT_PARTNER_SEARCH}
                        >
                            {translate('navigation_bar.tab_name.foreign_contract_partners')}
                        </Tab>
                    )}
                    <Tab
                        name={dashboardScreenTabs.TEXT_MANAGEMENT}
                        value={routePaths.TEXT_MANAGEMENT}
                        className={cx('ace-c-tab--no-wrap')}
                    >
                        {translate('navigation_bar.tab_name.text_management')}
                    </Tab>
                    {acAccessControl.grantFeatureAccess(acFeatures.INA_RULES, acFeatureActions.READ) && (
                        <Tab
                            name={dashboardScreenTabs.INA_RULES}
                            value={routePaths.INA_RULES}
                            className={cx('ace-c-tab--no-wrap')}
                        >
                            {translate('navigation_bar.tab_name.ina_rules')}
                        </Tab>
                    )}
                    {acAccessControl.grantFeatureAccess(acFeatures.BUDGETS, acFeatureActions.READ) && (
                        <Tab
                            name={dashboardScreenTabs.BUDGETS}
                            value={routePaths.BUDGETS}
                            className={cx('ace-c-tab--no-wrap')}
                        >
                            {translate('navigation_bar.tab_name.budgets')}
                        </Tab>
                    )}
                    {acAccessControl.grantFeatureAccess(acFeatures.FEES_AND_ESTIMATION, acFeatureActions.READ) && (
                        <Tab
                            name={dashboardScreenTabs.FEES_AND_ESTIMATION}
                            value={routePaths.FEES_AND_ESTIMATION}
                            className={cx('ace-c-tab--no-wrap')}
                        >
                            {translate('navigation_bar.tab_name.fees_and_estimation')}
                        </Tab>
                    )}
                </TabBar>
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--large-span-3',
                    'ace-c-content-item--x-large-span-3',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                &nbsp;
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                    'global!ace-u-flex--align-center',
                ])}
            >
                {user && (
                    <AvatarMenu
                        userImage=""
                        userName={`${user.initials}`}
                        onSelect={handleOnSelect}
                    >
                        <Option
                            name="avatarOptionLogout"
                            value="logout"
                        >
                            {translate('navigation_bar.avatar_menu_option.log_out')}
                        </Option>
                    </AvatarMenu>
                )}
            </ContentItem>
        </ContentBlock>
    );
};

NavigationBarContent.propTypes = {
    signOutUser: PropTypes.func,
    user: PropTypes.object,
};

NavigationBarContent.defaultProps = {
    signOutUser: () => null,
    user: null,
};

const mapStateToProps = state => ({
    user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
    signOutUser: () => dispatch(keycloakAuth.signOutUserAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBarContent);
