import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {push, resolveRoute} from '@computerrock/formation-router';
import * as budgetActionTypes from '../budgetsActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const initiateCreateTimePeriodFlow = function* initiateCreateTimePeriodFlow() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    while (true) {
        yield take(budgetActionTypes.INITIATE_CREATE_TIME_PERIOD_FLOW);

        yield* openModal(modalIds.CREATE_TIME_PERIOD_MODAL);

        const chosenModalOption = yield take([
            budgetActionTypes.CONFIRM_CREATE_TIME_PERIOD,
            budgetActionTypes.DECLINE_CREATE_TIME_PERIOD,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === budgetActionTypes.CONFIRM_CREATE_TIME_PERIOD
        ) {
            const {payload} = chosenModalOption;
            const {searchQueryParams, copyPrevious, file} = payload;

            yield fork(
                fetchRequest,
                budgetActionTypes.CREATE_TIME_PERIOD_REQUEST,
                pricingManagementService.createBudgetTimePeriod, {
                    searchQueryParams,
                    file: file || '',
                    ...(copyPrevious && {copyPrevious}),
                },
            );
            const responseAction = yield take([
                budgetActionTypes.CREATE_TIME_PERIOD_REQUEST_SUCCEEDED,
                budgetActionTypes.CREATE_TIME_PERIOD_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {budgetTimePeriodDTO} = response;

                yield put({
                    type: budgetActionTypes.STORE_BUDGETS_BY_TIME,
                    payload: {budgetTimePeriodDTO},
                });
                yield* closeModal(modalIds.CREATE_TIME_PERIOD_MODAL);

                // switch tab
                if (budgetTimePeriodDTO) {
                    const {location} = yield select(state => state.router);
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set('validFrom', budgetTimePeriodDTO['validFrom']);
                    searchParams.set('validUntil', budgetTimePeriodDTO['validUntil']);
                    searchParams.set('budgetPeriodId', budgetTimePeriodDTO['id']);
                    yield put(push(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));
                }
                continue;
            }

            if (responseAction.error) {
                const {message} = responseAction.payload;
                let isXLSXFileFormatError = false;
                try {
                    const parsedMessage = JSON.parse(message);
                    isXLSXFileFormatError = parsedMessage?.['exceptionMessage']
                        && parsedMessage['exceptionMessage'].toLowerCase().includes('invalid .xlsx file');
                } catch (e) {
                    // no-op
                }

                yield* closeModal(modalIds.CREATE_TIME_PERIOD_MODAL);
                yield put({
                    type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
                    payload: {errorType: isXLSXFileFormatError
                        ? errorTypes.BUDGET_TIME_PERIOD_XLSX_UPLOAD_FAILED
                        : errorTypes.BUDGET_TIME_PERIOD_CREATE_FAILED},
                });
                continue;
            }
        }

        yield* closeModal(modalIds.CREATE_TIME_PERIOD_MODAL);
    }
};

export default initiateCreateTimePeriodFlow;
