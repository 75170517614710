import React, {Fragment} from 'react';
import {HeaderBar, AppLayoutMain, ContentItem} from '@ace-de/ui-components/app-layout';
import {useStyles} from '@ace-de/ui-components';
import StandbyTimesPanel from './ui-elements/StandbyTimesPanel';
import StandbyTimesHeader from './ui-elements/StandbyTimesHeader';

const StandbyTimesScreen = props => {
    const {cx} = useStyles();

    return (
        <Fragment>
            <HeaderBar className={cx('ace-c-header-bar--primary-navigation')}>
                <StandbyTimesHeader />
            </HeaderBar>
            <AppLayoutMain>
                <ContentItem className={cx('ace-c-content-item--span-9')}>
                    <StandbyTimesPanel />
                </ContentItem>
            </AppLayoutMain>
        </Fragment>
    );
};

export default StandbyTimesScreen;
