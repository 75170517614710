import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Divider} from '@ace-de/ui-components';
import {InteractiveIcon, linkIcon} from '@ace-de/ui-components/icons';
import {resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {europeanCountries} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';

const EmergencyLawyerItem = ({emergencyLawyer, standbyTimeDates}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('emergency_lawyers_panel');
    const serviceProviderId = emergencyLawyer.id;
    const {address, businessContactDetails} = emergencyLawyer;
    const country = address?.country
        ? translate(`global.country.${europeanCountries[address?.country].name.toLowerCase()}`)
        : '';
    const formattedAddress = `${address?.street || ''}, ${address?.postCode || ''}, ${address?.city || ''}, ${country}`;

    const openServiceProviderScreen = serviceProviderId => {
        const {pathname} = resolveRoute(routePaths.SERVICE_PROVIDER, {serviceProviderId});
        window.open(pathname, '_blank');
    };

    return (
        <div className={cx('global!ace-u-margin--bottom-16')}>
            <p
                className={cx([
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-margin--bottom-8',
                ])}
            >
                {standbyTimeDates || ''}
            </p>
            <div
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-margin--bottom-4',
                    ])}
                >
                    <p>{emergencyLawyer.name || ''}</p>
                </div>
                <InteractiveIcon
                    icon={linkIcon}
                    className={cx('ace-c-interactive-icon--primary')}
                    onClick={() => openServiceProviderScreen(serviceProviderId)}
                />
            </div>
            <p
                className={cx([
                    'global!ace-u-typography--variant-body',
                    'global!ace-u-margin--bottom-4',
                ])}
            >
                {formattedAddress || ''}
            </p>
            <p
                className={cx([
                    'global!ace-u-typography--variant-body',
                    'global!ace-u-margin--bottom-16',
                ])}
            >
                {`${translatePanel('emergency_lawyer_item_text.tel')} ${businessContactDetails?.phoneNo ? businessContactDetails?.phoneNo : ''}`}
            </p>
            <Divider />
        </div>
    );
};

EmergencyLawyerItem.propTypes = {
    emergencyLawyer: PropTypes.object,
    standbyTimeDates: PropTypes.string,
};

EmergencyLawyerItem.defaultProps = {
    emergencyLawyer: {},
    standbyTimeDates: '',
};

export default EmergencyLawyerItem;
