import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Form, Input, ButtonPrimary, AutocompleteField, Option, InputField} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon, closeIcon, arrowDownIcon} from '@ace-de/ui-components/icons';
import {europeanCountries} from '@ace-de/eua-entity-types';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import {validateEmail} from '../../utils/validation';

const nonMandatoryFields = ['websiteUrl', 'faxNo'];
const initialErrorFields = {
    name: [],
    contactPerson: [],
    street: [],
    postCode: [],
    city: [],
    country: [],
    phoneNo: [],
    email: [],
};

const ServiceProviderBasicDataPanel = ({serviceProvider, submitServiceProviderForm}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('service_provider_basic_data_panel');
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [serviceProviderCountry, setServiceProviderCountry] = useState(
        serviceProvider?.address?.country
            ? serviceProvider.address.country
            : '',
    );
    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });
    const [errorFields, setErrorFields] = useState({...initialErrorFields});

    useEffect(() => {
        if (serviceProvider?.address?.country) {
            setServiceProviderCountry(serviceProvider.address.country);
        }
    }, [serviceProvider?.address?.country]);

    const handleFormFieldsValidation = formValues => {
        let hasError = false;
        const mandatoryErrorMessage = translatePanel('field_cannot_be_empty');
        const emailErrorMessage = translatePanel('please_enter_valid_email');

        Object.entries(formValues).forEach(formValueEntry => {
            const key = formValueEntry[0];
            const value = formValueEntry[1];

            if (value === '' && !nonMandatoryFields.includes(key)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [mandatoryErrorMessage],
                }));
                hasError = true;
            } else if (key === 'email' && !validateEmail(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [emailErrorMessage],
                }));
                hasError = true;
            } else if (errorFields[key]) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [],
                }));
            }
        });

        return hasError;
    };

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
        if (isEditModeActive) setErrorFields({...initialErrorFields});
    };

    const handleOnSubmit = formValues => {
        const hasError = handleFormFieldsValidation(formValues);
        if (hasError || !formValues) return;
        const {street, city, postCode, phoneNo, email, faxNo, websiteUrl} = formValues;

        const serviceProviderData = {
            ...formValues,
            address: {
                street,
                city,
                postCode,
                country: serviceProviderCountry,
            },
            businessContactDetails: {
                phoneNo,
                email,
                faxNo,
                websiteUrl,
            },
        };

        submitServiceProviderForm({
            serviceProviderData,
            serviceProviderId: serviceProvider.id,
        });

        setIsEditModeActive(false);
    };

    if (!serviceProvider) return null;
    const {address, businessContactDetails} = serviceProvider;

    return (
        <Panel
            title={translatePanel('panel_title.basic_data')}
            actions={!isEditModeActive ? (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={toggleEditMode}
                />
            ) : (
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={toggleEditMode}
                />
            )}
        >
            {!isEditModeActive ? (
                <div className={cx('global!ace-u-two-column-layout')}>
                    <div>
                        <DataRow
                            label={translatePanel('data_row_label.name')}
                            qaIdent="sp-name"
                        >
                            {serviceProvider.name || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.contact_person')}
                            qaIdent="sp-contact-person"
                        >
                            {serviceProvider?.contactPerson || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.street')}
                            qaIdent="sp-street"
                        >
                            {address?.street || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.post_code')}
                            qaIdent="sp-post-code"
                        >
                            {address?.postCode || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.city')}
                            qaIdent="sp-city"
                        >
                            {address?.city || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.country')}
                            qaIdent="sp-country"
                        >
                            {address?.country
                                ? translate(`global.country.${snakeCase(europeanCountries[serviceProvider.address.country].name)}`)
                                : '-'
                            }
                        </DataRow>
                    </div>
                    <div>
                        <DataRow
                            label={translatePanel('data_row_label.phone')}
                            qaIdent="sp-phone"
                        >
                            {businessContactDetails?.phoneNo || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.fax')}
                            qaIdent="sp-fax"
                        >
                            {businessContactDetails?.faxNo || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.email')}
                            qaIdent="sp-email"
                        >
                            {businessContactDetails?.email || '-'}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.website')}
                            qaIdent="sp-website"
                        >
                            {businessContactDetails?.websiteUrl || '-'}
                        </DataRow>
                    </div>
                </div>
            ) : (
                <Form name="updateServiceProviderData" onSubmit={handleOnSubmit}>
                    {formValues => {
                        const isMandatoryFieldEmpty = !!(Object.entries(formValues)
                            .find(([fieldName, value]) => {
                                return fieldName !== 'submitSPData'
                                    && !nonMandatoryFields.includes(fieldName)
                                    && !value;
                            }));
                        return (
                            <Fragment>
                                <div className={cx('global!ace-u-two-column-layout')}>
                                    <div>
                                        <DataRow
                                            label={translatePanel('data_row_label.name')}
                                            qaIdent="sp-name"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="name"
                                                value={serviceProvider.name || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.name}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.contact_person')}
                                            qaIdent="sp-contact-person"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="contactPerson"
                                                value={serviceProvider?.contactPerson || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.contactPerson}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.street')}
                                            qaIdent="sp-street"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="street"
                                                value={address?.street || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.street}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.post_code')}
                                            qaIdent="sp-post-code"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="postCode"
                                                value={address?.postCode || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.postCode}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.city')}
                                            qaIdent="sp-city"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="city"
                                                value={address?.city || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.city}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.country')}
                                            qaIdent="sp-country"
                                            isFieldRequired={true}
                                        >
                                            <AutocompleteField
                                                name="country"
                                                value={serviceProviderCountry || ''}
                                                className={cx(['ace-c-select--small', 'global!ace-u-full-width'])}
                                                onOptionSelect={value => setServiceProviderCountry(value)}
                                                icon={arrowDownIcon}
                                                errors={errorFields.country}
                                            >
                                                {sortedCountries
                                                    .map(([countryCode, country]) => {
                                                        return (
                                                            <Option
                                                                key={country.id}
                                                                name={`country-${country.id}`}
                                                                value={countryCode}
                                                                className={cx('ace-c-option--small')}
                                                            >
                                                                {country.name}
                                                            </Option>
                                                        );
                                                    })
                                                }
                                            </AutocompleteField>
                                        </DataRow>
                                    </div>
                                    <div>
                                        <DataRow
                                            label={translatePanel('data_row_label.phone')}
                                            qaIdent="sp-phone"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="phoneNo"
                                                value={businessContactDetails?.phoneNo || ''}
                                                className={cx('ace-c-input--small')}
                                                errors={errorFields.phoneNo}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.fax')}
                                            qaIdent="sp-fax"
                                        >
                                            <Input
                                                name="faxNo"
                                                value={businessContactDetails?.faxNo || ''}
                                                className={cx('ace-c-input--small')}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.email')}
                                            qaIdent="sp-email"
                                            isFieldRequired={true}
                                        >
                                            <InputField
                                                name="email"
                                                value={businessContactDetails?.email || ''}
                                                className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                                errors={errorFields.email}
                                            />
                                        </DataRow>
                                        <DataRow
                                            label={translatePanel('data_row_label.website')}
                                            qaIdent="sp-website"
                                        >
                                            <Input
                                                name="websiteUrl"
                                                value={businessContactDetails?.websiteUrl || ''}
                                                className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                                            />
                                        </DataRow>
                                    </div>
                                </div>
                                <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-flex-end'])}>
                                    <ButtonPrimary
                                        name="submitSPData"
                                        type="submit"
                                        isDisabled={isMandatoryFieldEmpty}
                                    >
                                        {translatePanel('button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </Fragment>
                        );
                    }}
                </Form>
            )}
        </Panel>
    );
};

ServiceProviderBasicDataPanel.propTypes = {
    serviceProvider: PropTypes.object,
    submitServiceProviderForm: PropTypes.func.isRequired,
};

ServiceProviderBasicDataPanel.defaultProps = {
    serviceProvider: null,
};

const mapDispatchToProps = dispatch => ({
    submitServiceProviderForm: payload => dispatch({
        type: serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(ServiceProviderBasicDataPanel);
