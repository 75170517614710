import {put, take, fork, select} from 'redux-saga/effects';
import {matchPath, replace, END_SIDE_EFFECTS_RUNNING} from '@computerrock/formation-router';
import {clientTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';
import * as selectors from '../userProfileSelectors';
import routePaths from '../../routePaths';
import acAccessControl from '../../acAccessControl';

const loadUserProfile = function* loadUserProfile() {
    const {location} = yield select(state => state.router);
    const userProfile = yield select(selectors.getUser);

    const userManagementScreenMatch = matchPath(location.pathname, {
        path: routePaths.USER_MANAGEMENT,
        exact: true,
    });

    if (userProfile && !userManagementScreenMatch) return;

    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    yield fork(fetchRequest, userProfileActionTypes.FETCH_USER_PROFILE_REQUEST, userProfileService.getUserProfile);
    const fetchUserResultAction = yield take([
        userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_SUCCEEDED,
        userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_FAILED,
    ]);

    if (fetchUserResultAction.type === userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_FAILED) {
        yield put(replace(routePaths.SYSTEM_ACCESS_DENIED));
        return END_SIDE_EFFECTS_RUNNING;
    }

    const {response: fetchUserResponse} = fetchUserResultAction.payload;
    const {userDTO} = fetchUserResponse;
    const systemRoleSubset = userDTO.userRoles?.find(userRoleSubset => userRoleSubset.client === clientTypes.AC_CLIENT);
    acAccessControl.setActiveUser({
        ...userDTO,
        userRoles: systemRoleSubset?.userRoles || [],
    });
    yield put({
        type: userProfileActionTypes.STORE_USER_PROFILE,
        payload: {userDTO},
    });
};

export default loadUserProfile;
